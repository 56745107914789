import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
} from 'shared/components';
import './styles.scss';

const WelcomeScreen = ({
  openSelectedTab,
}) => (
  <div className="WelcomeScreen">
    <h1>Welcome to the Designatted Hitter</h1>
    <p>To start, please select your team or add a new team:</p>
    <div className="actions-container">
      <Button
        onClick={() => openSelectedTab(1)}
      >
        Search for a team
      </Button>
      <Button
        onClick={() => openSelectedTab(2)}
      >
        Add a team
      </Button>
    </div>
  </div>
);

WelcomeScreen.propTypes = {
  openSelectedTab: PropTypes.func.isRequired,
};

export default WelcomeScreen;
