import React from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import {
  GameResultsTable,
} from 'shared/components';
import './styles.scss';

const GameHeader = ({
  game,
}) => (
  <div className="GameHeader">
    <div className="GameHeader-scores">
      <div className="score-row scores-away">
        <div className="team-score">
          {get(game, 'r_away') || 0}
        </div>
        <div className="team-name">
          {get(game, 'team_away.name') || 'TBD'}
        </div>
      </div>
      <div className="score-row scores-home">
        <div className="team-score">
          {get(game, 'r_home') || 0}
        </div>
        <div className="team-name">
          {get(game, 'team_home.name') || 'TBD'}
        </div>
      </div>
    </div>
    <div className="GameHeader-table">
      <GameResultsTable game={game} />
    </div>
  </div>
);

GameHeader.propTypes = {
  game: PropTypes.object.isRequired,
};

export default GameHeader;
