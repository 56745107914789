import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import get from "lodash.get";
import { withRouter } from "react-router-dom";
import { SearchInput, Table } from "shared/components";
import { displayValue } from "shared/helpers";
import { searchLeagues } from "./actions";
import "./styles.scss";

const SearchLeagues = ({ history }) => {
  const isMounted = React.useRef(true);
  const [loading, setLoading] = useState(true);
  const [leagues, setLeagues] = useState([]);
  const [query, setQuery] = useState("");

  const initSearchLeagues = () => {
    searchLeagues(query)
      .then((res) => {
        if (isMounted.current) {
          setLeagues(get(res, "data.results"));
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    initSearchLeagues();

    return () => {
      isMounted.current = false;
    };
  }, []);

  const redirectToLeaguePage = (rowData) => {
    const leagueId = get(rowData, "original.id");
    history.push(`/leagues/${leagueId}`);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    initSearchLeagues();
  };

  return (
    <div className="SearchLeagues">
      <div className="search-input-container">
        <SearchInput
          id="SearchLeagues-input"
          placeholder="Search for a league by name"
          handleChange={(val) => setQuery(val)}
          handleClearBtn={() => setQuery("")}
          onSearch={handleSearchSubmit}
          value={query}
          disabled={loading}
        />
      </div>
      <div className="results-container">
        <Table
          data={leagues}
          noDataText=""
          loading={loading}
          loadingText="Searching for a league"
          columns={[
            {
              accessor: "name",
              Header: "Name",
              width: 250,
              Cell: (cellData) => displayValue(cellData.value),
            },
            {
              accessor: "seasons",
              Header: "Current season",
              Cell: (cellData) => {
                const currentSeason = cellData.value.find((s) => s.is_current);
                if (!cellData.value.length || !currentSeason) {
                  return "-";
                }

                return `${currentSeason.year}`;
              },
            },
            {
              accessor: "num_of_teams",
              Header: "Number of teams",
              Cell: (cellData) => displayValue(cellData.value),
            },
            {
              accessor: "num_of_players",
              Header: "Number of players",
              Cell: (cellData) => displayValue(cellData.value),
            },
          ]}
          clickable
          handleClick={(rowData) => redirectToLeaguePage(rowData)}
          minRows={1}
          showPagination={leagues.length > 10}
        />
      </div>
    </div>
  );
};

SearchLeagues.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(SearchLeagues);
