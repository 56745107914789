/* eslint-disable */
import React from "react";
import { Button } from "shared/components";
import { DescriptionList } from "shared/components";
import { useState, useEffect } from "react";
import { getStat } from "./actions";
import get from "lodash.get";
import { displayValue } from "shared/helpers";
import { useParams } from "react-router-dom";
import { NavLink } from "react-router-dom";
import UpdateStat from "./components/UpdateStat/UpdateStat";
function Stat({}) {
  const [loading, setLoading] = useState(true);

  const [refetching, setRefetching] = useState(false);

  const [results, setResults] = useState([]);
  const [modalCreateEvent, setModalCreateEvent] = useState();

  const { statId } = useParams();

  const fetchEvents = () => {
    console.log(statId);
    getStat(statId)
      .then((res) => {
        const data = get(res, "data") || [];
        console.log("stat", data);
        setLoading(false);
        setRefetching(false);
        setResults(data);
      })
      .catch(() => {
        setLoading(false);
        setRefetching(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    fetchEvents();
  }, [refetching]);

  const handleEventUpdateClick = () => {
    return setModalCreateEvent(true);
  };
  return (
    <div className="LeagueTeamsList">
      <div className="PlayerContainer-breadcrumb">
        <NavLink to={`/events/stats/`}>
          <span>&#8249;</span>
          back to events list
        </NavLink>
      </div>
      <Button onClick={handleEventUpdateClick} disabled={false} size="sm">
        Update stat
      </Button>
      <div className="teams-container">
        <DescriptionList
          loading={loading}
          data={[
            { label: "Code", value: displayValue(results?.code) },
            { label: "Description", value: displayValue(results?.description) },
            {
              label: "Number of measurments",
              value: displayValue(results?.number_of_measurments),
            },
          ]}
        />
      </div>
      {modalCreateEvent && (
        <UpdateStat
          setRefetching={setRefetching}
          isOpen={modalCreateEvent}
          close={() => setModalCreateEvent(false)}
          event={results}
        />
      )}
    </div>
  );
}

export default Stat;
