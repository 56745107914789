/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Modal } from "shared/components";
import "./styles.scss";
import { Label, TextInput, Button, NumberInput } from "shared/components";
import DatePicker from "react-date-picker";
import { createEventStat } from "./actions";
import getCookie from "shared/helpers/getCookie";
function AddEventStat({ isOpen, close, setRefetching }) {
  const [dirty, setDirty] = useState(false);

  const handleClose = () => {
    if (
      !dirty ||
      window.confirm("Are you sure you want to discard your changes?")
    ) {
      close();
    }
  };
  const [statCode, setStatCode] = useState("");
  const [statDesc, setStatDesc] = useState("");
  const [measurments, setMeasurments] = useState(1);

  const [organization, setOrganization] = useState(getCookie("Organization"));

  const handleSubmit = () => {
    let data = {
      code: statCode,
      description: statDesc,
      organization: organization,
      number_of_measurments: measurments,
    };

    createEventStat(data).then((res) => {
      console.log(data);
      setRefetching(true);
      close();
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      closeCb={handleClose}
      title="Create Statistic"
      size="sm"
    >
      <div className="form-row">
        <Label inputId="event-name" text="Statistic Code" />
        <TextInput
          id="event-name"
          value={statCode}
          placeHolder="Statistic Code"
          handleChange={(val) => {
            setDirty(true);
            setStatCode(val);
          }}
        />
      </div>
      <div className="form-row">
        <Label inputId="event-division" text="Description" />
        <TextInput
          id="event-name"
          value={statDesc}
          placeHolder="Description"
          handleChange={(val) => {
            setDirty(true);
            setStatDesc(val);
          }}
        />
      </div>
      <div className="form-row">
        <Label inputId="event-division" text="Description" />
        <NumberInput
          id="event-name"
          value={measurments}
          placeHolder="Description"
          handleChange={(val) => {
            setDirty(true);
            setMeasurments(val);
          }}
        />
      </div>

      <div className="form-submit">
        <Button
          theme="link"
          type="button"
          disabled={false}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          theme="success"
          type="submit"
          disabled={false}
          onClick={handleSubmit}
        >
          Save edits
        </Button>
      </div>
    </Modal>
  );
}

export default AddEventStat;
