import React, { useState } from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  Notification,
} from 'shared/components';
import api from 'shared/api';
import './styles.scss';

const DeletePlayerParentPopup = ({
  close,
  isOpen,
  player,
  parents,
  parentIndex,
  fetchPlayerData,
}) => {
  const playerId = get(player, 'id');
  const parentToDelete = JSON.parse(get(parents, `[${parentIndex}]`) || {});
  const [loading, setLoading] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();

    setLoading(true);

    const parentsData = parents.map((p, i) => {
      if (i !== parentIndex) {
        return p;
      }

      return undefined;
    });

    const data = {
      parents: parentsData.filter(Boolean),
    };

    api.patch(`/api/v1/players/${playerId}/`, data)
      .then(() => {
        fetchPlayerData();
        Notification('success', 'Players parent info sucesfully removed');
        setLoading(false);
        close();
      })
      .catch(() => {
        setLoading(false);
        Notification(
          'error',
          'Error occured',
          'There was an error while removing the parent info from a players',
        );
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      closeCb={close}
      title="Are you sure you want to delete this parent from a player?"
      size="md"
    >
      <div className="DeletePlayerParentPopup">
        <form
          className="DeletePlayerParentPopup-form"
          onSubmit={handleSubmit}
        >
          <span>
            {`${get(parentToDelete, 'name')}`}
          </span>
          <div className="form-submit">
            <Button
              theme="link"
              type="button"
              disabled={loading}
              onClick={close}
            >
              Cancel
            </Button>
            <Button
              theme="error"
              type="submit"
              disabled={loading}
            >
              Delete
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

DeletePlayerParentPopup.propTypes = {
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  fetchPlayerData: PropTypes.func.isRequired,
  player: PropTypes.object.isRequired,
  parents: PropTypes.array.isRequired,
  parentIndex: PropTypes.number.isRequired,
};

export default DeletePlayerParentPopup;
