export const mockStandard = {
  data: [
    {
      date: new Date(),
      against: 'TBD',
      type: 'League',
      pa: 4,
      ab: 1,
      h: 0,
      '1b': 1,
      '2b': 2,
      '3b': 2,
      hr: 0,
      rbi: 0,
      r: 2,
      hpb: 1,
      roe: 2,
      fc: 1,
      ci: 0,
      bb: 0,
      so: 1,
      avg: 0.000,
      obp: 0.500,
      slg: 0.000,
      ops: 0.500,
    },
  ],
  total: {
    pa: 4,
    ab: 1,
    h: 0,
    '1b': 1,
    '2b': 2,
    '3b': 2,
    hr: 0,
    rbi: 0,
    r: 2,
    hpb: 1,
    roe: 2,
    fc: 1,
    ci: 0,
    bb: 0,
    so: 1,
    avg: 0.000,
    obp: 0.500,
    slg: 0.000,
    ops: 0.500,
  },
};

export const mockPatience = {
  data: [
    {
      date: new Date(),
      against: 'TBD',
      type: 'League',
      pa: 4,
      ab: 2,
      pabb: 1.750,
      bbk: 1.750,
      cperc: 0.500,
      kl: 0,
      sb: 0,
      cs: 1,
      sbperc: 0.500,
      pik: 0,
      gidp: 0,
      gitp: 0,
      xbh: 1,
      tb: 0,
      abhr: 0.000,
      barisp: 0.000,
      slg: 0.00,
    },
  ],
  total: {
    pa: 4,
    ab: 2,
    pabb: 1.750,
    bbk: 1.750,
    cperc: 0.500,
    kl: 0,
    sb: 0,
    cs: 1,
    sbperc: 0.500,
    pik: 0,
    gidp: 0,
    gitp: 0,
    xbh: 1,
    tb: 0,
    abhr: 0.000,
    barisp: 0.000,
    slg: 0.00,
  },
};

export const mockQab = {
  data: [
    {
      date: new Date(),
      against: 'TBD',
      type: 'League',
      pa: 4,
      ab: 2,
      ps: 17,
      pspa: 3.750,
      '2s3': 1,
      '2s3perc': 0.250,
      '6plus': 1,
      '6plusperc': 0.250,
      flbperc: '100.00%',
      gbperc: '0.00%',
      sac: 0,
      sf: 0,
      lob: 2,
      '2outrbi': 0,
      hhb: 0,
      xbh: 1,
      qab: 3,
      qabperc: '75.00%',
      babip: 0.000,
    },
  ],
  total: {
    pa: 4,
    ab: 2,
    ps: 17,
    pspa: 3.750,
    '2s3': 1,
    '2s3perc': 0.250,
    '6plus': 1,
    '6plusperc': 0.250,
    flbperc: '100.00%',
    gbperc: '0.00%',
    sac: 0,
    sf: 0,
    lob: 2,
    '2outrbi': 0,
    hhb: 0,
    xbh: 1,
    qab: 3,
    qabperc: '75.00%',
    babip: 0.000,
  },
};
