import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import {
  // NavLink,
  Switch,
  Route,
} from 'react-router-dom';
import {
  NotAuthorized,
  NotFound,
} from 'shared/components';
import {
  isAdmin,
} from 'shared/helpers';
import {
  TeamStatsBasic,
  // TeamStatsBatting,
  // TeamStatsPitching,
  // TeamStatsFielding,
  // TeamStatsQab,
} from './components';
import './styles.scss';

const TeamStats = ({
  team,
}) => {
  const teamId = get(team, 'id');
  const isAuth = isAdmin(teamId);

  if (!isAuth) {
    return <NotAuthorized message="Only team admins can see seasons stats" />;
  }

  return (
    <div className="TeamStats">
      {/* <div className="TeamStats-menu">
        <NavLink exact to={`/${teamId}/stats`}>Batting</NavLink>
        <NavLink exact to={`/${teamId}/stats/pitching`}>Pitching</NavLink>
        <NavLink exact to={`/${teamId}/stats/fielding`}>Fielding</NavLink>
        <NavLink exact to={`/${teamId}/stats/qab`}>Quality At-Bat Differential</NavLink>
      </div> */}
      <Switch>
        <Route
          exact
          path="/:teamId/stats"
          render={() => <TeamStatsBasic teamId={teamId} />}
        />
        {/* <Route
          exact
          path="/:teamId/stats"
          render={() => <TeamStatsBatting />}
        /> */}
        {/* <Route
          exact
          path="/:teamId/stats/pitching"
          render={() => <TeamStatsPitching />}
        />
        <Route
          exact
          path="/:teamId/stats/fielding"
          render={() => <TeamStatsFielding />}
        />
        <Route
          exact
          path="/:teamId/stats/qab"
          render={() => <TeamStatsQab />}
        /> */}
        <Route
          render={() => <NotFound />}
        />
      </Switch>
    </div>
  );
};

TeamStats.propTypes = {
  team: PropTypes.object.isRequired,
};

export default TeamStats;
