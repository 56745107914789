import React, { useState } from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  Notification,
} from 'shared/components';
import { unfollowPlayer } from './actions';
import './styles.scss';

const UnfollowPlayerPopup = ({
  close,
  isOpen,
  getPlayers,
  player,
}) => {
  const userPlayerId = get(player, 'id');
  const playerName = `${get(player, 'player.first_name')} ${get(player, 'player.last_name')}`;

  const [loading, setLoading] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();

    setLoading(true);

    unfollowPlayer(userPlayerId)
      .then(() => {
        getPlayers();
        Notification('success', 'Player succesfully unfollowed');
        close();
      })
      .catch(() => {
        setLoading(false);
        Notification('error', 'Error occured', 'There was an error while unfollowing a player');
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      closeCb={close}
      title="Are you sure you want to unfollow this player?"
      size="md"
    >
      <div className="UnfollowPlayerPopup">
        <form
          className="UnfollowPlayerPopup-form"
          onSubmit={handleSubmit}
        >
          <span>
            {playerName}
          </span>
          <div className="form-submit">
            <Button
              theme="link"
              type="button"
              disabled={loading}
              onClick={close}
            >
              Cancel
            </Button>
            <Button
              theme="error"
              type="submit"
              disabled={loading}
            >
              Unfollow
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

UnfollowPlayerPopup.propTypes = {
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  getPlayers: PropTypes.func.isRequired,
  player: PropTypes.object.isRequired,
};

export default UnfollowPlayerPopup;
