import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  IconInvisible,
  IconVisible,
  InputErrorMessage,
  LoadingIndicator,
} from 'shared/components';
import './styles.scss';

const PasswordInput = ({
  disabled,
  error,
  handleChange,
  loading,
  ...rest
}) => {
  const [passVisible, setPasswordDisplay] = useState(false);

  const handleInputChange = e => {
    handleChange(e.target.value);
  };

  return (
    <div className="PasswordInput">
      <input
        className={error ? 'has-error' : ''}
        type={passVisible ? 'text' : 'password'}
        disabled={disabled}
        onChange={handleInputChange}
        {...rest}
      />
      {
        loading && <LoadingIndicator />
      }
      <button
        tabIndex={-1}
        type="button"
        onClick={() => setPasswordDisplay(!passVisible)}
      >
        {!passVisible && (
          <IconVisible
            color="#777"
            viewBox="64 64 896 896"
            focusable="false"
            width="16px"
            height="16px"
            aria-hidden="true"
          />
        )}
        {passVisible && (
          <IconInvisible
            color="#777"
            viewBox="64 64 896 896"
            focusable="false"
            width="16px"
            height="16px"
            aria-hidden="true"
          />
        )}
      </button>
      <InputErrorMessage text={error} />
    </div>
  );
};

PasswordInput.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

PasswordInput.defaultProps = {
  disabled: false,
  error: '',
  loading: false,
};

export default PasswordInput;
