import React, { useEffect, useState } from "react";
import "./styles.scss";
import { TextInput } from "shared/components";
import {
  createEventConnection,
  checkEventConnection,
  existingStats,
  createStat,
  updateStat,
} from "./actions";
import { ContentLoader } from "shared/components";
import { raw } from "file-loader";

function PlayerStat({ player, stat, eventId, testEventId, index }) {
  //   console.log("Player: ", player.id, "Event: ", eventId);
  //   console.log("Stat: ", stat?.id);
  //   console.log("Player: ", player?.id);

  const [playerTestStat, setPlayerTestStat] = useState(null);
  const [numOfFields, setNumOfFields] = useState(null);
  const [stats, setStats] = useState([]);
  const [loading, setLoading] = useState([]);

  const length = stat?.number_of_measurments;

  useEffect(() => {
    setLoading(true);
    checkEventConnection(testEventId, player.id).then((res) => {
      //   console.log("Check: ", res.data);
      if (res.data.count == 0) {
        let data = {
          category: "Some category",
          player: player.id,
          testing_event: testEventId,
        };
        createEventConnection(data).then((res) => {
          console.log("Created connection: ", res.data);
          setPlayerTestStat(res.data.id);
          setLoading(false);
        });
      } else {
        setPlayerTestStat(res.data.results[0].id);
        // console.log("Def id: ", res.data.results[0].id);
        setLoading(false);
      }
    });
  }, [stat]);

  useEffect(() => {
    let rawNum = [];
    for (let i = 0; i < length; i++) {
      rawNum.push({});
    }
    setNumOfFields(rawNum);
  }, [stat]);

  useEffect(() => {
    if (player && stat && testEventId) {
      setLoading(true);
      existingStats(player.id, stat.id, testEventId).then((res) => {
        console.log("Existing stats: ", res.data);
        let rawStats = [];
        res.data.results.forEach((e) => {
          e.loading = false;
          rawStats.push(e);
        });
        console.log("Raw stats:", rawStats);
        setStats(res.data.results);
        setLoading(false);
      });
    }
  }, [player, stat, testEventId]);

  useEffect(() => {}, [stats]);

  return (
    <div className="stat-row">
      <div className="stat-cell-player">
        {player.first_name + " " + player.last_name}
      </div>
      <div className="stat-input-holder">
        {numOfFields &&
          numOfFields.map((e, i) => {
            return (
              <div
                className="stat-cell"
                onKeyDown={(e) => {
                  if (e.key == "Enter") {
                    let next = document.getElementById(
                      `statInput-${i + index + 1}`
                    );
                    let current = document.getElementById(
                      `statInput-${i + index}`
                    );

                    if (next) next.focus();

                    if (stats[i]?.value) {
                      if (stats[i]?.id) {
                        /// Loading indicator
                        setStats((s) => {
                          s[i] = { ...s[i], loading: true };
                          return [...s];
                        });
                        /// Saving update
                        updateStat(stats[i]?.id, stats[i]?.value)
                          .then(() => {
                            setStats((s) => {
                              s[i] = { ...s[i], loading: false };
                              return [...s];
                            });
                            current.style.outline = "none";
                          })
                          .catch((err) => {
                            current.style.outline = "2px solid red";
                            setStats((s) => {
                              s[i] = {
                                ...s[i],
                                loading: false,
                                value: "Wrong input",
                              };
                              return [...s];
                            });
                          });
                      } else {
                        /// Loading indicator
                        setStats((s) => {
                          s[i] = { ...s[i], loading: true };
                          return [...s];
                        });
                        /// Saving new value
                        createStat(stat?.id, playerTestStat, stats[i]?.value)
                          .then(() => {
                            current.style.outline = "none";

                            setStats((s) => {
                              s[i] = { ...s[i], loading: false };
                              return [...s];
                            });
                          })
                          .catch((err) => {
                            current.style.outline = "2px solid red";
                            setStats((s) => {
                              s[i] = {
                                ...s[i],
                                loading: false,
                                value: "Wrong input",
                              };
                              return [...s];
                            });
                          });
                      }
                    }
                  }
                }}
              >
                <TextInput
                  value={stats[i]?.value ? stats[i]?.value : null}
                  key={i}
                  id={`statInput-${i + index}`}
                  handleChange={(val) => {
                    let current = document.getElementById(
                      `statInput-${i + index}`
                    );
                    current.style.outline = "2px solid orange";
                    console.log(val);
                    stats[i]?.value
                      ? setStats((s) => {
                          s[i].value = val;
                          return [...s];
                        })
                      : setStats((s) => {
                          s[i] = { value: val };
                          return [...s];
                        });
                  }}
                  loading={loading || stats[i]?.loading}
                  disabled={stats[i]?.loading}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default PlayerStat;
