/* eslint-disable */
import React from "react";
import { Button } from "shared/components";
import { Table } from "shared/components";
import { useState, useEffect } from "react";
import { getEvents } from "./actions";
import get from "lodash.get";
import { displayValue } from "shared/helpers";
import { useHistory } from "react-router-dom";
import AddEvent from "./components/AddEvent/AddEvent";

function EventsList({ organizationId }) {
  const [loading, setLoading] = useState(true);
  console.log(organizationId);
  const [refetching, setRefetching] = useState(false);
  const [modalCreateEvent, setModalCreateEvent] = useState(false);

  const [results, setResults] = useState([]);
  const history = useHistory();

  const redirectToEventPage = (rowData) => {
    const eventId = get(rowData, "original.id");
    history.push(`/events/${eventId}/`);
  };

  const fetchEvents = () => {
    getEvents(organizationId)
      .then((res) => {
        const data = get(res, "data") || [];
        console.log(data);
        setLoading(false);
        setResults(data.results);
        setRefetching(false);
      })
      .catch(() => {
        setLoading(false);
        setRefetching(false);
      });
  };

  const handleEventCreateClick = () => {
    return setModalCreateEvent(true);
  };

  useEffect(() => {
    setLoading(true);
    fetchEvents();
  }, [refetching]);

  return (
    <div className="LeagueTeamsList">
      <Button onClick={handleEventCreateClick} disabled={false} size="sm">
        Create event
      </Button>
      <div className="teams-container">
        <Table
          data={results}
          noDataText=""
          loading={loading || refetching}
          columns={[
            {
              accessor: "name",
              Header: "Name",
              width: 250,
            },
            {
              accessor: "start_date",
              Header: "Start date",
              Cell: (cellData) => displayValue(cellData.value),
            },
            {
              accessor: "end_date",
              Header: "End date",
              Cell: (cellData) => displayValue(cellData.value),
            },
            {
              accessor: "type",
              Header: "Type",
              Cell: (cellData) => displayValue(cellData.value),
            },
          ]}
          clickable
          handleClick={(rowData) => redirectToEventPage(rowData)}
          minRows={1}
          showPagination={false}
        />
      </div>
      {modalCreateEvent && (
        <AddEvent
          setRefetching={setRefetching}
          isOpen={modalCreateEvent}
          close={() => setModalCreateEvent(false)}
        />
      )}
    </div>
  );
}

export default EventsList;
