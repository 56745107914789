/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash.get';
import {
  Selector,
  Tab,
  Tabs,
  TabContent,
  TabsHeader,
  Table,
} from 'shared/components';
import {
  displayValue,
  formatDate,
  mapDivisionsToSelector,
} from 'shared/helpers';
import {
  defaultDateFormat,
} from 'shared/constants';
import { setActiveDivision } from 'store/activeLeague/actions';
import {
  getMaxPitchData,
  getRestDaysData,
} from './actions';
import ReportDelivery from './components/ReportDelivery';
import './styles.scss';

const LeagueReport = ({
  activeDivision,
  activeSeason,
  divisions,
  leagueId,
  setActiveDivisionAction,
}) => {
  const seasonId = get(activeSeason, 'id');
  const [loadingMaxPitch, setLoadingMaxPitch] = useState(false);
  const [loadingRestDays, setLoadingRestDays] = useState(false);
  const [maxPitchData, setMaxPitchData] = useState([]);
  const [restDaysData, setRestDaysData] = useState([]);

  const fetchData = async () => {
    setLoadingMaxPitch(true);
    setLoadingRestDays(true);

    const maxPitch = await getMaxPitchData(seasonId);
    setMaxPitchData(get(maxPitch, 'data') || []);
    setLoadingMaxPitch(false);

    const restDays = await getRestDaysData(seasonId);
    setRestDaysData(get(restDays, 'data') || []);
    setLoadingRestDays(false);
  };

  useEffect(() => {
    fetchData();
  }, [seasonId]);

  const handleTabChange = index => {
    if (index === 1 && restDaysData.length === 0) {
      console.log('fetch rest_days_needed data');
    }
  };

  const divisionsForSelector = mapDivisionsToSelector(divisions);

  const maxPitchFilteredData = maxPitchData.filter(p => {
    if (activeDivision === 'all') { return true; }
    return p.team.division === activeDivision;
  });

  const getRestDayCellClassName = restDay => {
    switch (restDay) {
      case 1:
        return 'rest-day-1';
      case 2:
        return 'rest-day-2';
      case 3:
        return 'rest-day-3';
      case 4:
        return 'rest-day-4';
      default:
        return '';
    }
  };

  return (
    <div className="LeagueReport">
      <div className="LeagueReport-filters">
        <Selector
          options={divisionsForSelector}
          value={activeDivision}
          handleChange={val => setActiveDivisionAction(val)}
          disabled={loadingMaxPitch || loadingRestDays}
        />
      </div>
      <div className="LeagueReport-tabs">
        <Tabs onSelect={handleTabChange}>
          <TabsHeader>
            <Tab>Max pitch count</Tab>
            <Tab>Rest days needed</Tab>
            <Tab>Report subscribers</Tab>
          </TabsHeader>
          <TabContent>
            <Table
              data={maxPitchFilteredData}
              noDataText=""
              NoDataComponent={() => null}
              minRows={1}
              showPagination={false}
              loading={loadingMaxPitch}
              defaultSorted={[
                {
                  desc: true,
                  id: 'pitches',
                },
                {
                  desc: true,
                  id: 'last_name',
                },
              ]}
              columns={[
                {
                  accessor: 'last_name',
                  Header: 'Player',
                  Cell: cellData => {
                    const firstName = get(cellData, 'original.first_name');
                    const lastName = get(cellData, 'original.last_name');
                    if (!firstName && !lastName) { return '-'; }
                    const name = `${firstName} ${lastName}`;
                    return displayValue(name);
                  },
                },
                {
                  accessor: 'team.name',
                  Header: 'Team',
                  Cell: cellData => displayValue(cellData.value),
                },
                {
                  accessor: 'pitches',
                  Header: 'Pitch count',
                  Cell: cellData => displayValue(cellData.value),
                },
                {
                  accessor: 'daily_max',
                  Header: 'Pitch count limit',
                  Cell: cellData => displayValue(cellData.value),
                },
                {
                  accessor: 'date',
                  Header: 'Date',
                  Cell: cellData => formatDate(cellData.value, defaultDateFormat),
                },
                {
                  accessor: 'last_game_on_date.rival_team_name',
                  Header: 'Game (vs)',
                  Cell: cellData => displayValue(cellData.value),
                },
              ]}
            />
          </TabContent>
          <TabContent>
            <Table
              data={restDaysData}
              noDataText=""
              NoDataComponent={() => null}
              minRows={1}
              showPagination={false}
              loading={loadingRestDays}
              defaultSorted={[
                {
                  desc: true,
                  id: 'rest_days_left',
                },
                {
                  desc: true,
                  id: 'last_name',
                },
              ]}
              columns={[
                {
                  accessor: 'last_name',
                  Header: 'Player',
                  Cell: cellData => {
                    const firstName = get(cellData, 'original.first_name');
                    const lastName = get(cellData, 'original.last_name');
                    if (!firstName && !lastName) { return '-'; }
                    const name = `${firstName} ${lastName}`;
                    return displayValue(name);
                  },
                },
                {
                  accessor: 'team.name',
                  Header: 'Team',
                  Cell: cellData => displayValue(cellData.value),
                },
                {
                  accessor: 'last_game_on_date.rival_team_name',
                  Header: 'Last games (vs)',
                  Cell: cellData => displayValue(cellData.value),
                },
                {
                  accessor: 'date',
                  Header: 'Last game date',
                  Cell: cellData => formatDate(cellData.value, defaultDateFormat),
                },
                {
                  accessor: 'reasons',
                  Header: 'Reasons',
                  Cell: cellData => {
                    const reasons = cellData.value.map(r => r.description);
                    return (
                      <>
                        {reasons.map(r => <div>{r}</div>)}
                      </>
                    );
                  },
                  minWidth: 150,
                },
                {
                  accessor: 'rest_days_left',
                  Header: 'Rest days needed',
                  className: 'rest-day-cell',
                  Cell: cellData => {
                    const val = get(cellData, 'value');
                    const className = getRestDayCellClassName(val);
                    return (
                      <div className={`rest-day ${className}`}>
                        {val}
                      </div>
                    );
                  },
                },
                {
                  accessor: 'rest_until',
                  Header: 'Next eligible date',
                  Cell: cellData => formatDate(cellData.value, defaultDateFormat),
                },
              ]}
            />
          </TabContent>
          <TabContent>
            <ReportDelivery leagueId={leagueId} />
          </TabContent>
        </Tabs>
      </div>
    </div>
  );
};

LeagueReport.propTypes = {
  activeDivision: PropTypes.string.isRequired,
  activeSeason: PropTypes.object.isRequired,
  divisions: PropTypes.array.isRequired,
  leagueId: PropTypes.number.isRequired,
  setActiveDivisionAction: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  divisions: get(state, 'activeLeague.details.divisions'),
  activeDivision: get(state, 'activeLeague.activeDivision'),
  activeSeason: get(state, 'activeLeague.activeSeason'),
});

const mapDispatchToProps = {
  setActiveDivisionAction: setActiveDivision,
};

export default connect(mapStateToProps, mapDispatchToProps)(LeagueReport);
