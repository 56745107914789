/* eslint-disable no-useless-escape */
/* eslint-disable max-len */
export const defaultDateFormat = 'YYYY-MM-DD';
export const defaultTimeFormat = 'HH:mm';
export const defaultDateTimeFormat = 'YYYY-MM-DD HH:mm';
// export const defaultDateTimeFormat = 'ddd, MMM YYYY HH:mm';

export const emailRegex = /^[a-z0-9!#$%&'*+/=?\^_`{|}~\-]+(?:.[a-z0-9!#$%&'*+/=?\^_`{|}~\-]+)*@(?:[a-z0-9](?:[a-z0-9\-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9\-]*[a-z0-9])?$/i;
export const urlRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;

export const numberRegex = /\d+/g;
export const lettersRegex = /[a-zA-Z]+/g;

export const validationTimeDefault = 300;
export const validationTimeLong = 1000;

export const errorMsg = {
  dateYYYYMMDDformat: 'Please enter date in YYYY-MM-DD format',
  emailDuplicate: 'This email is already in use',
  globalError: 'There was an error, please try again later',
  invalidEmail: 'Enter valid email',
  invalidEmails: 'Enter valid emails',
  invalidUrl: 'Enter valid URL',
  passCharNum: 'Password should contain at least 8 characters',
  passCurrent: 'Enter your current password',
  passLowercase: 'Password should contain at least 1 lowercase letter',
  passMatch: 'Passwords do not match',
  passNumber: 'Password should contain at least 1 number',
  passUppercase: 'Password should contain at least 1 uppercase letter',
  required: 'This field is required',
  selectErr: 'Select from dropdown',
};

export const ageOptions = [
  {
    label: '12 and under',
    value: '12_under',
  },
  {
    label: '13 - 18 Years',
    value: '13_18_years',
  },
  {
    label: 'College',
    value: 'college',
  },
  {
    label: 'Adult',
    value: 'adult',
  },
];

export const genderOptions = [
  {
    label: 'Male',
    value: 'M',
  },
  {
    label: 'Female',
    value: 'F',
  },
  {
    label: 'Coed',
    value: 'C',
  },
];

export const userRoleOptions = [
  {
    label: 'Admin',
    value: 'admin',
  },
  {
    label: 'Scorekeeper',
    value: 'scorekeeper',
  },
  {
    label: 'Coach',
    value: 'coach',
  },
  {
    label: 'Assistant coach',
    value: 'assistant_coach',
  },
  {
    label: 'Team volunteer',
    value: 'team_volunteer',
  },
  {
    label: 'Family',
    value: 'family',
  },
  {
    label: 'Fan',
    value: 'fan',
  },
];

export const countryOptions = [
  {
    label: 'United States',
    value: 'usa',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

export const gamesStatusOptions = [
  {
    label: 'Scheduled',
    value: 'scheduled',
  },
  {
    label: 'Canceled',
    value: 'canceled',
  },
  {
    label: 'Finished',
    value: 'finished',
  },
  {
    label: 'In progress',
    value: 'in_progress',
  },
];

export const playerSideOptions = [
  {
    label: 'Left',
    value: 'l',
  },
  {
    label: 'Right',
    value: 'r',
  },
  {
    label: 'Both',
    value: 'b',
  },
];

export const reserveOptions = [
  {
    label: 'No',
    value: 'no',
  },
  {
    label: 'Yes',
    value: 'yes',
  },
];

export const playerPositionOptions = [
  {
    fullLabel: 'Pitcher',
    label: 'P',
    value: 'P',
  },
  {
    fullLabel: 'Catcher',
    label: 'C',
    value: 'C',
  },
  {
    fullLabel: 'First baseman',
    label: '1B',
    value: '1B',
  },
  {
    fullLabel: 'Second baseman',
    label: '2B',
    value: '2B',
  },
  {
    fullLabel: 'Third baseman',
    label: '3B',
    value: '3B',
  },
  {
    fullLabel: 'Shortstop',
    label: 'SS',
    value: 'SS',
  },
  {
    fullLabel: 'Left fielder',
    label: 'LF',
    value: 'LF',
  },
  {
    fullLabel: 'Center fielder',
    label: 'CF',
    value: 'CF',
  },
  {
    fullLabel: 'Right fielder',
    label: 'RF',
    value: 'RF',
  },
  {
    fullLabel: 'Extra hitter',
    label: 'EH',
    value: 'EH',
  },
  {
    fullLabel: 'Designated hitter',
    label: 'DH',
    value: 'DH',
  },
];

export const suplementalRulesObject = {
  pitches: 'Number of pitches',
  catch_innings: 'Number of innings when catching',
  pitch_innings: 'Number of innings when pitching',
  age_max: 'Max age',
  age_min: 'Min age',
  pitches_max: 'Max pitches',
  pitches_min: 'Min pitches',
  rest_days: 'Rest days',
  other_pitchers_count: 'Number of other players',
  other_min_pitches: 'Other players min pitches',
  other_min_pitch_innings: 'Other players min innings when pitching',
};
