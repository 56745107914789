import get from 'lodash.get';
import api from 'shared/api';
import * as actionTypes from './actionTypes';

// active team actions
const initGetLeague = actions => () => ({
  type: actions.getLeagueInit,
});

const getLeagueError = actions => () => ({
  type: actions.getLeagueError,
});

const getLeagueSuccess = actions => (res, seasonId) => ({
  type: actions.getLeagueSuccess,
  league: res,
  seasonId,
});

export const getActiveLeague = (id, seasonId = undefined) => (dispatch) => {
  dispatch(initGetLeague(actionTypes)());
  return api.get(`/api/v1/leagues/${id}`).then(
    res => dispatch(getLeagueSuccess(actionTypes)(get(res, 'data'), seasonId)),
    () => dispatch(getLeagueError(actionTypes)()),
  );
};

export const refreshActiveLeague = id => (dispatch) => api.get(`/api/v1/leagues/${id}`)
  .then(
    res => dispatch(getLeagueSuccess(actionTypes)(get(res, 'data'))),
    () => dispatch(getLeagueError(actionTypes)()),
  );

export const cleanLeague = () => ({
  type: actionTypes.cleanLeague,
});

export const getActiveSeason = season => ({
  type: actionTypes.getActiveSeason,
  season,
});

export const setActiveDivision = division => ({
  type: actionTypes.setActiveDivision,
  activeDivision: division,
});

export const getLeagueTeams = teams => ({
  type: actionTypes.getLeagueTeams,
  teams,
});

export const cleanActiveLeague = () => ({
  type: actionTypes.cleanActiveLeague,
});
