import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import get from 'lodash.get';
import { withRouter } from 'react-router-dom';
import DatePicker from 'react-date-picker';
import TimePicker from 'react-time-picker';
import {
  AsyncSelector,
  Button,
  Checkbox,
  GoogleLocationSearch,
  InputErrorMessage,
  Label,
  Modal,
  Notification,
  NumberInput,
  TextArea,
  Selector,
} from 'shared/components';
import api from 'shared/api';
import {
  gamesStatusOptions,
} from 'shared/constants';
import { refreshActiveGames } from 'store/activeTeam/actions';
import { createGame } from './actions';
import './styles.scss';

const getLocation = (location, customLocation) => {
  if (!get(location, 'id') && !get(customLocation, 'name')) {
    return undefined;
  }

  if (get(location, 'id')) {
    return get(location, 'id');
  }

  return {
    name: get(customLocation, 'name'),
    address: get(customLocation, 'address'),
    city: get(customLocation, 'city'),
    state: get(customLocation, 'state'),
    zipcode: get(customLocation, 'zipcode'),
    country: get(customLocation, 'country'),
    lat: get(customLocation, 'lat'),
    lng: get(customLocation, 'lng'),
  };
};

const AddGameForm = ({
  activeSeason,
  close,
  isOpen,
  history,
  refreshActiveGamesAction,
  team,
}) => {
  const teamId = get(team, 'id');
  const leagueId = get(team, 'league.id');
  const seasonId = get(activeSeason, 'id');

  const [loading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);

  const [status, setStatus] = useState({
    label: 'Scheduled',
    value: 'scheduled',
  });
  const [away, setAway] = useState(false);
  const [opponent, setOpponent] = useState(null);
  const [date, setDate] = useState(new Date());
  const [time, setTime] = useState(moment().format('HH:mm'));
  const [arrival, setArrival] = useState(60);
  const [notes, setNotes] = useState('');

  const [location, setLocation] = useState(null);
  const [customLocation, setCustomLocation] = useState(null);
  const [locationError, setLocationError] = useState('');

  const validateLocation = () => {
    if (!!get(location, 'id') && get(customLocation, 'address')) {
      setLocationError('You can select only existing location or add a new one, not both');
      return false;
    }

    return true;
  };

  const handleSubmit = e => {
    e.preventDefault();

    const isLocationValid = validateLocation();
    if (!isLocationValid) {
      return false;
    }

    setLoading(true);

    const formatDate = moment(date).format('YYYY-MM-DD');
    const momentObj = moment(`${formatDate} ${time}`);
    const dateTime = momentObj.format('YYYY-MM-DDTHH:mm:ssZ');

    const teamHome = away ? (get(opponent, 'id') || null) : teamId;
    const teamAway = away ? teamId : (get(opponent, 'id') || null);

    const baseballField = getLocation(location, customLocation);

    const data = {
      time_start: dateTime,
      arrival_minutes: Number(arrival) || 0,
      status: status.value,
      team_home: teamHome,
      team_away: teamAway,
      season: seasonId,
      baseball_field: baseballField,
      note: notes,
    };

    return createGame(data)
      .then(res => {
        const gameId = get(res, 'data.id');
        Notification('success', 'New game created');
        history.push(`/${teamId}/games/${gameId}`);
        refreshActiveGamesAction(teamId, seasonId);
      })
      .catch(() => {
        setLoading(false);
        Notification('error', 'Error occured', 'There was an error while creating a new game');
      });
  };

  const handleClose = () => {
    if (
      !dirty ||
      window.confirm('Are you sure you want to discard your changes?')
    ) {
      close();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      closeCb={handleClose}
      title="Add a game"
    >
      <div className="AddGameForm">
        <div
          className="AddGameForm-form"
          // onSubmit={handleSubmit}
        >
          <div className="form-left">
            <div className="form-row">
              <Label text="Select date of game" inputId="date-input" />
              <DatePicker
                onChange={val => {
                  setDirty(true);
                  setDate(val);
                }}
                value={date}
                required
              />
            </div>
            <div className="form-row">
              <Label text="Select time of game" inputId="time-input" />
              <TimePicker
                value={time}
                onChange={val => {
                  setDirty(true);
                  setTime(val);
                }}
                disableClock
                required
              />
            </div>
            <div className="form-row">
              <Label text="Arrival before game start" inputId="game-arrival" />
              <NumberInput
                id="game-arrival"
                value={arrival}
                min="0"
                max="1000"
                handleChange={val => {
                  setDirty(true);
                  setArrival(val);
                }}
                placeholder="Enter number of minutes"
              />
            </div>
          </div>
          <div className="form-right">
            <div className="form-row">
              <Label
                inputId="games-status"
                text="Status"
              />
              <Selector
                id="games-status"
                options={gamesStatusOptions}
                value={status.value}
                handleChange={val => {
                  const selected = gamesStatusOptions.find(option => option.value === val);
                  setStatus(selected);
                  setDirty(true);
                }}
                disabled={loading}
              />
            </div>
            <div className="form-row">
              <Label
                text="Opponent"
                inputId="games-opponent"
              />
              <AsyncSelector
                exclude={teamId}
                append={{
                  name: 'TBD',
                  id: null,
                }}
                valueKey="id"
                labelKey="name"
                fetchOptions={val => (
                  api.get(`/api/v1/teams/?league__id=${leagueId}&name__icontains=${val}`)
                )}
                fetchInitialOptions={() => api.get(`/api/v1/teams/?league__id=${leagueId}&limit=10`)}
                handleChange={val => {
                  setDirty(true);
                  setOpponent(val);
                }}
                value={get(opponent, 'name') || ''}
              />
            </div>
            <div className="form-row">
              <Checkbox
                inputId="away-input"
                value={away}
                handleChange={val => {
                  setDirty(true);
                  setAway(val);
                }}
                disabled={loading}
                label="Is game away?"
              />
            </div>
            <div className="form-row">
              <Label
                text="Notes"
                inputId="game-note"
              />
              <TextArea
                value={notes}
                handleChange={val => {
                  setDirty(true);
                  setNotes(val);
                }}
                disabled={loading}
                rows={4}
              />
            </div>
          </div>
          <div className="form-row form-row-wide">
            <div className="location-heading">Location</div>
            <div className="location-wrapper">
              <div className="form-row">
                <Label
                  inputId="games-location-existing"
                  text="Existing location"
                />
                <AsyncSelector
                  valueKey="id"
                  labelKey="name"
                  isClearable
                  fetchOptions={val => (
                    api.get(`/api/v1/baseballfields/?name__icontains=${val}`)
                  )}
                  fetchInitialOptions={() => api.get('/api/v1/baseballfields/?limit=10')}
                  // eslint-disable-next-line no-unused-vars
                  handleChange={val => {
                    setDirty(true);
                    setLocation(val);
                  }}
                  value={get(location, 'name') || ''}
                />
              </div>
              <div style={{ alignSelf: 'center', fontWeight: 'bold' }}>OR</div>
              <div className="form-row">
                <Label
                  inputId="games-location-new"
                  text="Add a new location"
                />
                <GoogleLocationSearch
                  handlePlaceSelect={val => setCustomLocation(val)}
                  clearSelection={() => setCustomLocation(null)}
                />
              </div>
            </div>
            <InputErrorMessage text={locationError} />
          </div>
          <div className="form-submit">
            <Button
              theme="link"
              type="button"
              disabled={loading}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              theme="success"
              // type="submit"
              onClick={handleSubmit}
              disabled={loading}
            >
              Add game
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

AddGameForm.propTypes = {
  activeSeason: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  refreshActiveGamesAction: PropTypes.func.isRequired,
  team: PropTypes.object.isRequired,
};

const mapDispatchToProps = {
  refreshActiveGamesAction: refreshActiveGames,
};

export default connect(null, mapDispatchToProps)(withRouter(AddGameForm));
