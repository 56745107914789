/* eslint-disable */
import api from "shared/api";

export const addPlayer = (data) => {
  const url = `/api/v1/playerevents/`;
  return api.post(url, data);
};

export const getPlayers = () => {
  const url = `/api/v1/players/`;
  return api.get(url);
};
