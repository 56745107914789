import React from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
} from 'shared/components';
import './styles.scss';

const DeleteSuplementalRulePopup = ({
  close,
  isOpen,
  confirmCb,
  ruleToDelete,
  loading,
}) => {
  const handleSubmit = e => {
    e.preventDefault();
    confirmCb();
  };

  return (
    <Modal
      isOpen={isOpen}
      closeCb={close}
      title="Are you sure you want to delete this rule from a league?"
      size="md"
    >
      <div className="DeleteSuplementalRulePopup">
        <form
          className="DeleteSuplementalRulePopup-form"
          onSubmit={handleSubmit}
        >
          <span>
            {get(ruleToDelete, 'description') || get(ruleToDelete, 'code')}
          </span>
          <div className="form-submit">
            <Button
              theme="link"
              type="button"
              disabled={loading}
              onClick={close}
            >
              Cancel
            </Button>
            <Button
              theme="error"
              type="submit"
              disabled={loading}
            >
              Delete
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

DeleteSuplementalRulePopup.propTypes = {
  close: PropTypes.func.isRequired,
  confirmCb: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  ruleToDelete: PropTypes.object.isRequired,
};

export default DeleteSuplementalRulePopup;
