/* eslint-disable */
import React from "react";
import get from "lodash.get";
import Select from "react-select";
import { LoadingIndicator } from "shared/components";
import PropTypes from "prop-types";
import "./styles.scss";

const Selector = ({
  disabled,
  handleChange,
  loading,
  multi,
  options,
  value,
  labelKey,
  required,
  isClearable,
  onKeyPress,
  ...rest
}) => {
  const handleSelectChange = (option) => handleChange(get(option, "value"));
  let valueForSelect = null;

  if (value) {
    valueForSelect = !multi
      ? options.find((o) => o[labelKey] === value || o.value === value)
      : value;
  }

  return (
    <div className="Selector" onKeyPress={onKeyPress}>
      <Select
        options={options}
        className="selector-container"
        classNamePrefix="selector-inner"
        value={valueForSelect}
        onChange={handleSelectChange}
        isMulti={multi}
        isDisabled={disabled}
        isClearable={isClearable}
        {...rest}
      />
      {!disabled && (
        <input
          tabIndex={-1}
          autoComplete="off"
          style={{
            opacity: 0,
            height: 0,
            position: "absolute",
            bottom: "10px",
            left: "20px",
            pointerEvents: "none",
          }}
          value={valueForSelect || ""}
          required={required}
          readOnly
        />
      )}
      {loading && <LoadingIndicator />}
    </div>
  );
};

Selector.propTypes = {
  disabled: PropTypes.bool,
  handleChange: PropTypes.func,
  loading: PropTypes.bool,
  isClearable: PropTypes.bool,
  multi: PropTypes.bool,
  options: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number,
  ]),
  labelKey: PropTypes.string,
  required: PropTypes.bool,
};

Selector.defaultProps = {
  disabled: false,
  handleChange: () => {},
  loading: false,
  isClearable: false,
  multi: false,
  value: undefined,
  labelKey: null,
  required: false,
};

export default Selector;
