import React, { useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import {
  Button,
  GoogleLocationSearch,
  Modal,
  TextInput,
  Label,
  Notification,
} from 'shared/components';
import { patchBaseballField } from './actions';
import './styles.scss';

const EditLocationForm = ({
  close,
  isOpen,
  game,
  fetchGameData,
}) => {
  // const homeTeamId = get(game, 'team_home.id');
  const [loading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [fieldName, setFieldName] = useState(get(game, 'baseball_field.name') || '');
  const [address, setAddress] = useState(get(game, 'baseball_field.address') || '');
  const [city, setCity] = useState(get(game, 'baseball_field.city') || '');
  const [state, setState] = useState(get(game, 'baseball_field.state') || '');
  const [zipcode, setZipcode] = useState(get(game, 'baseball_field.zipcode') || '');
  const [country, setCountry] = useState(get(game, 'baseball_field.country') || '');
  const [lat, setLat] = useState(get(game, 'baseball_field.lat') || '');
  const [lng, setLng] = useState(get(game, 'baseball_field.lng') || '');

  const handleSubmit = e => {
    e.preventDefault();

    setLoading(true);

    const baseballFieldId = get(game, 'baseball_field.id');

    const data = {
      name: fieldName,
      address,
      city,
      state,
      zipcode,
      country,
      lat,
      lng,
    };

    patchBaseballField(baseballFieldId, data)
      .then(() => {
        Notification('success', 'Baseball field edited');
        fetchGameData();
        close();
      })
      .catch(() => {
        setLoading(false);
        Notification('error', 'Error occured', 'There was an error while editing a baseball field');
      });
  };

  const selectCustomLocation = customLocation => {
    if (!customLocation) {
      setAddress('');
    }

    setFieldName(get(customLocation, 'name'));
    setAddress(get(customLocation, 'address'));
    setCity(get(customLocation, 'city'));
    setState(get(customLocation, 'state'));
    setZipcode(get(customLocation, 'zipcode'));
    setCountry(get(customLocation, 'country'));
    setLat(get(customLocation, 'lat'));
    setLng(get(customLocation, 'lng'));
  };

  const handleClose = () => {
    if (
      !dirty ||
      window.confirm('Are you sure you want to discard your changes?')
    ) {
      close();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      closeCb={handleClose}
      title="Edit game location"
      size="sm"
    >
      <div className="EditLocationForm">
        <form
          className="EditLocationForm-form"
          onSubmit={handleSubmit}
        >
          <div className="form-row">
            <Label text="Baseball field name" inputId="field-name" />
            <TextInput
              id="field-name"
              value={fieldName}
              handleChange={val => {
                setDirty(true);
                setFieldName(val);
              }}
              placeholder="Enter baseball field name"
            />
          </div>
          <div className="form-row">
            <Label text="Address" inputId="field-address" />
            <GoogleLocationSearch
              handlePlaceSelect={val => selectCustomLocation(val)}
              clearSelection={() => selectCustomLocation(null)}
              placeholder="Search for an address"
              value={address}
            />
          </div>
          <div className="form-row">
            <Label text="City" inputId="field-city" />
            <TextInput
              id="field-city"
              value={city}
              handleChange={val => {
                setDirty(true);
                setCity(val);
              }}
              placeholder="Enter city"
            />
          </div>
          <div className="form-row">
            <Label text="State" inputId="field-state" />
            <TextInput
              id="field-state"
              value={state}
              handleChange={val => {
                setDirty(true);
                setState(val);
              }}
              placeholder="Enter state"
            />
          </div>
          <div className="form-row">
            <Label text="Zipcode" inputId="field-zipcode" />
            <TextInput
              id="field-zipcode"
              value={zipcode}
              handleChange={val => {
                setDirty(true);
                setZipcode(val);
              }}
              placeholder="Enter zipcode"
            />
          </div>
          <div className="form-row">
            <Label text="Country" inputId="field-country" />
            <TextInput
              id="field-country"
              value={country}
              handleChange={val => {
                setDirty(true);
                setCountry(val);
              }}
              placeholder="Enter country"
            />
          </div>
          <div className="form-submit">
            <Button
              theme="link"
              type="button"
              disabled={loading}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              theme="success"
              type="submit"
              disabled={loading}
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

EditLocationForm.propTypes = {
  close: PropTypes.func.isRequired,
  fetchGameData: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  game: PropTypes.object.isRequired,
};

export default EditLocationForm;
