import React, { useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import {
  Button,
  Label,
  Modal,
  Notification,
  TextInput,
  Selector,
} from 'shared/components';
import {
  playerPositionOptions,
  reserveOptions,
} from 'shared/constants';
import {
  checkExistingLineupPosition,
} from 'shared/helpers';
import {
  getPlayer,
  mapPositionToSelector,
  mapReserveToSelector,
} from './helpers';
import { patchPlayer } from './actions';
import './styles.scss';

const EditLineupPlayer = ({
  close,
  isOpen,
  refreshActiveLineup,
  teamId,
  seasonId,
  playerToEdit,
  lineup,
  players,
}) => {
  const lineupId = get(lineup, 'id');
  const lineupPlayers = get(lineup, 'lineup_players');
  const player = getPlayer(players, playerToEdit);

  const [loading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);

  const [position, setPosition] = useState(mapPositionToSelector(get(playerToEdit, 'position')));
  const [reserve, setReserve] = useState(mapReserveToSelector(get(playerToEdit, 'is_reserve')));

  const editUniquePositionLineupPlayer = (id, data) => {
    patchPlayer(id, data)
      .then(() => {
        refreshActiveLineup(teamId, seasonId);
        Notification('success', 'Lineup player edited');
        close();
      })
      .catch(() => {
        setLoading(false);
        Notification(
          'error',
          'Error occured',
          'There was an error while editing a lineup player',
        );
      });
  };

  const handleLineupEdit = (id, data, existing) => {
    patchPlayer(id, data)
      .then(() => {
        const existingPlayerId = get(existing, 'id');
        return patchPlayer(existingPlayerId, { position: 'EH' });
      })
      .then(() => {
        refreshActiveLineup(teamId, seasonId);
        Notification('success', 'Lineup player edited');
        close();
      })
      .catch(() => {
        setLoading(false);
        Notification(
          'error',
          'Error occured',
          'There was an error while editing a lineup player',
        );
      });
  };

  const handleSubmit = e => {
    e.preventDefault();

    setLoading(true);

    const isReserve = get(reserve, 'value') === 'yes';

    const existingPositionPlayer = checkExistingLineupPosition(position, lineupPlayers);

    const data = {
      player: get(player, 'player.id'),
      position: get(position, 'value') || '',
      lineup: lineupId,
      is_reserve: isReserve,
    };

    const lineupPlayerId = get(playerToEdit, 'id');

    if (!existingPositionPlayer) {
      editUniquePositionLineupPlayer(lineupPlayerId, data);
    } else {
      handleLineupEdit(lineupPlayerId, data, existingPositionPlayer);
    }
  };

  const handleClose = () => {
    if (
      !dirty ||
      window.confirm('Are you sure you want to discard your changes?')
    ) {
      close();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      closeCb={handleClose}
      title="Edit a lineup player"
      size="sm"
    >
      <div className="EditLineupPlayer">
        <form
          className="EditLineupPlayer-form"
          onSubmit={handleSubmit}
        >
          <div className="Form-row">
            <TextInput
              id="player-name"
              value={`${get(player, 'player.first_name')} ${get(player, 'player.last_name')}`}
              handleChange={() => {}}
              disabled
            />
          </div>
          <div className="form-row">
            <Label
              inputId="player-position"
              text="Position"
            />
            <Selector
              options={playerPositionOptions}
              value={get(position, 'value') || ''}
              handleChange={val => {
                setDirty(true);
                const selected = playerPositionOptions.find(option => option.value === val);
                setPosition(selected || {});
              }}
            />
          </div>
          <div className="form-row">
            <Label
              inputId="player-reserve"
              text="Is reserve"
            />
            <Selector
              options={reserveOptions}
              value={get(reserve, 'value') || ''}
              handleChange={val => {
                setDirty(true);
                const selected = reserveOptions.find(option => option.value === val);
                setReserve(selected || {});
              }}
            />
          </div>
          <div className="form-submit">
            <Button
              theme="link"
              type="button"
              disabled={loading}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              theme="success"
              type="submit"
              disabled={loading}
            >
              Add player
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

EditLineupPlayer.propTypes = {
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  refreshActiveLineup: PropTypes.func.isRequired,
  teamId: PropTypes.number.isRequired,
  lineup: PropTypes.object.isRequired,
  seasonId: PropTypes.number.isRequired,
  playerToEdit: PropTypes.object.isRequired,
  players: PropTypes.array.isRequired,
};

export default EditLineupPlayer;
