import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  IconSearch,
  InputErrorMessage,
} from 'shared/components';
import './styles.scss';

const SearchInput = ({
  disabled,
  error,
  handleChange,
  handleClearBtn,
  onSearch,
  showButton,
  ...rest
}) => {
  const [query, setQuery] = useState('');
  const handleInputChange = e => {
    setQuery(e.target.value);
    handleChange(e.target.value);
  };
  const handleClearBtnClick = () => {
    setQuery('');
    handleClearBtn();
  };

  return (
    <div className="SearchInput">
      <form className="SearchInput-form" onSubmit={onSearch}>
        <div className="search-input-wrapper">
          <input
            className={`${error ? 'has-error' : ''} ${showButton ? '' : 'round'}`}
            disabled={disabled}
            onChange={handleInputChange}
            {...rest}
          />
          {
            query && (
              <button
                type="button"
                className="search-clear-btn"
                onClick={handleClearBtnClick}
              >
                &times;
              </button>
            )
          }
        </div>
        {
          showButton && (
            <button
              type="submit"
              disabled={disabled}
              className="search-submit-btn"
            >
              <IconSearch color="#fff" />
            </button>
          )
        }
      </form>
      <InputErrorMessage text={error} />
    </div>
  );
};

SearchInput.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  handleClearBtn: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  showButton: PropTypes.bool,
};

SearchInput.defaultProps = {
  disabled: false,
  error: '',
  showButton: true,
};

export default SearchInput;
