import React from 'react';
import { Button } from 'shared/components';
import icon from './bug_fixing.svg';
import './styles.scss';

const GlobalError = () => (
  <div className="GlobalError">
    <div className="wrapper">
      <img src={icon} alt="Error message" />
      <h2>
        An error occured
      </h2>
      <p>
        Our tech team has been notified, to restart browsing please click on a button below
      </p>
      <Button
        onClick={() => { window.location = '/'; }}
      >
        Restart browsing
      </Button>
    </div>
  </div>
);

export default GlobalError;
