import React from 'react';
import PropTypes from 'prop-types';
// import get from 'lodash.get';
import './styles.scss';

const actionHistoryToText = (data = {}) => {
  if (data.length) {
    return data.join(',');
  }

  return '-';
};

// eslint-disable-next-line arrow-body-style
const mapActionMetaToJson = (data = {}) => ({
  inning: data.inning,
  inningHalf: data.inningHalf,
  play: data.play,
  actions: data.actions,
  pitcher: data.pitcher,
  batter: data.batter,
});

const InningCard = ({
  heading,
  events,
  isUserStaff,
}) => (
  <article className="InningCard">
    <div className="InningCard-heading">
      {heading}
    </div>
    <div className="InningCard-events">
      {
        events.map(e => (
          <div key={e.id} className="event-container">
            <div className="event-name">
              {e.play}
            </div>
            <div className="event-details">
              {actionHistoryToText(e.actions)}
              {
                isUserStaff && (
                  <div className="is_staff_json">
                    {JSON.stringify(mapActionMetaToJson(e))}
                  </div>
                )
              }
            </div>
          </div>
        ))
      }
    </div>
  </article>
);

InningCard.propTypes = {
  heading: PropTypes.string.isRequired,
  events: PropTypes.array,
  isUserStaff: PropTypes.bool.isRequired,
};

InningCard.defaultProps = {
  events: [],
};

export default InningCard;
