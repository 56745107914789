import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  Button,
} from 'shared/components';
import icon from './404_page_not_found.svg';
import './styles.scss';

const NotFound = ({
  history,
}) => (
  <div className="NotFound-container">
    <img src={icon} alt="Not found" />
    <p>
      We can not seem to find the page you are looking for.
    </p>
    <Button
      onClick={() => { history.push('/'); }}
    >
      Go to homepage
    </Button>
  </div>
);

NotFound.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(NotFound);
