import React, { useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import {
  Button,
  Modal,
  Label,
  NumberInput,
  Notification,
  InputErrorMessage,
} from 'shared/components';
import { patchComplianceRules } from './actions';
import * as validation from './validation';
import './styles.scss';

const EditRuleForm = ({
  rule,
  close,
  isOpen,
  refetchRules,
}) => {
  const [loading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);

  const [dailyMax, setDailyMax] = useState(get(rule, 'daily_max'));

  const [rest0Min, setRest0Min] = useState(get(rule, 'rest_0_min'));
  const [rest0Max, setRest0Max] = useState(get(rule, 'rest_0_max'));
  const [rest0Error, setRest0Error] = useState('');

  const [rest1Min, setRest1Min] = useState(get(rule, 'rest_1_min'));
  const [rest1Max, setRest1Max] = useState(get(rule, 'rest_1_max'));
  const [rest1Error, setRest1Error] = useState('');

  const [rest2Min, setRest2Min] = useState(get(rule, 'rest_2_min'));
  const [rest2Max, setRest2Max] = useState(get(rule, 'rest_2_max'));
  const [rest2Error, setRest2Error] = useState('');

  const [rest3Min, setRest3Min] = useState(get(rule, 'rest_3_min'));
  const [rest3Max, setRest3Max] = useState(get(rule, 'rest_3_max'));
  const [rest3Error, setRest3Error] = useState('');

  const [rest4Min, setRest4Min] = useState(get(rule, 'rest_4_min'));
  const [rest4Max, setRest4Max] = useState(get(rule, 'rest_4_max'));
  const [rest4Error, setRest4Error] = useState('');

  const [rest5Min, setRest5Min] = useState(get(rule, 'rest_5_min'));
  const [rest5Max, setRest5Max] = useState(get(rule, 'rest_5_max'));
  const [rest5Error, setRest5Error] = useState('');

  const validateRest0DaysRange = () => {
    let isValid = false;

    setLoading(true);

    return validation.validateRestRange(rest0Min, rest0Max, rest1Min, true)
      .then((d) => {
        setLoading(false);
        setRest0Error(d);

        isValid = !d;
        return !!isValid;
      });
  };

  const validateRest1DaysRange = () => {
    let isValid = false;

    setLoading(true);

    return validation.validateRestRange(rest1Min, rest1Max, rest2Min, rest0Max)
      .then((d) => {
        setLoading(false);
        setRest1Error(d);

        isValid = !d;
        return !!isValid;
      });
  };

  const validateRest2DaysRange = () => {
    let isValid = false;

    setLoading(true);

    return validation.validateRestRange(rest2Min, rest2Max, rest3Min, rest1Max)
      .then((d) => {
        setLoading(false);
        setRest2Error(d);

        isValid = !d;
        return !!isValid;
      });
  };

  const validateRest3DaysRange = () => {
    let isValid = false;

    setLoading(true);

    return validation.validateRestRange(rest3Min, rest3Max, rest4Min, rest2Max)
      .then((d) => {
        setLoading(false);
        setRest3Error(d);

        isValid = !d;
        return !!isValid;
      });
  };

  const validateRest4DaysRange = () => {
    let isValid = false;

    setLoading(true);

    return validation.validateRestRange(rest4Min, rest4Max, rest5Min, rest3Max)
      .then((d) => {
        setLoading(false);
        setRest4Error(d);

        isValid = !d;
        return !!isValid;
      });
  };

  const validateRest5DaysRange = () => {
    let isValid = false;

    setLoading(true);

    return validation.validate5DaysRestRange(rest5Min, rest5Max, rest4Max)
      .then((d) => {
        setLoading(false);
        setRest5Error(d);

        isValid = !d;
        return !!isValid;
      });
  };

  const isFormValid = async () => {
    const isRest0DaysValid = await validateRest0DaysRange();
    const isRest1DaysValid = await validateRest1DaysRange();
    const isRest2DaysValid = await validateRest2DaysRange();
    const isRest3DaysValid = await validateRest3DaysRange();
    const isRest4DaysValid = await validateRest4DaysRange();
    const isRest5DaysValid = await validateRest5DaysRange();

    return !!isRest0DaysValid && !!isRest1DaysValid && !!isRest2DaysValid &&
      !!isRest3DaysValid && !!isRest4DaysValid && !!isRest5DaysValid;
  };

  const handleSubmit = async e => {
    e.preventDefault();

    const isValid = await isFormValid();

    if (!isValid || loading) {
      return;
    }

    const data = {
      id: get(rule, 'id'),
      active: get(rule, 'active'),
      league: get(rule, 'league'),
      daily_max: dailyMax,
      rest_0_min: rest0Min,
      rest_0_max: rest0Max,
      rest_1_min: rest1Min,
      rest_1_max: rest1Max,
      rest_2_min: rest2Min,
      rest_2_max: rest2Max,
      rest_3_min: rest3Min,
      rest_3_max: rest3Max,
      rest_4_min: rest4Min,
      rest_4_max: rest4Max,
      rest_5_min: rest5Min,
      rest_5_max: rest5Max,
    };

    setLoading(true);

    patchComplianceRules(data)
      .then(() => {
        refetchRules();
        close();
        Notification('success', 'League rule edited');
      })
      .catch(() => {
        setLoading(false);
        Notification('error', 'Error occured', 'There was an error while editing a league rule');
      });
  };

  const handleClose = () => {
    if (
      !dirty ||
      window.confirm('Are you sure you want to discard your changes?')
    ) {
      close();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      closeCb={handleClose}
      title="Edit league rule"
    >
      <div className="EditRuleForm">
        <div className="age-heading">
          <span>Age group: </span>
          {get(rule, 'age_group')}
        </div>
        <form
          className="EditRuleForm-form"
          onSubmit={handleSubmit}
        >
          <div className="form-left">
            <div className="form-row">
              <Label
                inputId="daily-max"
                text="Daily Max (Pitches in Game)"
              />
              <NumberInput
                id="daily-max"
                value={dailyMax}
                min="0"
                max="1000"
                handleChange={val => {
                  setDirty(true);
                  setDailyMax(val);
                }}
                placeholder="Daily max"
                disabled={loading}
              />
            </div>
            <div className="form-row">
              <Label
                inputId="rest-0"
                text="0 Days Rest (Pitches)"
              />
              <div className="range-inputs">
                <NumberInput
                  id="rest-0"
                  value={rest0Min}
                  min="0"
                  max="1000"
                  handleChange={val => {
                    setDirty(true);
                    setRest0Min(val);
                  }}
                  placeholder="Min"
                  disabled={loading}
                />
                <span className="divider"> - </span>
                <NumberInput
                  id="rest-0"
                  value={rest0Max}
                  min="0"
                  max="1000"
                  handleChange={val => {
                    setDirty(true);
                    setRest0Max(val);
                  }}
                  placeholder="Max"
                  disabled={loading}
                />
              </div>
              <InputErrorMessage text={rest0Error} />
            </div>
            <div className="form-row">
              <Label
                inputId="rest-1"
                text="1 Days Rest (Pitches)"
              />
              <div className="range-inputs">
                <NumberInput
                  id="rest-1"
                  value={rest1Min}
                  min="0"
                  max="1000"
                  handleChange={val => {
                    setDirty(true);
                    setRest1Min(val);
                  }}
                  placeholder="Min"
                  disabled={loading}
                />
                <span className="divider"> - </span>
                <NumberInput
                  id="rest-1"
                  value={rest1Max}
                  min="0"
                  max="1000"
                  handleChange={val => {
                    setDirty(true);
                    setRest1Max(val);
                  }}
                  placeholder="Max"
                  disabled={loading}
                />
              </div>
              <InputErrorMessage text={rest1Error} />
            </div>
          </div>
          <div className="form-right">
            <div className="form-row">
              <Label
                inputId="rest-2"
                text="2 Days Rest (Pitches)"
              />
              <div className="range-inputs">
                <NumberInput
                  id="rest-2"
                  value={rest2Min}
                  min="0"
                  max="1000"
                  handleChange={val => {
                    setDirty(true);
                    setRest2Min(val);
                  }}
                  placeholder="Min"
                  disabled={loading}
                />
                <span className="divider"> - </span>
                <NumberInput
                  id="rest-2"
                  value={rest2Max}
                  min="0"
                  max="1000"
                  handleChange={val => {
                    setDirty(true);
                    setRest2Max(val);
                  }}
                  placeholder="Max"
                  disabled={loading}
                />
              </div>
              <InputErrorMessage text={rest2Error} />
            </div>
            <div className="form-row">
              <Label
                inputId="rest-3"
                text="3 Days Rest (Pitches)"
              />
              <div className="range-inputs">
                <NumberInput
                  id="rest-3"
                  value={rest3Min}
                  min="0"
                  max="1000"
                  handleChange={val => {
                    setDirty(true);
                    setRest3Min(val);
                  }}
                  placeholder="Min"
                  disabled={loading}
                />
                <span className="divider"> - </span>
                <NumberInput
                  id="rest-3"
                  value={rest3Max}
                  min="0"
                  max="1000"
                  handleChange={val => {
                    setDirty(true);
                    setRest3Max(val);
                  }}
                  placeholder="Max"
                  disabled={loading}
                />
              </div>
              <InputErrorMessage text={rest3Error} />
            </div>
            <div className="form-row">
              <Label
                inputId="rest-4"
                text="4 Days Rest (Pitches)"
              />
              <div className="range-inputs">
                <NumberInput
                  id="rest-4"
                  value={rest4Min}
                  min="0"
                  max="1000"
                  handleChange={val => {
                    setDirty(true);
                    setRest4Min(val);
                  }}
                  placeholder="Min"
                  disabled={loading}
                />
                <span className="divider"> - </span>
                <NumberInput
                  id="rest-4"
                  value={rest4Max}
                  min="0"
                  max="1000"
                  handleChange={val => {
                    setDirty(true);
                    setRest4Max(val);
                  }}
                  placeholder="Max"
                  disabled={loading}
                />
              </div>
              <InputErrorMessage text={rest4Error} />
            </div>
            <div className="form-row">
              <Label
                inputId="rest-5"
                text="5 Days Rest (Pitches)"
              />
              <div className="range-inputs">
                <NumberInput
                  id="rest-5"
                  value={rest5Min}
                  min="0"
                  max="1000"
                  handleChange={val => {
                    setDirty(true);
                    setRest5Min(val);
                  }}
                  placeholder="Min"
                  disabled={loading}
                />
                <span className="divider"> - </span>
                <NumberInput
                  id="rest-4"
                  value={rest5Max}
                  min="0"
                  max="1000"
                  handleChange={val => {
                    setDirty(true);
                    setRest5Max(val);
                  }}
                  placeholder="Max"
                  disabled={loading}
                />
              </div>
              <InputErrorMessage text={rest5Error} />
            </div>
          </div>
          <div className="form-submit">
            <Button
              theme="link"
              type="button"
              disabled={loading}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              theme="success"
              type="submit"
              disabled={loading}
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

EditRuleForm.propTypes = {
  rule: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  refetchRules: PropTypes.func.isRequired,
};

export default EditRuleForm;
