import get from 'lodash.get';
import api from 'shared/api';
import * as actionTypes from './actionTypes';
// user actions
const initGetUser = actions => () => ({
  type: actions.getUserInit,
});

const getUserError = actions => () => ({
  type: actions.getUserError,
});

const getUserSuccess = actions => (res) => ({
  type: actions.getUserSuccess,
  user: res,
});

export const getUser = () => (dispatch) => {
  dispatch(initGetUser(actionTypes)());
  return api.get('/rest-auth/user/').then(
    res => dispatch(getUserSuccess(actionTypes)(get(res, 'data'))),
    () => dispatch(getUserError(actionTypes)()),
  );
};

export const getUserSilent = () => (dispatch) => api.get('/rest-auth/user/').then(
  res => dispatch(getUserSuccess(actionTypes)(get(res, 'data'))),
  () => dispatch(getUserError(actionTypes)()),
);

export const refreshUser = res => ({
  type: actionTypes.refreshUser,
  user: res,
});

// login actions
const initLogin = actions => () => ({
  type: actions.loginInit,
});

export const loginSuccess = res => dispatch => dispatch({
  type: actionTypes.loginSuccess,
  user: res.user,
});

export const loginError = res => dispatch => dispatch({
  type: actionTypes.loginError,
  payload: res,
});

export const login = loginData => (dispatch) => {
  dispatch(initLogin(actionTypes)());
  return api.post('/rest-auth/login/', loginData);
};

// logout actions
export const logoutSuccess = () => dispatch => dispatch({
  type: actionTypes.logoutSuccess,
  user: null,
});

export const logoutError = () => dispatch => dispatch({
  type: actionTypes.logoutError,
});

export const logout = () => (dispatch) => {
  dispatch({ type: actionTypes.logoutInit });
  return api.post('/rest-auth/logout/');
};

// register actions
const initRegister = actions => () => ({
  type: actions.registerInit,
});

export const registerSuccess = res => dispatch => dispatch({
  type: actionTypes.registerSuccess,
  user: res,
});

export const registerError = res => dispatch => dispatch({
  type: actionTypes.registerError,
  payload: res,
});

export const register = registerData => (dispatch) => {
  dispatch(initRegister(actionTypes)());
  return api.post('/rest-auth/registration/', registerData);
};
