import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { InputErrorMessage } from 'shared/components';
import './styles.scss';

class RadioBtn extends Component {
  constructor() {
    super();

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(e) {
    const { handleChange } = this.props;
    handleChange(e.target.value);
  }

  render() {
    const {
      error,
      inputId,
      value,
      name,
      label,
      ...rest
    } = this.props;
    const { handleChange, ...restProps } = rest;

    return (
      <div className="RadioBtn">
        <label htmlFor={inputId}>
          <input
            type="radio"
            name={name}
            className={error ? 'has-error' : ''}
            id={inputId}
            value={value}
            onChange={this.handleInputChange}
            {...restProps}
          />
          {label}
        </label>
        <InputErrorMessage text={error} />
      </div>
    );
  }
}

RadioBtn.propTypes = {
  error: PropTypes.string,
  inputId: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  handleChange: PropTypes.func,
};

RadioBtn.defaultProps = {
  error: '',
  label: 'label',
  handleChange: () => {},
};

export default RadioBtn;
