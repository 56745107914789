import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import {
  Button,
  Label,
  Modal,
  Notification,
  Selector,
  TextInput,
} from 'shared/components';
import {
  emailRegex,
  userRoleOptions,
} from 'shared/constants';
import {
  mapPlayersToSelector,
} from 'shared/helpers';
import {
  addUser,
  patchUser,
  getPlayers,
  addUserPlayer,
} from './actions';
import './styles.scss';

const AddUserForm = ({
  close,
  isOpen,
  teamId,
  seasonId,
  refreshTeam,
  users,
}) => {
  const isMounted = React.useRef(true);

  const [loading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [role, setRole] = useState(get(userRoleOptions, '[0]') || '');

  const [players, setPlayers] = useState([]);
  const [selectedPlayer, setSelectedPlayer] = useState('');

  const validateEmail = () => emailRegex.test(email);

  const checkIfUserExisting = val => users.find(u => get(u, 'email') === val);

  const checkIfUserHasRole = existingUser => {
    if (!existingUser) {
      return false;
    }

    const userRole = get(existingUser, 'userteam') || {};
    return get(userRole, 'role');
  };

  const checkIfExistingUserRole = (selectedRole, user) => {
    const userRole = get(user, 'userteam') || {};
    return get(userRole, 'role') === selectedRole;
  };

  const checkIfUserAdmin = user => {
    const userRole = get(user, 'userteam') || {};
    const isAdmin = get(userRole, 'role') === 'admin';
    const isCoach = get(userRole, 'role') === 'coach';
    const isScorekeeper = get(userRole, 'role') === 'scorekeeper';
    return isAdmin || isCoach || isScorekeeper;
  };

  const checkIfAlreadyUserPlayer = (isExisting, playerId) => {
    if (!isExisting) {
      return false;
    }

    const userPlayers = get(isExisting, 'userplayers') || [];
    return userPlayers.find(up => get(up, 'player.id') === playerId);
  };

  const fetchPlayers = () => {
    getPlayers(teamId, seasonId)
      .then(res => {
        const results = get(res, 'data.results') || [];

        if (isMounted.current) {
          setPlayers(mapPlayersToSelector(results));
        }
      });
  };

  useEffect(() => {
    fetchPlayers();

    return () => {
      isMounted.current = false;
    };
  }, []);

  const createNewUser = () => {
    const selectedRole = get(role, 'value');

    const data = {
      email,
      role: selectedRole,
      team: teamId,
    };

    return addUser(teamId, data)
      .then(() => {
        Notification('success', 'New user added to team');
        refreshTeam(teamId);
        close();
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        Notification('error', 'Error occured', 'There was an error while adding a new user');
      });
  };

  const updateUserRole = (user, selectedRole) => {
    const data = {
      role: selectedRole,
      user: get(user, 'id'),
      team: teamId,
    };
    const userTeamId = get(user, 'userteam.id');

    patchUser(userTeamId, data)
      .then(() => {
        Notification('success', 'New user added to team');
        refreshTeam(teamId);
        close();
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        return Notification(
          'error',
          'Error occured',
          'There was an error while adding a new team user',
        );
      });
  };

  const handleUserPlayer = (isExisting) => {
    const playerId = get(selectedPlayer, 'data.id');
    const data = {
      email,
    };

    if (checkIfAlreadyUserPlayer(isExisting, playerId)) {
      setLoading(false);
      return Notification(
        'error',
        'Error occured',
        'User is already family member of this player',
      );
    }

    return addUserPlayer(playerId, data)
      .then(() => {
        Notification('success', 'New family member added to team');
        refreshTeam(teamId);
        close();
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        Notification(
          'error',
          'Error occured',
          'There was an error while adding a new family member.',
        );
      });
  };

  const handleSubmit = e => {
    e.preventDefault();

    const isEmailValid = validateEmail();

    if (!isEmailValid) {
      setEmailError('Enter valid email');
      return false;
    }

    setLoading(true);

    const existingUser = checkIfUserExisting(email);
    const hasRole = checkIfUserHasRole(existingUser);
    const selectedRole = get(role, 'value');

    if (selectedRole === 'family') {
      return handleUserPlayer(existingUser);
    }

    if (!existingUser || !hasRole) {
      return createNewUser();
    }

    const isSelectedRoleExisting = checkIfExistingUserRole(selectedRole, existingUser);
    const isUserAdmin = checkIfUserAdmin(existingUser);

    if (isSelectedRoleExisting) {
      setLoading(false);
      setDirty(false);
      return Notification(
        'error',
        'Error occured',
        'User with this role already exists',
      );
    }

    if (selectedRole === 'fan' && isUserAdmin) {
      setLoading(false);
      setDirty(false);
      return Notification(
        'error',
        'Error occured',
        'User is already admin in this team',
      );
    }

    return updateUserRole(existingUser, selectedRole);
  };

  const handleClose = () => {
    if (
      !dirty ||
      window.confirm('Are you sure you want to discard your changes?')
    ) {
      close();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      closeCb={handleClose}
      title="Add team member"
      size="sm"
    >
      <div className="AddUserForm">
        <form
          className="AddUserForm-form"
          onSubmit={handleSubmit}
        >
          <div className="form-row">
            <Label
              inputId="user-email"
              text="Email"
            />
            <TextInput
              id="user-email"
              value={email}
              handleChange={val => {
                setDirty(true);
                setEmail(val);
              }}
              placeholder="Enter email"
              disabled={loading}
              error={emailError}
              type="email"
              required
            />
          </div>
          <div className="form-row">
            <Label inputId="user-role" text="Role" />
            <Selector
              options={userRoleOptions}
              id="user-role"
              value={get(role, 'value')}
              disabled={loading}
              handleChange={val => {
                const selected = userRoleOptions.find(o => o.value === val);
                setDirty(true);
                setRole(selected);
              }}
            />
          </div>
          {
            role.value === 'family' && (
              <div className="form-row">
                <Label inputId="player-select" text="Select player" />
                <Selector
                  options={players}
                  disabled={loading}
                  id="player-select"
                  value={get(selectedPlayer, 'value') || ''}
                  handleChange={val => {
                    const selected = players.find(p => p.value === val);
                    setDirty(true);
                    setSelectedPlayer(selected);
                  }}
                />
              </div>
            )
          }
          <div className="form-submit">
            <Button
              theme="link"
              type="button"
              disabled={loading}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              theme="success"
              type="submit"
              disabled={loading}
            >
              Add user
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

AddUserForm.propTypes = {
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  teamId: PropTypes.number.isRequired,
  refreshTeam: PropTypes.func.isRequired,
  seasonId: PropTypes.number.isRequired,
  users: PropTypes.array.isRequired,
};

export default AddUserForm;
