import React, { useState } from "react";
import PropTypes from "prop-types";
import get from "lodash.get";
import { Button, DescriptionList } from "shared/components";
import { displayValue } from "shared/helpers";
import EditProfileForm from "./components/EditProfileForm";

const ProfileHome = ({ refreshUser, user }) => {
  const [isEditProfileModalDisplayed, setEditProfileModalDisplay] =
    useState(false);
  console.log("User: ", user);
  return (
    <div className="ProfileHome">
      <div className="ProfileHome-actions">
        <Button onClick={() => setEditProfileModalDisplay(true)} size="sm">
          edit profile
        </Button>
      </div>
      <DescriptionList
        data={[
          { label: "First name", value: displayValue(get(user, "first_name")) },
          { label: "Last name", value: displayValue(get(user, "last_name")) },
          {
            label: "Stripe key",
            value: displayValue(get(user, "stripe_api_key")),
          },
        ]}
      />
      {isEditProfileModalDisplayed && (
        <EditProfileForm
          close={() => setEditProfileModalDisplay(false)}
          isOpen={isEditProfileModalDisplayed}
          refreshUser={refreshUser}
          user={user}
        />
      )}
    </div>
  );
};

ProfileHome.propTypes = {
  refreshUser: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

export default ProfileHome;
