import get from 'lodash.get';
import api from 'shared/api';
import * as actionTypes from './actionTypes';

// active team actions
const initGetTeam = actions => () => ({
  type: actions.getTeamInit,
});

const getTeamError = actions => () => ({
  type: actions.getTeamError,
});

const getTeamSuccess = actions => (res, seasonId) => ({
  type: actions.getTeamSuccess,
  team: res,
  seasonId,
});

export const getActiveTeam = (id, seasonId = undefined) => (dispatch) => {
  dispatch(initGetTeam(actionTypes)());
  return api.get(`/api/v1/teams/${id}`).then(
    res => dispatch(getTeamSuccess(actionTypes)(get(res, 'data'), seasonId)),
    () => dispatch(getTeamError(actionTypes)()),
  );
};

export const refreshActiveTeam = id => (dispatch) => api.get(`/api/v1/teams/${id}`)
  .then(
    res => dispatch(getTeamSuccess(actionTypes)(get(res, 'data'))),
    () => dispatch(getTeamError(actionTypes)()),
  );

export const cleanTeam = () => ({
  type: actionTypes.cleanTeam,
});

export const getActiveSeason = season => ({
  type: actionTypes.getActiveSeason,
  season,
});

export const getActiveGames = games => ({
  type: actionTypes.getActiveGames,
  games,
});

export const getActivePlayers = players => ({
  type: actionTypes.getActivePlayers,
  players,
});

export const getActiveLineup = lineup => ({
  type: actionTypes.getActiveLineup,
  lineup,
});

export const cleanActiveTeam = () => ({
  type: actionTypes.cleanActiveTeam,
});

export const refreshActiveGames = (teamId, seasonId) => dispatch => {
  api.get(`/api/v1/games/?team__id=${teamId}&season__id=${seasonId}`)
    .then(
      res => dispatch(getActiveGames(get(res, 'data.results'))),
    );
};

export const refreshActivePlayers = (teamId, seasonId) => dispatch => {
  api.get(`/api/v1/playerteamhistories/?team__id=${teamId}&season__id=${seasonId}`)
    .then(
      res => dispatch(getActivePlayers(get(res, 'data.results'))),
    );
};

export const refreshActiveLineup = (teamId, seasonId) => dispatch => {
  api.get(`/api/v1/lineups/?team__id=${teamId}&season__id=${seasonId}`)
    .then(
      res => dispatch(getActiveLineup(get(res, 'data.results[0]') || [])),
    );
};
