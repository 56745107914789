import api from 'shared/api';

export const getLineup = (teamId, seasonId) => (
  api.get(`/api/v1/lineups/?team__id=${teamId}&season__id=${seasonId}`)
);

export const postLineup = (teamId, seasonId, userId) => {
  const data = {
    team: teamId,
    season: seasonId,
    user: userId,
  };

  return api.post('/api/v1/lineups/', data);
};
