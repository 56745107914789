import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import get from "lodash.get";
import { Selector } from "shared/components";
import { getActiveSeason, cleanActiveTeam } from "store/activeTeam/actions";
import "./styles.scss";

// const formatLabel = d => {
//   if (d.season === 'winter') {
//     const yearString = `${d.year}/${d.year + 1}`;
//     return `${d.season}, ${yearString}`;
//   }

//   return `${d.season}, ${d.year}`;
// };

const mapSeasonsToSelector = (seasons) => {
  const data = [];
  if (seasons && Array.isArray(seasons)) {
    seasons.forEach((d) => {
      const obj = {
        label: `${d.year}`,
        value: d.id,
        data: d,
      };
      data.push(obj);
    });
  }
  return data.reverse();
};

const getActiveSeasonSelectorOption = (s) => ({
  label: `${s.year}`,
  value: s.id,
  data: s,
});

const checkDisabled = (match) => {
  const params = get(match, "params");

  return !!params.gameId || !!params.playerId;
};

const SeasonSelector = ({
  disabledSeason,
  activeSeason,
  getActiveSeasonAction,
  seasons,
  cleanActiveTeamAction,
  match,
}) => {
  const active = getActiveSeasonSelectorOption(activeSeason);
  const data = mapSeasonsToSelector(seasons);

  const isDisabled = checkDisabled(match);

  const handleSeasonChange = (val) => {
    const selected = data.find((option) => option.value === val);
    getActiveSeasonAction(selected.data);
    cleanActiveTeamAction();
  };

  return (
    <div className="SeasonSelector">
      <div className="SeasonSelector-heading">Season:</div>
      <Selector
        options={data}
        value={get(active, "value") || "-"}
        handleChange={handleSeasonChange}
        isSearchable={false}
        disabled={isDisabled || disabledSeason}
      />
    </div>
  );
};

SeasonSelector.propTypes = {
  activeSeason: PropTypes.object,
  disabledSeason: PropTypes.bool,
  getActiveSeasonAction: PropTypes.func.isRequired,
  seasons: PropTypes.array.isRequired,
  cleanActiveTeamAction: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};

SeasonSelector.defaultProps = {
  activeSeason: {},
  disabled: false,
  disabledSeason: false,
};

const mapStateToProps = (state) => ({
  activeSeason: get(state, "activeTeam.activeSeason"),
  seasons: get(state, "activeTeam.seasons"),
});

const mapDispatchToProps = {
  cleanActiveTeamAction: cleanActiveTeam,
  getActiveSeasonAction: getActiveSeason,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SeasonSelector));
