import React, { useState } from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  Notification,
} from 'shared/components';
import { deleteReportEmail } from '../../actions';
import './styles.scss';

const DeleteEmailPopup = ({
  close,
  isOpen,
  email,
  refreshEmailList,
}) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = e => {
    const emailId = get(email, 'id');
    e.preventDefault();

    setLoading(true);

    deleteReportEmail(emailId)
      .then(() => {
        refreshEmailList();
        Notification('success', 'Email removed');
        close();
      })
      .catch(() => {
        setLoading(false);
        Notification('error', 'Error occured', 'There was an error while removing an email');
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      closeCb={close}
      title="Are you sure you want to remove this email from a report subscribers list?"
      size="md"
    >
      <div className="DeleteEmailPopup">
        <form
          className="DeleteEmailPopup-form"
          onSubmit={handleSubmit}
        >
          <span>
            {email.email}
          </span>
          <div className="form-submit">
            <Button
              theme="link"
              type="button"
              disabled={loading}
              onClick={close}
            >
              Cancel
            </Button>
            <Button
              theme="error"
              type="submit"
              disabled={loading}
            >
              Remove
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

DeleteEmailPopup.propTypes = {
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  email: PropTypes.object.isRequired,
  refreshEmailList: PropTypes.func.isRequired,
};

export default DeleteEmailPopup;
