/* eslint-disable */
import api from 'shared/api';

export const getDivisions = (organization) => {
  const url = `/api/v1/divisions/?organization=${organization}`;
  return api.get(url);
};

export const updateEvent = (data, id) => {
  const url = `/api/v1/events/${id}/`;
  return api.patch(url,data);
};
