import React, {
  useState,
  useEffect,
} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import sortBy from 'lodash.sortby';
import {
  ContentLoader,
  NotAuthorized,
} from 'shared/components';
import {
  isAdmin,
} from 'shared/helpers';
import InningCard from './components/InningCard';
import { getGameActions } from './actions';
import { selectActions } from './helpers';
import './styles.scss';

const GamePlays = ({
  gameId,
  isUserStaff,
  teamId,
  fetchGameData,
}) => {
  const [gameActions, setGameActions] = useState([]);
  const [debugData, setDebugData] = useState({});
  const [loading, setLoading] = useState(true);
  const isAuth = isAdmin(teamId);

  if (!isAuth) {
    return <NotAuthorized message="Only team admins can see game stats" />;
  }

  const fetchActions = () => {
    getGameActions(gameId)
      .then(res => {
        const data = get(res, 'data.results') || [];
        const filteredData = data.filter(item => {
          const plays = get(item, 'action_meta.plays') || [];

          return plays.length > 0;
        });
        const sortedData = sortBy(filteredData, ['id']).reverse();
        setGameActions(get(sortedData, '[0].action_meta.plays') || []);
        setDebugData(get(sortedData, '[0]') || {});
        setLoading(false);
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
  };

  useEffect(() => {
    fetchActions();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchActions();
      fetchGameData();
    }, 10 * 1000);

    return () => clearInterval(interval);
  }, []);

  if (loading) {
    return (
      <div className="GamePlays">
        <ContentLoader />
      </div>
    );
  }

  const inning1Bottom = selectActions(gameActions, 1, 'bottom');
  const inning1Top = selectActions(gameActions, 1, 'top');
  const inning2Bottom = selectActions(gameActions, 2, 'bottom');
  const inning2Top = selectActions(gameActions, 2, 'top');
  const inning3Bottom = selectActions(gameActions, 3, 'bottom');
  const inning3Top = selectActions(gameActions, 3, 'top');
  const inning4Bottom = selectActions(gameActions, 4, 'bottom');
  const inning4Top = selectActions(gameActions, 4, 'top');
  const inning5Bottom = selectActions(gameActions, 5, 'bottom');
  const inning5Top = selectActions(gameActions, 5, 'top');
  const inning6Bottom = selectActions(gameActions, 6, 'bottom');
  const inning6Top = selectActions(gameActions, 6, 'top');

  const debugDataSummary = {
    time: debugData.time,
    playerOnFirstBase: get(debugData, 'action_meta.playerOnFirstBase') || null,
    playerOnSecondBase: get(debugData, 'action_meta.playerOnSecondBase') || null,
    playerOnThirdBase: get(debugData, 'action_meta.playerOnThirdBase') || null,
    shortStopDefender: get(debugData, 'action_meta.shortStopDefender') || null,
    secondBaseDefender: get(debugData, 'action_meta.secondBaseDefender') || null,
    leftMidFielderDefender: get(debugData, 'action_meta.leftMidFielderDefender') || null,
    runs_by_inning_away: get(debugData, 'action_meta.runs_by_inning_away') || null,
    runs_by_inning_home: get(debugData, 'action_meta.runs_by_inning_home') || null,
    errors_by_inning_away: get(debugData, 'action_meta.errors_by_inning_away') || null,
    errors_by_inning_home: get(debugData, 'action_meta.errors_by_inning_home') || null,
    hits_by_inning_away: get(debugData, 'action_meta.hits_by_inning_away') || null,
    hits_by_inning_home: get(debugData, 'action_meta.hits_by_inning_home') || null,
    r_by_inning_away: get(debugData, 'action_meta.r_by_inning_away') || null,
    r_by_inning_home: get(debugData, 'action_meta.r_by_inning_home') || null,
    r_away: get(debugData, 'action_meta.r_away') || null,
    r_home: get(debugData, 'action_meta.r_home') || null,
    outs: get(debugData, 'action_meta.outs') || null,
    balls: get(debugData, 'action_meta.balls') || null,
    calls: get(debugData, 'action_meta.calls') || null,
    homeTeamBatterIndex: get(debugData, 'action_meta.homeTeamBatterIndex') || null,
    awayTeamBatterIndex: get(debugData, 'action_meta.awayTeamBatterIndex') || null,
    currentBatterIndex: get(debugData, 'action_meta.currentBatterIndex') || null,
    homeTeamScore: get(debugData, 'action_meta.homeTeamScore') || null,
    awayTeamScore: get(debugData, 'action_meta.awayTeamScore') || null,
  };

  return (
    <div className="GamePlays">
      <div className="legend">
        *plays are sorted from newest to oldest
      </div>
      {
        isUserStaff && (
          <div style={{ marginBottom: '20px', overflowWrap: 'break-word', fontSize: '14px' }}>
            <div>{JSON.stringify(debugDataSummary)}</div>
          </div>
        )
      }
      {
        inning6Bottom && (
          <InningCard
            heading="Bottom 6th"
            events={inning6Bottom}
            isUserStaff={isUserStaff}
          />
        )
      }
      {
        inning6Top && (
          <InningCard
            heading="Top 6th"
            events={inning6Top}
            isUserStaff={isUserStaff}
          />
        )
      }
      {
        inning5Bottom && (
          <InningCard
            heading="Bottom 5th"
            events={inning5Bottom}
            isUserStaff={isUserStaff}
          />
        )
      }
      {
        inning5Top && (
          <InningCard
            heading="Top 5th"
            events={inning5Top}
            isUserStaff={isUserStaff}
          />
        )
      }
      {
        inning4Bottom && (
          <InningCard
            heading="Bottom 4th"
            events={inning4Bottom}
            isUserStaff={isUserStaff}
          />
        )
      }
      {
        inning4Top && (
          <InningCard
            heading="Top 4th"
            events={inning4Top}
            isUserStaff={isUserStaff}
          />
        )
      }
      {
        inning3Bottom && (
          <InningCard
            heading="Bottom 3rd"
            events={inning3Bottom}
            isUserStaff={isUserStaff}
          />
        )
      }
      {
        inning3Top && (
          <InningCard
            heading="Top 3rd"
            events={inning3Top}
            isUserStaff={isUserStaff}
          />
        )
      }
      {
        inning2Bottom && (
          <InningCard
            heading="Bottom 2nd"
            events={inning2Bottom}
            isUserStaff={isUserStaff}
          />
        )
      }
      {
        inning2Top && (
          <InningCard
            heading="Top 2nd"
            events={inning2Top}
            isUserStaff={isUserStaff}
          />
        )
      }
      {
        inning1Bottom && (
          <InningCard
            heading="Bottom 1st"
            events={inning1Bottom}
            isUserStaff={isUserStaff}
          />
        )
      }
      {
        inning1Top && (
          <InningCard
            heading="Top 1st"
            events={inning1Top}
            isUserStaff={isUserStaff}
          />
        )
      }
    </div>
  );
};

GamePlays.propTypes = {
  gameId: PropTypes.number.isRequired,
  isUserStaff: PropTypes.bool.isRequired,
  teamId: PropTypes.number.isRequired,
  fetchGameData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isUserStaff: get(state, 'user.details.is_staff'),
});

export default connect(mapStateToProps)(GamePlays);
