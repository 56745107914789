import React, { Component } from "react";
import get from "lodash.get";
import PropTypes from "prop-types";
import update from "immutability-helper";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Button,
  Label,
  Notification,
  PasswordInput,
  TextInput,
} from "shared/components";
import { register, registerError, registerSuccess } from "store/user/actions";
import * as validation from "./validation";
import "./styles.scss";
import logo from "./logo.png";

class RegisterContainer extends Component {
  constructor(props) {
    super(props);

    const { isLoading } = this.props;

    this.state = {
      loading: isLoading,
      email: {
        value: "",
        error: "",
      },
      password: {
        error: "",
        value: "",
      },
      passwordRepeat: {
        error: "",
        value: "",
      },
      isSuccess: false,
    };

    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handlePassword2Change = this.handlePassword2Change.bind(this);
    this.validateUserEmail = this.validateUserEmail.bind(this);
    this.validatePasswords = this.validatePasswords.bind(this);
    this.isFormValid = this.isFormValid.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  componentDidMount() {
    const { history, user } = this.props;
    if (get(user, "id")) {
      history.push("/");
    }
  }

  handleEmailChange(val) {
    this.setState(
      (prevState) =>
        update(prevState, {
          email: {
            value: { $set: val },
          },
        }),
      () => {
        validation.debouncedValidateUserEmail(val).then((d) => {
          this.setState((prevState) =>
            update(prevState, {
              email: {
                error: { $set: d },
              },
            })
          );
        });
      }
    );
  }

  handlePasswordChange(password) {
    this.setState(
      (prevState) =>
        update(prevState, {
          dirty: { $set: true },
          password: {
            value: { $set: password },
          },
        }),
      () => {
        validation.debouncedValidatePassword(password).then((d) => {
          this.setState((prevState) =>
            update(prevState, {
              password: { error: { $set: d } },
            })
          );
          const { passwordRepeat } = this.state;
          if (passwordRepeat.touched) {
            validation
              .debouncedValidatePasswordAgain(password, passwordRepeat.value)
              .then((data) => {
                this.setState((prevState) =>
                  update(prevState, {
                    passwordRepeat: {
                      error: { $set: data },
                    },
                  })
                );
              });
          }
        });
      }
    );
  }

  handlePassword2Change(passwordAgain) {
    const { password } = this.state;
    this.setState(
      (prevState) =>
        update(prevState, {
          dirty: { $set: true },
          passwordRepeat: {
            value: { $set: passwordAgain },
          },
        }),
      () => {
        validation
          .debouncedValidatePasswordAgain(password.value, passwordAgain)
          .then((d) => {
            this.setState((prevState) =>
              update(prevState, {
                passwordRepeat: {
                  error: { $set: d },
                },
              })
            );
          });
      }
    );
  }

  validateUserEmail() {
    const { email } = this.state;

    let isValid = false;

    this.setState({
      loading: true,
    });

    return validation.validateUserEmail(email.value).then((d) => {
      this.setState((prevState) =>
        update(prevState, {
          email: {
            error: { $set: d },
          },
          loading: { $set: false },
        })
      );
      isValid = !d;
      return isValid;
    });
  }

  validatePasswords() {
    const { password, passwordRepeat } = this.state;

    const isValid = [];

    this.setState({
      loading: true,
    });

    return validation
      .validatePassword(password.value)
      .then((d) => {
        this.setState((prevState) =>
          update(prevState, {
            password: {
              error: { $set: d },
            },
          })
        );
        isValid.push(!d);
        return validation.validatePasswordAgain(
          password.value,
          passwordRepeat.value
        );
      })
      .then((d) => {
        this.setState((prevState) =>
          update(prevState, {
            passwordRepeat: {
              error: { $set: d },
            },
            loading: { $set: false },
          })
        );
        isValid.push(!d);
        return !!isValid[0] && !!isValid[1];
      });
  }

  async isFormValid() {
    const isEmailValid = await this.validateUserEmail();
    const isNewPassValid = await this.validatePasswords();
    return isEmailValid && isNewPassValid;
  }

  async handleFormSubmit(e) {
    e.preventDefault();
    const { email, password, passwordRepeat, loading } = this.state;
    const { registerAction, registerErrorAction, registerSuccessAction } =
      this.props;

    const isFormValid = await this.isFormValid();

    if (!isFormValid || loading) {
      return;
    }

    this.setState({
      loading: true,
    });

    const registerData = {
      email: email.value,
      password1: password.value,
      password2: passwordRepeat.value,
    };

    registerAction(registerData)
      .then((res) => {
        registerSuccessAction(get(res, "data"));
        this.setState({
          isSuccess: true,
        });
      })
      .catch((err) => {
        registerErrorAction(err);
        Notification(
          "error",
          "Unable to register",
          "There was an error while saving your changes"
        );
        this.setState({
          loading: false,
        });
      });
  }

  render() {
    const { email, password, passwordRepeat, loading, isSuccess } = this.state;
    const { history } = this.props;

    if (isSuccess) {
      return (
        <div className="RegisterContainer">
          <div className="success-container">
            <div className="success-logo-cont">
              <img src={logo} alt="designatted hitter logo" height="80" />
            </div>
            <div className="success-message">
              Thank you for your registration! We have sent you an email to
              <span>{email.value}</span>
              to confirm your account and start using our platform.
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="RegisterContainer">
        <div className="form-container">
          <form
            className="register-form"
            id="register-form"
            onSubmit={this.handleFormSubmit}
          >
            <fieldset disabled={loading}>
              <div className="form-row logo-cont">
                <img src={logo} alt="designatted hitter logo" height="80" />
              </div>
              <div className="form-row">
                <h2>Sign up</h2>
              </div>
              <div className="form-row input-cont">
                <Label inputId="register-email" text="Email" />
                <TextInput
                  disabled={loading}
                  handleChange={this.handleEmailChange}
                  id="register-email"
                  type="email"
                  error={email.error}
                  value={email.value}
                />
              </div>
              <div className="form-row input-cont">
                <Label inputId="password" text="Enter password" />
                <PasswordInput
                  disabled={loading}
                  error={password.error}
                  id="password"
                  handleChange={this.handlePasswordChange}
                  value={password.value}
                />
              </div>
              <div className="form-row input-cont">
                <Label inputId="password-again" text="Confirm password" />
                <PasswordInput
                  disabled={loading}
                  error={passwordRepeat.error}
                  id="password-again"
                  handleChange={this.handlePassword2Change}
                  value={passwordRepeat.value}
                />
              </div>
              <div className="form-row btn-cont">
                <Button disabled={loading} size="lg" type="submit">
                  Sign up
                </Button>
              </div>
              <div className="form-row login-cont">
                {"Already have an account?"}
                <button
                  type="button"
                  className="login-redirect"
                  onClick={() => history.push("/login")}
                >
                  Log in
                </button>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    );
  }
}

RegisterContainer.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  registerAction: PropTypes.func.isRequired,
  registerErrorAction: PropTypes.func.isRequired,
  registerSuccessAction: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  isLoading: get(state, "user.registerLoading"),
  user: get(state, "user") || {},
});

const mapDispatchToProps = {
  registerAction: register,
  registerErrorAction: registerError,
  registerSuccessAction: registerSuccess,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RegisterContainer));
