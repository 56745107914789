import React, { useState } from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import {
  Button,
  Label,
  Modal,
  TextInput,
  Notification,
} from 'shared/components';
import {
  emailRegex,
} from 'shared/constants';
import { editReportEmail } from '../../actions';
import './styles.scss';

const EditEmailForm = ({
  close,
  isOpen,
  email,
  refreshEmailList,
}) => {
  const [dirty, setDirty] = useState(false);
  const [loading, setLoading] = useState(false);
  const [emailValue, setEmailValue] = useState(get(email, 'email'));
  const [emailError, setEmailError] = useState('');

  const handleClose = () => {
    if (
      !dirty ||
      window.confirm('Are you sure you want to discard your changes?')
    ) {
      close();
    }
  };

  const validateEmail = () => emailRegex.test(emailValue);

  const handleSubmit = e => {
    e.preventDefault();
    const emailId = get(email, 'id');

    const isEmailValid = validateEmail();

    if (!isEmailValid) {
      setEmailError('Enter valid email');
      return;
    }

    if (loading) {
      return;
    }

    setLoading(true);

    const data = {
      email: emailValue,
    };

    editReportEmail(emailId, data)
      .then(() => {
        refreshEmailList();
        Notification('success', 'Subscriber succesfully updated');
        close();
      })
      .catch(() => {
        setLoading(false);
        Notification('error', 'Error occured', 'There was an error while updating the subscriber');
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      closeCb={handleClose}
      title="Edit report subscriber"
      size="sm"
    >
      <div className="EditEmailForm">
        <form
          className="EditEmailForm-form"
          onSubmit={handleSubmit}
        >
          <Label
            inputId="user-email"
            text="Email"
          />
          <TextInput
            id="user-email"
            value={emailValue}
            handleChange={val => {
              setDirty(true);
              setEmailValue(val);
            }}
            placeholder="Enter email"
            disabled={loading}
            error={emailError}
            type="email"
            required
          />
          <div className="form-submit">
            <Button
              theme="link"
              type="button"
              disabled={loading}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              theme="success"
              type="submit"
              onClick={handleSubmit}
              disabled={loading}
            >
              Submit
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

EditEmailForm.propTypes = {
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  email: PropTypes.object.isRequired,
  refreshEmailList: PropTypes.func.isRequired,
};

export default EditEmailForm;
