import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import {
  Link,
} from 'react-router-dom';
import {
  DescriptionList,
  FileUploader,
  Notification,
  NotAuthorized,
  Table,
} from 'shared/components';
import api from 'shared/api';
import {
  displayValue,
  formatDate,
  isAdmin,
} from 'shared/helpers';
import {
  defaultDateTimeFormat,
} from 'shared/constants';
import ImportConsentPopup from './components/ImportConsentPopup';
import Template from './team_import_example.csv';
import './styles.scss';

const showErrorsContainer = (result) => {
  const traceback = get(result, 'traceback');
  const errors = get(result, 'rows.invalid.errors');

  if (traceback) {
    return (
      <div className="DescriptionList-errors">
        {traceback}
      </div>
    );
  }

  if (!errors || !errors.length) {
    return '-';
  }

  return (
    <div className="DescriptionList-errors">
      {
        errors.map(e => (
          <div className="error-item" key={e.row}>
            {`Row ${e.row}: ${e.error}`}
          </div>
        ))
      }
    </div>
  );
};

const showStatusCell = val => {
  switch (val) {
    case 'success':
      return (
        <div className="ImportStatusCell">
          <svg width="18px" height="18px" fill="#306d12" viewBox="0 0 48 50">
            <path
              d="M24,5A19,19,0,1,0,43,24,19,19,0,0,0,24,5ZM36.41,18.41l-14,14a2,2,0,0,1-2.82,0l-6-6a2,2,0,0,1,2.82-2.82L21,28.17,33.59,15.59a2,2,0,0,1,2.82,2.82Z"
            />
          </svg>
          {val}
        </div>
      );
    case 'fail':
      return (
        <div className="ImportStatusCell">
          <svg width="15px" height="15px" fill="#AE0721" viewBox="0 0 8 8">
            <path
              d="M4 0c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm-1.5 1.78l1.5 1.5 1.5-1.5.72.72-1.5 1.5 1.5 1.5-.72.72-1.5-1.5-1.5 1.5-.72-.72 1.5-1.5-1.5-1.5.72-.72z"
            />
          </svg>
          {val}
        </div>
      );
    case 'error':
      return (
        <div className="ImportStatusCell">
          <svg width="20px" height="20px" fill="#AE0721" viewBox="0 0 100 100">
            <path d="M50,18A32,32,0,1,0,82,50,32,32,0,0,0,50,18Zm0,50a4,4,0,1,1,4-4A4,4,0,0,1,50,68Zm4-14H46V32h8Z" />
          </svg>
          {val}
        </div>
      );
    case 'running':
      return (
        <div className="ImportStatusCell">
          <svg width="18px" height="18px" fill="#21366D" viewBox="0 0 100 125">
            <g>
              <path d="M50,15c-19.33,0-35,15.67-35,35s15.67,35,35,35s35-15.67,35-35S69.33,15,50,15z M39,70V30l30,20L39,70z" />
              <path
                d="M50,87c-20.4,0-37-16.6-37-37c0-20.4,16.6-37,37-37c20.4,0,37,16.6,37,37C87,70.4,70.4,87,50,87z M50,17   c-18.2,0-33,14.8-33,33s14.8,33,33,33s33-14.8,33-33S68.2,17,50,17z M37,73.74V26.26L72.61,50L37,73.74z M41,33.74v32.53L65.39,50   L41,33.74z"
              />
            </g>
          </svg>
          {val}
        </div>
      );
    case 'pending':
      return (
        <div className="ImportStatusCell">
          <svg width="18px" height="18px" fill="#717171" viewBox="0 0 30 27.5">
            <path
              d="M15,4C8.9,4,4,8.9,4,15s4.9,11,11,11s11-4.9,11-11S21.1,4,15,4z M21.7,16.8c-0.1,0.4-0.5,0.6-0.9,0.5l-5.6-1.1  c-0.2,0-0.4-0.2-0.6-0.3C14.2,15.7,14,15.4,14,15c0,0,0,0,0,0l0.2-8c0-0.5,0.4-0.8,0.8-0.8c0.4,0,0.8,0.4,0.8,0.8l0.1,6.9l5.2,1.8  C21.6,15.8,21.8,16.3,21.7,16.8z"
            />
          </svg>
          {val}
        </div>
      );
    default:
      return (
        <div className="ImportStatusCell" />
      );
  }
};

const TeamImport = ({
  activeSeason,
  teamId,
  leagueId,
}) => {
  const seasonId = get(activeSeason, 'id');
  const isAuth = isAdmin(teamId);

  if (!isAuth) {
    return <NotAuthorized message="Only team admins can see team imports" />;
  }

  const [loading, setLoading] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [importList, setImportList] = useState([]);
  const [expandedTableRow, setExpandedTableRow] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [isImportConsentDisplayed, setImportConsentDisplay] = useState(false);

  const fetchImports = () => {
    api.get(`/api/v1/importbcgtasks/?season__league__id=${leagueId}&team__id=${teamId}`)
      .then((res) => {
        const list = get(res, 'data.results');
        setLoading(false);
        setImportList(list);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchImports();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchImports();
    }, 20 * 1000);

    return () => clearInterval(interval);
  }, []);

  const handleWrongFileType = () => {
    Notification('error', 'Wrong file type', 'Only .csv files can be uploaded');
    setUploading(false);
  };

  const handleItemRemove = () => {
    setUploading(false);
    setProgress(0);
  };

  const handleSubmitClick = val => {
    setSelectedFile(val);
    setImportConsentDisplay(true);
  };

  const handleSubmit = val => {
    const file = get(val, 'file');
    setUploading(true);
    // eslint-disable-next-line no-undef
    const data = new FormData();
    data.append('file', file);

    api.post(`/api/v1/seasons/${seasonId}/import_csv/?team=${teamId}`, data, {
      onUploadProgress: (progressEvent) => {
        if (progressEvent.lengthComputable) {
          const percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
          setProgress(percentCompleted);
        }
      },
    })
      .then(() => {
        Notification('success', `File ${file.name} uploaded`, 'Import process has started');
        val.remove();
        setUploading(false);
        fetchImports();
      })
      .catch(() => {
        Notification('error', 'Upload failed', 'Please try again or change the file');
        setUploading(false);
      });
  };

  return (
    <div className="TeamImport">
      <FileUploader
        disabled={uploading}
        progress={progress}
        onSubmit={handleSubmitClick}
        onWrongFileTypeReject={handleWrongFileType}
        onItemRemove={handleItemRemove}
      />
      <div className="template-link">
        <Link to={Template} target="_blank" download>Download import template</Link>
      </div>
      <div className="TeamImport-list">
        <div className="heading">Imports list</div>
        <Table
          data={importList}
          noDataText=""
          NoDataComponent={() => null}
          minRows={1}
          loading={loading}
          onExpandedChange={expanded => setExpandedTableRow(expanded)}
          expanded={expandedTableRow}
          // eslint-disable-next-line no-unused-vars
          SubComponent={row => (
            <div className="SubComponent">
              <DescriptionList
                data={[
                  {
                    label: 'Total rows',
                    value: displayValue(get(row, 'original.result.rows.total')),
                  },
                  {
                    label: 'Valid rows',
                    value: displayValue(get(row, 'original.result.rows.valid')),
                  },
                  {
                    label: 'Errors',
                    value: showErrorsContainer(get(row, 'original.result')),
                  },
                ]}
              />
            </div>
          )}
          columns={[
            {
              expander: true,
              Header: 'Details',
              headerClassName: 'text-center',
              width: 80,
              style: {
                fontSize: 25,
                padding: '0',
                textAlign: 'center',
                userSelect: 'none',
              },
            },
            {
              accessor: 'file_name',
              Header: 'File name',
              Cell: cellData => displayValue(cellData.value),
            },
            {
              accessor: 'time_create',
              Header: 'Upload date',
              Cell: cellData => formatDate(cellData.value, defaultDateTimeFormat),
            },
            {
              accessor: 'status',
              Header: 'Status',
              Cell: cellData => {
                const val = get(cellData, 'value');
                return showStatusCell(val);
              },
            },
            {
              accessor: 'result.users.created',
              Header: 'Created users',
              Cell: cellData => displayValue(cellData.value),
            },
            {
              accessor: 'result.players.created',
              Header: 'Created players',
              Cell: cellData => displayValue(cellData.value),
            },
          ]}
        />
      </div>
      {
        isImportConsentDisplayed && (
          <ImportConsentPopup
            isOpen={isImportConsentDisplayed}
            close={() => {
              setImportConsentDisplay(false);
              setSelectedFile(null);
            }}
            confirmCb={() => {
              setImportConsentDisplay(false);
              handleSubmit(selectedFile);
            }}
          />
        )
      }
    </div>
  );
};

TeamImport.propTypes = {
  teamId: PropTypes.number.isRequired,
  leagueId: PropTypes.number.isRequired,
  activeSeason: PropTypes.object.isRequired,
};

export default TeamImport;
