import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import sortBy from 'lodash.sortby';
import {
  ContentLoader,
  Table,
  IconEdit,
} from 'shared/components';
import {
  displayValue,
} from 'shared/helpers';
import { fetchComplianceRules } from './actions';
import EditRuleForm from './components/EditRuleForm';
import TablePitchesRange from './components/TablePitchesRange';
import SuplementalRules from './components/SuplementalRules';
import './styles.scss';

const LeagueRules = ({
  leagueId,
  isLeagueAdmin,
}) => {
  const [loading, setLoading] = useState(true);
  const [rules, setRules] = useState([]);
  const [ruleToEdit, setRuleToEdit] = useState(null);
  const [isEditFormDisplayed, setEditFormDisplay] = useState(false);

  const getRules = () => {
    fetchComplianceRules(leagueId)
      .then(res => {
        const data = get(res, 'data.results') || [];

        setRules(data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getRules();
  }, [leagueId]);

  if (loading) {
    return <ContentLoader />;
  }

  return (
    <div className="LeagueRules">
      <div className="LeagueRules-table">
        <Table
          data={sortBy(rules, ['daily_max'])}
          noDataText=""
          columns={[
            {
              accessor: 'age_group',
              Header: 'Age',
              headerClassName: 'text-center',
              className: 'text-center',
              maxWidth: 70,
              Cell: cellData => displayValue(cellData.value),
            },
            {
              accessor: 'daily_max',
              Header: 'Daily Max (Pitches in Game)',
              headerClassName: 'text-center',
              className: 'text-center',
              Cell: cellData => displayValue(cellData.value),
            },
            {
              id: 'rest_0_days',
              Header: '0 Days Rest',
              headerClassName: 'text-center',
              className: 'text-center',
              Cell: cellData => (
                <TablePitchesRange
                  minPitchCount={get(cellData, 'original.rest_0_min')}
                  maxPitchCount={get(cellData, 'original.rest_0_max')}
                />
              ),
            },
            {
              id: 'rest_1_days',
              Header: '1 Days Rest',
              headerClassName: 'text-center',
              className: 'text-center',
              Cell: cellData => (
                <TablePitchesRange
                  minPitchCount={get(cellData, 'original.rest_1_min')}
                  maxPitchCount={get(cellData, 'original.rest_1_max')}
                />
              ),
            },
            {
              id: 'rest_2_days',
              Header: '2 Days Rest',
              headerClassName: 'text-center',
              className: 'text-center',
              Cell: cellData => (
                <TablePitchesRange
                  minPitchCount={get(cellData, 'original.rest_2_min')}
                  maxPitchCount={get(cellData, 'original.rest_2_max')}
                />
              ),
            },
            {
              id: 'rest_3_days',
              Header: '3 Days Rest',
              headerClassName: 'text-center',
              className: 'text-center',
              Cell: cellData => (
                <TablePitchesRange
                  minPitchCount={get(cellData, 'original.rest_3_min')}
                  maxPitchCount={get(cellData, 'original.rest_3_max')}
                />
              ),
            },
            {
              id: 'rest_4_days',
              Header: '4 Days Rest',
              headerClassName: 'text-center',
              className: 'text-center',
              Cell: cellData => (
                <TablePitchesRange
                  minPitchCount={get(cellData, 'original.rest_4_min')}
                  maxPitchCount={get(cellData, 'original.rest_4_max')}
                />
              ),
            },
            {
              id: 'rest_5_days',
              Header: '5 Days Rest',
              headerClassName: 'text-center',
              className: 'text-center',
              Cell: cellData => (
                <TablePitchesRange
                  minPitchCount={get(cellData, 'original.rest_5_min')}
                  maxPitchCount={get(cellData, 'original.rest_5_max')}
                />
              ),
            },
            {
              Header: 'Edit',
              headerClassName: 'text-center',
              className: 'text-center button-wrapper',
              sortable: false,
              Cell: cellData => (
                <button
                  aria-label="Edit rule"
                  className="edit-button"
                  onClick={e => {
                    e.stopPropagation();
                    setRuleToEdit(cellData.original);
                    setEditFormDisplay(true);
                  }}
                  type="button"
                >
                  <IconEdit
                    height="20px"
                    width="20px"
                  />
                </button>
              ),
              width: 100,
              show: isLeagueAdmin,
            },
          ]}
          minRows={1}
          showPagination={false}
          sortable={false}
        />
        <SuplementalRules leagueId={leagueId} isLeagueAdmin={isLeagueAdmin} />
      </div>
      {
        isEditFormDisplayed && (
          <EditRuleForm
            isOpen={isEditFormDisplayed}
            close={() => setEditFormDisplay(false)}
            rule={ruleToEdit}
            refetchRules={getRules}
          />
        )
      }
    </div>
  );
};

LeagueRules.propTypes = {
  leagueId: PropTypes.number.isRequired,
  isLeagueAdmin: PropTypes.bool.isRequired,
};

export default LeagueRules;
