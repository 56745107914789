import * as actionTypes from './actionTypes';

const initialState = {
  loading: true,
  details: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.getUserTeamsInit:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.getUserTeamsSuccess:
      return {
        ...state,
        loading: false,
        details: action.teams,
      };
    case actionTypes.getUserTeamsError:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.refreshUserTeams:
      return {
        ...state,
        details: action.teams,
      };
    default:
      return state;
  }
}
