export const mockQab = {
  data: [
    {
      date: new Date(),
      against: 'TEAM2',
      awayGame: true,
      gameId: 3,
      dsgnqab: '62.79%',
      qab: 18,
      qabperc: '48.65%',
      plusminus: 9,
      wl: 'W, TEAM1 13, TEAM2 4',
    },
  ],
};
