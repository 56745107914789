import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import {
  Button,
  Modal,
  Table,
} from 'shared/components';
import {
  displayValue,
  formatDate,
} from 'shared/helpers';
import {
  defaultDateFormat,
  playerPositionOptions,
  playerSideOptions,
} from 'shared/constants';
import './styles.scss';

const ExistingPlayerPopup = ({
  addExistingPlayer,
  createNewPlayer,
  isOpen,
  close,
  players,
  loading,
}) => {
  const handleExistingPlayerClick = player => {
    addExistingPlayer(player);
  };

  return (
    <Modal
      isOpen={isOpen}
      closeCb={close}
      title="Player with this name already exists"
      size="xl"
    >
      <div className="ExistingPlayerPopup">
        <div className="ExistingPlayerPopup-heading">
          Do you want to add existing player to the team season roster?
        </div>
        <div className="ExistingPlayerPopup-table">
          <Table
            data={players}
            columns={[
              {
                accessor: 'first_name',
                Header: 'First name',
                Cell: cellData => displayValue(cellData.value),
              },
              {
                accessor: 'last_name',
                Header: 'Last name',
                Cell: cellData => displayValue(cellData.value),
              },
              {
                accessor: 'position',
                Header: 'Position',
                Cell: cellData => {
                  const position = get(cellData, 'value') || '';
                  const item = playerPositionOptions.find(p => p.value === position);
                  return displayValue(get(item, 'label'));
                },
              },
              {
                accessor: 'throws',
                Header: 'Throws',
                Cell: cellData => {
                  const position = get(cellData, 'value') || '';
                  const item = playerSideOptions.find(p => p.value === position);
                  return displayValue(get(item, 'label'));
                },
              },
              {
                accessor: 'bats',
                Header: 'Bats',
                Cell: cellData => {
                  const position = get(cellData, 'value') || '';
                  const item = playerSideOptions.find(p => p.value === position);
                  return displayValue(get(item, 'label'));
                },
              },
              {
                accessor: 'date_of_birth',
                Header: 'Date of birth',
                Cell: cellData => formatDate(cellData.value, defaultDateFormat),
              },
              {
                accessor: 'city',
                Header: 'City',
                Cell: cellData => displayValue(cellData.value),
              },
              {
                accessor: 'state',
                Header: 'State',
                Cell: cellData => displayValue(cellData.value),
              },
              {
                Header: '',
                headerClassName: 'text-center',
                className: 'text-center button-wrapper',
                sortable: false,
                Cell: cellData => (
                  <button
                    aria-label="Add player"
                    className="add-player-button"
                    disabled={loading}
                    onClick={e => {
                      e.stopPropagation();
                      handleExistingPlayerClick(cellData.original);
                    }}
                    type="button"
                  >
                    Add player
                  </button>
                ),
                width: 150,
              },
            ]}
            minRows={1}
            showPagination={false}
          />
        </div>
        <div className="form-submit">
          <Button
            theme="link"
            type="button"
            disabled={loading}
            onClick={close}
          >
            Cancel
          </Button>
          <Button
            type="button"
            theme="success"
            disabled={loading}
            onClick={() => createNewPlayer()}
          >
            Create new player
          </Button>
        </div>
      </div>
    </Modal>
  );
};

ExistingPlayerPopup.propTypes = {
  addExistingPlayer: PropTypes.func.isRequired,
  createNewPlayer: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  players: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default ExistingPlayerPopup;
