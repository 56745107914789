import React, { useState } from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import {
  Button,
  InputErrorMessage,
  Modal,
  MultiEmailInput,
  Notification,
} from 'shared/components';
import { bulkReportEmail } from '../../actions';
import './styles.scss';

const AddEmailsForm = ({
  close,
  isOpen,
  leagueId,
  refreshEmailList,
}) => {
  const [dirty, setDirty] = useState(false);
  const [loading, setLoading] = useState(false);
  const [emails, setEmails] = useState(null);
  const [emailError, setEmailsError] = useState('');

  const handleClose = () => {
    if (
      !dirty ||
      window.confirm('Are you sure you want to discard your changes?')
    ) {
      close();
    }
  };

  const handleSubmit = e => {
    e.preventDefault();

    if (!get(emails, 'emails')) {
      close();
      return;
    }

    if (get(emails, 'emailError')) {
      return;
    }

    if (get(emails, 'value')) {
      setEmailsError(`You have unsubmitted email left: ${get(emails, 'value')}. Delete or submit with 'Enter'`);
      return;
    }

    setLoading(true);

    const data = get(emails, 'emails') || [];

    bulkReportEmail(leagueId, data)
      .then(() => {
        refreshEmailList();
        Notification('success', 'Emails added to the report subscribers list');
        close();
      })
      .catch(() => {
        setLoading(false);
        Notification('error', 'Error occured', 'There was an error while adding emails');
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      closeCb={handleClose}
      title="Add report subscribers"
      size="md"
    >
      <div className="AddEmailsForm">
        <form
          className="AddEmailsForm-form"
          onSubmit={handleSubmit}
        >
          <MultiEmailInput
            onEmailSubmit={val => {
              setDirty(true);
              setEmailsError('');
              setEmails(val);
            }}
          />
          <InputErrorMessage text={emailError} />
          <div className="form-submit">
            <Button
              theme="link"
              type="button"
              disabled={loading}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              theme="success"
              type="submit"
              onClick={handleSubmit}
              disabled={loading}
            >
              Submit
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

AddEmailsForm.propTypes = {
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  leagueId: PropTypes.number.isRequired,
  refreshEmailList: PropTypes.func.isRequired,
};

export default AddEmailsForm;
