import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import {
  Button,
  Checkbox,
  ContentLoader,
  Modal,
  Notification,
  Table,
} from 'shared/components';
import {
  displayValue,
} from 'shared/helpers';
import {
  getAllSuplementalRules,
  addBulkRules,
} from './actions';
import SuplementalRuleMeta from '../SuplementalRuleMeta';
import './styles.scss';

const AddSuplementalRule = ({
  close,
  isOpen,
  refetchRules,
  leagueId,
  existingRules,
}) => {
  const isMounted = React.useRef(true);

  const [fetchingInitialRules, setFetchingInitialRules] = useState(true);
  const [loading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);

  const [rules, setRules] = useState([]);
  const [selectedRules, setSelectedRules] = useState([]);

  const filteredList = (data) => {
    const list = [];

    data.forEach(d => {
      const isAdded = existingRules.find(er => er.code === d.code);
      if (!isAdded) {
        list.push(d);
      }
    });
    return list;
  };

  const fetchRules = () => {
    getAllSuplementalRules()
      .then(res => {
        const results = get(res, 'data.results') || [];

        if (isMounted.current) {
          setRules(filteredList(results));
          setFetchingInitialRules(false);
        }
      });
  };

  useEffect(() => {
    fetchRules();

    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);

    if (!selectedRules || !selectedRules.length) {
      close();
      return false;
    }

    const data = selectedRules.map((sl) => ({
      code: sl.code,
      rule_meta: sl.rule_meta,
      league: leagueId,
    }));

    addBulkRules(data)
      .then(() => {
        Notification('success', 'New rules added to the league');
        refetchRules();
        close();
      })
      .catch(() => {
        setLoading(false);
        Notification(
          'error',
          'Error occured',
          'There was an error while adding new rules to the league.',
        );
      });
    return true;
  };

  const handleClose = () => {
    if (
      !dirty ||
      window.confirm('Are you sure you want to discard your changes?')
    ) {
      close();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      closeCb={handleClose}
      title="Add suplemental rule"
      size="xl"
    >
      <div className="AddSuplementalRule">
        <form
          className="AddSuplementalRule-form"
          onSubmit={handleSubmit}
        >
          {fetchingInitialRules ? <ContentLoader /> : (
            <div className="form-container">
              <Table
                data={rules}
                noDataText="All suplemental rules have been added already to the league"
                columns={[
                  {
                    id: 'select',
                    Header: 'Select',
                    className: 'select-container',
                    Cell: cellData => (
                      <button
                        className="table-select"
                        aria-label="Select rule"
                        onClick={(e) => {
                          e.stopPropagation();
                          let newState;
                          const isSelected = selectedRules.find(sr => cellData.original.id === sr.id);
                          if (isSelected) {
                            newState = selectedRules.filter(sr => sr.id !== cellData.original.id);
                          } else {
                            newState = selectedRules.concat(cellData.original);
                          }
                          setSelectedRules(newState);
                          setDirty(true);
                        }}
                        type="button"
                      >
                        <Checkbox
                          inputId={`${cellData.original.id}-select`}
                          handleChange={(val, e) => {
                            e.stopPropagation();
                            e.preventDefault();
                          }}
                          checked={!!selectedRules.find(sr => cellData.original.id === sr.id)}
                        />
                      </button>
                    ),
                    width: 60,
                    show: true,
                    sortable: false,
                  },
                  {
                    accessor: 'description',
                    Header: 'Rule description',
                    Cell: cellData => displayValue(cellData.value),
                  },
                  {
                    accessor: 'rule_meta',
                    Header: 'Default values',
                    Cell: (cellData) => {
                      const data = get(cellData, 'value');
                      return <SuplementalRuleMeta ruleMeta={data} />;
                    },
                  },
                ]}
                minRows={2}
                showPagination={false}
                loading={loading}
              />
            </div>
          )}
          <div className="form-submit">
            <Button
              theme="link"
              type="button"
              disabled={loading || fetchingInitialRules}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              theme="success"
              type="submit"
              disabled={loading || fetchingInitialRules}
            >
              Add selected rules
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

AddSuplementalRule.propTypes = {
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  refetchRules: PropTypes.func.isRequired,
  leagueId: PropTypes.number.isRequired,
  existingRules: PropTypes.array.isRequired,
};

export default AddSuplementalRule;
