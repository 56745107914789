import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash.get';
import {
  Route,
  Switch,
  withRouter,
} from 'react-router-dom';
import {
  ContentLoader,
  Page,
  NotFound,
} from 'shared/components';
// import {
//   checkUserTeam,
// } from 'shared/helpers';
import { getActiveTeam } from 'store/activeTeam/actions';
import TeamDashboard from 'dashboards/team_dashboard/TeamDashboard';
import GamesDashboard from 'dashboards/games_dashboard/GamesDashboard';
import PlayersDashboard from 'dashboards/players_dashboard/PlayersDashboard';
import LeagueDashboard from 'dashboards/league_dashboard/LeagueDashboard';
import { Sidebar } from '../components';
import './styles.scss';

const getSeasonFromQueryParams = search => {
  const hasSeasonParam = search.includes('?sid=');
  if (!hasSeasonParam) {
    return undefined;
  }
  const seasonParam = search.split('?sid=')[1];
  return Number(seasonParam);
};

const TeamContainer = ({
  activeSeason,
  team,
  getTeamAction,
  location,
  loading,
  match,
  // userTeams,
}) => {
  const teamId = get(match, 'params.teamId');
  const seasonQuery = getSeasonFromQueryParams(get(location, 'search'));

  const fetchTeamData = () => {
    getTeamAction(teamId, seasonQuery);
  };

  useEffect(() => {
    fetchTeamData();
  }, [teamId]);

  if (loading) {
    return (
      <div className="TeamContainer">
        <Sidebar teamId={teamId} location={location} />
        <Page>
          <ContentLoader text="Getting team data" />
        </Page>
      </div>
    );
  }

  if (!team) {
    return (
      <div className="TeamContainer">
        <Sidebar teamId={teamId} location={location} />
        <Page>
          <NotFound />
        </Page>
      </div>
    );
  }

  return (
    <div className="TeamContainer">
      <Sidebar
        teamId={teamId}
        location={location}
      />
      <Page>
        <Switch>
          <Route
            path="/:teamId/games"
            render={() => <GamesDashboard team={team} activeSeason={activeSeason} />}
          />
          <Route
            path="/:teamId/players"
            render={() => <PlayersDashboard team={team} activeSeason={activeSeason} />}
          />
          <Route
            path="/:teamId/league"
            render={() => <LeagueDashboard team={team} activeSeason={activeSeason} />}
          />
          <Route
            path="/:teamId"
            render={() => <TeamDashboard team={team} />}
          />
        </Switch>
      </Page>
    </div>
  );
};

TeamContainer.propTypes = {
  activeSeason: PropTypes.object.isRequired,
  getTeamAction: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  team: PropTypes.object,
  // userTeams: PropTypes.array.isRequired,
};

TeamContainer.defaultProps = {
  team: null,
};

const mapStateToProps = state => ({
  activeSeason: get(state, 'activeTeam.activeSeason'),
  loading: get(state, 'activeTeam.loading'),
  team: get(state, 'activeTeam.details'),
  userTeams: get(state, 'userTeams.details'),
});

const mapDispatchToProps = {
  getTeamAction: getActiveTeam,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TeamContainer));
