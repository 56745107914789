import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash.get';
import { withRouter } from 'react-router-dom';
import {
  Table,
  Selector,
} from 'shared/components';
import {
  displayValue,
  mapDivisionsToSelector,
} from 'shared/helpers';
import { getLeagueTeams, setActiveDivision } from 'store/activeLeague/actions';
import { getTeams } from './actions';
import './styles.scss';

const LeagueTeamsList = ({
  activeSeason,
  activeDivision,
  divisions,
  history,
  leagueId,
  getLeagueTeamsAction,
  setActiveDivisionAction,
  teams,
}) => {
  const activeSeasonId = get(activeSeason, 'id');
  const isMounted = React.useRef(true);
  const [loading, setLoading] = useState(true);

  const redirectToTeamPage = rowData => {
    const teamId = get(rowData, 'original.id');
    return history.push(`/leagues/${leagueId}/${teamId}`);
  };

  const fetchLeagueTeams = () => {
    getTeams(activeSeasonId)
      .then(res => {
        const data = get(res, 'data') || [];
        setLoading(false);
        getLeagueTeamsAction(data);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    fetchLeagueTeams();

    return () => {
      isMounted.current = false;
    };
  }, [activeSeasonId]);

  const divisionsForSelector = mapDivisionsToSelector(divisions);
  const teamsInSelectedDivision = teams.filter(t => {
    if (activeDivision === 'all') { return true; }
    return t.division === activeDivision;
  });

  return (
    <div className="LeagueTeamsList">
      <div className="LeagueTeamsList-filters">
        <Selector
          options={divisionsForSelector}
          value={activeDivision}
          handleChange={val => setActiveDivisionAction(val)}
          disabled={loading}
        />
      </div>
      <div className="teams-container">
        <Table
          data={teamsInSelectedDivision}
          noDataText=""
          loading={loading}
          columns={[
            {
              accessor: 'name',
              Header: 'Name',
              width: 250,
            },
            {
              accessor: 'city',
              Header: 'City',
              Cell: cellData => displayValue(cellData.value),
            },
            {
              accessor: 'state',
              Header: 'State',
              Cell: cellData => displayValue(cellData.value),
            },
            {
              accessor: 'gender',
              Header: 'Gender',
              Cell: cellData => displayValue(cellData.value),
            },
            {
              accessor: 'head_coach',
              Header: 'Head coach',
              Cell: cellData => displayValue(cellData.value),
            },
          ]}
          clickable
          handleClick={rowData => redirectToTeamPage(rowData)}
          minRows={1}
          showPagination={false}
        />
      </div>
    </div>
  );
};

LeagueTeamsList.propTypes = {
  activeSeason: PropTypes.object.isRequired,
  activeDivision: PropTypes.string.isRequired,
  divisions: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  leagueId: PropTypes.number.isRequired,
  getLeagueTeamsAction: PropTypes.func.isRequired,
  setActiveDivisionAction: PropTypes.func.isRequired,
  teams: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  teams: get(state, 'activeLeague.teams'),
  divisions: get(state, 'activeLeague.details.divisions'),
  activeDivision: get(state, 'activeLeague.activeDivision'),
});

const mapDispatchToProps = {
  getLeagueTeamsAction: getLeagueTeams,
  setActiveDivisionAction: setActiveDivision,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LeagueTeamsList));
