import React, { useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import {
  Button,
  Label,
  Modal,
  TextInput,
  Notification,
} from 'shared/components';
import {
  emailRegex,
} from 'shared/constants';
import api from 'shared/api';
import './styles.scss';

const AddPlayerParentsForm = ({
  isOpen,
  close,
  player,
  fetchPlayerData,
}) => {
  const playerId = get(player, 'id');

  const [loading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');

  const validateEmail = () => emailRegex.test(email);

  const handleSubmit = e => {
    e.preventDefault();

    const isEmailValid = validateEmail();

    if (!isEmailValid) {
      setEmailError('Enter valid email');
      return false;
    }

    setLoading(true);

    const parentsData = get(player, 'parents') || [];
    parentsData.push(JSON.stringify({
      name,
      email,
      phone,
    }));

    const data = {
      parents: parentsData,
    };

    return api.patch(`/api/v1/players/${playerId}/`, data)
      .then(() => {
        Notification('success', 'Parents info succesfully added to player');
        fetchPlayerData();
        setLoading(false);
        close();
      })
      .catch(() => {
        setLoading(false);
        Notification(
          'error',
          'Error occured',
          'There was an error while adding a new parent.',
        );
      });
  };

  const handleClose = () => {
    if (
      !dirty ||
      window.confirm('Are you sure you want to discard your changes?')
    ) {
      close();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      closeCb={handleClose}
      title="Add players parent"
      size="sm"
    >
      <div className="AddPlayerParentsForm">
        <form className="AddPlayerParentsForm-form" onSubmit={handleSubmit}>
          <div className="form-row">
            <Label
              inputId="user-name"
              text="Name"
            />
            <TextInput
              id="user-name"
              type="text"
              value={name}
              handleChange={val => {
                setDirty(true);
                setName(val);
              }}
              placeholder="Enter parents name"
              disabled={loading}
              required
            />
          </div>
          <div className="form-row">
            <Label
              inputId="user-email"
              text="Email"
            />
            <TextInput
              id="user-email"
              type="email"
              value={email}
              error={emailError}
              handleChange={val => {
                setDirty(true);
                setEmail(val);
              }}
              placeholder="Enter contact email"
              disabled={loading}
              required
            />
          </div>
          <div className="form-row">
            <Label
              inputId="user-phone"
              text="Phone"
            />
            <TextInput
              id="user-phone"
              type="tel"
              value={phone}
              handleChange={val => {
                setDirty(true);
                setPhone(val);
              }}
              placeholder="Enter contact phone number"
              disabled={loading}
              required
            />
          </div>
          <div className="form-submit">
            <Button
              theme="link"
              type="button"
              disabled={loading}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              theme="success"
              type="submit"
              disabled={loading}
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

AddPlayerParentsForm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  fetchPlayerData: PropTypes.func.isRequired,
  player: PropTypes.object.isRequired,
};

export default AddPlayerParentsForm;
