import get from 'lodash.get';

export const mapGameListItems = (games, teamId) => {
  const list = [];

  games.forEach(game => {
    const isAway = get(game, 'team_away.id') === Number(teamId);
    const item = {
      ...game,
      type: isAway ? 'away' : 'home',
      away: isAway,
      opponent: isAway ? get(game, 'team_home.name') : get(game, 'team_away.name'),
    };
    list.push(item);
  });

  return list;
};
