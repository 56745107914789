/* eslint-disable */
/* eslint-disable */
import Cookies from 'js-cookie'

const SetCookie = (name, value, days) => {
  Cookies.set(name, value, {
    expires: days,
    secure: true,
    sameSite: 'strict',
    path: '/',
  })
}

export default SetCookie;
