import get from 'lodash.get';

export const mapPlayersToSelector = players => {
  const options = [];

  players.forEach(p => {
    const player = get(p, 'player');

    options.push({
      data: player,
      value: get(player, 'id'),
      label: `${get(player, 'first_name')} ${get(player, 'last_name')}`,
    });
  });

  return options;
};
