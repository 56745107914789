import api from 'shared/api';

export const getTeams = seasonId => {
  const url = `/api/v1/seasons/${seasonId}/teams/`;
  return api.get(url);
};

export const addUserToTeams = (data) => {
  const url = '/api/v1/userteams/add_user_to_teams/';
  return api.post(url, data);
};
