import get from 'lodash.get';

export const getGenderLabel = (value, fallback = '-') => {
  switch (value) {
    case 'M':
      return 'Male';
    case 'F':
      return 'Female';
    case 'C':
      return 'Coed';
    default:
      return fallback;
  }
};

export const getAgeLabel = (value, fallback = '-') => {
  switch (value) {
    case '12_under':
      return '12 and under';
    case '13_18_years':
      return '13 - 18 Years';
    case 'college':
      return 'College';
    case 'adult':
      return 'Adult';
    default:
      return fallback;
  }
};

export const getCountryLabel = (value, fallback = 'Other') => {
  switch (value) {
    case 'USA':
    case 'usa':
      return 'USA';
    default:
      return fallback;
  }
};

export const mapLeagueToSelector = league => ({
  value: get(league, 'id') || '',
  label: get(league, 'name') || '',
});

export const mapLeaguesListToSelector = league => league.map(l => ({
  value: get(l, 'id') || '',
  label: get(l, 'name') || '',
}));
