import api from 'shared/api';

export const getMaxPitchData = seasonId => {
  const url = `/api/v1/seasons/${seasonId}/compliance_report_daily/`;
  return api.get(url);
};

export const getRestDaysData = seasonId => {
  const url = `/api/v1/seasons/${seasonId}/compliance_report_rest/`;
  return api.get(url);
};
