import React from 'react';
import PropTypes from 'prop-types';
import {
  Tab,
  Tabs,
  TabContent,
  TabsHeader,
  Table,
} from 'shared/components';
import {
  displayValue,
} from 'shared/helpers';
import {
  mockStandard,
  mockBaserunning,
  mockQab,
  mockTeamImpact,
  mockSituational,
} from './mockData';
import './styles.scss';

const GameStatsBatting = ({
  // eslint-disable-next-line no-unused-vars
  teamStatsId,
}) => (
  <div className="GameStatsBatting">
    <div className="GameStatsBatting-tabs">
      <Tabs>
        <TabsHeader>
          <Tab>Standard</Tab>
          <Tab>Baserunning</Tab>
          <Tab>Quality At-bats</Tab>
          <Tab>Team Impact</Tab>
          <Tab>Situational</Tab>
        </TabsHeader>
        <TabContent>
          <Table
            data={mockStandard.data}
            columns={[
              {
                accessor: 'jersey_nr',
                Header: '#',
                Cell: cellData => displayValue(cellData.value),
                width: 50,
                Footer: '',
              },
              {
                accessor: 'player',
                Header: 'Player',
                Cell: cellData => displayValue(cellData.value),
                maxWidth: 200,
                minWidth: 90,
                Footer: 'Team totals',
                getFooterProps: () => ({
                  className: 'ft-left-pos',
                }),
              },
              {
                accessor: 'pa',
                Header: 'PA',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockStandard.total.pa,
              },
              {
                accessor: 'ab',
                Header: 'AB',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockStandard.total.ab,
              },
              {
                accessor: 'h',
                Header: 'H',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockStandard.total.h,
              },
              {
                accessor: 'tb',
                Header: 'TB',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockStandard.total.tb,
              },
              {
                accessor: '1b',
                Header: '1B',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockStandard.total['1b'],
              },
              {
                accessor: '2b',
                Header: '2B',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockStandard.total['2b'],
              },
              {
                accessor: '3b',
                Header: '3B',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockStandard.total['3b'],
              },
              {
                accessor: 'hr',
                Header: 'HR',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockStandard.total.hr,
              },
              {
                accessor: 'rbi',
                Header: 'RBI',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockStandard.total.rbi,
              },
              {
                accessor: 'r',
                Header: 'R',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockStandard.total.r,
              },
              {
                accessor: 'bb',
                Header: 'BB',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockStandard.total.bb,
              },
              {
                accessor: 'so',
                Header: 'SO',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockStandard.total.so,
              },
              {
                accessor: 'kl',
                Header: 'K-L',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 60,
                Footer: mockStandard.total.kl,
              },
            ]}
            minRows={1}
            showPagination={false}
          />
        </TabContent>
        <TabContent>
          <Table
            data={mockBaserunning.data}
            columns={[
              {
                accessor: 'jersey_nr',
                Header: '#',
                Cell: cellData => displayValue(cellData.value),
                width: 50,
                Footer: '',
              },
              {
                accessor: 'player',
                Header: 'Player',
                Cell: cellData => displayValue(cellData.value),
                maxWidth: 200,
                minWidth: 90,
                Footer: 'Team totals',
                getFooterProps: () => ({
                  className: 'ft-left-pos',
                }),
              },
              {
                accessor: 'sb',
                Header: 'SB',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 100,
                Footer: mockBaserunning.total.sb,
              },
              {
                accessor: 'cs',
                Header: 'CS',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 100,
                Footer: mockBaserunning.total.cs,
              },
              {
                accessor: 'pik',
                Header: 'PIK',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 100,
                Footer: mockBaserunning.total.pik,
              },
            ]}
            minRows={1}
            showPagination={false}
          />
        </TabContent>
        <TabContent>
          <Table
            data={mockQab.data}
            columns={[
              {
                accessor: 'jersey_nr',
                Header: '#',
                Cell: cellData => displayValue(cellData.value),
                width: 50,
                Footer: '',
              },
              {
                accessor: 'player',
                Header: 'Player',
                Cell: cellData => displayValue(cellData.value),
                maxWidth: 200,
                minWidth: 90,
                Footer: 'Team totals',
                getFooterProps: () => ({
                  className: 'ft-left-pos',
                }),
              },
              {
                accessor: 'ab',
                Header: 'AB',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockQab.total.ab,
              },
              {
                accessor: 'ps',
                Header: 'PS',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockQab.total.ab,
              },
              {
                accessor: 'pspa',
                Header: 'PS/PA',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockQab.total.pspa,
              },
              {
                accessor: 'c',
                Header: 'C%',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockQab.total.c,
              },
              {
                accessor: '2s3',
                Header: '2S+3',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockQab.total['2s3'],
              },
              {
                accessor: '6+',
                Header: '6+',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockQab.total['6+'],
              },
              {
                accessor: 'hhb',
                Header: 'HHB',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockQab.total.hhb,
              },
              {
                accessor: 'qab',
                Header: 'QAB',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockQab.total.qab,
              },
            ]}
            minRows={1}
            showPagination={false}
          />
        </TabContent>
        <TabContent>
          <Table
            data={mockTeamImpact.data}
            columns={[
              {
                accessor: 'jersey_nr',
                Header: '#',
                Cell: cellData => displayValue(cellData.value),
                width: 50,
                Footer: '',
              },
              {
                accessor: 'player',
                Header: 'Player',
                Cell: cellData => displayValue(cellData.value),
                maxWidth: 200,
                minWidth: 90,
                Footer: 'Team totals',
                getFooterProps: () => ({
                  className: 'ft-left-pos',
                }),
              },
              {
                accessor: 'ab',
                Header: 'AB',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockTeamImpact.total.ab,
              },
              {
                accessor: 'lob',
                Header: 'LOB',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockTeamImpact.total.lob,
              },
              {
                accessor: 'gidp',
                Header: 'GIDP',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockTeamImpact.total.gidp,
              },
              {
                accessor: 'xbh',
                Header: 'XBH',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockTeamImpact.total.xbh,
              },
              {
                accessor: 'hrisp',
                Header: 'HRISP',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockTeamImpact.total.hrisp,
              },
              {
                accessor: 'sac',
                Header: 'SAC',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockTeamImpact.total.sac,
              },
              {
                accessor: 'sf',
                Header: 'SF',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockTeamImpact.total.sf,
              },
              {
                accessor: '2outrbi',
                Header: '2OUTRBI',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockTeamImpact.total['2outrbi'],
              },
              {
                accessor: 'babip',
                Header: 'BABIP',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockTeamImpact.total.babip,
              },
            ]}
            minRows={1}
            showPagination={false}
          />
        </TabContent>
        <TabContent>
          <Table
            data={mockSituational.data}
            columns={[
              {
                accessor: 'jersey_nr',
                Header: '#',
                Cell: cellData => displayValue(cellData.value),
                width: 50,
                Footer: '',
              },
              {
                accessor: 'player',
                Header: 'Player',
                Cell: cellData => displayValue(cellData.value),
                maxWidth: 200,
                minWidth: 90,
                Footer: 'Team totals',
                getFooterProps: () => ({
                  className: 'ft-left-pos',
                }),
              },
              {
                accessor: 'hbp',
                Header: 'HBP',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockSituational.total.hbp,
              },
              {
                accessor: 'fc',
                Header: 'FC',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockSituational.total.fc,
              },
              {
                accessor: 'roe',
                Header: 'ROE',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockSituational.total.roe,
              },
              {
                accessor: 'abrisp',
                Header: 'ABRISP',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockSituational.total.abrisp,
              },
              {
                accessor: 'ps',
                Header: 'PS',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockSituational.total.ps,
              },
              {
                accessor: 'sw',
                Header: 'SW',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockSituational.total.sw,
              },
              {
                accessor: 'sm',
                Header: 'SM',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockSituational.total.sm,
              },
              {
                accessor: 'ci',
                Header: 'CI',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockSituational.total.ci,
              },
              {
                accessor: 'flb',
                Header: 'FLB%',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockSituational.total.flb,
              },
              {
                accessor: 'gb',
                Header: 'GB%',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockSituational.total.gb,
              },
            ]}
            minRows={1}
            showPagination={false}
          />
        </TabContent>
      </Tabs>
    </div>
  </div>
);

GameStatsBatting.propTypes = {
  teamStatsId: PropTypes.number.isRequired,
};

export default GameStatsBatting;
