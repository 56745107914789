/*eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const DescriptionList = ({
  data,
  loading,
}) => (
  <div className="DescriptionList">
    <ul className="DescriptionList-container">
      {
      data.filter(d => d.label).map(d => (
          <li className="DescriptionList-item" key={d.label}>
            <div className="item-label">
              {d.label}
            </div>
            <div className="item-value">
              {loading ? 'Loading...' : d.value}
            </div>
          </li>
        ))
      }
    </ul>
  </div>
);

DescriptionList.propTypes = {
  data: PropTypes.array.isRequired,
};

export default DescriptionList;
