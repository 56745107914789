// import sortBy from 'lodash.sortby';

export const selectActions = (actions = [], inning, inningHalf) => {
  const actionsFromInning = actions.filter(a => a.inning === inning) || [];

  const halfInningsActions = actionsFromInning.filter(a => {
    const actionInningHalf = a.inningHalf || 'top'; // DEV only
    return actionInningHalf === inningHalf;
  });

  if (halfInningsActions.length) {
    return halfInningsActions.map((item, index) => {
      // eslint-disable-next-line no-param-reassign
      item.id = index;
      return item;
    }).reverse();
  }

  return null;
};
