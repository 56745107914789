export const getTeamInit = 'GET_TEAM_INIT';
export const getTeamSuccess = 'GET_TEAM_SUCCESS';
export const getTeamError = 'GET_TEAM_ERROR';
export const refreshTeam = 'REFRESH_TEAM';
export const cleanTeam = 'CLEAN_TEAM';
export const getActiveSeason = 'GET_ACTIVE_SEASON';
export const getActiveGames = 'GET_ACTIVE_GAMES';
export const getActivePlayers = 'GET_ACTIVE_PLAYERS';
export const getActiveLineup = 'GET_ACTIVE_LINEUP';
export const cleanActiveTeam = 'CLEAN_ACTIVE_TEAM';
