/* eslint-disable */
import api from 'shared/api';

export const getTeams = (org) => {
  const url = `/api/v1/teams/?organization=${org}`;
  return api.get(url);
};

export const getEvents = (org) => {
  const url = `/api/v1/events/?organization=${org}`;
  return api.get(url);
};

export const getPlayerStats = (event) => {
  const url = `/api/v1/playerstats/?event__id=${event}`;
  return api.get(url);
};