import React, {
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import {
  Button,
  IconUnfollow,
  Table,
} from 'shared/components';
import {
  displayValue,
} from 'shared/helpers';
import api from 'shared/api';
import UnfollowPlayerPopup from './components/UnfollowPlayerPopup';
import './styles.scss';

const ProfilePlayers = ({
  userId,
}) => {
  const isMounted = React.useRef(true);
  const [loading, setLoading] = useState(true);
  const [players, setPlayers] = useState([]);

  const [isPlayerUnfollowDisplayed, setPlayerUnfollowDisplay] = useState(false);
  const [playerToUnfollow, setPlayerToUnfollow] = useState(null);

  const getPlayers = () => {
    api.get(`/api/v1/userplayers/?user__id=${userId}`)
      .then(res => {
        const data = get(res, 'data.results') || [];

        if (isMounted.current) {
          setPlayers(data);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getPlayers();

    return () => {
      isMounted.current = false;
    };
  }, []);

  const handlePlayerUnfollowClick = userPlayer => {
    setPlayerToUnfollow(userPlayer);
    setPlayerUnfollowDisplay(true);
  };

  return (
    <div className="ProfilePlayers">
      <Table
        data={players}
        columns={[
          {
            accessor: 'player.first_name',
            Header: 'First name',
            Cell: cellData => displayValue(cellData.value),
          },
          {
            accessor: 'player.last_name',
            Header: 'Last name',
            Cell: cellData => displayValue(cellData.value),
          },
          {
            Header: 'Unfollow',
            headerClassName: 'text-center',
            className: 'text-center button-wrapper',
            sortable: false,
            Cell: rowData => (
              <Button
                aria-label="Unfollow team"
                onClick={e => {
                  e.stopPropagation();
                  return handlePlayerUnfollowClick(rowData.original);
                }}
                theme="link"
              >
                <IconUnfollow
                  height="20px"
                  width="20px"
                  stroke="#ee5253"
                />
              </Button>
            ),
            width: 150,
          },
        ]}
        minRows={1}
        showPagination={false}
        loading={loading}
      />
      {
        isPlayerUnfollowDisplayed && (
          <UnfollowPlayerPopup
            isOpen={isPlayerUnfollowDisplayed}
            close={() => setPlayerUnfollowDisplay(false)}
            player={playerToUnfollow}
            getPlayers={getPlayers}
          />
        )
      }
    </div>
  );
};

ProfilePlayers.propTypes = {
  userId: PropTypes.number.isRequired,
};

export default ProfilePlayers;
