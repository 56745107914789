/* eslint-disable */
import React from "react";
import { Button } from "shared/components";
import { DescriptionList } from "shared/components";
import { useState, useEffect } from "react";
import { getEvent } from "./actions";
import get from "lodash.get";
import { displayValue } from "shared/helpers";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { NavLink } from "react-router-dom";
import UpdateEvent from "./components/UpdateEvent/UpdateEvent";
import { Table } from "../../../shared/components";
import AddPlayer from "./components/AddPlayer/AddPlayer";
import "./styles.scss";
import { IconDelete } from "shared/components";
import { removePlayer, getPlayers } from "./actions";

function Event({}) {
  const [loading, setLoading] = useState(true);
  const navigate = useHistory();
  const [refetching, setRefetching] = useState(false);

  const [results, setResults] = useState([]);
  const [modalCreateEvent, setModalCreateEvent] = useState();
  const [modalAddPlayer, setModalAddPlayer] = useState();
  const [players, setPlayers] = useState([]);

  const { eventId } = useParams();

  const fetchEvents = () => {
    console.log(eventId);
    getEvent(eventId)
      .then((res) => {
        const data = get(res, "data") || [];
        console.log(data);

        setResults(data);
      })
      .catch(() => {
        // setLoading(false);
        // setRefetching(false);
      });

    getPlayers(eventId)
      .then((res) => {
        console.log("data: ", res);
        let players = [];
        res.data.results.filter((f) => {
          f.player.relationId = f.id;
          players.push(f.player);
        });
        setPlayers(players);
        setLoading(false);
        setRefetching(false);
      })
      .catch(() => {
        setLoading(false);
        setRefetching(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    fetchEvents();
  }, [refetching]);

  const handleEventUpdateClick = () => {
    return setModalCreateEvent(true);
  };

  const handleAddPlayerClick = () => {
    return setModalAddPlayer(true);
  };

  const handlePlayerRemoval = (id) => {
    setLoading(true);

    removePlayer(id).then(() => {
      setRefetching(true);
      setLoading(true);
    });

    // .then(()=> {
    // });
  };

  return (
    <div className="LeagueTeamsList">
      <div className="PlayerContainer-breadcrumb">
        <NavLink to={`/events/`}>
          <span>&#8249;</span>
          back to events list
        </NavLink>
      </div>
      <Button onClick={handleEventUpdateClick} disabled={false} size="sm">
        Update event
      </Button>
      <div className="teams-container">
        <DescriptionList
          loading={loading}
          data={[
            { label: "Name", value: displayValue(results?.name) },
            { label: "Division", value: displayValue(results?.division?.name) },
            { label: "Type", value: displayValue(results?.type) },
            { label: "Start date", value: displayValue(results?.start_date) },
            { label: "End date", value: displayValue(results?.end_date) },
          ]}
        />
        <div className="button-holder">
          <Button onClick={handleAddPlayerClick} disabled={false} size="sm">
            Add player
          </Button>

          <Button
            onClick={() => navigate.push("stats/")}
            disabled={false}
            size="sm"
          >
            Add statistics
          </Button>
        </div>
        <Table
          data={players}
          noDataText=""
          loading={loading || refetching}
          columns={[
            {
              accessor: "first_name",
              Header: "First Name",
              width: 250,
            },
            {
              accessor: "last_name",
              Header: "Last Name",
              Cell: (cellData) => displayValue(cellData.value),
              // <span className="span"> {displayValue(cellData.value)}</span>
            },
            {
              accessor: "birthday",
              Header: "Birthday",
              Cell: (cellData) => displayValue(cellData.value),
            },
            {
              accessor: "position",
              Header: "Position",
              Cell: (cellData) => displayValue(cellData.value),
            },
            {
              Header: "Delete",
              headerClassName: "text-center",
              className: "text-center button-wrapper",
              sortable: false,
              filterable: false,
              Cell: (cellData) => (
                <button
                  aria-label="Delete player"
                  className="delete-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    // console.log("Deleted: ", cellData.original.id);
                    handlePlayerRemoval(cellData.original.relationId);
                  }}
                  type="button"
                >
                  <IconDelete height="20px" width="20px" color="#ee5253" />
                </button>
              ),
              width: 100,
            },
          ]}
          clickable
          handleClick={(rowData) => console.log(rowData)}
          minRows={1}
          showPagination={false}
        />
      </div>
      {modalCreateEvent && (
        <UpdateEvent
          setRefetching={setRefetching}
          isOpen={modalCreateEvent}
          close={() => setModalCreateEvent(false)}
          event={results}
        />
      )}
      {modalAddPlayer && (
        <AddPlayer
          setRefetching={setRefetching}
          isOpen={modalAddPlayer}
          close={() => setModalAddPlayer(false)}
          event={results}
        />
      )}
    </div>
  );
}

export default Event;
