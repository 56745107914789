import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import get from 'lodash.get';
import {
  Page,
  Tab,
  Tabs,
  TabContent,
  TabsHeader,
} from 'shared/components';
import { cleanTeam } from 'store/activeTeam/actions';
import SearchTeam from '../components/SearchTeam';
import SearchPlayers from '../components/SearchPlayers';
import UserTeams from '../components/UserTeams';
import AddTeam from '../components/AddTeam';
import './styles.scss';

const TeamManagamentContainer = ({
  cleanActiveTeam,
  location,
  userTeams,
}) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const openSelectedTab = i => setSelectedTab(i);

  useEffect(() => {
    const queryParams = get(location, 'search');
    if (!queryParams) {
      setSelectedTab(0);
    }
    if (queryParams.indexOf('st=1') >= 0) {
      setSelectedTab(1);
    }
    if (queryParams.indexOf('st=2') >= 0) {
      setSelectedTab(2);
    }
    if (queryParams.indexOf('st=3') >= 0) {
      setSelectedTab(3);
    }
  }, [location]);

  useEffect(() => {
    cleanActiveTeam();
  }, []);

  return (
    <div className="TeamManagamentContainer">
      <Page>
        <Tabs
          defaultFocus
          selectedIndex={selectedTab}
          onSelect={tabIndex => setSelectedTab(tabIndex)}
          forceRenderTabPanel
        >
          <TabsHeader>
            <Tab>Your teams</Tab>
            <Tab>Search teams</Tab>
            <Tab>Search players</Tab>
            <Tab>Add a team</Tab>
          </TabsHeader>
          <TabContent>
            <UserTeams
              userTeams={userTeams}
              openSelectedTab={openSelectedTab}
            />
          </TabContent>
          <TabContent>
            <SearchTeam />
          </TabContent>
          <TabContent>
            <SearchPlayers />
          </TabContent>
          <TabContent>
            <AddTeam />
          </TabContent>
        </Tabs>
      </Page>
    </div>
  );
};

TeamManagamentContainer.propTypes = {
  cleanActiveTeam: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  userTeams: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  userTeams: get(state, 'userTeams.details'),
});

const mapDispatchToProps = {
  cleanActiveTeam: cleanTeam,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TeamManagamentContainer));
