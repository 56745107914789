import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash.get';
import {
  Page,
  Tab,
  Tabs,
  TabContent,
  TabsHeader,
} from 'shared/components';
import { cleanLeague } from 'store/activeLeague/actions';
import UserLeagues from '../components/UserLeagues';
import SearchLeagues from '../components/SearchLeagues';
import './styles.scss';

const LeaguesManagementContainer = ({
  userLeagues,
  cleanActiveLeague,
}) => {
  useEffect(() => {
    cleanActiveLeague();
  }, []);

  return (
    <div className="LeaguesManagementContainer">
      <Page>
        <Tabs
          defaultFocus
          forceRenderTabPanel
        >
          <TabsHeader>
            <Tab>Your leagues</Tab>
            <Tab>Search leagues</Tab>
          </TabsHeader>
          <TabContent>
            <UserLeagues userLeagues={userLeagues} />
          </TabContent>
          <TabContent>
            <SearchLeagues />
          </TabContent>
        </Tabs>
      </Page>
    </div>
  );
};

LeaguesManagementContainer.propTypes = {
  userLeagues: PropTypes.array.isRequired,
  cleanActiveLeague: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  userLeagues: get(state, 'user.details.leagues_admin'),
});

const mapDispatchToProps = {
  cleanActiveLeague: cleanLeague,
};

export default connect(mapStateToProps, mapDispatchToProps)(LeaguesManagementContainer);
