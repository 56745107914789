/* eslint-disable */
import api from "shared/api";

export const getDivisions = (organization) => {
  const url = `/api/v1/divisions/?organization=${organization}`;
  return api.get(url);
};

export const createEvent = (data) => {
  const url = `/api/v1/events/`;
  return api.post(url, data);
};

export const createTestEvent = (data) => {
  const url = `/api/v1/testingevents/`;
  return api.post(url, data);
};
