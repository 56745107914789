import get from 'lodash.get';
import {
  sortSeasons,
} from 'shared/helpers';
import * as actionTypes from './actionTypes';

const sortedSeasons = action => sortSeasons(get(action, 'team.league.seasons') || []);

const currentSeason = seasons => seasons.find(s => s.is_current);

const setActiveSeasonFromQueryParam = (action) => {
  const seasons = get(action, 'team.league.seasons') || [];
  const selectedSeason = seasons.find(s => s.id === action.seasonId);

  if (selectedSeason) {
    return selectedSeason;
  }

  return get(sortedSeasons(action), '[0]') || {};
};

const setActiveSeason = (action, activeSeason) => {
  const currentActiveSeason = get(activeSeason, 'id');
  const teamSeasons = get(action, 'team.league.seasons') || [];
  const doesTeamHaveSeason = teamSeasons.find(s => s.id === activeSeason.id);

  if (currentActiveSeason && doesTeamHaveSeason) {
    return activeSeason;
  }
  if (action.seasonId) {
    return setActiveSeasonFromQueryParam(action);
  }
  const current = currentSeason(sortedSeasons(action));

  if (!current) {
    return get(sortedSeasons(action), '[0]') || {};
  }

  return current;
};

const initialState = {
  loading: true,
  details: null,
  seasons: [],
  activeSeason: {},
  games: [],
  players: [],
  lineup: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.getTeamInit:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.getTeamSuccess:
      return {
        ...state,
        loading: false,
        details: action.team,
        seasons: sortedSeasons(action),
        activeSeason: setActiveSeason(action, state.activeSeason),
      };
    case actionTypes.getTeamError:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.refreshTeam:
      return {
        ...state,
        details: action.team,
        seasons: sortedSeasons(action),
        activeSeason: setActiveSeason(action),
      };
    case actionTypes.getActiveSeason:
      return {
        ...state,
        activeSeason: action.season,
      };
    case actionTypes.getActiveGames:
      return {
        ...state,
        games: action.games,
      };
    case actionTypes.getActivePlayers:
      return {
        ...state,
        players: action.players,
      };
    case actionTypes.getActiveLineup:
      return {
        ...state,
        lineup: action.lineup,
      };
    case actionTypes.cleanActiveTeam:
      return {
        ...state,
        games: [],
        players: [],
        lineup: null,
      };
    case actionTypes.cleanTeam:
      return initialState;
    default:
      return state;
  }
}
