export const mockStandard = {
  data: [
    {
      date: new Date(),
      against: 'TBD',
      type: 'League',
      tc: 1,
      a: 0,
      po: 0,
      e: 1,
      dp: 0,
      tp: 0,
      fpct: 1.000,
    },
  ],
  total: {
    tc: 1,
    a: 0,
    po: 0,
    e: 1,
    dp: 0,
    tp: 0,
    fpct: 1.000,
  },
};

export const mockCatching = {
  data: [
    {
      date: new Date(),
      against: 'TBD',
      type: 'League',
      inn: 3.0,
      pb: 0,
      sb: 3,
      sbatt: '3-4',
      cs: 1,
      csperc: 0.250,
      pik: 0,
      ci: 1,
    },
  ],
  total: {
    inn: 3.0,
    pb: 0,
    sb: 3,
    sbatt: '3-4',
    cs: 1,
    csperc: 0.250,
    pik: 0,
    ci: 1,
  },
};
