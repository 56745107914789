import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash.get';
import {
  NavLink,
  Route,
  Switch,
} from 'react-router-dom';
import {
  NotFound,
  PageHeader,
} from 'shared/components';
import {
  LeagueTeams,
  LeagueRules,
} from '../pages';
import './styles.scss';

const LeagueDashboard = ({
  team,
  user,
}) => {
  const teamId = get(team, 'id');
  const leagueId = get(team, 'league.id');
  const userLeagues = get(user, 'leagues_admin') || [];
  const isUserStaff = get(user, 'is_staff');
  const isUserLeagueAdmin = userLeagues.find(l => l.id === leagueId);

  return (
    <div className="LeagueDashboard">
      <PageHeader content="league" />
      <div className="LeagueDashboard-menu">
        <NavLink exact to={`/${teamId}/league`}>Teams</NavLink>
        <NavLink to={`/${teamId}/league/rules`}>Rules</NavLink>
      </div>
      <Switch>
        <Route
          exact
          path="/:teamId/league"
          render={() => <LeagueTeams leagueId={leagueId} isLeagueAdmin={!!isUserLeagueAdmin || isUserStaff} />}
        />
        <Route
          path="/:teamId/league/rules"
          render={() => <LeagueRules leagueId={leagueId} isLeagueAdmin={!!isUserLeagueAdmin || isUserStaff} />}
        />
        <Route
          render={() => <NotFound />}
        />
      </Switch>
    </div>
  );
};

LeagueDashboard.propTypes = {
  team: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  user: get(state, 'user.details'),
});

export default connect(mapStateToProps)(LeagueDashboard);
