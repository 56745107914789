import {
  compose,
  createStore,
  combineReducers,
  applyMiddleware,
} from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import user from './user/userReducer';
import userTeams from './userTeams/userTeamsReducer';
import activeTeam from './activeTeam/activeTeamReducer';
import activeLeague from './activeLeague/activeLeagueReducer';

// root reducer
const rootReducer = combineReducers({
  activeTeam,
  activeLeague,
  user,
  userTeams,
});

// eslint-disable-next-line
let composeEnhancers = process.env.NODE_ENV !== 'production' ?
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose :
  compose;

const usedMiddleware = [thunk];
if (process.env.NODE_ENV !== 'production') {
  usedMiddleware.push(logger);
}

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...usedMiddleware)),
);

export default store;
