import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import get from 'lodash.get';
import {
  // DescriptionList,
  NotAuthorized,
  // Tab,
  // Tabs,
  // TabContent,
  // TabsHeader,
  Table,
} from 'shared/components';
import {
  displayValue,
  isAdmin,
} from 'shared/helpers';
import api from 'shared/api';
// import {
//   mockBattingAway,
//   mockBattingHome,
//   mockPitchingAway,
//   mockPitchingHome,
// } from './mockData';
import './styles.scss';

const GameBoxScore = ({
  game,
  history,
  teamId,
}) => {
  const isAuth = isAdmin(teamId);
  const gameId = get(game, 'id');
  const homeTeamId = get(game, 'team_home.id');
  const awayTeamId = get(game, 'team_away.id');

  if (!isAuth) {
    return <NotAuthorized message="Only team admins can see game stats" />;
  }

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const fetchSeasonStats = () => {
    api.get(`/api/v1/games/${gameId}/stats`)
      .then((res) => {
        const stats = get(res, 'data') || [];
        setLoading(false);
        setData(stats);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    fetchSeasonStats();
  }, [gameId]);

  const redirectToPlayerPage = (rowData, team) => {
    const playerId = get(rowData, 'original.id');
    history.push(`/${team}/players/${playerId}`);
  };

  return (
    <div className="GameBoxScore">
      <div className="tables-container">
        <div className="table-wrapper">
          <div className="Box-away-team team-details">
            {get(game, 'team_away.name') || 'TBD'}
          </div>
          <Table
            data={get(data, 'team_away.players') || []}
            loading={loading}
            columns={[
              {
                accessor: 'jersey_nr',
                Header: '#',
                Cell: cellData => displayValue(cellData.value),
                width: 50,
              },
              {
                accessor: 'player',
                Header: 'Player',
                Cell: cellData => displayValue(cellData.value),
                maxWidth: 200,
                minWidth: 90,
                Footer: 'Totals',
                getFooterProps: () => ({
                  className: 'ft-left-pos',
                }),
              },
              {
                accessor: 'pc',
                // Header: 'PC',
                Header: 'Pitch count',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: displayValue(get(data, 'team_away.total.pc')),
              },
              {
                accessor: 'cc',
                // Header: 'CC',
                Header: 'Catch count',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: displayValue(get(data, 'team_away.total.cc')),
              },
            ]}
            minRows={1}
            showPagination={false}
            clickable
            handleClick={rowData => redirectToPlayerPage(rowData, awayTeamId)}
          />
        </div>
        <div className="table-wrapper">
          <div className="Box-home-team team-details">
            {get(game, 'team_home.name') || 'TBD'}
          </div>
          <Table
            data={get(data, 'team_home.players') || []}
            loading={loading}
            columns={[
              {
                accessor: 'jersey_nr',
                Header: '#',
                Cell: cellData => displayValue(cellData.value),
                width: 50,
              },
              {
                accessor: 'player',
                Header: 'Player',
                Cell: cellData => displayValue(cellData.value),
                maxWidth: 200,
                minWidth: 90,
                Footer: 'Totals',
                getFooterProps: () => ({
                  className: 'ft-left-pos',
                }),
              },
              {
                accessor: 'pc',
                // Header: 'PC',
                Header: 'Pitch count',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: displayValue(get(data, 'team_home.total.pc')),
              },
              {
                accessor: 'cc',
                // Header: 'CC',
                Header: 'Catch count',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: displayValue(get(data, 'team_home.total.cc')),
              },
            ]}
            minRows={1}
            showPagination={false}
            clickable
            handleClick={rowData => redirectToPlayerPage(rowData, homeTeamId)}
          />
        </div>
      </div>

      {/* <div className="GameBoxScore-tabs">
        <Tabs>
          <TabsHeader>
            <Tab>Batting</Tab>
            <Tab>Pitching</Tab>
          </TabsHeader>
          <TabContent>
            <div className="Batting tables-container">
              <div className="Batting-away table-wrapper">
                <div className="Batting-away-team team-details">
                  {get(game, 'team_away.name') || 'TBD'}
                </div>
                <Table
                  data={mockBattingAway.data}
                  noDataText=""
                  columns={[
                    {
                      accessor: 'player',
                      Header: 'Player',
                      Cell: cellData => displayValue(cellData.value),
                      maxWidth: 200,
                      minWidth: 90,
                      Footer: 'Totals',
                    },
                    {
                      accessor: 'ab',
                      Header: 'AB',
                      Cell: cellData => displayValue(cellData.value),
                      minWidth: 50,
                      Footer: mockBattingAway.total.ab,
                    },
                    {
                      accessor: 'r',
                      Header: 'R',
                      Cell: cellData => displayValue(cellData.value),
                      minWidth: 50,
                      Footer: mockBattingAway.total.r,
                    },
                    {
                      accessor: 'h',
                      Header: 'H',
                      Cell: cellData => displayValue(cellData.value),
                      minWidth: 50,
                      Footer: mockBattingAway.total.h,
                    },
                    {
                      accessor: 'rbi',
                      Header: 'RBI',
                      Cell: cellData => displayValue(cellData.value),
                      minWidth: 50,
                      Footer: mockBattingAway.total.rbi,
                    },
                    {
                      accessor: 'bb',
                      Header: 'BB',
                      Cell: cellData => displayValue(cellData.value),
                      minWidth: 50,
                      Footer: mockBattingAway.total.bb,
                    },
                    {
                      accessor: 'so',
                      Header: 'SO',
                      Cell: cellData => displayValue(cellData.value),
                      minWidth: 50,
                      Footer: mockBattingAway.total.so,
                    },
                  ]}
                  minRows={1}
                  showPagination={false}
                />
                <div className="table-details">
                  <div className="list-wrapper">
                    <div className="list-heading">Batting</div>
                    <DescriptionList
                      data={[
                        { label: 'HR', value: '#69' },
                        { label: 'Grand slam', value: '#13' },
                        { label: 'TB', value: '#69, #13' },
                        { label: 'RBI', value: '#69' },
                      ]}
                    />
                  </div>
                  <div className="list-wrapper">
                    <div className="list-heading">Totals</div>
                    <DescriptionList
                      data={[
                        { label: 'Team QAB', value: '3 (42.86%)' },
                        { label: 'Team LOB', value: '0' },
                      ]}
                    />
                  </div>
                </div>
              </div>
              <div className="Batting-home table-wrapper">
                <div className="Batting-home-team team-details">
                  {get(game, 'team_home.name') || 'TBD'}
                </div>
                <Table
                  data={mockBattingHome.data}
                  noDataText=""
                  columns={[
                    {
                      accessor: 'player',
                      Header: 'Player',
                      Cell: cellData => displayValue(cellData.value),
                      maxWidth: 200,
                      minWidth: 90,
                      Footer: 'Totals',
                    },
                    {
                      accessor: 'ab',
                      Header: 'AB',
                      Cell: cellData => displayValue(cellData.value),
                      minWidth: 50,
                      Footer: mockBattingHome.total.ab,
                    },
                    {
                      accessor: 'r',
                      Header: 'R',
                      Cell: cellData => displayValue(cellData.value),
                      minWidth: 50,
                      Footer: mockBattingHome.total.r,
                    },
                    {
                      accessor: 'h',
                      Header: 'H',
                      Cell: cellData => displayValue(cellData.value),
                      minWidth: 50,
                      Footer: mockBattingHome.total.h,
                    },
                    {
                      accessor: 'rbi',
                      Header: 'RBI',
                      Cell: cellData => displayValue(cellData.value),
                      minWidth: 50,
                      Footer: mockBattingHome.total.rbi,
                    },
                    {
                      accessor: 'bb',
                      Header: 'BB',
                      Cell: cellData => displayValue(cellData.value),
                      minWidth: 50,
                      Footer: mockBattingHome.total.bb,
                    },
                    {
                      accessor: 'so',
                      Header: 'SO',
                      Cell: cellData => displayValue(cellData.value),
                      minWidth: 50,
                      Footer: mockBattingHome.total.so,
                    },
                  ]}
                  minRows={1}
                  showPagination={false}
                />
                <div className="table-details">
                  <div className="list-wrapper">
                    <div className="list-heading">Batting</div>
                    <DescriptionList
                      data={[
                        { label: 'HR', value: '#32' },
                        { label: 'Grand slam', value: '#27' },
                        { label: 'TB', value: '#32, #27' },
                        { label: 'RBI', value: '#32' },
                      ]}
                    />
                  </div>
                  <div className="list-wrapper">
                    <div className="list-heading">Totals</div>
                    <DescriptionList
                      data={[
                        { label: 'Team QAB', value: '2 (30.86%)' },
                        { label: 'Team LOB', value: '0' },
                      ]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </TabContent>
          <TabContent>
            <div className="Pitching tables-container">
              <div className="Pitching-away table-wrapper">
                <div className="Pitching-away-team team-details">
                  {get(game, 'team_away.name') || 'TBD'}
                </div>
                <Table
                  data={mockPitchingAway.data}
                  noDataText=""
                  columns={[
                    {
                      accessor: 'player',
                      Header: 'Player',
                      Cell: cellData => displayValue(cellData.value),
                      maxWidth: 200,
                      minWidth: 90,
                      Footer: 'Totals',
                    },
                    {
                      accessor: 'ip',
                      Header: 'IP',
                      Cell: cellData => displayValue(cellData.value),
                      minWidth: 60,
                      Footer: mockPitchingAway.total.ip,
                    },
                    {
                      accessor: 'p',
                      Header: '#P',
                      Cell: cellData => displayValue(cellData.value),
                      minWidth: 60,
                      Footer: mockPitchingAway.total.p,
                    },
                    {
                      accessor: 's',
                      Header: 'S%',
                      Cell: cellData => displayValue(cellData.value),
                      minWidth: 80,
                      Footer: mockPitchingAway.total.s,
                    },
                    {
                      accessor: 'h',
                      Header: 'H',
                      Cell: cellData => displayValue(cellData.value),
                      minWidth: 50,
                      Footer: mockPitchingAway.total.h,
                    },
                    {
                      accessor: 'r',
                      Header: 'R',
                      Cell: cellData => displayValue(cellData.value),
                      minWidth: 50,
                      Footer: mockPitchingAway.total.r,
                    },
                    {
                      accessor: 'er',
                      Header: 'ER',
                      Cell: cellData => displayValue(cellData.value),
                      minWidth: 50,
                      Footer: mockPitchingAway.total.er,
                    },
                    {
                      accessor: 'so',
                      Header: 'SO',
                      Cell: cellData => displayValue(cellData.value),
                      minWidth: 50,
                      Footer: mockPitchingAway.total.so,
                    },
                    {
                      accessor: 'bb',
                      Header: 'BB',
                      Cell: cellData => displayValue(cellData.value),
                      minWidth: 50,
                      Footer: mockPitchingAway.total.bb,
                    },
                    {
                      accessor: 'hr',
                      Header: 'HR',
                      Cell: cellData => displayValue(cellData.value),
                      minWidth: 50,
                      Footer: mockPitchingAway.total.hr,
                    },
                  ]}
                  minRows={1}
                  showPagination={false}
                />
                <div className="table-details">
                  <div className="list-wrapper">
                    <div className="list-heading">Pitching</div>
                    <DescriptionList
                      data={[
                        { label: 'LS', value: '#13' },
                        { label: 'BS', value: '#96' },
                        { label: 'Pitches-Strikes', value: '#13, 13-5' },
                        { label: 'Groundouts-Flyouts', value: '#96 0-0' },
                        { label: 'First pitch strikes-Batters faced:', value: '#13 2-5' },
                      ]}
                    />
                  </div>
                </div>
              </div>
              <div className="Pitching-home table-wrapper">
                <div className="Pitching-home-team team-details">
                  {get(game, 'team_home.name') || 'TBD'}
                </div>
                <Table
                  data={mockPitchingHome.data}
                  noDataText=""
                  columns={[
                    {
                      accessor: 'player',
                      Header: 'Player',
                      Cell: cellData => displayValue(cellData.value),
                      maxWidth: 200,
                      minWidth: 90,
                      Footer: 'Totals',
                    },
                    {
                      accessor: 'ip',
                      Header: 'IP',
                      Cell: cellData => displayValue(cellData.value),
                      minWidth: 60,
                      Footer: mockPitchingHome.total.ip,
                    },
                    {
                      accessor: 'p',
                      Header: '#P',
                      Cell: cellData => displayValue(cellData.value),
                      minWidth: 60,
                      Footer: mockPitchingHome.total.p,
                    },
                    {
                      accessor: 's',
                      Header: 'S%',
                      Cell: cellData => displayValue(cellData.value),
                      minWidth: 80,
                      Footer: mockPitchingHome.total.s,
                    },
                    {
                      accessor: 'h',
                      Header: 'H',
                      Cell: cellData => displayValue(cellData.value),
                      minWidth: 50,
                      Footer: mockPitchingHome.total.h,
                    },
                    {
                      accessor: 'r',
                      Header: 'R',
                      Cell: cellData => displayValue(cellData.value),
                      minWidth: 50,
                      Footer: mockPitchingHome.total.r,
                    },
                    {
                      accessor: 'er',
                      Header: 'ER',
                      Cell: cellData => displayValue(cellData.value),
                      minWidth: 50,
                      Footer: mockPitchingHome.total.er,
                    },
                    {
                      accessor: 'so',
                      Header: 'SO',
                      Cell: cellData => displayValue(cellData.value),
                      minWidth: 50,
                      Footer: mockPitchingHome.total.so,
                    },
                    {
                      accessor: 'bb',
                      Header: 'BB',
                      Cell: cellData => displayValue(cellData.value),
                      minWidth: 50,
                      Footer: mockPitchingHome.total.bb,
                    },
                    {
                      accessor: 'hr',
                      Header: 'HR',
                      Cell: cellData => displayValue(cellData.value),
                      minWidth: 50,
                      Footer: mockPitchingHome.total.hr,
                    },
                  ]}
                  minRows={1}
                  showPagination={false}
                />
                <div className="table-details">
                  <div className="list-wrapper">
                    <div className="list-heading">Pitching</div>
                    <DescriptionList
                      data={[
                        { label: 'LS', value: '#32' },
                        { label: 'BS', value: '#27' },
                        { label: 'Pitches-Strikes', value: '#32, 10-5' },
                        { label: 'Groundouts-Flyouts', value: '#27 3-1' },
                        { label: 'First pitch strikes-Batters faced:', value: '#32 3-9' },
                      ]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </TabContent>
        </Tabs>
      </div> */}
    </div>
  );
};

GameBoxScore.propTypes = {
  game: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  teamId: PropTypes.number.isRequired,
};

export default withRouter(GameBoxScore);
