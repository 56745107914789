/* eslint-disable react/jsx-filename-extension */
/* eslint-disable */
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/browser";
import "./index.scss";
import store from "store";
import { ErrorBoundary } from "shared/components";
import App from "./App";
// import * as serviceWorker from './serviceWorker';

if (
  process.env.NODE_ENV === "production" ||
  process.env.NODE_ENV === "staging"
) {
  Sentry.init({
    dsn: "https://a86ca90454ff42f19d7cb7997e5d71da@sentry.kraken.hr/26",
    ignoreErrors: ["ResizeObserver loop limit exceeded"],
  });
  console.log = () => {};
}

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <App />
    </Provider>
  </ErrorBoundary>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
