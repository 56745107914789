/* eslint-disable */
import React from 'react';
import moment from 'moment';
import omit from 'lodash.omit';
import {
  Button,
  IconCalendar,
} from 'shared/components';
import { DayPickerRangeController } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css'
import './styles.scss';

const START_DATE = 'startDate';
const END_DATE = 'endDate';
const HORIZONTAL_ORIENTATION = 'horizontal';

function isBeforeDay(a, b) {
  if (!moment.isMoment(a) || !moment.isMoment(b)) return false;

  const aYear = a.year();
  const aMonth = a.month();

  const bYear = b.year();
  const bMonth = b.month();

  const isSameYear = aYear === bYear;
  const isSameMonth = aMonth === bMonth;

  if (isSameYear && isSameMonth) return a.date() < b.date();
  if (isSameYear) return aMonth < bMonth;
  return aYear < bYear;
}

function isInclusivelyAfterDay(a, b) {
  if (!moment.isMoment(a) || !moment.isMoment(b)) return false;
  return !isBeforeDay(a, b);
}

const defaultProps = {
  // example props for the demo
  autoFocusEndDate: false,
  initialStartDate: null,
  initialEndDate: null,
  startDateOffset: undefined,
  endDateOffset: undefined,
  showInputs: false,
  minDate: null,
  maxDate: null,

  // day presentation and interaction related props
  renderCalendarDay: undefined,
  renderDayContents: null,
  minimumNights: 1,
  isDayBlocked: () => false,
  isOutsideRange: day => !isInclusivelyAfterDay(day, moment()),
  isDayHighlighted: () => false,
  enableOutsideDays: false,

  // calendar presentation and interaction related props
  orientation: HORIZONTAL_ORIENTATION,
  verticalHeight: undefined,
  withPortal: false,
  initialVisibleMonth: null,
  numberOfMonths: 2,
  onOutsideClick() {},
  keepOpenOnDateSelect: false,
  renderCalendarInfo: null,
  isRTL: false,
  renderMonthText: null,
  renderMonthElement: null,
  renderKeyboardShortcutsButton: undefined,
  renderKeyboardShortcutsPanel: undefined,

  // navigation related props
  navPrev: null,
  navNext: null,
  renderNavPrevButton: null,
  renderNavNextButton: null,
  onPrevMonthClick() {},
  onNextMonthClick() {},

  // internationalization
  monthFormat: 'MMMM YYYY',
};

class WeekPicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      focusedInput: props.autoFocusEndDate ? END_DATE : START_DATE,
      // startDate: moment().startOf('week'),
      // endDate: moment().endOf('week'),
      startDate: props.initialStartDate,
      endDate: props.initialEndDate,
      selectedStartDate: null,
      selectedEndDate: null,
      showPicker: false,
    };

    this.onDatesChange = this.onDatesChange.bind(this);
    this.onFocusChange = this.onFocusChange.bind(this);
  }

  onDatesChange({ startDate, endDate }) {
    this.setState({ startDate, endDate });
  }

  onFocusChange(focusedInput) {
    this.setState({
      // Force the focusedInput to always be truthy so that dates are always selectable
      focusedInput: !focusedInput ? START_DATE : focusedInput,
    });
  }

  render() {
    const { showInputs, handleSelectedWeek, initialEndDate, initialStartDate, loading } = this.props;
    const { selectedEndDate, selectedStartDate, focusedInput, startDate, endDate, showPicker } = this.state;

    const props = omit(this.props, [
      'autoFocus',
      'autoFocusEndDate',
      'initialStartDate',
      'initialEndDate',
      'showInputs',
      'handleSelectedWeek',
      'loading',
    ]);

    const startDateString = !!selectedStartDate ? selectedStartDate.format('YYYY-MM-DD') : initialStartDate.format('YYYY-MM-DD');
    const endDateString = !!selectedEndDate ? selectedEndDate.format('YYYY-MM-DD') : initialEndDate.format('YYYY-MM-DD');

    return (
      <div className="WeekPicker">
        <div className="WeekPicker-heading">Selected week:</div>
        <div className={`WeekPicker-wrapper ${loading ? 'loading' : ''}`} onClick={() => {
          if(loading) {
            return;
          }

          this.setState({
            showPicker: !showPicker,
            startDate: selectedStartDate || initialStartDate,
            endDate: selectedEndDate || initialEndDate,
          })
        }}>
          <div className="weekDate start">{startDateString}</div>
          <div className="weekDate end">{endDateString}</div>
          <button
            disabled={loading}
            onClick={() => {
              if (loading) {
                return;
              }

              this.setState({
                showPicker: !showPicker,
                startDate: selectedStartDate || initialStartDate,
                endDate: selectedEndDate || initialEndDate,
              })
            }}
          >
            <IconCalendar />
          </button>
          <div className={`Picker-wrapper ${showPicker ? 'show' : ''}`}>
            {showInputs && (
              <div style={{ marginBottom: 16 }}>
                <input type="text" name="start date" value={startDateString} readOnly />
                <input type="text" name="end date" value={endDateString} readOnly />
              </div>
            )}
            <DayPickerRangeController
              {...props}
              onDatesChange={this.onDatesChange}
              onFocusChange={this.onFocusChange}
              focusedInput={focusedInput}
              startDate={startDate}
              endDate={endDate}
              numberOfMonths={1}
              enableOutsideDays
              isOutsideRange={() => false}
              hideKeyboardShortcutsPanel
              noBorder
            />
            <div className="button-cont">
              <Button
                theme="success"
                size="sm"
                onClick={() => {
                  this.setState({
                    selectedStartDate: startDate,
                    selectedEndDate: endDate,
                  })
                  return handleSelectedWeek(startDate, endDate);
                }}
              >
                Confirm
              </Button>
            </div>
          </div>
      </div>
    </div>
    );
  }
}

WeekPicker.defaultProps = defaultProps;

export default WeekPicker;
