import React from 'react';
import {
  Tooltip,
} from 'shared/components';
import './styles.scss';

const RuleAlert = () => (
  <div className="RuleAlert">
    <Tooltip
      content="Player achieved daily max of pitches in a game!"
    >
      <div className="RuleAlert-inner">
        rule alert
      </div>
    </Tooltip>
  </div>
);

export default RuleAlert;
