import React, { useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import {
  Button,
  Modal,
  Notification,
} from 'shared/components';
import {
  deleteUserPlayer,
} from './actions';
import './styles.scss';

const UserPlayer = ({
  fetchUsers,
  isAuth,
  players,
  userRoleEmail,
}) => {
  const [loading, setLoading] = useState(false);
  const [isDeletePopupDisplayed, setDeletePopupDisplay] = useState(false);

  const removePlayerRelationship = id => {
    setLoading(true);

    deleteUserPlayer(id)
      .then(() => {
        fetchUsers();
        setLoading(false);
        setDeletePopupDisplay(false);
        Notification('success', 'Family member removed from the player');
      })
      .catch(() => {
        setLoading(false);
        Notification(
          'error',
          'Error occured',
          'There was an error while removing a family member from the player',
        );
      });
  };

  return players.map(p => {
    if (get(p, 'role') === 'self') {
      return (
        <div
          className="Family-player-wrapper role-container"
          key={get(p, 'player.id')}
        >
          <strong>is player:</strong>
          {`${get(p, 'player.first_name')} ${get(p, 'player.last_name')}`}
        </div>
      );
    }

    return (
      <div
        className="Family-player-wrapper role-container"
        key={get(p, 'player.id')}
        style={{ marginBottom: '5px' }}
      >
        <span
          className="player-name"
        >
          {`${get(p, 'player.first_name')} ${get(p, 'player.last_name')}`}
        </span>
        {
          isAuth && (
            <button
              className="player-action"
              type="button"
              onClick={() => setDeletePopupDisplay(true)}
            >
              Remove relationship
            </button>
          )
        }
        {
          isDeletePopupDisplayed && (
            <Modal
              isOpen={isDeletePopupDisplayed}
              closeCb={() => setDeletePopupDisplay(false)}
              title="Are you sure you want to delete this user from the players family?"
            >
              <div className="DeleteUserPlayerPopup">
                <div className="content-row">
                  <span>PLAYER:</span>
                  {` ${get(p, 'player.first_name')} ${get(p, 'player.last_name')}`}
                </div>
                <div className="content-row">
                  <span>USER:</span>
                  {userRoleEmail}
                </div>
                <form
                  className="DeleteUserPlayerPopup-form"
                  onSubmit={e => {
                    e.preventDefault();
                    removePlayerRelationship(get(p, 'id'));
                  }}
                >
                  <div className="form-submit">
                    <Button
                      theme="link"
                      type="button"
                      disabled={loading}
                      onClick={() => setDeletePopupDisplay(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      theme="error"
                      type="submit"
                      disabled={loading}
                    >
                      Delete
                    </Button>
                  </div>
                </form>
              </div>
            </Modal>
          )
        }
      </div>
    );
  });
};

UserPlayer.propTypes = {
  fetchUsers: PropTypes.func.isRequired,
  isAuth: PropTypes.bool.isRequired,
  players: PropTypes.array,
  userRoleEmail: PropTypes.string.isRequired,
};

UserPlayer.defaultProps = {
  players: [],
};

export default UserPlayer;
