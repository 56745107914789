import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { withRouter } from 'react-router-dom';
import {
  Table,
} from 'shared/components';
import {
  displayValue,
} from 'shared/helpers';
import { getTeams } from './actions';
import './styles.scss';

const LeagueTeams = ({
  history,
  leagueId,
  // isLeagueAdmin,
}) => {
  const isMounted = React.useRef(true);
  const [loading, setLoading] = useState(true);
  const [teams, setTeams] = useState([]);

  const redirectToTeamPage = rowData => {
    const teamId = get(rowData, 'original.id');
    return history.push(`/${teamId}`);
  };

  const getLeagueTeams = () => {
    getTeams(leagueId)
      .then(res => {
        const data = get(res, 'data.results') || [];
        setLoading(false);
        setTeams(data);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getLeagueTeams();

    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <div className="LeagueTeams">
      <div className="teams-container">
        <Table
          data={teams}
          noDataText=""
          loading={loading}
          columns={[
            {
              accessor: 'name',
              Header: 'Name',
              width: 250,
            },
            {
              accessor: 'city',
              Header: 'City',
              Cell: cellData => displayValue(cellData.value),
            },
            {
              accessor: 'state',
              Header: 'State',
              Cell: cellData => displayValue(cellData.value),
            },
            {
              accessor: 'gender',
              Header: 'Gender',
              Cell: cellData => displayValue(cellData.value),
            },
            {
              accessor: 'head_coach',
              Header: 'Head coach',
              Cell: cellData => displayValue(cellData.value),
            },
          ]}
          clickable
          handleClick={rowData => redirectToTeamPage(rowData)}
          minRows={1}
          showPagination={false}
        />
      </div>
    </div>
  );
};

LeagueTeams.propTypes = {
  history: PropTypes.object.isRequired,
  leagueId: PropTypes.number.isRequired,
  // isLeagueAdmin: PropTypes.bool.isRequired,
};

export default withRouter(LeagueTeams);
