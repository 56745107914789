import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { GlobalError } from 'shared/components';

class ErrorBoundary extends Component {
  constructor() {
    super();

    this.state = {
      hasError: false,
    };
  }

  // eslint-disable-next-line no-unused-vars
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return <GlobalError />;
    }

    return (<>{children}</>);
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

ErrorBoundary.defaultProps = {
  children: '',
};

export default ErrorBoundary;
