/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import Script from 'react-load-script';
import PropTypes from 'prop-types';
import {
  SearchInput,
} from 'shared/components';
import { parseAddres } from './helpers';

class GoogleLocationSearch extends Component {
  constructor(props) {
    super(props);

    // Declare State
    this.state = {
      address: '',
      query: '',
    };
  }

  handleScriptLoad = () => {
    // Declare Options For Autocomplete
    const options = {};

    // Initialize Google Autocomplete
    /* global google */
    this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById('autocomplete'),
      options,
    );
    // Avoid paying for data that you don't need by restricting the
    // set of place fields that are returned to just the address
    // components and formatted address
    this.autocomplete.setFields(['address_components',
      'formatted_address', 'name', 'geometry.location']);
    // Fire Event when a suggested name is selected
    this.autocomplete.addListener('place_changed',
      this.handleSearchPlaceSelect);
  }

  handleSearchPlaceSelect = () => {
    const { handlePlaceSelect } = this.props;
    // Extract City From Address Object
    const addressObject = this.autocomplete.getPlace();
    const address = addressObject.address_components;
    const parsedAddres = parseAddres(address);
    // Check if address is valid
    if (address) {
      // Set State
      this.setState(
        {
          address: addressObject.formatted_address,
          query: addressObject.formatted_address,
        },
      );

      handlePlaceSelect({
        address: addressObject.formatted_address,
        name: addressObject.name,
        city: parsedAddres.city,
        state: parsedAddres.state,
        zipcode: parsedAddres.zipcode,
        country: parsedAddres.country,
        lat: addressObject.geometry.location.lat(),
        lng: addressObject.geometry.location.lng(),
      });
    }
  }

  render() {
    const { query } = this.state;
    const { clearSelection, placeholder, value } = this.props;

    return (
      <div className="GoogleLocationSearch">
        <Script
          url="https://maps.googleapis.com/maps/api/js?key=AIzaSyAkLOugR-YRxdiL1DpNfEnivUD1fjAoAQc&libraries=places"
          onLoad={this.handleScriptLoad}
        />
        <SearchInput
          showButton={false}
          handleChange={val => {
            if (!val) {
              clearSelection();
            }
            this.setState({ query: val });
          }}
          handleClearBtn={() => {
            this.setState({ query: '', address: '' });
            clearSelection();
          }}
          onSearch={e => e.preventDefault()}
          id="autocomplete"
          value={query || value}
          placeholder={placeholder}
        />
      </div>
    );
  }
}

GoogleLocationSearch.propTypes = {
  handlePlaceSelect: PropTypes.func.isRequired,
  clearSelection: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
};

GoogleLocationSearch.defaultProps = {
  placeholder: '',
  value: '',
};

export default GoogleLocationSearch;
