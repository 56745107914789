import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import get from "lodash.get";
import { withRouter, NavLink, Route, Switch } from "react-router-dom";
import { ContentLoader, Page, NotFound } from "shared/components";
import { getActiveLeague } from "store/activeLeague/actions";
import LeagueRules from "dashboards/league_dashboard/pages/LeagueRules";
import LeagueReport from "../pages/LeagueReport";
import LeagueTeamsList from "../pages/LeagueTeamsList";
import LeagueTeamView from "../pages/LeagueTeamView";
import LeagueImport from "../pages/LeagueImport";
import LeagueHeader from "../components/LeagueHeader";
import "./styles.scss";

const getSeasonFromQueryParams = (search) => {
  const hasSeasonParam = search.includes("?sid=");
  if (!hasSeasonParam) {
    return undefined;
  }
  const seasonParam = search.split("?sid=")[1];
  return Number(seasonParam);
};

const LeagueContainer = ({
  activeSeason,
  league,
  getLeagueAction,
  location,
  loading,
  match,
}) => {
  const leagueId = get(match, "params.leagueId");
  const seasonQuery = getSeasonFromQueryParams(get(location, "search"));

  const isTeamNavActive = () => {
    const isRulesView =
      get(location, "pathname") === `/leagues/${leagueId}/rules`;
    const isReportView = get(location, "pathname").includes(
      `/leagues/${leagueId}/report`
    );
    const isImportView = get(location, "pathname").includes(
      `/leagues/${leagueId}/imports`
    );

    return !isRulesView && !isReportView && !isImportView;
  };

  const fetchLeagueData = () => {
    getLeagueAction(leagueId, seasonQuery);
  };

  useEffect(() => {
    fetchLeagueData();
  }, [leagueId]);

  if (loading) {
    return (
      <div className="LeagueContainer">
        <Page>
          <ContentLoader text="Getting league data" />
        </Page>
      </div>
    );
  }

  if (!league) {
    return (
      <div className="LeagueContainer">
        <Page>
          <NotFound />
        </Page>
      </div>
    );
  }

  return (
    <div className="LeagueContainer">
      <Page>
        <LeagueHeader />
        <div className="LeagueContainer-menu">
          <NavLink
            to={`/leagues/${leagueId}`}
            isActive={() => isTeamNavActive()}
          >
            Teams
          </NavLink>
          <NavLink to={`/leagues/${leagueId}/rules`}>Compliance rules</NavLink>
          <NavLink to={`/leagues/${leagueId}/report`}>
            Compliance Report
          </NavLink>
          <NavLink to={`/leagues/${leagueId}/imports`}>Imports</NavLink>
        </div>
        <Switch>
          <Route
            exact
            path="/leagues/:leagueId"
            render={() => (
              <LeagueTeamsList
                activeSeason={activeSeason}
                leagueId={Number(leagueId)}
              />
            )}
          />
          <Route
            exact
            path="/leagues/:leagueId/rules"
            render={() => (
              <LeagueRules leagueId={Number(leagueId)} isLeagueAdmin />
            )}
          />
          <Route
            exact
            path="/leagues/:leagueId/report"
            render={() => <LeagueReport leagueId={Number(leagueId)} />}
          />
          <Route
            exact
            path="/leagues/:leagueId/imports"
            render={() => (
              <LeagueImport
                activeSeason={activeSeason}
                leagueId={Number(leagueId)}
              />
            )}
          />
          <Route
            exact
            path="/leagues/:leagueId/:teamId"
            render={() => (
              <LeagueTeamView
                leagueId={Number(leagueId)}
                activeSeason={activeSeason}
              />
            )}
          />
          <Route render={() => <NotFound />} />
        </Switch>
      </Page>
    </div>
  );
};

LeagueContainer.propTypes = {
  activeSeason: PropTypes.object.isRequired,
  getLeagueAction: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  league: PropTypes.object,
};

LeagueContainer.defaultProps = {
  league: null,
};

const mapStateToProps = (state) => ({
  activeSeason: get(state, "activeLeague.activeSeason"),
  loading: get(state, "activeLeague.loading"),
  league: get(state, "activeLeague.details"),
});

const mapDispatchToProps = {
  getLeagueAction: getActiveLeague,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LeagueContainer));
