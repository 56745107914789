import React from 'react';
import PropTypes from 'prop-types';
import {
  Tab,
  Tabs,
  TabContent,
  TabsHeader,
  Table,
} from 'shared/components';
import {
  displayValue,
} from 'shared/helpers';
import {
  mockStandard,
  mockCatching,
} from './mockData';
import './styles.scss';

const GameStatsFielding = ({
  // eslint-disable-next-line no-unused-vars
  teamStatsId,
}) => (
  <div className="GameStatsFielding">
    <div className="GameStatsFielding-tabs">
      <Tabs>
        <TabsHeader>
          <Tab>Standard</Tab>
          <Tab>Catching</Tab>
        </TabsHeader>
        <TabContent>
          <Table
            data={mockStandard.data}
            columns={[
              {
                accessor: 'jersey_nr',
                Header: '#',
                Cell: cellData => displayValue(cellData.value),
                width: 50,
                Footer: '',
              },
              {
                accessor: 'player',
                Header: 'Player',
                Cell: cellData => displayValue(cellData.value),
                maxWidth: 200,
                minWidth: 90,
                Footer: 'Team totals',
                getFooterProps: () => ({
                  className: 'ft-left-pos',
                }),
              },
              {
                accessor: 'tc',
                Header: 'TC',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockStandard.total.tc,
              },
              {
                accessor: 'a',
                Header: 'A',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockStandard.total.a,
              },
              {
                accessor: 'po',
                Header: 'PO',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockStandard.total.po,
              },
              {
                accessor: 'e',
                Header: 'E',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockStandard.total.e,
              },
              {
                accessor: 'dp',
                Header: 'DP',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockStandard.total.dp,
              },
              {
                accessor: 'tp',
                Header: 'TP',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockStandard.total.tp,
              },
              {
                accessor: 'fpct',
                Header: 'FPCT',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockStandard.total.fpct,
              },
            ]}
            minRows={1}
            showPagination={false}
          />
        </TabContent>
        <TabContent>
          <Table
            data={mockCatching.data}
            columns={[
              {
                accessor: 'jersey_nr',
                Header: '#',
                Cell: cellData => displayValue(cellData.value),
                width: 50,
                Footer: '',
              },
              {
                accessor: 'player',
                Header: 'Player',
                Cell: cellData => displayValue(cellData.value),
                maxWidth: 200,
                minWidth: 90,
                Footer: 'Team totals',
                getFooterProps: () => ({
                  className: 'ft-left-pos',
                }),
              },
              {
                accessor: 'inn',
                Header: 'INN',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockCatching.total.inn,
              },
              {
                accessor: 'pb',
                Header: 'PB',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockCatching.total.pb,
              },
              {
                accessor: 'sb',
                Header: 'SB',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockCatching.total.sb,
              },
              {
                accessor: 'sbatt',
                Header: 'SB-ATT',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockCatching.total.sbatt,
              },
              {
                accessor: 'cs',
                Header: 'CS',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockCatching.total.cs,
              },
              {
                accessor: 'csperc',
                Header: 'CS%',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockCatching.total.csperc,
              },
              {
                accessor: 'pik',
                Header: 'PIK',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockCatching.total.pik,
              },
              {
                accessor: 'ci',
                Header: 'CI',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockCatching.total.ci,
              },
            ]}
            minRows={1}
            showPagination={false}
          />
        </TabContent>
      </Tabs>
    </div>
  </div>
);

GameStatsFielding.propTypes = {
  teamStatsId: PropTypes.number.isRequired,
};

export default GameStatsFielding;
