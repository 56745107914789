/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import get from 'lodash.get';
import { withRouter } from 'react-router-dom';
import {
  Table,
  Selector,
} from 'shared/components';
import {
  displayValue,
} from 'shared/helpers';
import { getLeagueTeams, setActiveDivision } from 'store/activeLeague/actions';
import { getTeams, getPlayerStats, getSeasons} from './actions';
import './styles.scss';

const TeamByLocation = ({
  getLeagueTeamsAction,
  teams,
  organizationId,
}) => {

  const [loading, setLoading] = useState(true);
  const [refetching, setRefetching] = useState(false);

  const [hookTeams, setHookTeams] = useState(teams);
  const [seasons, setSeasons] = useState();

  // const redirectToTeamPage = rowData => {
  //   const teamId = get(rowData, 'original.id');
  //   return history.push(`/leagues/${leagueId}/${teamId}`);
  // };

  const fetchLeagueTeams = () => {
    getTeams(organizationId)
      .then(res => {
        const data = get(res, 'data') || [];
        setLoading(false);
        getLeagueTeamsAction(data);
        setHookTeams(data);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    getSeasons(organizationId).then(res =>{
      setSeasons(res.data);
})
    fetchLeagueTeams();
  }, []);


  const [teamFilter, setTeamFilter] = useState([{label: 'Graveyard Boys', id: 21}]);
  const [pickedTeam, setPickedTeam] = useState(86);
  const [locationFilter, setLocationFilter] = useState([{label: 'Home', value: 'home'},{label: 'Away', value: 'away'}]);
  const [pickedLocation, setPickedLocation] = useState('home');

  useEffect(() => {
    if(hookTeams?.results){
      let newFilter = []
    hookTeams?.results.forEach(t => {newFilter.push({label: t.name, value: t.id});})
    setTeamFilter(newFilter);
  }
  },[hookTeams])

  const [results, setResults] = useState([]);

 useEffect(() => {
  getPlayerStats(pickedTeam, pickedLocation).then(res => {  setRefetching(false); setResults(res.data.results); 
  });
 }, [pickedTeam, pickedLocation])
 

  return (
    <div className="LeagueTeamsList">
      <div className="LeagueTeamsList-filters">
        <Selector
          options={teamFilter}
          value={pickedTeam}
          handleChange={val => {setPickedTeam(val); setRefetching(true); }}
          disabled={loading}
        />
          <Selector
          options={locationFilter}
          value={pickedLocation}
          handleChange={val => {setPickedLocation(val); setRefetching(true); }}
          disabled={loading}
        />
      </div>
      <div className="teams-container">
        <Table
          data={results}
          noDataText=""
          loading={loading || refetching}
          columns={[
            {
              accessor: 'player',
              Header: 'Player',
              width: 250,
            },
            {
              accessor: 'ab',
              Header: 'AB',
              Cell: cellData => displayValue(cellData.value),
            },
            {
              accessor: 'obp',
              Header: 'OBP',
              Cell: cellData => displayValue(cellData.value),
            },
            {
              accessor: 'era',
              Header: 'ERA',
              Cell: cellData => displayValue(cellData.value),
            },
            {
              accessor: 'ba',
              Header: 'BA',
              Cell: cellData => displayValue(cellData.value),
            },
          ]}
          clickable
          handleClick={rowData => console.log(rowData)}
          minRows={1}
          showPagination={false}
        />
      </div>
    </div>
  );
};

// LeagueTeamsList.propTypes = {
//   activeSeason: PropTypes.object.isRequired,
//   activeDivision: PropTypes.string.isRequired,
//   divisions: PropTypes.array.isRequired,
//   history: PropTypes.object.isRequired,
//   leagueId: PropTypes.number.isRequired,
//   getLeagueTeamsAction: PropTypes.func.isRequired,
//   setActiveDivisionAction: PropTypes.func.isRequired,
//   teams: PropTypes.array.isRequired,
// };

const mapStateToProps = state => ({
  teams: get(state, 'activeLeague.teams'),
  divisions: get(state, 'activeLeague.details.divisions'),
  activeDivision: get(state, 'activeLeague.activeDivision'),
});

const mapDispatchToProps = {
  getLeagueTeamsAction: getLeagueTeams,
  setActiveDivisionAction: setActiveDivision,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TeamByLocation));
