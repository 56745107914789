export const mockStandard = {
  data: [
    {
      jersey_nr: 12,
      player: 'John Doe',
      tc: 4,
      a: 1,
      po: 0,
      e: 4,
      dp: 0,
      tp: 0,
      fpct: 0.500,
    },
  ],
  total: {
    tc: 4,
    a: 1,
    po: 0,
    e: 4,
    dp: 0,
    tp: 0,
    fpct: 0.500,
  },
};

export const mockCatching = {
  data: [
    {
      jersey_nr: 12,
      player: 'John Doe',
      inn: 3.0,
      pb: 1,
      sb: 2,
      sbatt: '3-4',
      cs: 1,
      csperc: 0.250,
      pik: 0,
      ci: 0,
    },
  ],
  total: {
    inn: 3.0,
    pb: 1,
    sb: 2,
    sbatt: '3-4',
    cs: 1,
    csperc: 0.250,
    pik: 0,
    ci: 0,
  },
};
