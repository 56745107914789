import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import get from 'lodash.get';
import api from 'shared/api';
import {
  Table,
} from 'shared/components';
import {
  displayValue,
} from 'shared/helpers';
// import './styles.scss';

const TeamStatsBasic = ({
  history,
  teamId,
  seasonId,
}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const fetchSeasonStats = (team, season) => {
    api.get(`/api/v1/teams/${team}/stats/?season=${season}`)
      .then((res) => {
        const stats = get(res, 'data') || [];
        setLoading(false);
        setData(stats);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    fetchSeasonStats(teamId, seasonId);
  }, [teamId, seasonId]);

  const redirectToPlayerPage = rowData => {
    const playerId = get(rowData, 'original.id');
    history.push(`/${teamId}/players/${playerId}`);
  };

  return (
    (
      <div className="TeamStatsBasic">
        <Table
          data={data.players}
          loading={loading}
          columns={[
            {
              accessor: 'jersey_nr',
              Header: '#',
              Cell: cellData => displayValue(cellData.value),
              width: 50,
            },
            {
              accessor: 'player',
              Header: 'Player',
              Cell: cellData => displayValue(cellData.value),
              maxWidth: 200,
              minWidth: 90,
              Footer: 'Totals',
              getFooterProps: () => ({
                className: 'ft-left-pos',
              }),
            },
            {
              accessor: 'gc',
              // Header: 'GC',
              Header: 'Game count',
              Cell: cellData => displayValue(cellData.value),
              minWidth: 40,
              Footer: displayValue(get(data, 'total.gc')),
            },
            {
              accessor: 'pc',
              // Header: 'PC',
              Header: 'Pitch count',
              Cell: cellData => displayValue(cellData.value),
              minWidth: 40,
              Footer: displayValue(get(data, 'total.pc')),
            },
            {
              accessor: 'avgpc',
              // Header: 'AVGPC',
              Header: 'Average pitch count',
              Cell: cellData => displayValue(cellData.value),
              minWidth: 40,
              Footer: displayValue(get(data, 'total.avgpc')),
            },
            {
              accessor: 'cc',
              // Header: 'CC',
              Header: 'Catch count',
              Cell: cellData => displayValue(cellData.value),
              minWidth: 40,
              Footer: displayValue(get(data, 'total.cc')),
            },
            {
              accessor: 'avgcc',
              // Header: 'AVGCC',
              Header: 'Average catch count',
              Cell: cellData => displayValue(cellData.value),
              minWidth: 40,
              Footer: displayValue(get(data, 'total.avgcc')),
            },
          ]}
          minRows={1}
          showPagination={false}
          clickable
          handleClick={rowData => redirectToPlayerPage(rowData)}
          // style={{
          //   height: `${get(data, 'players.length', 0) > 10 ? '450px' : 'unset'}`, // This will force the table body to overflow and scroll, since there is not enough room
          // }}
        />
      </div>
    )
  );
};

TeamStatsBasic.propTypes = {
  history: PropTypes.object.isRequired,
  teamId: PropTypes.number.isRequired,
  seasonId: PropTypes.number.isRequired,
};

const mapStateToProps = state => ({
  seasonId: get(state, 'activeTeam.activeSeason.id'),
});


export default connect(mapStateToProps)(withRouter(TeamStatsBasic));
