import api from 'shared/api';

export const followTeam = (teamId, userId) => {
  const data = {
    role: 'fan',
    user: Number(userId),
    team: Number(teamId),
  };

  return api.post('/api/v1/userteams/', data);
};

export const unfollowTeam = userTeamId => api.delete(`/api/v1/userteams/${userTeamId}`);
