import React, { useState } from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  Notification,
} from 'shared/components';
import { deletePlayer } from './actions';
import './styles.scss';

const DeleteLineupPlayer = ({
  close,
  isOpen,
  player,
  refreshActiveLineup,
  teamId,
  seasonId,
}) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = e => {
    const playerId = get(player, 'id');
    e.preventDefault();

    setLoading(true);

    deletePlayer(playerId)
      .then(() => {
        Notification('success', 'Player deleted from lineup');
        refreshActiveLineup(teamId, seasonId);
        close();
      })
      .catch(() => {
        setLoading(false);
        Notification(
          'error',
          'Error occured',
          'There was an error while deleting a player from lineup',
        );
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      closeCb={close}
      title="Are you sure you want to delete this player from a lineup?"
      size="md"
    >
      <div className="DeleteLineupPlayer">
        <form
          className="DeleteLineupPlayer-form"
          onSubmit={handleSubmit}
        >
          <div className="form-submit">
            <Button
              theme="link"
              type="button"
              disabled={loading}
              onClick={close}
            >
              Cancel
            </Button>
            <Button
              theme="error"
              type="submit"
              disabled={loading}
            >
              Delete
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

DeleteLineupPlayer.propTypes = {
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  player: PropTypes.object.isRequired,
  refreshActiveLineup: PropTypes.func.isRequired,
  teamId: PropTypes.number.isRequired,
  seasonId: PropTypes.number.isRequired,
};

export default DeleteLineupPlayer;
