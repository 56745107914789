import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import get from 'lodash.get';
import api from 'shared/api';
import {
  Table,
} from 'shared/components';
import {
  formatDate,
  displayValue,
} from 'shared/helpers';
import {
  defaultDateFormat,
} from 'shared/constants';
// import './styles.scss';

const PlayerStatsBasic = ({
  history,
  playerId,
  seasonId,
  teamId,
}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const fetchSeasonStats = (player, season) => {
    api.get(`/api/v1/players/${player}/stats/?season=${season}`)
      .then((res) => {
        const stats = get(res, 'data') || [];
        setLoading(false);
        setData(stats);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    fetchSeasonStats(playerId, seasonId);
  }, [playerId, seasonId]);

  const redirectToGamePage = rowData => {
    const gameId = get(rowData, 'original.id');
    history.push(`/${teamId}/games/${gameId}`);
  };

  return (
    <div className="PlayerStatsBasic">
      <Table
        data={data.games}
        loading={loading}
        columns={[
          {
            accessor: 'date',
            Header: 'Game date',
            Cell: cellData => formatDate(cellData.value, defaultDateFormat),
            // minWidth: 60,
            Footer: 'All totals',
          },
          {
            accessor: 'against',
            Header: 'Against',
            Cell: cellData => displayValue(cellData.value),
            // maxWidth: 200,
            // minWidth: 90,
            Footer: displayValue(get(data, 'total.gc')),
          },
          {
            accessor: 'pc',
            // Header: 'PC',
            Header: 'Pitch count',
            Cell: cellData => displayValue(cellData.value),
            // minWidth: 40,
            Footer: displayValue(get(data, 'total.pc')),
          },
          {
            accessor: 'cc',
            // Header: 'CC',
            Header: 'Catch count',
            Cell: cellData => displayValue(cellData.value),
            // minWidth: 40,
            Footer: displayValue(get(data, 'total.cc')),
          },
        ]}
        minRows={1}
        showPagination={false}
        clickable
        handleClick={rowData => redirectToGamePage(rowData)}
      />
    </div>
  );
};

PlayerStatsBasic.propTypes = {
  history: PropTypes.object.isRequired,
  playerId: PropTypes.number.isRequired,
  seasonId: PropTypes.number.isRequired,
  teamId: PropTypes.number.isRequired,
};

const mapStateToProps = state => ({
  seasonId: get(state, 'activeTeam.activeSeason.id'),
});

export default connect(mapStateToProps)(withRouter(PlayerStatsBasic));
