import get from 'lodash.get';
import api from 'shared/api';
import * as actionTypes from './actionTypes';

// userteams actions
const initGetUserTeams = actions => () => ({
  type: actions.getUserTeamsInit,
});

const getUserTeamsError = actions => () => ({
  type: actions.getUserTeamsError,
});

const getUserTeamsSuccess = actions => (res) => ({
  type: actions.getUserTeamsSuccess,
  teams: res,
});

export const getUserTeams = id => dispatch => {
  dispatch(initGetUserTeams(actionTypes)());
  return api.get(`/api/v1/userteams/?user__id=${id}`).then(
    res => dispatch(getUserTeamsSuccess(actionTypes)(get(res, 'data.results'))),
    () => dispatch(getUserTeamsError(actionTypes)()),
  );
};

export const refreshUserTeams = id => dispatch => api.get(`/api/v1/userteams/?user__id=${id}`)
  .then(
    res => dispatch(getUserTeamsSuccess(actionTypes)(get(res, 'data.results'))),
    () => dispatch(getUserTeamsError(actionTypes)()),
  );
