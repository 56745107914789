import React from 'react';
import PropTypes from 'prop-types';
// import { withRouter } from 'react-router-dom';
// import {
//   Button,
// } from 'shared/components';
import icon from './authentication.svg';
import './styles.scss';

const NotAuthorized = ({
  message,
}) => (
  <div className="NotAuthorized">
    <img src={icon} alt="Not authorized" />
    <p>
      You are not authorized to view this page
    </p>
    <p className="message">{message}</p>
    {/* <Button
      onClick={() => { history.goBack(); }}
    >
      Go back
    </Button> */}
  </div>
);

NotAuthorized.propTypes = {
  // history: PropTypes.object.isRequired,
  message: PropTypes.string,
};

NotAuthorized.defaultProps = {
  message: '',
};

// export default withRouter(NotAuthorized);
export default NotAuthorized;
