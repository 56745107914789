import api from 'shared/api';

export const createPlayer = data => api.post('/api/v1/players/', data);

export const addFamilyMember = (playerId, familyEmail) => {
  const data = {
    email: familyEmail,
    player: playerId,
  };

  return api.post(`/api/v1/players/${playerId}/add_user/`, data);
};
