import React from 'react';
import {
  UnderConstruction,
} from 'shared/components';

const ProfileTournaments = () => (
  <div className="ProfileTournaments" style={{ marginTop: '10px' }}>
    <UnderConstruction />
  </div>
);

export default ProfileTournaments;
