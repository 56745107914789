import React from 'react';
import PropTypes from 'prop-types';
import { suplementalRulesObject } from 'shared/constants';
import './styles.scss';

const SuplementalRuleMeta = ({
  ruleMeta,
}) => (
  <ul className="SuplementalRuleMeta">
    {
      Object.entries(ruleMeta).map(([key, value]) => (
        <li className="ruleMeta-row" key={key}>
          <span className="key">{suplementalRulesObject[key]}</span>
          <span className="value">{value}</span>
        </li>
      ))
    }
  </ul>
);

SuplementalRuleMeta.propTypes = {
  ruleMeta: PropTypes.object.isRequired,
};

export default SuplementalRuleMeta;
