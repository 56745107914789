import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import get from 'lodash.get';
import {
  Button,
  DescriptionList,
  Notification,
  Table,
} from 'shared/components';
import {
  displayValue,
  formatDate,
  isAdmin,
  isEditableSeason,
  isPlayersFamily,
} from 'shared/helpers';
import {
  playerPositionOptions,
  gamesStatusOptions,
} from 'shared/constants';
import EditGameForm from './components/EditGameForm';
import AddLocationForm from './components/AddLocationForm';
import EditLocationForm from './components/EditLocationForm';
import './styles.scss';

const GameHighlights = ({
  activeSeason,
  fetchGameData,
  game,
  teamId,
  history,
}) => {
  const isAuth = isAdmin(teamId);

  const [isEditGameDisplayed, setEditGameDisplay] = useState(false);
  const [isEditLocationDisplayed, setEditLocationDisplay] = useState(false);
  const [isAddLocationDisplayed, setAddLocationDisplay] = useState(false);

  const isLocationAdded = get(game, 'baseball_field') || false;

  const handleGameEdit = () => {
    if (!isAuth) {
      return Notification(
        'error',
        'You are not authorized for this action',
        'Only admin can edit team',
      );
    }

    if (!isEditableSeason(activeSeason.id)) {
      return Notification(
        'error',
        'Action not available for past seasons',
      );
    }

    return setEditGameDisplay(true);
  };

  const handleLocationEdit = () => {
    if (!isAuth) {
      return Notification(
        'error',
        'You are not authorized for this action',
        'Only admin can edit team',
      );
    }

    if (!isEditableSeason(activeSeason.id)) {
      return Notification(
        'error',
        'Action not available for past seasons',
      );
    }

    return isLocationAdded ? setEditLocationDisplay(true) : setAddLocationDisplay(true);
  };

  const handlePlayerClickAction = (player, team) => {
    const playerId = get(player, 'original.id');

    if (!isAuth && !isPlayersFamily(playerId)) {
      return Notification(
        'error',
        'You are not authorized for this action',
        'Only admin or family members can view players stats',
      );
    }

    return history.push(`/${team}/players/${playerId}`);
  };

  const statusObject = gamesStatusOptions.find(i => i.value === get(game, 'status'));

  return (
    <div className="GameHighlights">
      <div className="GameHighlights-actions">
        <div className="PlayersList-actions">
          <Button
            size="sm"
            onClick={handleGameEdit}
          >
            Edit game
          </Button>
        </div>
      </div>
      <div className="GameHighlights-details">
        <DescriptionList
          data={[
            { label: 'Time', value: formatDate(get(game, 'time_start')) },
            { label: 'Arrival before start', value: displayValue(get(game, 'arrival_minutes')) },
            { label: 'Status', value: displayValue(get(statusObject, 'label') || '-') },
            { label: 'Notes', value: displayValue(get(game, 'note')) },
            { label: 'Game duration', value: displayValue(get(game, 'time_duration')) },
            { label: 'Home pitcher', value: displayValue(get(game, 'pitcher_home.first_name')) },
            { label: 'Away pitcher', value: displayValue(get(game, 'pitcher_away.last_name')) },
            { label: 'Total innings', value: displayValue(get(game, 'inn')) },
            { label: 'Total runs', value: displayValue(get(game, 'r')) },
            { label: 'Runs average', value: displayValue(get(game, 'ra')) },
          ]}
        />
      </div>
      <div className="GameHighlights-location">
        <h3 className="location-heading">
          Location
        </h3>
        <Button
          size="sm"
          onClick={handleLocationEdit}
        >
          {
            isLocationAdded ? 'Edit location' : 'Add location'
          }
        </Button>
        <div className="location-details">
          <DescriptionList
            data={[
              { label: 'Baseball field', value: displayValue(get(game, 'baseball_field.name')) },
              { label: 'Address', value: displayValue(get(game, 'baseball_field.address')) },
              { label: 'City', value: displayValue(get(game, 'baseball_field.city')) },
              { label: 'State', value: displayValue(get(game, 'baseball_field.state')) },
              { label: 'Zipcode', value: displayValue(get(game, 'baseball_field.zipcode')) },
              { label: 'Country', value: displayValue(get(game, 'baseball_field.country')) },
            ]}
          />
        </div>
      </div>
      <div className="GameHighlights-players">
        <div className="team-home-players players-container">
          <div className="team-name">
            {`${get(game, 'team_home.name') || 'TBD'} players`}
          </div>
          <Table
            data={get(game, 'team_home.players') || []}
            noDataText=""
            columns={[
              {
                accessor: 'first_name',
                Header: 'First name',
                Cell: cellData => displayValue(cellData.value),
              },
              {
                accessor: 'last_name',
                Header: 'Last name',
                Cell: cellData => displayValue(cellData.value),
              },
              {
                accessor: 'position',
                Header: 'Position',
                Cell: cellData => {
                  const position = get(cellData, 'value') || '';
                  const item = playerPositionOptions.find(p => p.value === position);
                  return displayValue(get(item, 'label'));
                },
              },
            ]}
            clickable
            handleClick={rowData => handlePlayerClickAction(rowData, get(game, 'team_home.id'))}
            minRows={1}
            showPagination={false}
            sortable={false}
          />
        </div>
        <div className="team-away-players players-container">
          <div className="team-name">
            {`${get(game, 'team_away.name') || 'TBD'} players`}
          </div>
          <Table
            data={get(game, 'team_away.players') || []}
            noDataText=""
            columns={[
              {
                accessor: 'first_name',
                Header: 'First name',
                Cell: cellData => displayValue(cellData.value),
              },
              {
                accessor: 'last_name',
                Header: 'Last name',
                Cell: cellData => displayValue(cellData.value),
              },
              {
                accessor: 'position',
                Header: 'Position',
                Cell: cellData => {
                  const position = get(cellData, 'value') || '';
                  const item = playerPositionOptions.find(p => p.value === position);
                  return displayValue(get(item, 'label'));
                },
              },
            ]}
            clickable
            handleClick={rowData => handlePlayerClickAction(rowData, get(game, 'team_away.id'))}
            minRows={1}
            showPagination={false}
            sortable={false}
          />
        </div>
      </div>
      {
        isEditGameDisplayed && (
          <EditGameForm
            isOpen={isEditGameDisplayed}
            fetchGameData={fetchGameData}
            close={() => setEditGameDisplay(false)}
            game={game}
            teamId={teamId}
            activeSeason={activeSeason}
          />
        )
      }
      {
        isEditLocationDisplayed && (
          <EditLocationForm
            isOpen={isEditLocationDisplayed}
            close={() => setEditLocationDisplay(false)}
            game={game}
            fetchGameData={fetchGameData}
          />
        )
      }
      {
        isAddLocationDisplayed && (
          <AddLocationForm
            isOpen={isAddLocationDisplayed}
            close={() => setAddLocationDisplay(false)}
            game={game}
            fetchGameData={fetchGameData}
          />
        )
      }
    </div>
  );
};

GameHighlights.propTypes = {
  activeSeason: PropTypes.object.isRequired,
  game: PropTypes.object.isRequired,
  fetchGameData: PropTypes.func.isRequired,
  teamId: PropTypes.number.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(GameHighlights);
