/* eslint-disable */
import api from "shared/api";

export const createEventStat = (data) => {
  const url = `/api/v1/statdefs/`;
  return api.post(url, data);
};

export const createEventConnection = (data) => {
  const url = `/api/v1/playerteststats/`;
  return api.post(url, data);
};

export const checkEventConnection = (eventId, playerId) => {
  const url = `/api/v1/playerteststats/?player__id=${playerId}&testing_event__id=${eventId}`;
  return api.get(url);
};

export const existingStats = (playerId, statId, testEventId) => {
  const url = `/api/v1/playerstatvalues/?player_test_stat__player__id=${playerId}&stat_def__code=${statId}&player_test_stat__testing_event__id=${testEventId}`;
  return api.get(url);
};

export const createStat = (statId, PlayerTestStatId, value) => {
  let data = {
    value: value,
    player_test_stat: PlayerTestStatId,
    stat_def: statId,
  };
  const url = `/api/v1/playerstatvalues/`;
  return api.post(url, data);
};

export const updateStat = (statValueId, value) => {
  let data = {
    value: value,
  };
  const url = `/api/v1/playerstatvalues/${statValueId}/`;
  return api.patch(url, data);
};
