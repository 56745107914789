import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import get from "lodash.get";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { getUser } from "store/user/actions";
import { ContentLoader } from "shared/components";
import AppContainer from "./app/AppContainer";
import LoginContainer from "./login/LoginContainer";
import RegisterContainer from "./login/RegisterContainer";
import isAuthorized from "./app/isAuthorized";
import NoOrganization from "shared/components/error/NoOrganization";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";

class App extends Component {
  componentDidMount() {
    const { getUserAction } = this.props;
    getUserAction();
  }

  render() {
    const { loading, userId, organizationId } = this.props;
    if (loading) {
      return <ContentLoader />;
    }

    return (
      <div className="App">
        <BrowserRouter>
          <Switch>
            <Route path="/error" component={NoOrganization} />
            <Route path="/login" component={LoginContainer} />
            <Route path="/register" component={RegisterContainer} />
            <Route
              path="/"
              component={isAuthorized(
                AppContainer,
                userId,
                organizationId ? organizationId : []
              )}
            />
          </Switch>
        </BrowserRouter>
        <ToastContainer
          autoClose={3000}
          closeButton={false}
          toastClassName="Notification-container"
          hideProgressBar={false}
          position="bottom-right"
        />
      </div>
    );
  }
}

App.propTypes = {
  getUserAction: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  userId: PropTypes.number,
  organizationId: PropTypes.object,
};

App.defaultProps = {
  userId: null,
  organizationId: null,
};

const mapStateToProps = (state) => ({
  organizationId: get(state, "user").details.organizations,
  loading: get(state, "user.loading"),
  userId: get(state, "user.userID"),
});

const mapDispatchToProps = {
  getUserAction: getUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
