import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash.get';
import { NavLink, withRouter } from 'react-router-dom';
import {
  Dropdown,
  IconUser,
} from 'shared/components';
import { logout, logoutError, logoutSuccess } from 'store/user/actions';
import './styles.scss';

class ProfileNavigation extends Component {
  constructor() {
    super();

    this.state = {
      isMenuDisplayed: false,
    };

    this.popupRef = React.createRef();
  }

  handleDropdown = () => {
    const { isMenuDisplayed } = this.state;
    this.setState({
      isMenuDisplayed: !isMenuDisplayed,
    });
  }

  handleLogout = () => {
    const {
      history,
      logoutAction,
      logoutErrorAction,
      logoutSuccessAction,
    } = this.props;
    logoutAction().then(() => {
      logoutSuccessAction();
      history.push('/login');
    }).catch(() => {
      logoutErrorAction();
    });
  }

  render() {
    const { isMenuDisplayed } = this.state;
    const { user } = this.props;

    return (
      <div className="ProfileNavigation">
        <div className="profile-label">
          <div className="sidebar-item" onClick={this.handleDropdown} role="presentation">
            <IconUser height="18px" width="18px" viewBox="0 0 512 512" />
            {get(user, 'first_name') || ''}
            <span className="arrow-down">
              {`${'▾'}`}
            </span>
          </div>
        </div>
        {isMenuDisplayed && (
          <Dropdown ref={this.popupRef} close={this.handleDropdown}>
            <ul className="links">
              <li>
                <NavLink
                  className="li-content"
                  onClick={this.handleDropdown}
                  to="/profile"
                >
                  My profile
                </NavLink>
              </li>
              <li>
                <button
                  className="li-content"
                  onClick={this.handleLogout}
                  type="button"
                >
                  Logout
                </button>
              </li>
            </ul>
          </Dropdown>
        )}
      </div>
    );
  }
}

ProfileNavigation.propTypes = {
  history: PropTypes.object.isRequired,
  logoutAction: PropTypes.func.isRequired,
  logoutErrorAction: PropTypes.func.isRequired,
  logoutSuccessAction: PropTypes.func.isRequired,
  user: PropTypes.object,
};

ProfileNavigation.defaultProps = {
  user: null,
};

const mapDispatchToProps = {
  logoutAction: logout,
  logoutErrorAction: logoutError,
  logoutSuccessAction: logoutSuccess,
};

export default connect(null, mapDispatchToProps)(withRouter(ProfileNavigation));
