/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import RTable from 'react-table';
import isString from 'lodash.isstring';
import 'react-table/react-table.css';
import './styles.scss';

const Table = ({
  clickable,
  columns,
  data,
  handleClick,
  resizable,
  disableHover,
  ...rest
}) => (
  <div className={`Table ${clickable ? 'Table-clickable' : ''} ${disableHover ? 'Table-disable-hover' : ''}`}>
    <RTable
      columns={columns}
      data={data}
      getTrProps={(state, rowInfo) => ({
        onClick: () => handleClick(rowInfo),
      })}
      getTdProps={() => ({
        style: {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        },
        onClick: (e, handleOriginal) => {
          const targetClassName = e.target.className;
          let isExpanderClicked;
          if (isString(targetClassName)) {
            isExpanderClicked = targetClassName.includes('rt-expander');
          } else {
            isExpanderClicked = false;
          }

          if (handleOriginal) {
            if (isExpanderClicked) {
              handleOriginal();
              e.preventDefault();
              e.stopPropagation();
            }
          }
        },
      })}
      resizable={resizable}
      showPageSizeOptions={false}
      previousText="Previous"
      nextText="Next"
      loadingText="Loading..."
      noDataText="Search returned 0 results"
      pageText="Page"
      ofText="of"
      rowsText="rows"
      defaultPageSize={100}
      {...rest}
    />
  </div>
);

Table.propTypes = {
  clickable: PropTypes.bool,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  handleClick: PropTypes.func,
  resizable: PropTypes.bool,
  disableHover: PropTypes.bool,
};

Table.defaultProps = {
  clickable: false,
  disableHover: false,
  handleClick: () => {},
  resizable: false,
};

export default Table;
