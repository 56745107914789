import api from 'shared/api';

export const addUser = (id, data) => api.post(`/api/v1/teams/${id}/add_user/`, data);

export const patchUser = (id, data) => api.patch(`/api/v1/userteams/${id}/`, data);

export const getPlayers = (teamId, seasonId) => (
  api.get(`/api/v1/playerteamhistories/?team__id=${teamId}&season__id=${seasonId}`)
);

export const addUserPlayer = (id, data) => api.post(`/api/v1/players/${id}/add_user/`, data);
