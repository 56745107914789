import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import get from 'lodash.get';
import DatePicker from 'react-date-picker';
import TimePicker from 'react-time-picker';
import {
  AsyncSelector,
  Button,
  Checkbox,
  Label,
  Modal,
  Notification,
  NumberInput,
  TextArea,
  Selector,
} from 'shared/components';
import api from 'shared/api';
import {
  gamesStatusOptions,
} from 'shared/constants';
import { refreshActiveGames } from 'store/activeTeam/actions';
import { patchGame } from './actions';
import {
  isAwayGame,
  getGameStatus,
} from './helpers';
import './styles.scss';

const EditGameForm = ({
  activeSeason,
  fetchGameData,
  close,
  isOpen,
  game,
  teamId,
  refreshActiveGamesAction,
}) => {
  const gameId = get(game, 'id');
  const leagueId = get(game, 'team_home.league.id') || get(game, 'team_away.league.id');
  const seasonId = get(activeSeason, 'id');
  const isAway = isAwayGame(game, teamId);
  const opponentTeam = isAway ? get(game, 'team_home') : get(game, 'team_away');

  const [loading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [status, setStatus] = useState(getGameStatus(game));
  const [away, setAway] = useState(isAway);
  const [opponent, setOpponent] = useState(opponentTeam || {
    name: 'TBD',
    id: null,
  });
  const [date, setDate] = useState(new Date(moment(get(game, 'time_start')).format('YYYY-MM-DD')));
  const [time, setTime] = useState(moment(get(game, 'time_start')).format('HH:mm'));

  const [arrival, setArrival] = useState(get(game, 'arrival_minutes') || 0);
  const [notes, setNotes] = useState(get(game, 'note') || '');

  const handleSubmit = e => {
    e.preventDefault();

    setLoading(true);

    const formatDate = moment(date).format('YYYY-MM-DD');
    const momentObj = moment(`${formatDate} ${time}`);
    const dateTime = momentObj.format('YYYY-MM-DDTHH:mm:ssZ');

    const teamHome = away ? (get(opponent, 'id') || null) : teamId;
    const teamAway = away ? teamId : (get(opponent, 'id') || null);

    const data = {
      time_start: dateTime,
      status: status.value,
      team_home: teamHome,
      team_away: teamAway,
      season: seasonId,
      arrival_minutes: Number(arrival) || 0,
      note: notes,
    };

    patchGame(gameId, data)
      .then(() => {
        Notification('success', 'Game edited');
        fetchGameData();
        refreshActiveGamesAction(teamId, seasonId);
        close();
      })
      .catch(() => {
        setLoading(false);
        Notification('error', 'Error occured', 'There was an error while editing a game');
      });
  };

  const handleClose = () => {
    if (
      !dirty ||
      window.confirm('Are you sure you want to discard your changes?')
    ) {
      close();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      closeCb={handleClose}
      title="Edit a game"
      size="sm"
    >
      <div className="EditGameForm">
        <form
          className="EditGameForm-form"
          onSubmit={handleSubmit}
        >
          <div className="form-row">
            <Label text="Select date of game" inputId="date-input" />
            <DatePicker
              onChange={val => {
                setDirty(true);
                setDate(val);
              }}
              value={date}
              required
            />
          </div>
          <div className="form-row">
            <Label text="Select time of game" inputId="time-input" />
            <TimePicker
              value={time}
              onChange={val => {
                setDirty(true);
                setTime(val);
              }}
              disableClock
              required
            />
          </div>
          <div className="form-row">
            <Label text="Arrival before game start" inputId="game-arrival" />
            <NumberInput
              id="game-arrival"
              value={arrival}
              min="0"
              max="1000"
              handleChange={val => {
                setDirty(true);
                setArrival(val);
              }}
              placeholder="Enter number of minutes"
            />
          </div>
          <div className="form-row">
            <Label
              inputId="games-status"
              text="Status"
            />
            <Selector
              id="games-status"
              options={gamesStatusOptions}
              value={status.value}
              handleChange={val => {
                const selected = gamesStatusOptions.find(option => option.value === val);
                setStatus(selected);
                setDirty(true);
              }}
              disabled={loading}
            />
          </div>
          <div className="form-row">
            <Label
              text="Opponent"
              inputId="games-opponent"
            />
            <AsyncSelector
              append={{
                name: 'TBD',
                id: null,
              }}
              exclude={teamId}
              valueKey="id"
              labelKey="name"
              fetchOptions={val => (
                api.get(`/api/v1/teams/?league__id=${leagueId}&name__icontains=${val}`)
              )}
              fetchInitialOptions={() => api.get(`/api/v1/teams/?league__id=${leagueId}&limit=10`)}
              handleChange={val => {
                setDirty(true);
                setOpponent(val);
              }}
              value={get(opponent, 'name') || 'TBD'}
            />
          </div>
          <div className="form-row">
            <Checkbox
              inputId="away-input"
              value={away}
              handleChange={val => {
                setDirty(true);
                setAway(val);
              }}
              disabled={loading}
              label="Is game away?"
            />
          </div>
          <div className="form-row">
            <Label
              text="Notes"
              inputId="game-note"
            />
            <TextArea
              value={notes}
              handleChange={val => {
                setDirty(true);
                setNotes(val);
              }}
              disabled={loading}
              rows={4}
            />
          </div>
          <div className="form-submit">
            <Button
              theme="link"
              type="button"
              disabled={loading}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              theme="success"
              type="submit"
              disabled={loading}
            >
              Save edits
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

EditGameForm.propTypes = {
  activeSeason: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  refreshActiveGamesAction: PropTypes.func.isRequired,
  teamId: PropTypes.number.isRequired,
  game: PropTypes.object.isRequired,
  fetchGameData: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  refreshActiveGamesAction: refreshActiveGames,
};

export default connect(null, mapDispatchToProps)(EditGameForm);
