import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { connect } from 'react-redux';
import {
  NavLink,
  Switch,
  Route,
} from 'react-router-dom';
import { refreshUser } from 'store/user/actions';
import { cleanTeam } from 'store/activeTeam/actions';
import {
  Page,
  NotFound,
} from 'shared/components';
import ProfileHome from '../pages/ProfileHome';
import ProfileTeams from '../pages/ProfileTeams';
import ProfileAlerts from '../pages/ProfileAlerts';
import ProfilePlayers from '../pages/ProfilePlayers';
import ProfileTournaments from '../pages/ProfileTournaments';
import './styles.scss';

const ProfileContainer = ({
  cleanActiveTeam,
  refreshUserAction,
  user,
}) => {
  useEffect(() => {
    cleanActiveTeam();
  }, []);

  return (
    <div className="ProfileContainer">
      <Page>
        <div className="ProfileContainer-menu">
          <NavLink exact to="/profile">Profile</NavLink>
          <NavLink exact to="/profile/teams">Teams</NavLink>
          <NavLink exact to="/profile/players">Players</NavLink>
          <NavLink exact to="/profile/alerts">Alerts</NavLink>
          {/* <NavLink exact to="/profile/tournaments">Tournaments and groups</NavLink> */}
        </div>
        <Switch>
          <Route
            exact
            path="/profile"
            render={() => <ProfileHome user={user} refreshUser={refreshUserAction} />}
          />
          <Route
            exact
            path="/profile/teams"
            render={() => <ProfileTeams userId={get(user, 'id')} />}
          />
          <Route
            exact
            path="/profile/players"
            render={() => <ProfilePlayers userId={get(user, 'id')} />}
          />
          <Route
            exact
            path="/profile/alerts"
            component={ProfileAlerts}
          />
          <Route
            exact
            path="/profile/tournaments"
            component={ProfileTournaments}
          />
          <Route render={() => <NotFound />} />
        </Switch>
      </Page>
    </div>
  );
};

ProfileContainer.propTypes = {
  cleanActiveTeam: PropTypes.func.isRequired,
  refreshUserAction: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  user: get(state, 'user.details'),
});

const mapDispatchToProps = {
  cleanActiveTeam: cleanTeam,
  refreshUserAction: refreshUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileContainer);
