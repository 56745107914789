import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import {
  Route,
  Switch,
} from 'react-router-dom';
import {
  ContentLoader,
  PageHeader,
} from 'shared/components';
import { getActiveGames } from 'store/activeTeam/actions';
import { getGameData } from './actions';
import {
  GamesList,
  GameContainer,
} from '../pages';
import './styles.scss';

const GamesDashboard = ({
  activeSeason,
  games,
  getActiveGamesAction,
  team,
}) => {
  const teamId = get(team, 'id');
  const seasonId = get(activeSeason, 'id');
  const isMounted = React.useRef(true);

  const [loading, setLoading] = useState(true);

  const fetchGames = () => {
    setLoading(true);

    getGameData(teamId, seasonId)
      .then(res => {
        if (isMounted.current) {
          const data = get(res, 'data.results') || [];
          getActiveGamesAction(data);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchGames();
  }, [seasonId]);

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  if (loading) {
    return (
      <div className="GamesDashboard">
        <PageHeader disabledSeason />
        <ContentLoader text="Getting team games list" />
      </div>
    );
  }

  return (
    <div className="GamesDashboard">
      <Switch>
        <Route
          exact
          path="/:teamId/games"
          render={() => (
            <GamesList team={team} games={games} activeSeason={activeSeason} />
          )}
        />
        <Route
          path="/:teamId/games/:gameId"
          render={() => <GameContainer team={team} />}
        />
      </Switch>
    </div>
  );
};

GamesDashboard.propTypes = {
  activeSeason: PropTypes.object.isRequired,
  games: PropTypes.array.isRequired,
  getActiveGamesAction: PropTypes.func.isRequired,
  team: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  games: get(state, 'activeTeam.games'),
});

const mapDispatchToProps = {
  getActiveGamesAction: getActiveGames,
};

export default connect(mapStateToProps, mapDispatchToProps)(GamesDashboard);
