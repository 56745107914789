/* eslint-disable */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import get from "lodash.get";
import DatePicker from "react-date-picker";
import {
  Button,
  Label,
  Modal,
  Notification,
  NumberInput,
  Selector,
  TextInput,
  TextArea,
} from "shared/components";
import {
  defaultDateFormat,
  playerPositionOptions,
  playerSideOptions,
} from "shared/constants";
import { refreshActivePlayers } from "store/activeTeam/actions";
import { editPlayer, patchJerseyNr } from "./actions";
import "./styles.scss";
import { inc } from "semver";

const mapSideValueToSelector = (val) => {
  const item = playerSideOptions.find((o) => o.value === val);

  return item;
};

const mapPositionValueToSelector = (val) => {
  const item = playerPositionOptions.find((o) => o.value === val);

  return item;
};

const EditPlayerForm = ({
  close,
  isOpen,
  fetchPlayerData,
  jerseyNr,
  player,
  refreshActivePlayersAction,
  teamId,
  teamPlayers,
  seasonId,
}) => {
  const playerId = get(player, "id");
  const [loading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);

  const dob = get(player, "date_of_birth")
    ? new Date(get(player, "date_of_birth"))
    : undefined;

  const [jersey, setJersey] = useState(jerseyNr);
  const [jerseyError, setJerseyError] = useState("");
  const [firstName, setFirstName] = useState(get(player, "first_name"));
  const [lastName, setLastName] = useState(get(player, "last_name"));
  const [city, setCity] = useState(get(player, "city"));
  const [state, setState] = useState(get(player, "state"));

  const [date, setDate] = useState(dob);
  const [graduation, setGraduation] = useState(get(player, "graduation_year"));

  const [bats, setBats] = useState(mapSideValueToSelector(get(player, "bats")));
  const [throws, setThrows] = useState(
    mapSideValueToSelector(get(player, "throws"))
  );
  const [position, setPosition] = useState(
    mapPositionValueToSelector(get(player, "position"))
  );
  const [height, setHeight] = useState(
    mapPositionValueToSelector(get(player, "height"))
  );
  const [weight, setWeight] = useState(
    parseInt(get(player, "weight")?.replace(`kg`, ""))
  );
  const [bio, setBio] = useState(get(player, "bio"));

  const tempFeet = parseInt(get(player, "height").split(`'`)[0]);
  const tempInch = parseInt(
    get(player, "height").split(`'`)[1]?.replace(`''`, "")
  );
  console.log(tempFeet, tempInch);
  const [feet, setFeet] = useState(mapPositionValueToSelector(tempFeet));
  const [inch, setInch] = useState(mapPositionValueToSelector(tempInch));

  useEffect(() => {
    console.log("Height: ", height);
  }, [height]);

  console.log(player);

  const validateJerseyNr = (val) => {
    if (!val) {
      setJerseyError("This field is required");
      return false;
    }
    const filteredTeamPlayers = teamPlayers.filter(
      (tp) => get(tp, "player.id") !== playerId
    );
    const isJerseyOccupied = filteredTeamPlayers.find(
      (tp) => get(tp, "jersey_nr") === Number(val)
    );
    const occupiedBy = `${get(isJerseyOccupied, "player.first_name")} ${get(
      isJerseyOccupied,
      "player.last_name"
    )}`;

    if (isJerseyOccupied) {
      setJerseyError(`Jersey number is currently occupied by ${occupiedBy}`);
      return false;
    }

    setJerseyError("");
    return true;
  };

  const isFormValid = () => {
    if (!firstName || !lastName || !!jerseyError) {
      return false;
    }
    return true;
  };

  let GradYears = [];
  let CurrentYear = new Date().getFullYear();

  for (let i = 0; i < 120; i++) {
    let Year = CurrentYear - i;
    GradYears.push({
      label: Year.toString(),
      value: Year,
    });
  }

  const Feet = [
    { label: "3 ft", value: 3 },
    { label: "4 ft", value: 4 },
    { label: "5 ft", value: 5 },
    { label: "6 ft", value: 6 },
    { label: "7 ft", value: 7 },
  ];
  const Inch = [
    { label: "0 inc", value: "0" },
    { label: "1 inc", value: 1 },
    { label: "2 inc", value: 2 },
    { label: "3 inc", value: 3 },
    { label: "4 inc", value: 4 },
    { label: "5 inc", value: 5 },
    { label: "6 inc", value: 6 },
    { label: "7 inc", value: 7 },
    { label: "8 inc", value: 8 },
    { label: "9 inc", value: 9 },
    { label: "10 inc", value: 10 },
    { label: "11 inc", value: 11 },
    { label: "12 inc", value: 12 },
  ];

  const editPlayerWithNewJerseyNr = (data) => {
    setLoading(true);
    const playerTeam = teamPlayers.find((tp) => tp.jersey_nr === jerseyNr);
    const jerseyNRData = {
      jersey_nr: jersey,
    };

    patchJerseyNr(get(playerTeam, "id"), jerseyNRData)
      .then(() => editPlayer(playerId, data))
      .then(() => {
        Notification("success", "Player edited");
        refreshActivePlayersAction(teamId, seasonId);
        fetchPlayerData();
        close();
      })
      .catch(() => {
        setLoading(false);
        Notification(
          "error",
          "Error occured",
          "There was an error while editing a player"
        );
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = isFormValid();
    if (!isValid) {
      Notification(
        "error",
        "Some fields are not valid",
        "Fix all errors before submiting the form"
      );
      return false;
    }

    const dobDate = date ? moment(date).format(defaultDateFormat) : undefined;

    const data = {
      first_name: firstName,
      last_name: lastName,
      city,
      state,
      date_of_birth: dobDate,
      throws: get(throws, "value") || "",
      bats: get(bats, "value") || "",
      position: get(position, "value") || "",
      height: height,
      weight: weight + " kg",
      bio: bio,
      graduation_year: graduation,
    };

    if (Number(jersey) !== jerseyNr) {
      editPlayerWithNewJerseyNr(data);
      return true;
    }

    setLoading(true);
    // editiram playera na players/id
    editPlayer(playerId, data)
      .then(() => {
        Notification("success", "Player edited");
        refreshActivePlayersAction(teamId, seasonId);
        fetchPlayerData();
        close();
      })
      .catch(() => {
        setLoading(false);
        Notification(
          "error",
          "Error occured",
          "There was an error while editing a player"
        );
      });
    return true;
  };

  const handleClose = () => {
    if (
      !dirty ||
      window.confirm("Are you sure you want to discard your changes?")
    ) {
      close();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      closeCb={handleClose}
      title="Edit a player"
      size="lg"
    >
      <div className="EditPlayerForm">
        <form className="EditPlayerForm-form" onSubmit={handleSubmit}>
          <div className="form-left">
            <div className="form-row">
              <Label inputId="player-jersey" text="Jersey number *" />
              <NumberInput
                id="player-jersey"
                value={jersey}
                error={jerseyError}
                min="1"
                max="1000"
                handleChange={(val) => {
                  setDirty(true);
                  setJersey(val);
                  validateJerseyNr(val);
                }}
                placeholder="Jersey number"
                disabled={loading}
                required
              />
            </div>
            <div className="form-row">
              <Label inputId="player-firstname" text="First name *" />
              <TextInput
                id="player-firstname"
                value={firstName}
                handleChange={(val) => {
                  setDirty(true);
                  setFirstName(val);
                }}
                placeholder="First name"
                disabled={loading}
                required
              />
            </div>
            <div className="form-row">
              <Label inputId="player-lastname" text="Last name *" />
              <TextInput
                id="player-lastname"
                value={lastName}
                handleChange={(val) => {
                  setDirty(true);
                  setLastName(val);
                }}
                placeholder="Last name"
                disabled={loading}
                required
              />
            </div>
            <div className="form-row">
              <Label inputId="player-city" text="City" />
              <TextInput
                id="player-city"
                value={city}
                handleChange={(val) => {
                  setDirty(true);
                  setCity(val);
                }}
                placeholder="City"
                disabled={loading}
              />
            </div>
            <div className="form-row">
              <Label inputId="player-state" text="State" />
              <TextInput
                id="player-state"
                value={state}
                handleChange={(val) => {
                  setDirty(true);
                  setState(val);
                }}
                placeholder="State"
                disabled={loading}
              />
            </div>
            <div className="form-row">
              <Label text="Graduation year" inputId="player-graduation" />
              <Selector
                options={GradYears}
                value={graduation}
                handleChange={(val) => {
                  setDirty(true);
                  setGraduation(val);
                }}
                disabled={loading}
              />
            </div>
            <div className="form-row">
              <Label inputId="player-bio" text="Biography" />
              <TextArea
                id="player-bio"
                value={bio}
                handleChange={(val) => {
                  setDirty(true);
                  setBio(val);
                }}
                placeholder="State"
                disabled={loading}
              />
            </div>
          </div>
          <div className="form-right">
            <div className="form-row">
              <Label text="Date of birth" inputId="player-dob" />
              <DatePicker
                onChange={(val) => {
                  setDirty(true);
                  setDate(val);
                }}
                value={date}
                disabled={loading}
              />
            </div>
            <div className="form-row">
              <Label inputId="player-position" text="Position" />
              <Selector
                options={playerPositionOptions}
                value={get(position, "value") || ""}
                handleChange={(val) => {
                  setDirty(true);
                  const selected = playerPositionOptions.find(
                    (option) => option.value === val
                  );
                  setPosition(selected || {});
                }}
                disabled={loading}
              />
            </div>
            <div className="form-row">
              <Label inputId="player-throws" text="Throws" />
              <Selector
                options={playerSideOptions}
                value={get(throws, "value") || ""}
                handleChange={(val) => {
                  setDirty(true);
                  const selected = playerSideOptions.find(
                    (option) => option.value === val
                  );
                  setThrows(selected || {});
                }}
                disabled={loading}
              />
            </div>
            <div className="form-row">
              <Label inputId="player-bats" text="Bats" />
              <Selector
                options={playerSideOptions}
                value={get(bats, "value") || ""}
                handleChange={(val) => {
                  setDirty(true);
                  const selected = playerSideOptions.find(
                    (option) => option.value === val
                  );
                  setBats(selected || {});
                }}
                disabled={loading}
              />
            </div>
            <div className="special-row">
              <Label inputId="player-height" text="Height" />
              <div className="form-row">
                <Selector
                  options={Feet}
                  value={feet ? feet : tempFeet || ""}
                  handleChange={(val) => {
                    setDirty(true);
                    setFeet(val || 0);
                    setHeight(val + `'` + (inch ? inch : "0") + `''` || {});
                  }}
                  disabled={loading}
                />
                <Selector
                  options={Inch}
                  value={inch ? inch : tempInch == 0 ? "0" : tempInch || ""}
                  handleChange={(val) => {
                    setDirty(true);
                    setInch(val || 0);
                    setHeight(feet + `'` + val + `''` || {});
                  }}
                  disabled={loading}
                />
              </div>
            </div>
            <div className="form-row">
              <Label inputId="player-weight" text="Weight ( kg )" />
              <TextInput
                id="player-weight"
                value={weight}
                type="number"
                handleChange={(val) => {
                  setDirty(true);
                  setWeight(val);
                }}
                placeholder="Weight in kgs"
                disabled={loading}
              />
            </div>
          </div>
          <div className="form-submit">
            <Button
              theme="link"
              type="button"
              disabled={loading}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button theme="success" type="submit" disabled={loading}>
              Save edits
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

EditPlayerForm.propTypes = {
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  fetchPlayerData: PropTypes.func.isRequired,
  jerseyNr: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  player: PropTypes.object.isRequired,
  refreshActivePlayersAction: PropTypes.func.isRequired,
  teamId: PropTypes.number.isRequired,
  teamPlayers: PropTypes.array.isRequired,
  seasonId: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  teamId: get(state, "activeTeam.details.id"),
  seasonId: get(state, "activeTeam.activeSeason.id"),
  teamPlayers: get(state, "activeTeam.players"),
});

const mapDispatchToProps = {
  refreshActivePlayersAction: refreshActivePlayers,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditPlayerForm);
