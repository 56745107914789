import React from 'react';
import PropTypes from 'prop-types';
import TooltipRC from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';
import './styles.scss';

const align = {
  offset: [0, -13],
};

const Tooltip = ({
  children,
  content,
  position,
  trigger,
}) => (
  <div className="Tooltip">
    <TooltipRC placement={position} trigger={trigger} overlay={content} align={align}>
      {children}
    </TooltipRC>
  </div>
);

/* eslint-disable react/require-default-props */
Tooltip.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  content: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  position: PropTypes.oneOf([
    'left',
    'right',
    'top',
    'bottom',
    'topLeft',
    'topRight',
    'bottomLeft',
    'bottomRight',
  ]),
  trigger: PropTypes.arrayOf(PropTypes.string),
};
/* eslint-enable react/require-default-props */

Tooltip.defaultProps = {
  position: 'top',
  trigger: ['hover'],
  content: <span>tooltip content</span>,
};

export default Tooltip;
