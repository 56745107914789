export const mockStandard = {
  data: [
    {
      date: new Date(),
      against: 'TBD',
      type: 'League',
      ip: 1.1,
      gs: 1,
      w: 0,
      l: 0,
      sv: 0,
      svo: 0,
      bs: 1,
      svperc: 0.000,
      h: 6,
      r: 16,
      er: 11,
      bb: 4,
      so: 2,
      hbp: 5,
      era: 27.500,
      whip: 3.750,
    },
  ],
  total: {
    ip: 1.1,
    gs: 1,
    w: 0,
    l: 0,
    sv: 0,
    svo: 0,
    bs: 1,
    svperc: 0.000,
    h: 6,
    r: 16,
    er: 11,
    bb: 4,
    so: 2,
    hbp: 5,
    era: 27.500,
    whip: 3.750,
  },
};

export const mockEfficiency = {
  data: [
    {
      date: new Date(),
      against: 'TBD',
      type: 'League',
      ip: 2.0,
      bf: 20,
      p: 18,
      ts: 30,
      tb: 26,
      pip: 31.500,
      pbf: 2.333,
      '3less': 11,
      '3lessperc': 0.556,
      loo: 0,
      '1st2out': 0,
      '123inn': 1,
      '13less': 0,
      fip: 31.200,
    },
  ],
  total: {
    ip: 2.0,
    bf: 20,
    p: 18,
    ts: 30,
    tb: 26,
    pip: 31.500,
    pbf: 2.333,
    '3less': 11,
    '3lessperc': 0.556,
    loo: 0,
    '1st2out': 0,
    '123inn': 1,
    '13less': 0,
    fip: 31.200,
  },
};

export const mockCommand = {
  data: [
    {
      date: new Date(),
      against: 'TBD',
      type: 'League',
      ip: 2.0,
      bf: 20,
      ts: 30,
      sperc: 0.619,
      fps: 15,
      fpsperc: 0.593,
      fpsoperc: 0.500,
      fpswperc: 0.063,
      fpshperc: 0.438,
      bbinn: 1.500,
      '0bbinn': 0,
      bbs: 1,
      lobb: 0,
      lobbs: 0,
      wp: 1,
    },
  ],
  total: {
    ip: 2.0,
    bf: 20,
    ts: 30,
    sperc: 0.619,
    fps: 15,
    fpsperc: 0.593,
    fpsoperc: 0.500,
    fpswperc: 0.063,
    fpshperc: 0.438,
    bbinn: 1.500,
    '0bbinn': 0,
    bbs: 1,
    lobb: 0,
    lobbs: 0,
    wp: 1,
  },
};

export const mockBatter = {
  data: [
    {
      date: new Date(),
      against: 'TBD',
      type: 'League',
      ip: 2.0,
      bf: 20,
      p: 41,
      sm: 0.619,
      smperc: 0.063,
      so: 3,
      kg: 8.500,
      kbf: 0.111,
      kbb: 0.759,
      weakperc: 0.462,
      hhbperc: 0.538,
      gbperc: 0.250,
      flbperc: 0.750,
      go: 0,
      fo: 1,
      gofo: 0.000,
      baa: 0.438,
      hr: 4,
      babip: 0.333,
    },
  ],
  total: {
    ip: 2.0,
    bf: 20,
    p: 41,
    sm: 0.619,
    smperc: 0.063,
    so: 3,
    kg: 8.500,
    kbf: 0.111,
    kbb: 0.759,
    weakperc: 0.462,
    hhbperc: 0.538,
    gbperc: 0.250,
    flbperc: 0.750,
    go: 0,
    fo: 1,
    gofo: 0.000,
    baa: 0.438,
    hr: 4,
    babip: 0.333,
  },
};

export const mockRuns = {
  data: [
    {
      date: new Date(),
      against: 'TBD',
      type: 'League',
      ip: 2.0,
      lob: 2,
      bk: 0,
      pik: 1,
      sb: 1,
      cs: 1,
      sbperc: 0.063,
    },
  ],
  total: {
    ip: 2.0,
    lob: 2,
    bk: 0,
    pik: 1,
    sb: 1,
    cs: 1,
    sbperc: 0.063,
  },
};

export const mockPitch = {
  data: [
    {
      date: new Date(),
      against: 'TBD',
      type: 'League',
      p: 42,
      fb: 0,
      fbs: 2,
      ct: 0,
      cts: 0,
      cb: 0,
      cbs: 0,
      sl: 0,
      sls: 0,
      ch: 0,
      chs: 0,
      os: 0,
      oss: 0,
    },
  ],
  total: {
    p: 42,
    fb: 0,
    fbs: 2,
    ct: 0,
    cts: 0,
    cb: 0,
    cbs: 0,
    sl: 0,
    sls: 0,
    ch: 0,
    chs: 0,
    os: 0,
    oss: 0,
  },
};
