import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const Label = ({
  inputId,
  text,
}) => (
  <div className="Label">
    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
    <label htmlFor={inputId} className="Label-elem">
      {text}
    </label>
  </div>
);

Label.propTypes = {
  inputId: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default Label;
