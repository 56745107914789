import React, { useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import {
  Button,
  Modal,
  Notification,
} from 'shared/components';
import {
  capitalizeFirstLetter,
} from 'shared/helpers';
import {
  userRoleOptions,
} from 'shared/constants';
import {
  patchToFanRole,
} from './actions';
import './styles.scss';

const UserRole = ({
  currentUserId,
  fetchUsers,
  isAuth,
  role,
  teamId,
  userRoleId,
  userRoleEmail,
}) => {
  const isAdmin = role.role === 'admin' || role.role === 'coach' || role.role === 'scorekeeper' || role.role === 'assistant_coach' || role.role === 'team_volunteer';
  const roleLabel = userRoleOptions.find(uro => uro.value === role.role);

  const [loading, setLoading] = useState(false);
  const [isDeletePopupDisplayed, setDeletePopupDisplay] = useState(false);

  const handleAdminRemove = (userteamId) => {
    setLoading(true);

    patchToFanRole(userteamId, userRoleId, teamId)
      .then(() => {
        fetchUsers();
        setLoading(false);
        setDeletePopupDisplay(false);
        Notification('success', 'Admin removed from the team');
      })
      .catch(() => {
        setLoading(false);
        Notification(
          'error',
          'Error occured',
          'There was an error while removing an admin from the team',
        );
      });
  };

  if (isAdmin && currentUserId !== userRoleId) {
    return (
      <div className="Role-admin-wrapper role-container">
        {/* <span className="role-name">Admin</span> */}
        <span className="role-name">{roleLabel.label || capitalizeFirstLetter(role.role)}</span>
        {
          isAuth && (
            <button
              className="role-action"
              type="button"
              onClick={() => setDeletePopupDisplay(true)}
            >
              {/* Remove admin */}
              {`Remove ${roleLabel.label || capitalizeFirstLetter(role.role)}`}
            </button>
          )
        }
        {
          isDeletePopupDisplayed && (
            <Modal
              isOpen={isDeletePopupDisplayed}
              closeCb={() => setDeletePopupDisplay(false)}
              title="Are you sure you want to delete this user from the team?"
            >
              <div className="ChangeUserRolePopup">
                {userRoleEmail}
                <form
                  className="ChangeUserRolePopup-form"
                  onSubmit={e => {
                    e.preventDefault();
                    handleAdminRemove(get(role, 'id'));
                  }}
                >
                  <div className="form-submit">
                    <Button
                      theme="link"
                      type="button"
                      disabled={loading}
                      onClick={() => setDeletePopupDisplay(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      theme="error"
                      type="submit"
                      disabled={loading}
                    >
                      Delete
                    </Button>
                  </div>
                </form>
              </div>
            </Modal>
          )
        }
      </div>
    );
  }

  return <div className="role-container">{roleLabel.label || capitalizeFirstLetter(role.role)}</div>;
};

UserRole.propTypes = {
  currentUserId: PropTypes.number.isRequired,
  isAuth: PropTypes.bool.isRequired,
  fetchUsers: PropTypes.func.isRequired,
  role: PropTypes.object.isRequired,
  teamId: PropTypes.number.isRequired,
  userRoleId: PropTypes.number.isRequired,
  userRoleEmail: PropTypes.string.isRequired,
};

export default UserRole;
