import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import get from "lodash.get";
import { Route, Switch } from "react-router-dom";
import { ContentLoader, NotFound } from "shared/components";
import Header from "header";
import TeamManagementContainer from "team_management/TeamManagementContainer";
import LeaguesManagementContainer from "leagues_management/LeaguesManagementContainer";
import LeagueContainer from "league/LeagueContainer";
import LeaderboardContainer from "Leaderboard/LeaderboardContainer";
import TeamContainer from "team/TeamContainer";
import ProfileContainer from "profile/ProfileContainer";
import { getUserTeams } from "store/userTeams/actions";
import EventsList from "../../EventsDashboard";
import EventManagementContainer from "EventsDashboard/pages/EventManagementContainer";
import SetCookie from "shared/helpers/setCookie";

const AppContainer = ({
  userId,
  getUserTeamsAction,
  userTeamsLoading,
  user,
}) => {
  useEffect(() => {
    getUserTeamsAction(userId);
  }, []);

  SetCookie("Organization", user?.organizations[0]?.id, 15);

  useEffect(() => {
    if (user) {
      // eslint-disable-next-line no-undef
      Canny("identify", {
        appID: "60081aa8aee7c56b1b070021",
        user: {
          // Replace these values with the current user's data
          email: user.email,
          name: `${user.first_name} ${user.last_name}`,
          id: user.id,

          // These fields are optional, but recommended:
          created: new Date(user.created_at).toISOString(),
        },
      });
    }
  }, [user]);

  if (userTeamsLoading) {
    return <ContentLoader text="Getting user teams" />;
  }

  return (
    <>
      <Header />
      <Switch>
        <Route exact path="/" component={TeamManagementContainer} />
        <Route path="/profile" component={ProfileContainer} />
        <Route exact path="/leagues" component={LeaguesManagementContainer} />
        <Route path="/leagues/:leagueId" component={LeagueContainer} />
        <Route path="/leaderboard" component={LeaderboardContainer} />
        <Route path="/events" component={EventManagementContainer} />
        <Route path="/:teamId" component={TeamContainer} />
        <Route render={() => <NotFound />} />
      </Switch>
    </>
  );
};

AppContainer.propTypes = {
  userId: PropTypes.number.isRequired,
  userTeamsLoading: PropTypes.bool.isRequired,
  getUserTeamsAction: PropTypes.func.isRequired,
  user: PropTypes.object,
};

AppContainer.defaultProps = {
  user: null,
};

const mapStateToProps = (state) => ({
  userTeamsLoading: get(state, "userTeams.loading"),
  user: get(state, "user.details"),
});

const mapDispatchToProps = {
  getUserTeamsAction: getUserTeams,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
