import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
} from 'shared/components';
import './styles.scss';

const ImportConsentPopup = ({
  close,
  confirmCb,
  isOpen,
}) => {
  const handleSubmit = e => {
    e.preventDefault();
    confirmCb();
  };

  return (
    <Modal
      isOpen={isOpen}
      closeCb={close}
      title="Important message"
      size="md"
    >
      <div className="ImportConsentPopup">
        <form
          className="ImportConsentPopup-form"
          onSubmit={handleSubmit}
        >
          By clicking on `Confirm` you are aware that you cannot import users personal information (including their medical data) without their consent.
          <div className="form-submit">
            <Button
              theme="link"
              type="button"
              onClick={close}
            >
              Cancel
            </Button>
            <Button
              theme="success"
              type="submit"
            >
              Confirm
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

ImportConsentPopup.propTypes = {
  close: PropTypes.func.isRequired,
  confirmCb: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default ImportConsentPopup;
