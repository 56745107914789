import React, { useState } from "react";
import PropTypes from "prop-types";
import get from "lodash.get";
import {
  Button,
  Label,
  Modal,
  Notification,
  TextInput,
} from "shared/components";
import { editProfile } from "./actions";
import "./styles.scss";

const EditProfileForm = ({ close, isOpen, refreshUser, user }) => {
  const [loading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [firstName, setFirstName] = useState(get(user, "first_name"));
  const [lastName, setLastName] = useState(get(user, "last_name"));
  const [stripe, setStripe] = useState(get(user, "stripe_api_key"));

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    const data = {
      first_name: firstName,
      last_name: lastName,
      stripe_api_key: stripe,
    };

    editProfile(data)
      .then((res) => {
        close();
        const userData = get(res, "data");
        refreshUser(userData);
        Notification("success", "Profile edited");
      })
      .catch(() => {
        setLoading(false);
        Notification(
          "error",
          "Error occured",
          "There was an error while editing your data"
        );
      });
  };

  const handleClose = () => {
    if (
      !dirty ||
      window.confirm("Are you sure you want to discard your changes?")
    ) {
      close();
    }
  };

  return (
    <Modal isOpen={isOpen} closeCb={handleClose} title="Edit profile" size="sm">
      <div className="EditProfileForm">
        <form className="EditProfileForm-form" onSubmit={handleSubmit}>
          <div className="form-row">
            <Label inputId="profile-firstname" text="First name" />
            <TextInput
              id="profile-firstname"
              value={firstName}
              handleChange={(val) => {
                setDirty(true);
                setFirstName(val);
              }}
              placeholder="First name"
              disabled={loading}
            />
          </div>
          <div className="form-row">
            <Label inputId="profile-lastname" text="Last name" />
            <TextInput
              id="profile-lastname"
              value={lastName}
              handleChange={(val) => {
                setDirty(true);
                setLastName(val);
              }}
              placeholder="Last name"
              disabled={loading}
            />
          </div>
          <div className="form-row">
            <Label inputId="profile-stripe" text="Stripe key" />
            <TextInput
              id="profile-stripe"
              value={stripe}
              handleChange={(val) => {
                setDirty(true);
                setStripe(val);
              }}
              placeholder="Stripe key"
              disabled={loading}
            />
          </div>
          <div className="form-submit">
            <Button
              theme="link"
              type="button"
              disabled={loading}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button theme="success" type="submit" disabled={loading}>
              Save edits
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

EditProfileForm.propTypes = {
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  refreshUser: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

export default EditProfileForm;
