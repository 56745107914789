import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash.get';
import { withRouter } from 'react-router-dom';
import {
  Button,
  ContentLoader,
  IconCheckmark,
  IconDisabled,
  IconDelete,
  IconEdit,
  Table,
  Notification,
  NotAuthorized,
  Selector,
  TextInput,
} from 'shared/components';
import {
  displayValue,
  isAdmin,
  isEditableSeason,
} from 'shared/helpers';
import {
  playerPositionOptions,
} from 'shared/constants';
import {
  getActiveLineup,
  refreshActiveLineup,
} from 'store/activeTeam/actions';
import DeleteLineupPlayer from './components/DeleteLineupPlayer';
import AddLineupPlayer from './components/AddLineupPlayer';
import EditLineupPlayer from './components/EditLineupPlayer';
import RuleAlert from '../RuleAlert';
import {
  getLineup,
  postLineup,
} from './actions';
import './styles.scss';

const Lineup = ({
  lineup,
  players,
  getActiveLineupAction,
  refreshActiveLineupAction,
  teamId,
  seasonId,
  history,
  userId,
}) => {
  const lineupPlayers = get(lineup, 'lineup_players');
  const isAuth = isAdmin(teamId);

  if (!isAuth) {
    return <NotAuthorized message="Only team admins can see lineups" />;
  }

  const [lineupCreating, setLineupCreating] = useState(false);
  const [lineupLoading, setLineupLoading] = useState(true);

  const [isDeletePlayerDisplayed, setDeletePlayerDisplay] = useState(false);
  const [playerToDelete, setPlayerToDelete] = useState(null);

  const [isEditPlayerDisplayed, setEditPlayerDisplay] = useState(false);
  const [playerToEdit, setPlayerToEdit] = useState(null);

  const [isAddPlayerDisplayed, setAddPlayerDisplay] = useState(false);
  const [remainingPlayers, setRemainingPlayersToAdd] = useState(null);

  const fetchLineup = () => {
    setLineupLoading(true);

    getLineup(teamId, seasonId)
      .then(res => {
        const data = get(res, 'data.results[0]') || null;
        getActiveLineupAction(data);
        setLineupLoading(false);
      })
      .catch(() => {
        setLineupLoading(false);
      });
  };

  useEffect(() => {
    if (lineup === null) {
      fetchLineup();
    } else {
      setLineupLoading(false);
    }
  }, []);

  const createLineup = () => {
    setLineupCreating(true);

    postLineup(teamId, seasonId, userId)
      .then(() => {
        setLineupCreating(false);
        fetchLineup();
      })
      .catch(() => {
        setLineupCreating(false);
      });
  };

  const handlePlayerDelete = index => {
    if (!isEditableSeason(seasonId)) {
      return Notification(
        'error',
        'Action available only for current season',
      );
    }
    const selected = lineupPlayers[index];

    setPlayerToDelete(selected);
    return setDeletePlayerDisplay(true);
  };

  const handlePlayerEdit = index => {
    if (!isEditableSeason(seasonId)) {
      return Notification(
        'error',
        'Action available only for current season',
      );
    }
    const selected = lineupPlayers[index];

    setPlayerToEdit(selected);
    return setEditPlayerDisplay(true);
  };

  const handlePlayerAdd = () => {
    if (!isEditableSeason(seasonId)) {
      return Notification(
        'error',
        'Action available only for current season',
      );
    }

    if (!players.length) {
      return Notification('error', 'Team has no players', 'Try adding new players');
    }

    if (players.length === lineupPlayers.length) {
      return Notification(
        'error',
        'All players are added to the lineup',
        'Try adding new players or editing the lineup players',
      );
    }

    const remaining = [];

    players.forEach(player => {
      const isInLineup = lineupPlayers.find(l => l.player === player.player.id);
      if (!isInLineup) {
        remaining.push(player);
      }
    });

    setRemainingPlayersToAdd(remaining);
    return setAddPlayerDisplay(true);
  };

  const redirectToPlayerPage = rowData => {
    const playerId = get(rowData, 'original.player');
    history.push(`/${teamId}/players/${playerId}`);
  };

  if (lineupLoading) {
    return <ContentLoader text="Getting lineup data" />;
  }

  if (lineup === null) {
    return (
      <div>
        <div>You don&apos;t have any lineups created</div>
        <Button
          onClick={createLineup}
          disabled={lineupCreating}
        >
          Create a lineup
        </Button>
      </div>
    );
  }

  return (
    <div className="PlayerList-lineup">
      <div className="PlayerList-lineup-actions">
        <Button
          size="sm"
          onClick={handlePlayerAdd}
          disabled={lineupLoading}
        >
          Add player to lineup
        </Button>
      </div>
      <Table
        data={lineupPlayers || []}
        loading={lineupLoading}
        loadingText="Getting lineup players"
        noDataText=""
        filterable
        NoDataComponent={() => null}
        defaultFilterMethod={(filter, row) => {
          const rowValue = row[filter.id] || '';
          if (!rowValue) { return false; }
          return (
            String(row[filter.id].toLowerCase()).includes(filter.value.toLowerCase())
          );
        }}
        columns={[
          {
            accessor: 'position',
            Header: 'Position',
            // Cell: cellData => displayValue(cellData.value),
            Cell: cellData => {
              const playerId = get(cellData, 'original.player');
              const player = players.find(p => get(p, 'player.id') === playerId);
              if (!player) {
                return '-';
              }
              return (
                <div className="position-container">
                  {cellData.value}
                  {
                    get(player, 'player.first_name') === 'Matija' && (
                      <div>
                        <RuleAlert />
                      </div>
                    )
                  }
                </div>
              );
            },
            // eslint-disable-next-line consistent-return
            filterMethod: (filter, row) => {
              if (!filter.value) {
                return true;
              }
              const rowValue = row[filter.id] || '';
              if (!rowValue) { return false; }

              return rowValue === filter.value;
            },
            Filter: cellInfo => {
              const { filter, onChange } = cellInfo;
              return (
                <Selector
                  options={playerPositionOptions}
                  value={get(filter, 'value') || ''}
                  handleChange={val => onChange(val)}
                  isClearable
                />
              );
            },
          },
          {
            accessor: 'player',
            Header: 'Player',
            Cell: cellData => {
              const player = players.find(p => get(p, 'player.id') === cellData.value);
              if (!player) {
                return '-';
              }
              return displayValue(
                `${get(player, 'player.first_name')} ${get(player, 'player.last_name')}`,
              );
            },
            Filter: cellInfo => {
              const { filter, onChange } = cellInfo;

              return (
                <TextInput
                  placeholder="Search by name"
                  handleChange={val => onChange(val)}
                  value={filter ? filter.value : ''}
                />
              );
            },
            filterMethod: (filter, row) => {
              const playerId = get(row, 'player') || [];
              const player = players.find(p => get(p, 'player.id') === playerId);
              if (!player) {
                return false;
              }

              const firstName = get(player, 'player.first_name') || '';
              const lastName = get(player, 'player.last_name') || '';
              if (firstName.toLowerCase().indexOf(filter.value.toLowerCase()) !== -1 ||
                lastName.toLowerCase().indexOf(filter.value.toLowerCase()) !== -1
              ) {
                return true;
              }

              return false;
            },
          },
          {
            accessor: 'is_reserve',
            Header: 'Is reserve',
            filterable: false,
            Cell: cellData => (cellData.value ? (
              <IconCheckmark color="#10ac84" height="14px" />
            ) : (
              <IconDisabled color="red" height="14px" />
            )),
          },
          {
            Header: 'Edit',
            headerClassName: 'text-center',
            className: 'text-center button-wrapper',
            sortable: false,
            filterable: false,
            Cell: cellData => (
              <button
                aria-label="Edit player"
                className="edit-button"
                onClick={e => {
                  e.stopPropagation();
                  handlePlayerEdit(cellData.index);
                }}
                type="button"
              >
                <IconEdit
                  height="20px"
                  width="20px"
                />
              </button>
            ),
            width: 100,
          },
          {
            Header: 'Delete',
            headerClassName: 'text-center',
            className: 'text-center button-wrapper',
            sortable: false,
            filterable: false,
            Cell: cellData => (
              <button
                aria-label="Delete player"
                className="delete-button"
                onClick={e => {
                  e.stopPropagation();
                  handlePlayerDelete(cellData.index);
                }}
                type="button"
              >
                <IconDelete
                  height="20px"
                  width="20px"
                  color="#ee5253"
                />
              </button>
            ),
            width: 100,
          },
        ]}
        minRows={1}
        showPagination={false}
        clickable
        handleClick={rowData => redirectToPlayerPage(rowData)}
        defaultSorted={[{
          desc: true,
          id: 'player',
        }]}
      />
      {
        isAddPlayerDisplayed && (
          <AddLineupPlayer
            close={() => setAddPlayerDisplay(false)}
            isOpen={isAddPlayerDisplayed}
            refreshActiveLineup={refreshActiveLineupAction}
            teamId={teamId}
            seasonId={seasonId}
            players={remainingPlayers}
            lineup={lineup}
          />
        )
      }
      {
        isEditPlayerDisplayed && (
          <EditLineupPlayer
            close={() => setEditPlayerDisplay(false)}
            isOpen={isEditPlayerDisplayed}
            refreshActiveLineup={refreshActiveLineupAction}
            teamId={teamId}
            seasonId={seasonId}
            playerToEdit={playerToEdit}
            lineup={lineup}
            players={players}
          />
        )
      }
      {
        isDeletePlayerDisplayed && (
          <DeleteLineupPlayer
            isOpen={isDeletePlayerDisplayed}
            close={() => setDeletePlayerDisplay(false)}
            player={playerToDelete}
            refreshActiveLineup={refreshActiveLineupAction}
            teamId={teamId}
            seasonId={seasonId}
          />
        )
      }
    </div>
  );
};

Lineup.propTypes = {
  lineup: PropTypes.object,
  players: PropTypes.array.isRequired,
  getActiveLineupAction: PropTypes.func.isRequired,
  refreshActiveLineupAction: PropTypes.func.isRequired,
  teamId: PropTypes.number.isRequired,
  seasonId: PropTypes.number.isRequired,
  history: PropTypes.object.isRequired,
  userId: PropTypes.number.isRequired,
};


Lineup.defaultProps = {
  lineup: null,
};

const mapStateToProps = state => ({
  lineup: get(state, 'activeTeam.lineup'),
  userId: get(state, 'user.userID'),
});

const mapDispatchToProps = {
  getActiveLineupAction: getActiveLineup,
  refreshActiveLineupAction: refreshActiveLineup,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Lineup));
