import React, { useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import {
  AsyncSelector,
  Button,
  GoogleLocationSearch,
  InputErrorMessage,
  Label,
  Modal,
  Notification,
} from 'shared/components';
import api from 'shared/api';
import { patchGame } from '../EditGameForm/actions';
import './styles.scss';

const getLocation = (location, customLocation) => {
  if (!get(location, 'id') && !get(customLocation, 'name')) {
    return undefined;
  }

  if (get(location, 'id')) {
    return get(location, 'id');
  }

  return {
    name: get(customLocation, 'name'),
    address: get(customLocation, 'address'),
    city: get(customLocation, 'city'),
    state: get(customLocation, 'state'),
    zipcode: get(customLocation, 'zipcode'),
    country: get(customLocation, 'country'),
    lat: get(customLocation, 'lat'),
    lng: get(customLocation, 'lng'),
  };
};

const AddLocationForm = ({
  close,
  isOpen,
  game,
  fetchGameData,
}) => {
  // const homeTeamId = get(game, 'team_home.id');
  const [loading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);

  const [location, setLocation] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [customLocation, setCustomLocation] = useState(null);
  const [locationError, setLocationError] = useState('');

  const validateLocation = () => {
    if (!!get(location, 'id') && get(customLocation, 'address')) {
      setLocationError('You can select only existing location or add a new one, not both');
      return false;
    }

    if (!get(location, 'id') && !get(customLocation, 'name')) {
      close();
      return false;
    }

    return true;
  };

  const handleSubmit = e => {
    e.preventDefault();

    const isLocationValid = validateLocation();
    if (!isLocationValid) {
      return false;
    }

    setLoading(true);

    const gameId = get(game, 'id');

    const baseballField = getLocation(location, customLocation);

    const data = {
      baseball_field: baseballField,
    };

    return patchGame(gameId, data)
      .then(() => {
        Notification('success', 'Baseball field added');
        fetchGameData();
        close();
      })
      .catch(() => {
        setLoading(false);
        Notification('error', 'Error occured', 'There was an error while adding a baseball field');
      });
  };

  const handleClose = () => {
    if (
      !dirty ||
      window.confirm('Are you sure you want to discard your changes?')
    ) {
      close();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      closeCb={handleClose}
      title="Add game location"
      size="sm"
    >
      <div className="AddLocationForm">
        <form
          className="AddLocationForm-form"
          onSubmit={handleSubmit}
        >
          <div className="form-row form-row-wide">
            <div className="location-wrapper">
              <div className="form-row">
                <Label
                  inputId="games-location-existing"
                  text="Existing location"
                />
                <AsyncSelector
                  valueKey="id"
                  labelKey="name"
                  isClearable
                  fetchOptions={val => (
                    api.get(`/api/v1/baseballfields/?name__icontains=${val}`)
                  )}
                  fetchInitialOptions={() => api.get('/api/v1/baseballfields/?limit=10')}
                  // eslint-disable-next-line no-unused-vars
                  handleChange={val => {
                    setDirty(true);
                    setLocation(val);
                  }}
                  value={get(location, 'name') || ''}
                />
              </div>
              <div style={{ alignSelf: 'center', fontWeight: 'bold', marginTop: '20px' }}>OR</div>
              <div className="form-row">
                <Label
                  inputId="games-location-new"
                  text="Add a new location"
                />
                <GoogleLocationSearch
                  handlePlaceSelect={val => setCustomLocation(val)}
                  clearSelection={() => setCustomLocation(null)}
                />
              </div>
            </div>
            <InputErrorMessage text={locationError} />
          </div>
          <div className="form-submit">
            <Button
              theme="link"
              type="button"
              disabled={loading}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              theme="success"
              type="submit"
              disabled={loading}
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

AddLocationForm.propTypes = {
  close: PropTypes.func.isRequired,
  fetchGameData: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  game: PropTypes.object.isRequired,
};

export default AddLocationForm;
