/* eslint-disable */
import api from "shared/api";

export const getEvent = (id) => {
  const url = `/api/v1/events/${id}/`;
  return api.get(url);
};

export const removePlayer = (id) => {
  const url = `/api/v1/playerevents/${id}`;
  return api.delete(url);
};

export const getPlayers = (eventId) => {
  const url = `/api/v1/playerevents/?event__id=${eventId}`;
  return api.get(url);
};
