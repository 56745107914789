import React from 'react';
import PropTypes from 'prop-types';

const TablePitchesRange = ({
  minPitchCount,
  maxPitchCount,
}) => (
  <div className="TablePitchesRange">
    {
      minPitchCount && maxPitchCount && (
        <div className="rest-pitch-range">
          {minPitchCount}
          <span> - </span>
          {maxPitchCount}
        </div>
      )
    }
    {
      minPitchCount && !maxPitchCount && (
        <div className="rest-pitch-range">
          {minPitchCount}
          <span>+</span>
        </div>
      )
    }
    {
      !minPitchCount && !maxPitchCount && (
        <div className="rest-pitch-range">
          <span>N/A</span>
        </div>
      )
    }
  </div>
);

TablePitchesRange.propTypes = {
  maxPitchCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minPitchCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

TablePitchesRange.defaultProps = {
  maxPitchCount: null,
  minPitchCount: null,
};

export default TablePitchesRange;
