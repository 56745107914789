import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import get from "lodash.get";
import LeagueSeasonSelector from "../LeagueSeasonSelector";
import "./styles.scss";

const LeagueHeader = ({ disabledSeason, league }) => (
  <div className="LeagueHeader">
    <div className="LeagueHeader-primary">
      <>
        <h3 className="LeagueHeader-primary-title">{get(league, "name")}</h3>
      </>
    </div>
    <div className="LeagueHeader-secondary">
      <LeagueSeasonSelector disabledSeason={disabledSeason} />
    </div>
  </div>
);

LeagueHeader.propTypes = {
  disabledSeason: PropTypes.bool,
  league: PropTypes.object,
};

LeagueHeader.defaultProps = {
  disabledSeason: false,
  league: null,
};

const mapStateToProps = (state) => ({
  league: get(state, "activeLeague.details"),
});

export default connect(mapStateToProps)(LeagueHeader);
