import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import {
  DescriptionList,
  Modal,
} from 'shared/components';
import {
  displayValue,
  formatDate,
  getSideLabel,
} from 'shared/helpers';
import {
  defaultDateFormat,
} from 'shared/constants';
import './styles.scss';

const PlayerPopup = ({
  player,
  isOpen,
  close,
}) => (
  <Modal
    isOpen={isOpen}
    closeCb={close}
    title={`${player.first_name} ${player.last_name}`}
    size="xl"
  >
    <div className="PlayerPopup">
      <DescriptionList
        data={[
          { label: 'First name', value: displayValue(get(player, 'first_name')) },
          { label: 'Last name', value: displayValue(get(player, 'last_name')) },
          {
            label: 'Date of birth',
            value: formatDate(get(player, 'date_of_birth'), defaultDateFormat),
          },
          { label: 'City', value: displayValue(get(player, 'city')) },
          { label: 'State', value: displayValue(get(player, 'state')) },
          { label: 'Position', value: displayValue(get(player, 'position')) },
          { label: 'Bats', value: getSideLabel(get(player, 'bats')) },
          { label: 'Throws', value: getSideLabel(get(player, 'throws')) },
        ]}
      />
    </div>
  </Modal>
);

PlayerPopup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  player: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
};

export default PlayerPopup;
