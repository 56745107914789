import React, { useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import {
  Button,
  Label,
  Modal,
  Notification,
  Selector,
  TextInput,
} from 'shared/components';
import {
  emailRegex,
  userRoleOptions,
} from 'shared/constants';
import { addUsers } from './actions';
import './styles.scss';

const AddMultipleUserForm = ({
  close,
  isOpen,
  teamId,
  refreshTeam,
}) => {
  const rolesWithoutFamily = userRoleOptions.filter(ur => ur.value !== 'family');
  const createUserRow = id => ({
    id,
    email: '',
    emailError: '',
    role: get(rolesWithoutFamily, '[0]') || '',
  });

  const [loading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [users, setUsers] = useState([createUserRow(Math.random())]);

  const validateEmail = val => emailRegex.test(val);

  const setEmailError = (email) => {
    const isEmailValid = validateEmail(email);
    if (!email) {
      return 'This field is required';
    }
    if (!isEmailValid) {
      return 'Enter valid email';
    }
    return '';
  };

  const handleUserEmailChange = (email, userIndex) => {
    const newUsers = users.map((u, i) => {
      if (i === userIndex) {
        const error = setEmailError(email);
        return ({
          ...u,
          email,
          emailError: error,
        });
      }
      return u;
    });

    setUsers(newUsers);
  };

  const handleUserRoleChange = (role, userIndex) => {
    const newUsers = users.map((u, i) => {
      if (i === userIndex) {
        return ({
          ...u,
          role,
        });
      }
      return u;
    });

    setUsers(newUsers);
  };

  const validateEmails = () => {
    let isValid = true;
    const newUsers = users.map((u) => {
      const error = setEmailError(u.email);
      if (error) { isValid = false; }
      return ({
        ...u,
        emailError: error,
      });
    });

    setUsers(newUsers);
    return isValid;
  };

  const handleSubmit = e => {
    e.preventDefault();
    const areEmailsValid = validateEmails();
    if (!areEmailsValid) {
      Notification('error', 'Some fields are not valid', 'Fix all errors before submiting the form');
      return false;
    }

    setLoading(true);
    const data = users.map(u => ({ email: get(u, 'email'), role: get(u, 'role.value') }));

    addUsers(teamId, data)
      .then(() => {
        Notification('success', 'New users added to team');
        refreshTeam(teamId);
        close();
      })
      .catch(() => {
        setLoading(false);
        Notification('error', 'Error occured', 'There was an error while adding new users');
      });
    return true;
  };

  const handleClose = () => {
    if (
      !dirty ||
      window.confirm('Are you sure you want to discard your changes?')
    ) {
      close();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      closeCb={handleClose}
      title="Add multiple team members"
      size="lg"
    >
      <div className="AddMultipleUserForm">
        <form
          className="AddMultipleUserForm-form"
          onSubmit={handleSubmit}
        >
          {
            users.map((user, userIndex) => (
              <div key={get(user, 'id')} className="user-add-row">
                <div className="form-row">
                  <Label
                    inputId="user-email"
                    text="Email"
                  />
                  <TextInput
                    id="user-email"
                    value={get(user, 'email')}
                    handleChange={val => {
                      setDirty(true);
                      handleUserEmailChange(val, userIndex);
                    }}
                    placeholder="Enter email"
                    disabled={loading}
                    error={get(user, 'emailError')}
                    type="email"
                  />
                </div>
                <div className="form-row">
                  <Label inputId="user-role" text="Role" />
                  <Selector
                    options={rolesWithoutFamily}
                    id="user-role"
                    value={get(user, 'role.value')}
                    disabled={loading}
                    handleChange={val => {
                      const selected = rolesWithoutFamily.find(o => o.value === val);
                      setDirty(true);
                      handleUserRoleChange(selected, userIndex);
                    }}
                  />
                </div>
                <div className="remove-row">
                  <Button
                    theme="error"
                    size="sm"
                    type="submit"
                    disabled={loading || users.length === 1}
                    onClick={() => {
                      const newList = users.filter((_, i) => i !== userIndex);
                      setUsers(newList);
                    }}
                  >
                    Remove
                  </Button>
                </div>
              </div>
            ))
          }
          <div className="add-row-btn">
            <Button
              theme="link"
              size="sm"
              disabled={loading}
              onClick={() => {
                setDirty(true);
                const newRow = createUserRow(Math.random());
                const newList = [...users, newRow];
                setUsers(newList);
              }}
            >
              Add another row
            </Button>
          </div>
          <div className="form-submit">
            <Button
              theme="link"
              type="button"
              disabled={loading}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              theme="success"
              type="submit"
              disabled={loading}
            >
              Add users
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

AddMultipleUserForm.propTypes = {
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  teamId: PropTypes.number.isRequired,
  refreshTeam: PropTypes.func.isRequired,
};

export default AddMultipleUserForm;
