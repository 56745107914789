/* eslint-disable */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import get from "lodash.get";
import { withRouter } from "react-router-dom";
import { Table, Selector, Button } from "shared/components";
import { displayValue } from "shared/helpers";
import { getLeagueTeams, setActiveDivision } from "store/activeLeague/actions";
import { getEvents, getPlayerStats } from "./actions";
import EventsList from "./pages/EventsList/EventsList";
import Event from "./pages/Event/Event";
import Stat from "./pages/Stat/Stat";
import EventStatsList from "./pages/EventStatsList/EventStatsList";
import EventPlayersStats from "./pages/EventPlayersStats/EventPlayersStats";
import "./styles.scss";
import { Switch, Route } from "react-router-dom";
const EventsDashboard = ({ getLeagueTeamsAction, teams, history, user }) => {
  return (
    <Switch>
      <Route
        exact
        path="/events/"
        render={() => (
          <EventsList organizationId={user?.organizations[0]?.id} />
        )}
      />
      <Route exact path="/events/stats/" render={() => <EventStatsList />} />
      <Route exact path="/events/:eventId" render={() => <Event />} />

      <Route path="/events/stat/:statId" render={() => <Stat />} />
      <Route
        exact
        path="/events/:eventId/stats/"
        render={() => <EventPlayersStats />}
      />

      <Route render={() => <NotFound />} />
    </Switch>
  );
};

// LeagueTeamsList.propTypes = {
//   activeSeason: PropTypes.object.isRequired,
//   activeDivision: PropTypes.string.isRequired,
//   divisions: PropTypes.array.isRequired,
//   history: PropTypes.object.isRequired,
//   leagueId: PropTypes.number.isRequired,
//   getLeagueTeamsAction: PropTypes.func.isRequired,
//   setActiveDivisionAction: PropTypes.func.isRequired,
//   teams: PropTypes.array.isRequired,
// };

const mapStateToProps = (state) => ({
  teams: get(state, "activeLeague.teams"),
  divisions: get(state, "activeLeague.details.divisions"),
  activeDivision: get(state, "activeLeague.activeDivision"),
  user: get(state, "user.details"),
});

const mapDispatchToProps = {
  getLeagueTeamsAction: getLeagueTeams,
  setActiveDivisionAction: setActiveDivision,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EventsDashboard));
