import React, { useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import {
  Button,
  Label,
  Modal,
  TextInput,
  Notification,
} from 'shared/components';
import api from 'shared/api';
import './styles.scss';

const EditPlayerGuardianForm = ({
  isOpen,
  close,
  player,
  fetchPlayerData,
  title,
}) => {
  const playerId = get(player, 'id');
  const guardianFirstName = get(player, 'guardian_first_name') || '';
  const guardianLastName = get(player, 'guardian_last_name') || '';

  const [loading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [firstName, setFirstName] = useState(guardianFirstName);
  const [lastName, setLastName] = useState(guardianLastName);


  const handleSubmit = e => {
    e.preventDefault();

    setLoading(true);

    const data = {
      guardian_first_name: firstName,
      guardian_last_name: lastName,
    };

    return api.patch(`/api/v1/players/${playerId}/`, data)
      .then(() => {
        Notification('success', 'Players guardian edited');
        fetchPlayerData();
        setLoading(false);
        close();
      })
      .catch(() => {
        setLoading(false);
        Notification(
          'error',
          'Error occured',
          'There was an error while editing a players guardian.',
        );
      });
  };

  const handleClose = () => {
    if (
      !dirty ||
      window.confirm('Are you sure you want to discard your changes?')
    ) {
      close();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      closeCb={handleClose}
      title={title}
      size="sm"
    >
      <div className="EditPlayerGuardianForm">
        <form className="EditPlayerGuardianForm-form" onSubmit={handleSubmit}>
          <div className="form-row">
            <Label
              inputId="guardian-firstname"
              text="First name"
            />
            <TextInput
              id="guardian-firstname"
              value={firstName}
              handleChange={val => {
                setDirty(true);
                setFirstName(val);
              }}
              placeholder="Enter first name"
              disabled={loading}
              required
            />
          </div>
          <div className="form-row">
            <Label
              inputId="guardian-lastname"
              text="Last name"
            />
            <TextInput
              id="guardian-lastname"
              value={lastName}
              handleChange={val => {
                setDirty(true);
                setLastName(val);
              }}
              placeholder="Enter last name"
              disabled={loading}
              required
            />
          </div>
          <div className="form-submit">
            <Button
              theme="link"
              type="button"
              disabled={loading}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              theme="success"
              type="submit"
              disabled={loading}
            >
              Submit
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

EditPlayerGuardianForm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  fetchPlayerData: PropTypes.func.isRequired,
  player: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
};

export default EditPlayerGuardianForm;
