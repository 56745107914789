import React from 'react';
import PropTypes from 'prop-types';
import { InputErrorMessage } from 'shared/components';
import './styles.scss';

const TextArea = ({
  handleChange,
  error,
  disabled,
  loading,
  ...rest
}) => {
  const handleInputChange = e => handleChange(e.target.value);

  return (
    <div className="TextArea">
      <textarea
        className={error ? 'has-error' : ''}
        disabled={disabled}
        onChange={handleInputChange}
        {...rest}
      />
      <InputErrorMessage text={error} />
    </div>
  );
};

TextArea.propTypes = {
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  loading: PropTypes.bool,
};

TextArea.defaultProps = {
  disabled: false,
  error: '',
  loading: false,
};

export default TextArea;
