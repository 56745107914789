/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import moment from 'moment';
import {
  Table,
  TextInput,
  WeekPicker,
} from 'shared/components';
import {
  displayValue,
} from 'shared/helpers';
import { getPlayersData } from './actions';
import './styles.scss';

const TimeRangePitchingStats = ({
  teamId,
  seasonId,
}) => {
  const isMounted = React.useRef(true);
  const [loading, setLoading] = useState(true);
  const [players, setPlayers] = useState([]);
  const [startDate, setStartDate] = useState(moment().startOf('week').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().endOf('week').format('YYYY-MM-DD'));

  const fetchPlayers = (start, end) => {
    setLoading(true);
    getPlayersData(teamId, seasonId, start || startDate, end || endDate)
      .then(res => {
        const playersList = get(res, 'data.players') || [];

        setLoading(false);
        setPlayers(playersList);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchPlayers();

    return () => {
      isMounted.current = false;
    };
  }, [seasonId]);

  return (
    <div className="TimeRangePitchingStats">
      <div className="TimeRangePitchingStats-picker">
        <WeekPicker
          startDateOffset={(day) => day.startOf('week')}
          endDateOffset={(day) => day.endOf('week')}
          initialStartDate={moment().startOf('week')}
          initialEndDate={moment().endOf('week')}
          handleSelectedWeek={(start, end) => {
            setStartDate(start.format('YYYY-MM-DD'));
            setEndDate(end.format('YYYY-MM-DD'));
            return fetchPlayers(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'));
          }}
          loading={loading}
        />
      </div>
      <Table
        data={players}
        noDataText=""
        filterable
        NoDataComponent={() => null}
        defaultFilterMethod={(filter, row) => {
          const rowValue = row[filter.id] || '';
          if (!rowValue) { return false; }
          return (
            String(row[filter.id].toLowerCase()).includes(filter.value.toLowerCase())
          );
        }}
        loading={loading}
        columns={[
          {
            accessor: 'id',
            Header: 'Player',
            Cell: cellData => {
              const firstName = get(cellData, 'original.first_name');
              const lastName = get(cellData, 'original.last_name');

              if (!firstName && !lastName) { return '-'; }
              return `${firstName} ${lastName}`;
            },
            Filter: cellInfo => {
              const { filter, onChange } = cellInfo;

              return (
                <TextInput
                  placeholder="Search by name"
                  handleChange={val => onChange(val)}
                  value={filter ? filter.value : ''}
                />
              );
            },
            filterMethod: (filter, row) => {
              const firstName = get(row, '_original.first_name') || '';
              const lastName = get(row, '_original.last_name') || '';
              if (firstName.toLowerCase().indexOf(filter.value.toLowerCase()) !== -1 ||
                lastName.toLowerCase().indexOf(filter.value.toLowerCase()) !== -1
              ) {
                return true;
              }

              return false;
            },
          },
          {
            accessor: 'pitches_on_date',
            Header: 'Sunday',
            id: 'Sunday',
            filterable: false,
            Cell: cellData => displayValue(get(cellData, 'value[0].team_pitches_count')),
          },
          {
            accessor: 'pitches_on_date',
            Header: 'Monday',
            id: 'Monday',
            filterable: false,
            Cell: cellData => displayValue(get(cellData, 'value[1].team_pitches_count')),
          },
          {
            accessor: 'pitches_on_date',
            Header: 'Tuesday',
            id: 'Tuesday',
            filterable: false,
            Cell: cellData => displayValue(get(cellData, 'value[2].team_pitches_count')),
          },
          {
            accessor: 'pitches_on_date',
            Header: 'Wednesday',
            id: 'Wednesday',
            filterable: false,
            Cell: cellData => displayValue(get(cellData, 'value[3].team_pitches_count')),
          },
          {
            accessor: 'pitches_on_date',
            Header: 'Thursday',
            id: 'Thursday',
            filterable: false,
            Cell: cellData => displayValue(get(cellData, 'value[4].team_pitches_count')),
          },
          {
            accessor: 'pitches_on_date',
            Header: 'Friday',
            id: 'Friday',
            filterable: false,
            Cell: cellData => displayValue(get(cellData, 'value[5].team_pitches_count')),
          },
          {
            accessor: 'pitches_on_date',
            Header: 'Saturday',
            id: 'Saturday',
            filterable: false,
            Cell: cellData => displayValue(get(cellData, 'value[6].team_pitches_count')),
          },
        ]}
        // clickable
        // handleClick={rowData => {
        //   setPlayerToShow(rowData.original);
        //   setIsPlayerPopupDisplayed(true);
        // }}
        minRows={1}
        showPagination={false}
      />
    </div>
  );
};

TimeRangePitchingStats.propTypes = {
  seasonId: PropTypes.number.isRequired,
  teamId: PropTypes.number.isRequired,
};

export default TimeRangePitchingStats;
