import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import {
  NavLink,
  withRouter,
} from 'react-router-dom';
import {
  ContentLoader,
  Tab,
  Tabs,
  TabContent,
  TabsHeader,
  Table,
  TextInput,
} from 'shared/components';
import {
  displayValue,
  formatDate,
} from 'shared/helpers';
import { getPlayersData } from './actions';
import PlayerStatsPopup from './components/PlayerStatsPopup';
import TimeRangePitchingStats from './components/TimeRangePitchingStats';
import TimeRangeCatchingStats from './components/TimeRangeCatchingStats';
import './styles.scss';

const LeagueTeamView = ({
  activeSeason,
  leagueId,
  match,
}) => {
  const teamId = get(match, 'params.teamId');
  const seasonId = get(activeSeason, 'id');
  const isMounted = React.useRef(true);
  const [loading, setLoading] = useState(true);
  const [teamName, setTeamName] = useState('');
  const [players, setPlayers] = useState([]);
  const [playerToShow, setPlayerToShow] = useState(null);
  const [isPlayerPopupDisplayed, setIsPlayerPopupDisplayed] = useState(false);

  const fetchPlayers = () => {
    getPlayersData(teamId, seasonId)
      .then(res => {
        const team = get(res, 'data.name') || '';
        const playersList = get(res, 'data.players') || [];

        setLoading(false);
        setTeamName(team);
        setPlayers(playersList);
      })
      .catch(() => {});
  };

  useEffect(() => {
    setLoading(true);
    fetchPlayers();

    return () => {
      isMounted.current = false;
    };
  }, [seasonId]);

  if (loading) {
    return <ContentLoader />;
  }

  return (
    <div className="LeagueTeamView">
      <div className="LeagueTeamView-breadcrumb">
        <NavLink to={`/leagues/${leagueId}`}>
          <span>&#8249;</span>
          back to teams list
        </NavLink>
      </div>
      <div className="LeagueTeamView-team-title">
        <NavLink to={`/${teamId}`}>
          <h3>{teamName}</h3>
        </NavLink>
      </div>
      <div className="LeagueTeamView-tabs">
        <Tabs>
          <TabsHeader>
            <Tab>Season pitching stats</Tab>
            <Tab>Season catching stats</Tab>
            <Tab>Weekly pitching stats</Tab>
            <Tab>Weekly catching stats</Tab>
          </TabsHeader>
          <TabContent>
            <Table
              data={players}
              noDataText=""
              filterable
              NoDataComponent={() => null}
              defaultFilterMethod={(filter, row) => {
                const rowValue = row[filter.id] || '';
                if (!rowValue) { return false; }
                return (
                  String(row[filter.id].toLowerCase()).includes(filter.value.toLowerCase())
                );
              }}
              loading={false}
              columns={[
                {
                  accessor: 'id',
                  Header: 'Player',
                  Cell: cellData => {
                    const firstName = get(cellData, 'original.first_name');
                    const lastName = get(cellData, 'original.last_name');

                    if (!firstName && !lastName) { return '-'; }
                    return `${firstName} ${lastName}`;
                  },
                  Filter: cellInfo => {
                    const { filter, onChange } = cellInfo;

                    return (
                      <TextInput
                        placeholder="Search by players name"
                        handleChange={val => onChange(val)}
                        value={filter ? filter.value : ''}
                      />
                    );
                  },
                  filterMethod: (filter, row) => {
                    const firstName = get(row, '_original.first_name') || '';
                    const lastName = get(row, '_original.last_name') || '';
                    if (firstName.toLowerCase().indexOf(filter.value.toLowerCase()) !== -1 ||
                      lastName.toLowerCase().indexOf(filter.value.toLowerCase()) !== -1
                    ) {
                      return true;
                    }

                    return false;
                  },
                },
                {
                  accessor: 'total_games_count',
                  Header: 'Total games',
                  filterable: false,
                  Cell: cellData => displayValue(cellData.value),
                },
                {
                  accessor: 'total_pitches_count',
                  Header: 'Total pitches',
                  filterable: false,
                  Cell: cellData => displayValue(cellData.value),
                },
                {
                  accessor: 'average_pitches_count',
                  Header: 'Average pitches',
                  filterable: false,
                  Cell: cellData => displayValue(cellData.value),
                },
                {
                  accessor: 'total_rest_days',
                  Header: 'Total rest days',
                  filterable: false,
                  Cell: cellData => displayValue(cellData.value),
                },
                {
                  accessor: 'latest_game',
                  id: 'latest_game_date',
                  Header: 'Last game date',
                  filterable: false,
                  Cell: cellData => {
                    if (!cellData.value) {
                      return '-';
                    }

                    return formatDate(get(cellData, 'value.time_start'));
                  },
                },
                {
                  accessor: 'latest_game',
                  id: 'latest_game_pitches_count',
                  Header: 'Last game pitches',
                  filterable: false,
                  Cell: cellData => {
                    if (!cellData.value) {
                      return '-';
                    }

                    return displayValue(get(cellData, 'value.pitches_count'));
                  },
                },
              ]}
              clickable
              handleClick={rowData => {
                setPlayerToShow(rowData.original);
                setIsPlayerPopupDisplayed(true);
              }}
              minRows={1}
              showPagination={false}
            />
          </TabContent>
          <TabContent>
            <Table
              data={players}
              noDataText=""
              filterable
              NoDataComponent={() => null}
              defaultFilterMethod={(filter, row) => {
                const rowValue = row[filter.id] || '';
                if (!rowValue) { return false; }
                return (
                  String(row[filter.id].toLowerCase()).includes(filter.value.toLowerCase())
                );
              }}
              loading={false}
              columns={[
                {
                  accessor: 'id',
                  Header: 'Player',
                  Cell: cellData => {
                    const firstName = get(cellData, 'original.first_name');
                    const lastName = get(cellData, 'original.last_name');

                    if (!firstName && !lastName) { return '-'; }
                    return `${firstName} ${lastName}`;
                  },
                  Filter: cellInfo => {
                    const { filter, onChange } = cellInfo;

                    return (
                      <TextInput
                        placeholder="Search by players name"
                        handleChange={val => onChange(val)}
                        value={filter ? filter.value : ''}
                      />
                    );
                  },
                  filterMethod: (filter, row) => {
                    const firstName = get(row, '_original.first_name') || '';
                    const lastName = get(row, '_original.last_name') || '';
                    if (firstName.toLowerCase().indexOf(filter.value.toLowerCase()) !== -1 ||
                      lastName.toLowerCase().indexOf(filter.value.toLowerCase()) !== -1
                    ) {
                      return true;
                    }

                    return false;
                  },
                },
                {
                  accessor: 'total_games_count',
                  Header: 'Total games',
                  filterable: false,
                  Cell: cellData => displayValue(cellData.value),
                },
                {
                  accessor: 'total_catches_count',
                  Header: 'Total catches',
                  filterable: false,
                  Cell: cellData => displayValue(cellData.value),
                },
                {
                  accessor: 'average_catches_count',
                  Header: 'Average catches',
                  filterable: false,
                  Cell: cellData => displayValue(cellData.value),
                },
                {
                  accessor: 'total_rest_days',
                  Header: 'Total rest days',
                  filterable: false,
                  Cell: cellData => displayValue(cellData.value),
                },
                {
                  accessor: 'latest_game',
                  id: 'latest_game_date',
                  Header: 'Last game date',
                  filterable: false,
                  Cell: cellData => {
                    if (!cellData.value) {
                      return '-';
                    }

                    return formatDate(get(cellData, 'value.time_start'));
                  },
                },
                {
                  accessor: 'latest_game',
                  id: 'latest_game_catches_count',
                  Header: 'Last game catches',
                  filterable: false,
                  Cell: cellData => {
                    if (!cellData.value) {
                      return '-';
                    }

                    return displayValue(get(cellData, 'value.catches_count'));
                  },
                },
              ]}
              clickable
              handleClick={rowData => {
                setPlayerToShow(rowData.original);
                setIsPlayerPopupDisplayed(true);
              }}
              minRows={1}
              showPagination={false}
            />
          </TabContent>
          <TabContent>
            <TimeRangePitchingStats
              teamId={Number(teamId)}
              seasonId={seasonId}
            />
          </TabContent>
          <TabContent>
            <TimeRangeCatchingStats
              teamId={Number(teamId)}
              seasonId={seasonId}
            />
          </TabContent>
        </Tabs>
      </div>
      {
        isPlayerPopupDisplayed && (
          <PlayerStatsPopup
            isOpen={isPlayerPopupDisplayed}
            close={() => {
              setIsPlayerPopupDisplayed(false);
              setPlayerToShow(null);
            }}
            teamId={teamId}
            seasonId={seasonId}
            player={playerToShow}
          />
        )
      }
    </div>
  );
};

LeagueTeamView.propTypes = {
  activeSeason: PropTypes.object.isRequired,
  leagueId: PropTypes.number.isRequired,
  match: PropTypes.object.isRequired,
};

export default withRouter(LeagueTeamView);
