import get from 'lodash.get';
import {
  gamesStatusOptions,
} from 'shared/constants';

export const isAwayGame = (game, teamId) => {
  const awayTeam = get(game, 'team_away');
  const isAway = get(awayTeam, 'id') === teamId;

  return !!isAway;
};

export const getGameStatus = game => {
  const s = get(game, 'status') || '';
  const hasStatus = gamesStatusOptions.filter(st => st.value === s);

  if (hasStatus[0]) {
    return hasStatus[0];
  }

  return {
    label: 'Scheduled',
    value: 'scheduled',
  };
};
