import React from 'react';
import Base from './Base';

export default props => (
  <Base {...props}>
    <path
      d="M47.67,45.23,35.09,32.66a18.53,18.53,0,1,0-3.52,3.11L44.35,
      48.55ZM7,20.21A14.07,14.07,0,1,1,21.09,34.28,14.09,14.09,0,0,1,7,20.21Z"
    />
  </Base>
);
