/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Modal } from "shared/components";
import "./styles.scss";
import { Label, Button, Selector } from "shared/components";
import { getPlayers, addPlayer } from "./actions";
import { useParams } from "react-router-dom";
function AddPlayer({ isOpen, close, setRefetching, event }) {
  const [dirty, setDirty] = useState(false);

  const handleClose = () => {
    if (
      !dirty ||
      window.confirm("Are you sure you want to discard your changes?")
    ) {
      close();
    }
  };
  const [orgPlayers, setOrgPlayers] = useState();
  const [playerId, setPlayerId] = useState();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const { eventId } = useParams();
  console.log("eventId: ", eventId);

  const dateFormater = (date) => {
    return (
      date?.getFullYear() + "-" + (date?.getMonth() + 1) + "-" + date?.getDate()
    );
  };

  const handleSubmit = () => {
    let data = {
      event: eventId,
      player: playerId,
    };
    if (dirty) {
      addPlayer(data)
        .then(() => {
          setRefetching(true);
          setPlayerId(null);
          close();
        })
        .catch(() => setError("Player already added"));
    }
  };

  const handleSubmitRefresh = () => {
    let data = {
      event: eventId,
      player: playerId,
    };
    if (dirty) {
      addPlayer(data)
        .then(() => {
          setRefetching(true);
          setPlayerId(null);
          setDirty(false);
        })
        .catch(() => setError("Player already added"));
    }
  };

  useEffect(() => {
    getPlayers().then((res) => {
      let array = [];
      res.data.results.filter((f) => {
        array.push({ label: f.first_name + " " + f.last_name, value: f.id });
      });
      setOrgPlayers(array);
      setLoading(false);
    });

    let PlayerField = document.getElementById("player");
    if (PlayerField) {
      PlayerField.addEventListener("keydown", (e) => {
        console.log(e);
      });
    }
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      closeCb={handleClose}
      title="Add Player to Event"
      size="md"
    >
      <div className="addplayer">
        <div className="form-row">
          <div className="field-holder">
            <Label inputId="player" text="Player" />
            <Selector
              options={orgPlayers}
              loading={loading}
              id="player"
              value={playerId}
              handleChange={(option) => {
                setPlayerId(option);
                setDirty(true);
                setError(null);
              }}
              onKeyPress={(e) => {
                if (e.key == "Enter") handleSubmitRefresh();
              }}
            />
          </div>
        </div>
        <div className="form-submit">
          <Button
            theme="link"
            type="button"
            disabled={false}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            theme="success"
            type="submit"
            disabled={false}
            onClick={handleSubmitRefresh}
          >
            Add Another Player
          </Button>
          <Button
            theme="success"
            type="submit"
            disabled={false}
            onClick={handleSubmit}
          >
            Add Player
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default AddPlayer;
