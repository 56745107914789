import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const Scorebook = ({
  // eslint-disable-next-line no-unused-vars
  actions,
  title,
}) => (
  <div className="Scorebook">
    <div className="Scorebook-title">
      {title}
    </div>
    <div className="Scorebook-team-actions">
      <div className="team-name">
        TBD
      </div>
      <div className="actions-container">
        <div className="action-item">
          Smith (#9) - 1B
        </div>
        <div className="action-item">
          Lawson (#11) - K
        </div>
        <div className="action-item">
          Smith (#9) - SB11 2B
        </div>
        <div className="action-item">
          Henry (#11) - BB
        </div>
      </div>
    </div>
  </div>
);

Scorebook.propTypes = {
  actions: PropTypes.array,
  title: PropTypes.string,
};

Scorebook.defaultProps = {
  actions: [],
  title: 'Scorebook',
};

export default Scorebook;
