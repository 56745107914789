/* eslint-disable */
import api from 'shared/api';

export const getSeasons = (org) => {
  const url = `/api/v1/seasons/?organization=${org}`;
  return api.get(url);
};

export const getPlayerStats = (season) => {
  const url = `/api/v1/playerstats/?season__id=${season}`;
  return api.get(url);
};