/* eslint-disable */
import api from 'shared/api';

export const getTeams = (org) => {
  const url = `/api/v1/teams/?organization=${org}`;
  return api.get(url);
};

export const getPlayerStats = (team) => {
  const url = `/api/v1/playerstats/?team__id=${team}`;
  return api.get(url);
};