import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  Button,
} from 'shared/components';
import icon from './under_constructions.svg';
import './styles.scss';

// color of image #911D1D

const UnderConstruction = ({
  history,
}) => (
  <div className="UnderConstruction">
    <img src={icon} alt="Page under construction" />
    <p>
      Page under construction
    </p>
    <Button
      onClick={() => { history.goBack(); }}
    >
      Go back
    </Button>
  </div>
);

UnderConstruction.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(UnderConstruction);
