/* eslint-disable */
import React from "react";
import { Button } from "shared/components";
import { Table } from "shared/components";
import { useState, useEffect, useRef, forwardRef } from "react";
import {
  getDefinedStats,
  getDefinedStat,
  getPlayer,
  getTestingEvent,
} from "./actions";
import "./styles.scss";
import get from "lodash.get";
import { displayValue } from "shared/helpers";
import { useHistory } from "react-router-dom";
import { Selector } from "shared/components";
import { useParams } from "react-router-dom";
import PlayerStat from "./components/PlayerStat/PlayerStat";
import { ContentLoader } from "shared/components";
import { NavLink } from "react-router-dom";

const Input = forwardRef((props, ref) => {
  <PlayerStat {...props} ref={ref} />;
});

function EventPlayersStats() {
  const [loading, setLoading] = useState(true);
  const [loadingStats, setLoadingStats] = useState(true);

  const [refetching, setRefetching] = useState(false);
  const [modalCreateEvent, setModalCreateEvent] = useState(false);
  const [stats, setStats] = useState([]);
  const [pickedStat, setPickedStat] = useState(null);
  const [stat, setStat] = useState([]);
  const [players, setPlayers] = useState(null);
  const [testingEvent, setTestingEvent] = useState(null);

  const inputRefs = useRef([]);

  const history = useHistory();
  const redirectToEventPage = (rowData) => {
    console.log("Row Data: ");
    const statId = get(rowData, "original.id");
    history.push(`/events/stat/${statId}/`);
  };

  const { eventId } = useParams();

  const fetchStats = () => {
    getPlayer(eventId)
      .then((res) => {
        setPlayers(res.data);
        setLoadingStats(false);
      })
      .catch(() => {
        setLoadingStats(false);
      });

    getTestingEvent(eventId)
      .then((res) => {
        console.log("Testing event", res.data.results[0].id);
        setTestingEvent(res.data.results[0].id);
      })
      .catch(() => {});

    getDefinedStats()
      .then((res) => {
        const data = get(res, "data") || [];
        let rawResults = [];
        res.data.results.forEach((e, i) => {
          if (i == 0) {
            console.log("Stat:", e);
            setStat([e]);
            setPickedStat(e.id);
          }
          rawResults.push({ label: e.code, value: e.id });
        });
        setStats(rawResults);

        setRefetching(false);
      })
      .catch(() => {
        setRefetching(false);
      });
  };

  const fetchStat = () => {
    getDefinedStat(pickedStat)
      .then((res) => {
        console.log(res);
        setStat([res.data]);

        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleEventCreateClick = () => {
    return setModalCreateEvent(true);
  };

  useEffect(() => {
    setLoadingStats(true);
    fetchStats();
  }, []);

  useEffect(() => {
    setLoading(true);
    fetchStat();
  }, [pickedStat]);

  return (
    <div className="LeagueTeamsList">
      <div className="PlayerContainer-breadcrumb">
        <NavLink to={`/events/${eventId}`}>
          <span>&#8249;</span>
          back to event
        </NavLink>
      </div>
      <Selector
        options={stats}
        value={pickedStat}
        loading={loadingStats}
        handleChange={(val) => {
          setPickedStat(val);
        }}
      />
      <div className="teams-container">
        <Table
          data={stat}
          noDataText=""
          loading={loading}
          columns={[
            {
              accessor: "code",
              Header: "Code",
              width: 250,
            },
            {
              accessor: "description",
              Header: "Description",
              Cell: (cellData) => displayValue(cellData.value),
            },
            {
              accessor: "number_of_measurments",
              Header: "Number of measurments",
              Cell: (cellData) => displayValue(cellData.value),
            },
          ]}
          minRows={1}
          showPagination={false}
        />
      </div>
      <div className="player-stat-holder">
        {!loadingStats &&
          !loading &&
          players.results.map((p, i) => {
            return (
              <PlayerStat
                player={p.player}
                stat={stat[0]}
                eventId={eventId}
                testEventId={testingEvent}
                index={(i + 1) * stat[0]?.number_of_measurments - 1}
              />
            );
          })}
        {(loadingStats || loading) && <ContentLoader />}
      </div>
    </div>
  );
}

export default EventPlayersStats;
