import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { Selector } from 'shared/components';

class AsyncSelector extends Component {
  constructor() {
    super();
    this.state = {
      options: [],
    };
    this.getInitialOptions = this.getInitialOptions.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  componentDidMount() {
    this.getInitialOptions();
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getInitialOptions() {
    const { fetchInitialOptions, exclude, append } = this.props;
    fetchInitialOptions()
      .then((res) => {
        if (this.mounted) {
          const data = get(res, 'data.results') || [];

          if (append) {
            data.push(append);
          }

          if (exclude) {
            const options = data.filter(d => d.id !== exclude);
            this.setState({
              options,
            });
          } else {
            this.setState({
              options: data,
            });
          }
        }
      })
      .catch(() => {
        console.error('Options could not be fetched'); // eslint-disable-line no-console
      });
  }

  handleSearch(query) {
    if (!query) {
      return;
    }
    this.setState({ loading: true });
    const { fetchOptions, exclude } = this.props;
    fetchOptions(query)
      .then((res) => {
        const data = get(res, 'data.results') || [];

        if (exclude) {
          const options = data.filter(d => d.id !== exclude);
          this.setState({
            loading: false,
            options,
          });
        } else {
          this.setState({
            loading: false,
            options: data,
          });
        }
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  }

  handleSelectChange(option) {
    const { handleChange } = this.props;
    handleChange(option);
  }

  render() {
    const { loading, options } = this.state;
    const {
      disabled,
      exclude,
      labelKey,
      multi,
      value,
      valueKey,
      ...rest
    } = this.props;


    return (
      <div className="Selector">
        <Selector
          getOptionLabel={o => o[labelKey]}
          getOptionValue={o => o[valueKey]}
          onChange={this.handleSelectChange}
          isLoading={false && loading}
          isDisabled={disabled}
          onInputChange={this.handleSearch}
          options={options}
          value={value}
          exclude={exclude}
          labelKey={labelKey}
          {...rest}
        />
      </div>
    );
  }
}

AsyncSelector.propTypes = {
  append: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  exclude: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.array,
    PropTypes.string,
  ]),
  fetchOptions: PropTypes.func.isRequired,
  fetchInitialOptions: PropTypes.func.isRequired,
  handleChange: PropTypes.func,
  labelKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  multi: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  valueKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

AsyncSelector.defaultProps = {
  append: null,
  disabled: false,
  exclude: null,
  handleChange: () => {},
  multi: false,
  value: undefined,
};

export default AsyncSelector;
