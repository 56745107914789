import {
  playerPositionOptions,
  reserveOptions,
} from 'shared/constants';

export const getPlayer = (players, selected) => {
  let toEdit = null;

  players.forEach(player => {
    if (player.player.id === selected.player) {
      toEdit = player;
    }
  });

  return toEdit;
};

export const mapPositionToSelector = (val) => {
  const selected = playerPositionOptions.find(o => o.value === val);
  return selected;
};

export const mapReserveToSelector = (val) => {
  if (val === false) {
    return reserveOptions[0];
  }

  return reserveOptions[1];
};
