/* eslint-disable */
import React from "react";
import { Button } from "shared/components";
import { Table } from "shared/components";
import { useState, useEffect } from "react";
import { getDefinedStats } from "./actions";
import get from "lodash.get";
import { displayValue } from "shared/helpers";
import { useHistory } from "react-router-dom";
import AddEventStat from "./components/AddEventStat/AddEventStat";

function EventStatsList({ organizationId }) {
  const [loading, setLoading] = useState(true);
  console.log(organizationId);
  const [refetching, setRefetching] = useState(false);
  const [modalCreateEvent, setModalCreateEvent] = useState(false);

  const [results, setResults] = useState([]);
  const history = useHistory();
  const redirectToEventPage = (rowData) => {
    console.log("Row Data: ");
    const statId = get(rowData, "original.id");
    history.push(`/events/stat/${statId}/`);
  };

  const fetchEvents = () => {
    getDefinedStats()
      .then((res) => {
        const data = get(res, "data") || [];
        console.log(data);
        setLoading(false);
        setResults(data.results);
        setRefetching(false);
      })
      .catch(() => {
        setLoading(false);
        setRefetching(false);
      });
  };

  const handleEventCreateClick = () => {
    return setModalCreateEvent(true);
  };

  useEffect(() => {
    setLoading(true);
    fetchEvents();
  }, [refetching]);
  return (
    <div className="LeagueTeamsList">
      <Button onClick={handleEventCreateClick} disabled={false} size="sm">
        Create Statistics
      </Button>
      <div className="teams-container">
        <Table
          data={results}
          noDataText=""
          loading={loading || refetching}
          columns={[
            {
              accessor: "code",
              Header: "Code",
              width: 250,
            },
            {
              accessor: "description",
              Header: "Description",
              Cell: (cellData) => displayValue(cellData.value),
            },
            {
              accessor: "number_of_measurments",
              Header: "Number of measurments",
              Cell: (cellData) => displayValue(cellData.value),
            },
          ]}
          clickable
          handleClick={(rowData) => redirectToEventPage(rowData)}
          minRows={1}
          showPagination={false}
        />
      </div>
      {modalCreateEvent && (
        <AddEventStat
          setRefetching={setRefetching}
          isOpen={modalCreateEvent}
          close={() => setModalCreateEvent(false)}
        />
      )}
    </div>
  );
}

export default EventStatsList;
