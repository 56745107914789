import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import {
  NavLink,
  Switch,
  Route,
} from 'react-router-dom';
import {
  NotAuthorized,
  NotFound,
} from 'shared/components';
import {
  isAdmin,
} from 'shared/helpers';
import {
  GameStatsBatting,
  GameStatsFielding,
  GameStatsPitching,
} from '../components';
import './styles.scss';

const GameStatsHome = ({
  gameId,
  team,
  teamId,
}) => {
  const isAuth = isAdmin(teamId);

  if (!isAuth) {
    return <NotAuthorized message="Only team admins can see game stats" />;
  }

  return (
    <div className="GameStatsHome">
      <div className="GameStatsHome-menu">
        <NavLink exact to={`/${teamId}/games/${gameId}/stats-home`}>Batting</NavLink>
        <NavLink exact to={`/${teamId}/games/${gameId}/stats-home/pitching`}>Pitching</NavLink>
        <NavLink exact to={`/${teamId}/games/${gameId}/stats-home/fielding`}>Fielding</NavLink>
      </div>
      <div className="GameStatsHome-team">
        <NavLink exact to={`/${get(team, 'id')}`}>{get(team, 'name')}</NavLink>
      </div>
      <Switch>
        <Route
          exact
          path="/:teamId/games/:gameId/stats-home"
          render={() => <GameStatsBatting teamStatsId={get(team, 'id')} />}
        />
        <Route
          exact
          path="/:teamId/games/:gameId/stats-home/pitching"
          render={() => <GameStatsPitching teamStatsId={get(team, 'id')} />}
        />
        <Route
          exact
          path="/:teamId/games/:gameId/stats-home/fielding"
          render={() => <GameStatsFielding teamStatsId={get(team, 'id')} />}
        />
        <Route
          render={() => <NotFound />}
        />
      </Switch>
    </div>
  );
};

GameStatsHome.propTypes = {
  gameId: PropTypes.number.isRequired,
  team: PropTypes.object.isRequired,
  teamId: PropTypes.number.isRequired,
};

export default GameStatsHome;
