/* eslint-disable */
import React, { useState } from "react";
import { withRouter, NavLink, Route, Switch } from "react-router-dom";
import { Page, NotFound } from "shared/components";
import PropTypes from "prop-types";
import TeamInSeason from "../pages/TeamInSeason";
import TeamByLocation from "../pages/TeamByLocation";
import TeamsList from "../pages/Team";
import SeasonsList from "../pages/Season";
import Events from "../pages/Events";
import "./styles.scss";
import { connect } from "react-redux";
import get from "lodash.get";
const LeagueContainer = ({ location, user }) => {
  const PickResolver = () => {
    switch (location.pathname) {
      default:
        return 1;
      case "/leaderboard":
        return 1;
      case "/leaderboard/seasons":
        return 2;
      case "/leaderboard/seasons/team":
        return 3;
      case "/leaderboard/team/game":
        return 4;
      case "/leaderboard/events":
        return 5;
    }
  };

  const [picked, setPicked] = useState(PickResolver());
  console.log(user);
  return (
    <Page>
      <div className="LederboardContainer">
        <div className="LederboardContainer-menu">
          <NavLink
            to="/leaderboard"
            onClick={() => setPicked(1)}
            isActive={() => picked === 1}
          >
            Teams
          </NavLink>
          <NavLink
            to="/leaderboard/seasons"
            onClick={() => setPicked(2)}
            isActive={() => picked === 2}
          >
            Seasons
          </NavLink>
          <NavLink
            to="/leaderboard/seasons/team"
            onClick={() => setPicked(3)}
            isActive={() => picked === 3}
          >
            Teams in Season
          </NavLink>

          <NavLink
            to="/leaderboard/team/game"
            onClick={() => setPicked(4)}
            isActive={() => picked === 4}
          >
            Teams by Game
          </NavLink>
          <NavLink
            to="/leaderboard/events"
            onClick={() => setPicked(5)}
            isActive={() => picked === 5}
          >
            Events
          </NavLink>
        </div>
        <Switch>
          <Route
            exact
            path="/leaderboard"
            render={() => (
              <TeamsList organizationId={user?.organizations[0]?.id} />
            )}
          />
          <Route
            exact
            path="/leaderboard/seasons"
            render={() => (
              <SeasonsList organizationId={user?.organizations[0]?.id} />
            )}
          />
          <Route
            exact
            path="/leaderboard/seasons/team"
            render={() => (
              <TeamInSeason organizationId={user?.organizations[0]?.id} />
            )}
          />
          <Route
            exact
            path="/leaderboard/team/game"
            render={() => (
              <TeamByLocation organizationId={user?.organizations[0]?.id} />
            )}
          />
          <Route
            exact
            path="/leaderboard/events"
            render={() => (
              <Events organizationId={user?.organizations[0]?.id} />
            )}
          />
          <Route render={() => <NotFound />} />
        </Switch>
      </div>
    </Page>
  );
};

LeagueContainer.propTypes = {
  location: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: get(state, "user.details"),
});

export default connect(mapStateToProps)(withRouter(LeagueContainer));
