import React, { useState } from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  Notification,
} from 'shared/components';
import { deleteUser } from './actions';
import './styles.scss';

const DeleteUserPopup = ({
  close,
  isOpen,
  refreshUsersList,
  user,
}) => {
  const userId = get(user, 'userteam.id');
  const userEmail = get(user, 'email');

  const [loading, setLoading] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();

    setLoading(true);

    deleteUser(userId)
      .then(() => {
        refreshUsersList();
        Notification('success', 'User removed from the team');
        close();
      })
      .catch(() => {
        setLoading(false);
        Notification('error', 'Error occured', 'There was an error while deleting user');
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      closeCb={close}
      title="Are you sure you want to delete this user?"
      size="md"
    >
      <div className="DeleteUserPopup">
        <form
          className="DeleteUserPopup-form"
          onSubmit={handleSubmit}
        >
          <p className="heading">User will be removed from the team and all player - user relationships in this team will be deleted</p>
          <p className="value">{userEmail}</p>
          <div className="form-submit">
            <Button
              theme="link"
              type="button"
              disabled={loading}
              onClick={close}
            >
              Cancel
            </Button>
            <Button
              theme="error"
              type="submit"
              disabled={loading}
            >
              Delete
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

DeleteUserPopup.propTypes = {
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  refreshUsersList: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

export default DeleteUserPopup;
