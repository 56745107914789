import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import {
  Table,
  Modal,
  ContentLoader,
} from 'shared/components';
import {
  displayValue,
  formatDate,
} from 'shared/helpers';
import { getPlayerData } from './actions';
import './styles.scss';

const PlayerStatsPopup = ({
  isOpen,
  close,
  player,
  teamId,
  seasonId,
}) => {
  const playerId = get(player, 'id');
  const isMounted = React.useRef(true);
  const [loading, setLoading] = useState(false);
  const [games, setGames] = useState([]);

  const fetchPlayersGames = () => {
    getPlayerData(playerId, teamId, seasonId)
      .then(res => {
        const gamesList = get(res, 'data.games') || [];

        setLoading(false);
        setGames(gamesList);
      })
      .catch(() => {});
  };

  useEffect(() => {
    setLoading(true);
    fetchPlayersGames();

    return () => {
      isMounted.current = false;
    };
  }, [seasonId]);

  return (
    <Modal
      isOpen={isOpen}
      closeCb={close}
      title={`${player.first_name} ${player.last_name}`}
      size="lg"
    >
      <div className="PlayerStatsPopup">
        {
          loading ? <ContentLoader /> : (
            <Table
              data={games}
              noDataText="No games found"
              loading={loading}
              columns={[
                {
                  accessor: 'time_start',
                  Header: 'Game date',
                  Cell: cellData => formatDate(get(cellData, 'value')),
                  Footer: 'Totals',
                },
                {
                  accessor: 'pitches_count',
                  Header: 'Pitches count',
                  Cell: cellData => displayValue(get(cellData, 'value')),
                  Footer: () => {
                    let totals = 0;
                    games.forEach(g => { totals += Number(g.pitches_count); });
                    return totals;
                  },
                },
                {
                  accessor: 'catches_count',
                  Header: 'Catches count',
                  Cell: cellData => displayValue(get(cellData, 'value')),
                  Footer: () => {
                    let totals = 0;
                    games.forEach(g => { totals += Number(g.catches_count); });
                    return totals;
                  },
                },
              ]}
              disableHover
              minRows={1}
              showPagination={false}
            />
          )
        }
      </div>
    </Modal>
  );
};

PlayerStatsPopup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  player: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  teamId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  seasonId: PropTypes.number.isRequired,
};

export default PlayerStatsPopup;
