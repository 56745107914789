/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Modal } from "shared/components";
import "./styles.scss";
import { Label, TextInput, Button } from "shared/components";
import { updateStat } from "./actions";

function UpdateStat({ isOpen, close, setRefetching, event }) {
  const [dirty, setDirty] = useState(false);

  const handleClose = () => {
    if (
      !dirty ||
      window.confirm("Are you sure you want to discard your changes?")
    ) {
      close();
    }
  };
  const [code, setCode] = useState(event?.code);
  const [description, setDescription] = useState(event?.description);
  const [numberOfM, setNumberOfM] = useState(event?.number_of_measurments);

  const handleSubmit = () => {
    let data = {
      code: code,
      description: description,
      number_of_measurments: numberOfM,
    };
    if (dirty) {
      updateStat(data, event?.id).then(() => {
        setRefetching(true);
        close();
      });
    }
  };
  useEffect(() => {}, []);

  return (
    <Modal isOpen={isOpen} closeCb={handleClose} title="Update stats" size="sm">
      <div className="form-row">
        <Label inputId="code" text="Code" />
        <TextInput
          id="code"
          value={code}
          placeholder="Code"
          handleChange={(val) => {
            setDirty(true);
            setCode(val);
          }}
        />
      </div>
      <div className="form-row">
        <Label inputId="description" text="Description" />
        <TextInput
          id="description"
          value={description}
          placeholder="Description"
          handleChange={(val) => {
            setDirty(true);
            setDescription(val);
          }}
        />
      </div>
      <div className="form-row">
        <Label inputId="nofm" text="Number of measurments" />
        <TextInput
          id="nofm"
          value={numberOfM}
          placeholder="Number if measurments"
          handleChange={(val) => {
            setDirty(true);
            setNumberOfM(val);
          }}
        />
      </div>

      <div className="form-submit">
        <Button
          theme="link"
          type="button"
          disabled={false}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          theme="success"
          type="submit"
          disabled={false}
          onClick={handleSubmit}
        >
          Save edits
        </Button>
      </div>
    </Modal>
  );
}

export default UpdateStat;
