import React, { useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import {
  Button,
  Label,
  Modal,
  Notification,
  NumberInput,
} from 'shared/components';
import { suplementalRulesObject } from 'shared/constants';
import {
  patchRule,
} from './actions';
import './styles.scss';

const EditSuplementalRule = ({
  close,
  isOpen,
  refetchRules,
  ruleToEdit,
}) => {
  const [loading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);

  const [ruleMeta, setRuleMeta] = useState(get(ruleToEdit, 'rule_meta'));

  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);

    const ruleID = get(ruleToEdit, 'id');

    const data = {
      rule_meta: ruleMeta,
    };

    patchRule(ruleID, data)
      .then(() => {
        Notification('success', 'New rule added to the league');
        refetchRules();
        close();
      })
      .catch(() => {
        setLoading(false);
        Notification(
          'error',
          'Error occured',
          'There was an error while adding a new rule to the league.',
        );
      });
  };

  const handleValueChange = (ruleMetaKey, val) => {
    const newMeta = {
      ...ruleMeta,
      [ruleMetaKey]: val,
    };
    setRuleMeta(newMeta);
  };

  const handleClose = () => {
    if (
      !dirty ||
      window.confirm('Are you sure you want to discard your changes?')
    ) {
      close();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      closeCb={handleClose}
      title="Edit suplemental rule"
      size="sm"
    >
      <div className="EditSuplementalRule">
        <form
          className="EditSuplementalRule-form"
          onSubmit={handleSubmit}
        >
          {
            Object.entries(ruleMeta).map(([key, value]) => (
              <div className="form-row" key={key}>
                <Label
                  inputId={`rule-meta-${key}`}
                  text={suplementalRulesObject[key]}
                />
                <NumberInput
                  id={`rule-meta-${key}`}
                  value={value}
                  error=""
                  min="1"
                  max="10000"
                  handleChange={val => {
                    setDirty(true);
                    handleValueChange(key, val);
                  }}
                  disabled={loading}
                  required
                />
              </div>
            ))
          }
          <div className="form-submit">
            <Button
              theme="link"
              type="button"
              disabled={loading}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              theme="success"
              type="submit"
              disabled={loading}
            >
              Confirm
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

EditSuplementalRule.propTypes = {
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  refetchRules: PropTypes.func.isRequired,
  ruleToEdit: PropTypes.object.isRequired,
};

export default EditSuplementalRule;

// imas listu defaultnih ruleova tu: GET /api/v1/defaultsupplementrules/

// to je prakticki lista svih koji postoje s formatima nevezano za ligu

// i onda ih mozes dodavat u lige sa npr
// POST /api/v1/supplementrules/
// {
//     "code": "pitch_blocks_catch",
//     "rule_meta": {
//         "pitches": 25
//     },
//     "league": 3
// }

// u defaultsupplementrules vidis kak rule_meta mora izgledati i eventualno ga mozes customizirat

// onda si mozes izlistat sve ruleove na ligi sa GET /api/v1/supplementrules/?league__id=3

// tu ti ih vracam sa idejevima koje koristis kod GET, PATCH rulea vezanog za ligu na /api/v1/supplementrules/{id}/
