import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  IconDelete,
  IconEdit,
  Table,
  TextInput,
} from 'shared/components';
import {
  getReportEmails,
} from './actions';
import DeleteEmailPopup from './components/DeleteEmailPopup';
import AddEmailsForm from './components/AddEmailsForm';
import EditEmailForm from './components/EditEmailForm';
import './styles.scss';

const ReportDelivery = ({
  leagueId,
}) => {
  const [loading, setLoading] = useState(true);
  const [emails, setEmails] = useState([]);
  const [emailToDelete, setEmailToDelete] = useState(null);
  const [isDeleteEmailModalDisplayed, setDeleteEmailModalDisplay] = useState(false);
  const [emailToEdit, setEmailToEdit] = useState(null);
  const [isEditEmailModalDisplayed, setEditEmailModalDisplay] = useState(false);
  const [isAddEmailModalDisplayed, setAddEmailModalDisplay] = useState(false);

  const fetchEmailList = () => {
    getReportEmails(leagueId)
      .then((res) => {
        setLoading(false);
        setEmails(res.data.results || []);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchEmailList();
  }, [leagueId]);

  return (
    <div className="ReportDelivery">
      <div className="ReportDelivery-actions">
        <Button
          theme="default"
          size="sm"
          onClick={() => setAddEmailModalDisplay(true)}
        >
          Add subscribers
        </Button>
      </div>
      <Table
        data={emails}
        noDataText=""
        filterable
        NoDataComponent={() => null}
        loading={loading}
        defaultFilterMethod={(filter, row) => {
          const rowValue = row[filter.id] || '';
          if (!rowValue) { return false; }
          return (
            String(row[filter.id]).toLowerCase().includes(filter.value.toLowerCase())
          );
        }}
        columns={[
          {
            accessor: 'email',
            Header: 'Email',
            Filter: cellInfo => {
              const { filter, onChange } = cellInfo;

              return (
                <TextInput
                  placeholder="Search users by email"
                  handleChange={val => onChange(val)}
                  value={filter ? filter.value : ''}
                />
              );
            },
          },
          {
            Header: 'Edit',
            headerClassName: 'text-center',
            className: 'text-center button-wrapper',
            sortable: false,
            filterable: false,
            Cell: cellData => (
              <button
                aria-label="Edit subscriber"
                className="edit-button"
                onClick={e => {
                  e.stopPropagation();
                  setEmailToEdit(cellData.original);
                  setEditEmailModalDisplay(true);
                }}
                type="button"
              >
                <IconEdit
                  height="20px"
                  width="20px"
                />
              </button>
            ),
            width: 100,
          },
          {
            Header: 'Remove',
            headerClassName: 'text-center',
            className: 'text-center button-wrapper',
            sortable: false,
            filterable: false,
            Cell: cellData => (
              <button
                aria-label="Remove"
                className="delete-button"
                onClick={e => {
                  e.stopPropagation();
                  setEmailToDelete(cellData.original);
                  setDeleteEmailModalDisplay(true);
                }}
                type="button"
              >
                <IconDelete
                  height="20px"
                  width="20px"
                  color="#ee5253"
                />
              </button>
            ),
            width: 100,
          },
        ]}
        minRows={1}
        showPagination={false}
      />
      {
        isDeleteEmailModalDisplayed && (
          <DeleteEmailPopup
            isOpen={isDeleteEmailModalDisplayed}
            close={() => setDeleteEmailModalDisplay(false)}
            email={emailToDelete}
            refreshEmailList={fetchEmailList}
          />
        )
      }
      {
        isAddEmailModalDisplayed && (
          <AddEmailsForm
            isOpen={isAddEmailModalDisplayed}
            close={() => setAddEmailModalDisplay(false)}
            leagueId={leagueId}
            refreshEmailList={fetchEmailList}
          />
        )
      }
      {
        isEditEmailModalDisplayed && (
          <EditEmailForm
            isOpen={isEditEmailModalDisplayed}
            close={() => setEditEmailModalDisplay(false)}
            email={emailToEdit}
            refreshEmailList={fetchEmailList}
          />
        )
      }
    </div>
  );
};

ReportDelivery.propTypes = {
  leagueId: PropTypes.number.isRequired,
};

export default ReportDelivery;
