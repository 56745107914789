/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Modal } from "shared/components";
import "./styles.scss";
import { Label, TextInput, Button, Selector } from "shared/components";
import DatePicker from "react-date-picker";
import { getDivisions, createEvent, createTestEvent } from "./actions";

function AddEvent({ isOpen, close, setRefetching }) {
  const [dirty, setDirty] = useState(false);

  const handleClose = () => {
    if (
      !dirty ||
      window.confirm("Are you sure you want to discard your changes?")
    ) {
      close();
    }
  };
  const [eventName, setEventName] = useState("");
  const [eventDivision, setEventDivision] = useState(1);
  const [eventType, setEventType] = useState("tournament");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const types = [
    { label: "Tournament", value: "tournament" },
    { label: "Testing", value: "testing" },
  ];

  const [loading, setLoading] = useState(true);
  const [divisions, setDivisions] = useState([{ label: "a", value: "24" }]);

  const dateFormater = (date) => {
    return (
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
    );
  };

  function convertDateToISO(date) {
    return date.toISOString();
  }
  const handleSubmit = () => {
    let data = {
      name: eventName,
      start_date: dateFormater(startDate),
      end_date: dateFormater(endDate),
      type: eventType,
      division: eventDivision,
    };

    createEvent(data).then((res) => {
      console.log("Created event:", res.data);
      if (res.data.type == "testing") {
        let testData = {
          event: res.data.id,
          name: eventName,
          ts_started: convertDateToISO(startDate),
          ts_stopped: convertDateToISO(endDate),
        };
        createTestEvent(testData).then(() => {
          setRefetching(true);
          close();
        });
      } else {
        setRefetching(true);
        close();
      }
    });
  };
  useEffect(() => {
    getDivisions(1).then((res) => {
      let array = [];
      res.data.results.filter((f) => {
        array.push({ label: f.name, value: f.id });
      });
      setDivisions(array);
      setLoading(false);
    });
  }, []);

  return (
    <Modal isOpen={isOpen} closeCb={handleClose} title="Create Event" size="sm">
      <div className="form-row">
        <Label inputId="event-name" text="Name" />
        <TextInput
          id="event-name"
          value={eventName}
          placeHolder="Event name"
          handleChange={(val) => {
            setDirty(true);
            setEventName(val);
          }}
        />
      </div>
      <div className="form-row">
        <Label inputId="event-division" text="Division" />
        <Selector
          options={divisions}
          loading={loading}
          id="team-gender"
          value={eventDivision}
          handleChange={(option) => {
            setEventDivision(option);
            setDirty(true);
          }}
        />
      </div>
      <div className="form-row">
        <Label inputId="event-type" text="Type" />
        <Selector
          options={types}
          loading={loading}
          id="team-gender"
          value={eventType}
          handleChange={(option) => {
            setEventType(option), setDirty(true);
          }}
        />
      </div>
      <div className="form-row">
        <Label inputId="event-startDate" text="Start date" />
        <DatePicker
          onChange={(val) => {
            setDirty(true);
            setStartDate(val);
          }}
          value={startDate}
          disabled={loading}
        />
      </div>
      <div className="form-row">
        <Label inputId="event-endDate" text="End date" />
        <DatePicker
          onChange={(val) => {
            setDirty(true);
            setEndDate(val);
          }}
          value={endDate}
          disabled={loading}
        />
      </div>
      <div className="form-submit">
        <Button
          theme="link"
          type="button"
          disabled={false}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          theme="success"
          type="submit"
          disabled={false}
          onClick={handleSubmit}
        >
          Save edits
        </Button>
      </div>
    </Modal>
  );
}

export default AddEvent;
