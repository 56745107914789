import React, { useState } from 'react';
import { connect } from 'react-redux';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  Notification,
} from 'shared/components';
import { refreshActivePlayers } from 'store/activeTeam/actions';
import { deletePlayer } from './actions';
import './styles.scss';

const DeletePlayerPopup = ({
  activeSeason,
  close,
  isOpen,
  player,
  refreshActivePlayersAction,
  teamId,
}) => {
  const seasonId = get(activeSeason, 'id');
  const [loading, setLoading] = useState(false);

  const handleSubmit = e => {
    const playerId = get(player, 'id');
    e.preventDefault();

    setLoading(true);

    deletePlayer(playerId)
      .then(() => {
        refreshActivePlayersAction(teamId, seasonId);
        Notification('success', 'Player deleted');
        close();
      })
      .catch(() => {
        setLoading(false);
        Notification('error', 'Error occured', 'There was an error while deleting a player');
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      closeCb={close}
      title="Are you sure you want to delete this player from a team?"
      size="md"
    >
      <div className="DeletePlayerPopup">
        <form
          className="DeletePlayerPopup-form"
          onSubmit={handleSubmit}
        >
          <span>
            {`${get(player, 'player.first_name')} ${get(player, 'player.last_name')}`}
          </span>
          <div className="form-submit">
            <Button
              theme="link"
              type="button"
              disabled={loading}
              onClick={close}
            >
              Cancel
            </Button>
            <Button
              theme="error"
              type="submit"
              disabled={loading}
            >
              Delete
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

DeletePlayerPopup.propTypes = {
  activeSeason: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  player: PropTypes.object.isRequired,
  refreshActivePlayersAction: PropTypes.func.isRequired,
  teamId: PropTypes.number.isRequired,
};

const mapDispatchToProps = {
  refreshActivePlayersAction: refreshActivePlayers,
};

export default connect(null, mapDispatchToProps)(DeletePlayerPopup);
