export const validateRestRange = (min, max, nextMin, prevMax) => new Promise((resolve) => {
  let error = '';

  if (!prevMax && !!min) {
    error = 'Previous range or previous max value cannot be empty';
    resolve(error);
  }

  if (!min && !max) {
    resolve(error);
  }

  if (!!min && !max) {
    resolve(error);
  }

  if (!min && !!max) {
    error = 'Min value shoud be defined when there is max value';
    resolve(error);
  }

  if (Number(min) > Number(max)) {
    error = 'Min is greater than max';
    resolve(error);
  }

  if (Number(max) >= Number(nextMin) && !nextMin) {
    error = '';
    resolve(error);
  }

  if (Number(max) >= Number(nextMin)) {
    error = 'Max should be smaller than next day rest min value';
    resolve(error);
  }

  resolve(error);
});

export const validate5DaysRestRange = (min, max, prevMax) => new Promise((resolve) => {
  let error = '';

  if (!prevMax && !!min) {
    error = 'Previous range or previous max value cannot be empty';
    resolve(error);
  }

  if (!!min && !max) {
    resolve(error);
  }

  if (!min && !max) {
    resolve(error);
  }

  if (!min && !!max) {
    error = 'Min value shoud be defined when there is max value';
    resolve(error);
  }

  if (Number(min) > Number(max)) {
    error = 'Min is greater than max';
    resolve(error);
  }

  resolve(error);
});
