/* eslint-disable */
import api from 'shared/api';

export const getTeams = (org) => {
  const url = `/api/v1/teams/?organization=${org}`;
  return api.get(url);
};
export const getSeasons = (org) => {
  const url = `/api/v1/seasons/?organization=${org}`;
  return api.get(url);
};

export const getPlayerStats = (team,location) => {
  const url = `/api/v1/playerstats/?game__team_${location}__id=${team}`;
  return api.get(url);
};