import get from 'lodash.get';

export const checkUserTeam = (userTeams, activeTeamId) => {
  const isUserTeam = userTeams
    .filter(team => get(team, 'team.id') === Number(activeTeamId))
    .filter(Boolean);

  if (!isUserTeam.length) {
    return null;
  }

  return {
    id: get(isUserTeam, '[0].id'),
    role: get(isUserTeam, '[0].role'),
    team: get(isUserTeam, '[0].team'),
  };
};
