/* eslint-disable */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import get from "lodash.get";
import { withRouter } from "react-router-dom";
import { Table, Selector } from "shared/components";
import { displayValue } from "shared/helpers";
import { getLeagueTeams, setActiveDivision } from "store/activeLeague/actions";
import { getSeasons, getPlayerStats } from "./actions";
import "./styles.scss";

const SeasonsList = ({ organizationId }) => {
  const [loading, setLoading] = useState(true);
  const [seasons, setSeasons] = useState();
  const [refetching, setRefetching] = useState(false);

  useEffect(() => {
    setLoading(true);
    getSeasons(organizationId).then((res) => {
      setSeasons(res.data);
      setLoading(false);
    });
  }, []);

  const [seasonFilter, setSeasonFilter] = useState([
    { label: "summer, 2019", id: 1 },
  ]);
  const [pickedSeason, setPickedSeason] = useState(67);

  useEffect(() => {
    if (seasons?.results) {
      let newFilter = [];
      seasons?.results.forEach((t) => {
        newFilter.push({ label: t.year, value: t.id });
      });
      setSeasonFilter(newFilter);
    }
  }, [seasons]);

  const [results, setResults] = useState([]);

  useEffect(() => {
    getPlayerStats(pickedSeason).then((res) => {
      setRefetching(false);
      setResults(res.data.results);
    });
  }, [pickedSeason]);

  return (
    <div className="LeagueTeamsList">
      <div className="LeagueTeamsList-filters">
        <Selector
          options={seasonFilter}
          value={pickedSeason}
          handleChange={(val) => {
            setPickedSeason(val);
            setRefetching(true);
          }}
          disabled={loading}
        />
      </div>
      <div className="teams-container">
        <Table
          data={results}
          noDataText=""
          loading={loading || refetching}
          columns={[
            {
              accessor: "player",
              Header: "Player",
              width: 250,
            },
            {
              accessor: "ab",
              Header: "AB",
              Cell: (cellData) => displayValue(cellData.value),
            },
            {
              accessor: "obp",
              Header: "OBP",
              Cell: (cellData) => displayValue(cellData.value),
            },
            {
              accessor: "era",
              Header: "ERA",
              Cell: (cellData) => displayValue(cellData.value),
            },
            {
              accessor: "ba",
              Header: "BA",
              Cell: (cellData) => displayValue(cellData.value),
            },
          ]}
          clickable
          handleClick={(rowData) => console.log(rowData)}
          minRows={1}
          showPagination={false}
        />
      </div>
    </div>
  );
};

// LeagueTeamsList.propTypes = {
//   activeSeason: PropTypes.object.isRequired,
//   activeDivision: PropTypes.string.isRequired,
//   divisions: PropTypes.array.isRequired,
//   history: PropTypes.object.isRequired,
//   leagueId: PropTypes.number.isRequired,
//   getLeagueTeamsAction: PropTypes.func.isRequired,
//   setActiveDivisionAction: PropTypes.func.isRequired,
//   teams: PropTypes.array.isRequired,
// };

const mapStateToProps = (state) => ({
  teams: get(state, "activeLeague.teams"),
  divisions: get(state, "activeLeague.details.divisions"),
  activeDivision: get(state, "activeLeague.activeDivision"),
});

const mapDispatchToProps = {
  getLeagueTeamsAction: getLeagueTeams,
  setActiveDivisionAction: setActiveDivision,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SeasonsList));
