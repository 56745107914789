import api from 'shared/api';

export const deleteUserTeam = userteamId => api.delete(`/api/v1/userteams/${userteamId}/`);

export const patchToFanRole = (userteamId, userID, teamId) => {
  const data = {
    role: 'fan',
    user: userID,
    team: teamId,
  };

  return api.patch(`/api/v1/userteams/${userteamId}/`, data);
};
