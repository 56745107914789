import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import get from "lodash.get";
import { Table } from "shared/components";
import { displayValue } from "shared/helpers";
import "./styles.scss";

const UserTeams = ({ history, userLeagues }) => {
  const redirectToLeaguePage = (rowData) => {
    const leagueId = get(rowData, "original.id");
    history.push(`/leagues/${leagueId}/`);
  };

  return (
    <div className="UserLeagues">
      <div className="user-leagues-table-container">
        <Table
          data={userLeagues}
          columns={[
            {
              accessor: "name",
              Header: "Name",
              Cell: (cellData) => displayValue(cellData.value),
            },
            {
              accessor: "current_season",
              Header: "Current season",
              Cell: (cellData) => {
                if (!cellData.value || !cellData.value?.year) {
                  return "-";
                }

                return `${cellData.value.year}`;
              },
            },
            {
              accessor: "num_of_teams",
              Header: "Number of teams",
              Cell: (cellData) => displayValue(cellData.value),
            },
            {
              accessor: "num_of_players",
              Header: "Number of players",
              Cell: (cellData) => displayValue(cellData.value),
            },
          ]}
          clickable
          handleClick={(rowData) => redirectToLeaguePage(rowData)}
          minRows={1}
          showPagination={false}
        />
      </div>
    </div>
  );
};

UserTeams.propTypes = {
  history: PropTypes.object.isRequired,
  userLeagues: PropTypes.array.isRequired,
};

export default withRouter(UserTeams);
