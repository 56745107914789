/* eslint-disable */
import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import DatePicker from "react-date-picker";
import get from "lodash.get";
import {
  Button,
  Label,
  Modal,
  Notification,
  NumberInput,
  Selector,
  TextInput,
} from "shared/components";
import {
  defaultDateFormat,
  emailRegex,
  playerPositionOptions,
  playerSideOptions,
} from "shared/constants";
import api from "shared/api";
import { refreshActivePlayers } from "store/activeTeam/actions";
import { createPlayer, addFamilyMember } from "./actions";
import ExistingPlayerPopup from "./components/ExistingPlayerPopup";
import "./styles.scss";

const AddPlayerForm = ({
  activePlayers,
  activeSeason,
  close,
  isOpen,
  refreshActivePlayersAction,
  teamId,
  userId,
  organizationId,
}) => {
  console.log(organizationId);
  const seasonId = get(activeSeason, "id");

  const [loading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [shouldContinueAdding, setShouldContinueAdding] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [jersey, setJersey] = useState("");
  const [jerseyError, setJerseyError] = useState("");
  const [bats, setBats] = useState({});
  const [throws, setThrows] = useState({});
  const [position, setPosition] = useState({});
  const [date, setDate] = useState(new Date(2008, 0, 1));

  const [familyEmail, setFamilyEmail] = useState("");
  const [familyEmailError, setFamilyEmailError] = useState("");

  const [isExistingPlayerPopupDisplayed, setExistingPlayerPopupDisplay] =
    useState(false);
  const [isExistingPlayerPopupLoading, setExistingPlayerPopupLoading] =
    useState(false);
  const [existingPlayers, setExistingPlayers] = useState(null);

  const setInitialState = () => {
    setLoading(false);
    setDirty(false);
    setShouldContinueAdding(false);
    setFirstName("");
    setLastName("");
    setJersey("");
    setJerseyError("");
    setBats({});
    setThrows({});
    setPosition({});
    setFamilyEmail("");
    setFamilyEmailError("");
    setExistingPlayerPopupDisplay(false);
    setExistingPlayerPopupLoading(false);
    setExistingPlayers(null);
  };

  const validateEmail = () => {
    if (!familyEmail) {
      return true;
    }

    return emailRegex.test(familyEmail);
  };

  // check if player with this name and lastname is in roster already
  const checkIfPlayerInRoster = (name, lastname) => {
    const rosterPlayer = activePlayers.find((p) => {
      const fname = get(p, "player.first_name");
      const lname = get(p, "player.last_name");

      return (
        fname.toLowerCase() === name.toLowerCase() &&
        lname.toLowerCase() === lastname.toLowerCase()
      );
    });

    return rosterPlayer;
  };

  const isJerseyNrOccupied = () => {
    if (!jersey) {
      return setJerseyError("This field is required");
    }

    return activePlayers.find((p) => get(p, "jersey_nr") === Number(jersey));
  };

  const createNewPlayer = (shouldResetAfter) => {
    const dobDate = date ? moment(date).format(defaultDateFormat) : undefined;

    const data = {
      first_name: firstName,
      last_name: lastName,
      throws: throws.value || "",
      bats: bats.value || "",
      position: position.value || "",
      date_of_birth: dobDate,
      organization: organizationId,
      playerteamhistory: {
        team: teamId,
        season: seasonId,
        jersey_nr: jersey,
      },
      user: userId,
    };
    // create new Player and adding to playerteamhistory
    setExistingPlayerPopupDisplay(false);
    createPlayer(data)
      .then((res) => {
        const playerId = get(res, "data.id");

        if (familyEmail) {
          addFamilyMember(playerId, familyEmail);
        }
        refreshActivePlayersAction(teamId, seasonId);
        Notification("success", "New player added to team");
        if (!shouldResetAfter) {
          return close();
        }

        return setInitialState();
      })
      .catch(() => {
        setLoading(false);
        Notification(
          "error",
          "Error occured",
          "There was an error while adding a new player"
        );
      });
  };

  // adding existing player to playerteamhistory
  const addExistingPlayer = (player) => {
    const data = {
      team: teamId,
      season: seasonId,
      jersey_nr: jersey,
      player: get(player, "id"),
    };

    setExistingPlayerPopupLoading(true);

    api
      .post("/api/v1/playerteamhistories/", data)
      .then((res) => {
        const playerId = get(res, "data.player");
        if (familyEmail) {
          addFamilyMember(playerId, familyEmail);
        }

        setExistingPlayerPopupDisplay(false);
        refreshActivePlayersAction(teamId, seasonId);
        Notification("success", "New player added to team");
        if (!shouldContinueAdding) {
          return close();
        }

        return setInitialState();
      })
      .catch(() => {
        setExistingPlayerPopupLoading(false);
        Notification(
          "error",
          "Error occured",
          "There was an error while adding a new player"
        );
      });
  };

  const checkPlayer = (name, lastname, shouldResetAfter) => {
    api
      .get(`/api/v1/players/?first_name=${name}&last_name=${lastname}`)
      .then((res) => {
        const count = get(res, "data.count");

        if (count > 0) {
          setExistingPlayers(get(res, "data.results"));
          return setExistingPlayerPopupDisplay(true);
        }

        return createNewPlayer(shouldResetAfter);
      })
      .catch(() => {
        setLoading(false);
        Notification("error", "Error occured", "Try again later");
      });
  };

  const isFormValid = () => {
    const isEmailValid = validateEmail();
    const isJerseyDuplicate = isJerseyNrOccupied();

    if (!isEmailValid) {
      setFamilyEmailError("Enter valid email");
    }

    if (isJerseyNrOccupied()) {
      setJerseyError("This jersey number is already in use");
    }

    if (!firstName) {
      setFirstNameError("This field is required");
    }

    if (!lastName) {
      setLastNameError("This field is required");
    }

    return isEmailValid && !isJerseyDuplicate && !!firstName && !!lastName;
  };

  const handleSubmit = (e, shouldResetAfter) => {
    e.preventDefault();

    if (shouldResetAfter) {
      setShouldContinueAdding(shouldResetAfter);
    }

    const isValid = isFormValid();
    if (!isValid) {
      return false;
    }

    setLoading(true);
    setFamilyEmailError("");
    setJerseyError("");
    setFirstNameError("");
    setLastNameError("");

    const isInRoster = checkIfPlayerInRoster(firstName, lastName);

    if (isInRoster) {
      setLoading(false);
      return Notification(
        "error",
        "Error occured",
        "Player with same name is already added to team roster"
      );
    }

    // check if existing player using name and lastname
    return checkPlayer(firstName, lastName, shouldResetAfter);
  };

  const handleClose = () => {
    if (
      !dirty ||
      window.confirm("Are you sure you want to discard your changes?")
    ) {
      close();
    }
  };

  return (
    <Modal isOpen={isOpen} closeCb={handleClose} title="Add a player">
      <div className="AddPlayerForm">
        <form className="AddPlayerForm-form" onSubmit={() => {}}>
          <div className="form-left">
            <div className="form-row">
              <Label inputId="player-jersey" text="Jersey number *" />
              <NumberInput
                id="player-jersey"
                value={jersey}
                error={jerseyError}
                min="1"
                max="1000"
                handleChange={(val) => {
                  setDirty(true);
                  setJersey(val);
                }}
                placeholder="Jersey number"
                disabled={loading}
                required
              />
            </div>
            <div className="form-row">
              <Label inputId="player-firstname" text="First name *" />
              <TextInput
                id="player-firstname"
                value={firstName}
                error={firstNameError}
                handleChange={(val) => {
                  setDirty(true);
                  setFirstName(val);
                }}
                placeholder="First name"
                disabled={loading}
                required
              />
            </div>
            <div className="form-row">
              <Label inputId="player-lastname" text="Last name *" />
              <TextInput
                id="player-lastname"
                value={lastName}
                error={lastNameError}
                handleChange={(val) => {
                  setDirty(true);
                  setLastName(val);
                }}
                placeholder="Last name"
                disabled={loading}
                required
              />
            </div>
            <div className="form-row">
              <Label inputId="player-family" text="Add family member email" />
              <TextInput
                id="player-family"
                value={familyEmail}
                error={familyEmailError}
                handleChange={(val) => {
                  setDirty(true);
                  setFamilyEmail(val);
                }}
                placeholder="Enter email"
                disabled={loading}
              />
            </div>
          </div>
          <div className="form-right">
            <div className="form-row">
              <Label text="Date of birth" inputId="player-dob" />
              <DatePicker
                onChange={(val) => {
                  setDirty(true);
                  setDate(val);
                }}
                value={date}
                disabled={loading}
              />
            </div>
            <div className="form-row">
              <Label inputId="player-position" text="Position" />
              <Selector
                options={playerPositionOptions}
                value={get(position, "value") || ""}
                handleChange={(val) => {
                  setDirty(true);
                  const selected = playerPositionOptions.find(
                    (option) => option.value === val
                  );
                  setPosition(selected || {});
                }}
                disabled={loading}
              />
            </div>
            <div className="form-row">
              <Label inputId="player-throws" text="Throws" />
              <Selector
                options={playerSideOptions}
                value={get(throws, "value") || ""}
                handleChange={(val) => {
                  setDirty(true);
                  const selected = playerSideOptions.find(
                    (option) => option.value === val
                  );
                  setThrows(selected || {});
                }}
                disabled={loading}
              />
            </div>
            <div className="form-row">
              <Label inputId="player-bats" text="Bats" />
              <Selector
                options={playerSideOptions}
                value={get(bats, "value") || ""}
                handleChange={(val) => {
                  setDirty(true);
                  const selected = playerSideOptions.find(
                    (option) => option.value === val
                  );
                  setBats(selected || {});
                }}
                disabled={loading}
              />
            </div>
          </div>
          <div className="form-submit">
            <Button
              theme="link"
              type="button"
              disabled={loading}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              // theme="success"
              // type="submit"
              disabled={loading}
              onClick={(e) => handleSubmit(e, false)}
            >
              Save
            </Button>
            <Button
              theme="success"
              // type="submit"
              disabled={loading}
              onClick={(e) => handleSubmit(e, true)}
            >
              Save and add another
            </Button>
          </div>
        </form>
      </div>
      {isExistingPlayerPopupDisplayed && (
        <ExistingPlayerPopup
          isOpen={isExistingPlayerPopupDisplayed}
          close={() => {
            setExistingPlayerPopupDisplay(false);
            return setLoading(false);
          }}
          players={existingPlayers}
          addExistingPlayer={addExistingPlayer}
          loading={isExistingPlayerPopupLoading}
          createNewPlayer={createNewPlayer}
        />
      )}
    </Modal>
  );
};

AddPlayerForm.propTypes = {
  activePlayers: PropTypes.array.isRequired,
  activeSeason: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  refreshActivePlayersAction: PropTypes.func.isRequired,
  teamId: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  activePlayers: get(state, "activeTeam.players"),
  organizationId: state.user.details.organizations[0]?.id,
});

const mapDispatchToProps = {
  refreshActivePlayersAction: refreshActivePlayers,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPlayerForm);
