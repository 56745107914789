import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash.get';
import {
  SeasonSelector,
} from 'shared/components';
import './styles.scss';

const displayTeamHeaderContent = team => (
  <>
    <h3 className="PageHeader-primary-title">{get(team, 'name')}</h3>
    <div className="PageHeader-primary-content">
      {get(team, 'city') ? <span>{get(team, 'city')}</span> : null}
      {get(team, 'state') ? <span>{get(team, 'state')}</span> : null}
      {get(team, 'league.name') ? <span>{get(team, 'league.name')}</span> : null}
      {get(team, 'division') ? <span>{get(team, 'division')}</span> : null}
    </div>
  </>
);

const displayLeagueHeaderContent = team => (
  <>
    <h3 className="PageHeader-primary-title">{get(team, 'league.name')}</h3>
  </>
);

const PageHeader = ({
  content,
  disabledSeason,
  team,
}) => (
  <div className="PageHeader">
    <div className="PageHeader-primary">
      {content === 'team' && displayTeamHeaderContent(team)}
      {content === 'league' && displayLeagueHeaderContent(team)}
    </div>
    <div className="PageHeader-secondary">
      <SeasonSelector disabledSeason={disabledSeason} />
    </div>
  </div>
);

PageHeader.propTypes = {
  content: PropTypes.oneOf(['team', 'league']),
  disabledSeason: PropTypes.bool,
  team: PropTypes.object,
};

PageHeader.defaultProps = {
  content: 'team',
  disabledSeason: false,
  team: null,
};

const mapStateToProps = state => ({
  team: get(state, 'activeTeam.details'),
});

export default connect(mapStateToProps)(PageHeader);
