import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import {
  NavLink,
  withRouter,
  Switch,
  Route,
} from 'react-router-dom';
import {
  ContentLoader,
  NotFound,
  PageHeader,
} from 'shared/components';
import GamePlays from '../GamePlays';
import GameBoxScore from '../GameBoxScore';
import GameStatsAway from '../GameStatsAway';
import GameStatsHome from '../GameStatsHome';
import GameHighlights from '../GameHighlights';
import GameScorebook from '../GameScorebook';
import GameHeader from './components/GameHeader';
import { isAwayGame } from './helpers';
import { getGame } from './actions';
import './styles.scss';

const GameContainer = ({
  activeTeam,
  activeSeason,
  match,
}) => {
  const teamId = get(activeTeam, 'id');
  const gameId = get(match, 'params.gameId');

  const [loading, setLoading] = useState(true);
  const [game, setGame] = useState(null);

  const fetchGameData = () => {
    getGame(gameId)
      .then(res => {
        const gameData = get(res, 'data');
        setGame(gameData);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchGameData();
  }, []);

  if (loading) {
    return (
      <div className="GameContainer">
        <PageHeader />
        <ContentLoader text="Getting game data" />
      </div>
    );
  }

  if (!game) {
    return <NotFound />;
  }

  const isAway = isAwayGame(game, activeTeam);
  const opponent = isAway ? get(game, 'team_home') : get(game, 'team_away');
  const opponentResult = isAway ? get(game, 'r_home') : get(game, 'r_away');
  const activeTeamResult = isAway ? get(game, 'r_away') : get(game, 'r_home');

  return (
    <div className="GameContainer">
      <PageHeader />
      <div className="GameContainer-breadcrumb">
        <NavLink to={`/${teamId}/games`}>
          <span>&#8249;</span>
          back to games list
        </NavLink>
      </div>
      <GameHeader
        activeTeam={activeTeam}
        activeTeamResult={activeTeamResult}
        isAway={isAway}
        opponent={opponent}
        opponentResult={opponentResult}
        game={game}
      />
      <div className="GameContainer-menu">
        <NavLink exact to={`/${teamId}/games/${gameId}`}>Highlights</NavLink>
        <NavLink to={`/${teamId}/games/${gameId}/box-score`}>Box Score</NavLink>
        {/* <NavLink to={`/${teamId}/games/${gameId}/stats-away`}>Away Stats</NavLink>
        <NavLink to={`/${teamId}/games/${gameId}/stats-home`}>Home Stats</NavLink> */}
        <NavLink to={`/${teamId}/games/${gameId}/plays`}>Plays</NavLink>
        {/* <NavLink to={`/${teamId}/games/${gameId}/scorebook`}>Scorebook</NavLink> */}
      </div>
      <Switch>
        <Route
          exact
          path="/:teamId/games/:gameId"
          render={() => (
            <GameHighlights
              fetchGameData={fetchGameData}
              game={game}
              teamId={teamId}
              activeSeason={activeSeason}
            />
          )}
        />
        <Route
          path="/:teamId/games/:gameId/box-score"
          render={() => <GameBoxScore game={game} teamId={teamId} />}
        />
        <Route
          path="/:teamId/games/:gameId/stats-away"
          render={() => (
            <GameStatsAway
              team={get(game, 'team_away')}
              teamId={teamId}
              gameId={Number(gameId)}
            />
          )}
        />
        <Route
          path="/:teamId/games/:gameId/stats-home"
          render={() => (
            <GameStatsHome
              team={get(game, 'team_home')}
              teamId={teamId}
              gameId={Number(gameId)}
            />
          )}
        />
        <Route
          exact
          path="/:teamId/games/:gameId/plays"
          render={() => <GamePlays gameId={Number(gameId)} teamId={teamId} fetchGameData={fetchGameData} />}
        />
        <Route
          exact
          path="/:teamId/games/:gameId/scorebook"
          render={() => <GameScorebook teamId={teamId} />}
        />
        <Route
          render={() => <NotFound />}
        />
      </Switch>
    </div>
  );
};

GameContainer.propTypes = {
  activeTeam: PropTypes.object.isRequired,
  activeSeason: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  activeTeam: get(state, 'activeTeam.details'),
  activeSeason: get(state, 'activeTeam.activeSeason'),
});

export default connect(mapStateToProps)(withRouter(GameContainer));
