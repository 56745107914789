export const mockStandard = {
  data: [
    {
      jersey_nr: 12,
      player: 'John Doe',
      pa: 4,
      ab: 1,
      h: 0,
      tb: 4,
      '1b': 1,
      '2b': 2,
      '3b': 2,
      hr: 0,
      rbi: 0,
      r: 2,
      bb: 1,
      so: 2,
      kl: 5,
    },
  ],
  total: {
    pa: 4,
    ab: 1,
    h: 0,
    tb: 4,
    '1b': 1,
    '2b': 2,
    '3b': 2,
    hr: 0,
    rbi: 0,
    r: 2,
    bb: 1,
    so: 2,
    kl: 5,
  },
};

export const mockBaserunning = {
  data: [
    {
      jersey_nr: 12,
      player: 'John Doe',
      sb: 2,
      cs: 1,
      pik: 0,
    },
  ],
  total: {
    sb: 2,
    cs: 1,
    pik: 0,
  },
};

export const mockQab = {
  data: [
    {
      jersey_nr: 12,
      player: 'John Doe',
      ab: 2,
      ps: 1,
      pspa: 2.350,
      c: 0.500,
      '2s3': 1,
      '6+': 1,
      hhb: 0,
      qab: 3,
    },
  ],
  total: {
    ab: 2,
    ps: 1,
    pspa: 2.350,
    c: 0.500,
    '2s3': 1,
    '6+': 1,
    hhb: 0,
    qab: 3,
  },
};

export const mockTeamImpact = {
  data: [
    {
      jersey_nr: 12,
      player: 'John Doe',
      ab: 2,
      lob: 1,
      gidp: 2.350,
      xbh: 0.500,
      hrisp: 1,
      sac: 1,
      sf: 0,
      '2outrbi': 3,
      babip: 0.750,
    },
  ],
  total: {
    ab: 2,
    lob: 1,
    gidp: 2.350,
    xbh: 0.500,
    hrisp: 1,
    sac: 1,
    sf: 0,
    '2outrbi': 3,
    babip: 0.750,
  },
};


export const mockSituational = {
  data: [
    {
      jersey_nr: 12,
      player: 'John Doe',
      hbp: 2,
      fc: 1,
      roe: 0,
      abrisp: 0,
      hrisp: 1,
      ps: 15,
      sw: 12,
      sm: 3,
      ci: 0,
      flb: '50.00%',
      gb: '10.00%',
    },
  ],
  total: {
    hbp: 2,
    fc: 1,
    roe: 0,
    abrisp: 0,
    hrisp: 1,
    ps: 15,
    sw: 12,
    sm: 3,
    ci: 0,
    flb: '50.00%',
    gb: '10.00%',
  },
};
