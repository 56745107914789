import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import {
  Button,
  Label,
  Modal,
  Notification,
  Selector,
  NumberInput,
  TextInput,
} from 'shared/components';
import {
  ageOptions,
  countryOptions,
  genderOptions,
} from 'shared/constants';
import {
  getGenderLabel,
  getAgeLabel,
  getCountryLabel,
} from 'shared/helpers';
import { refreshActiveTeam } from 'store/activeTeam/actions';
import { editTeam, getLeaguesList } from './actions';
import {
  mapLeagueToSelector,
  mapLeaguesListToSelector,
} from './helpers';
import './styles.scss';

const EditTeamForm = ({
  close,
  isOpen,
  team,
  refreshActiveTeamAction,
}) => {
  const isMounted = React.useRef(true);

  const [loading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [teamName, setTeamName] = useState(get(team, 'name'));
  const [teamCoach, setTeamCoach] = useState(get(team, 'head_coach'));
  const [teamAbbr, setTeamAbbr] = useState(get(team, 'abbreviation'));
  const [teamInnings, setTeamInnings] = useState(get(team, 'innings_per_game'));
  const [teamGender, setTeamGender] = useState({
    value: get(team, 'gender'),
    label: getGenderLabel(get(team, 'gender')),
  });
  const [teamAge, setTeamAge] = useState({
    value: get(team, 'age_group'),
    label: getAgeLabel(get(team, 'age_group')),
  });
  const [teamLeague, setTeamLeague] = useState(mapLeagueToSelector(get(team, 'league')));
  const [teamCountry, setTeamCountry] = useState({
    value: get(team, 'country'),
    label: getCountryLabel(get(team, 'country')),
  });
  const [teamZip, setTeamZip] = useState(get(team, 'zipcode'));
  const [teamCity, setTeamCity] = useState(get(team, 'city'));
  const [teamState, setTeamState] = useState(get(team, 'state'));
  const [teamDivision, setTeamDivision] = useState(get(team, 'division'));

  const [leaguesList, setLeaguesList] = useState([]);

  const fetchLeagues = () => {
    getLeaguesList()
      .then(res => {
        const leagues = get(res, 'data.results') || [];
        if (isMounted.current) {
          setLeaguesList(leagues);
        }
      })
      .catch(() => {
        setLeaguesList([]);
      });
  };

  useEffect(() => {
    fetchLeagues();

    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleTeamGenderSelect = val => {
    const selected = genderOptions.find(option => option.value === val);
    setDirty(true);
    setTeamGender(selected);
  };

  const handleTeamAgeSelect = val => {
    const selected = ageOptions.find(option => option.value === val);
    setDirty(true);
    setTeamAge(selected);
  };

  const handleTeamCountrySelect = val => {
    const selected = countryOptions.find(option => option.value === val);
    setDirty(true);
    setTeamCountry(selected);
  };

  const handleTeamLeagueSelect = val => {
    const selected = mapLeaguesListToSelector(leaguesList).find(option => option.value === val);
    setDirty(true);
    setTeamLeague(selected);
  };

  const handleSubmit = e => {
    e.preventDefault();
    const teamId = get(team, 'id');

    setLoading(true);

    const data = {
      name: teamName,
      head_coach: teamCoach,
      abbreviation: teamAbbr,
      gender: teamGender.value,
      age_group: teamAge.value,
      innings_per_game: Number(teamInnings),
      country: teamCountry.value,
      city: teamCity,
      zipcode: teamZip,
      state: teamState,
      league: teamLeague.value,
      division: teamDivision,
    };

    editTeam(teamId, data)
      .then(() => {
        setLoading(false);
        refreshActiveTeamAction(teamId);
        close();
        Notification('success', 'Team edited');
      })
      .catch(() => {
        setLoading(false);
        Notification('error', 'Error occured', 'There was an error while editing a team');
      });
  };

  const handleClose = () => {
    if (
      !dirty ||
      window.confirm('Are you sure you want to discard your changes?')
    ) {
      close();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      closeCb={handleClose}
      title="Edit team"
      size="lg"
    >
      <div className="EditTeamForm">
        <form
          className="EditTeamForm-form"
          onSubmit={handleSubmit}
        >
          <div className="form-container">
            <div className="form-column left">
              <div className="form-row">
                <Label inputId="team-name" text="Team name" />
                <TextInput
                  id="team-name"
                  value={teamName}
                  handleChange={val => {
                    setDirty(true);
                    setTeamName(val);
                  }}
                />
              </div>
              <div className="form-row">
                <Label inputId="team-coach" text="Head coach" />
                <TextInput
                  id="team-coach"
                  value={teamCoach}
                  handleChange={val => {
                    setDirty(true);
                    setTeamCoach(val);
                  }}
                />
              </div>
              <div className="form-row">
                <Label inputId="team-abbr" text="Team abbreviation" />
                <TextInput
                  id="team-abbr"
                  value={teamAbbr}
                  handleChange={val => {
                    setDirty(true);
                    setTeamAbbr(val);
                  }}
                />
              </div>
              <div className="form-row">
                <Label inputId="team-innings" text="Innings per game" />
                <NumberInput
                  min="1"
                  max="20"
                  id="team-innings"
                  value={teamInnings}
                  handleChange={val => {
                    setDirty(true);
                    setTeamInnings(val);
                  }}
                />
              </div>
              <div className="form-row">
                <Label inputId="team-gender" text="Gender" />
                <Selector
                  options={genderOptions}
                  id="team-gender"
                  value={teamGender.value}
                  handleChange={option => handleTeamGenderSelect(option)}
                />
              </div>
              <div className="form-row">
                <Label inputId="team-age" text="Age group" />
                <Selector
                  options={ageOptions}
                  id="team-age"
                  value={teamAge.value}
                  handleChange={option => handleTeamAgeSelect(option)}
                />
              </div>
            </div>
            <div className="form-column right">
              <div className="form-row">
                <Label inputId="team-league" text="League / Conference" />
                <Selector
                  id="team-league"
                  loading={!leaguesList.length}
                  options={mapLeaguesListToSelector(leaguesList)}
                  value={teamLeague.value}
                  handleChange={val => handleTeamLeagueSelect(val)}
                />
              </div>
              <div className="form-row">
                <Label inputId="team-division" text="Division (optional)" />
                <TextInput
                  id="team-division"
                  value={teamDivision}
                  handleChange={val => setTeamDivision(val)}
                />
              </div>
              <div className="form-row">
                <Label inputId="team-country" text="Country" />
                <Selector
                  options={countryOptions}
                  id="team-country"
                  value={teamCountry.value}
                  handleChange={option => handleTeamCountrySelect(option)}
                />
              </div>
              <div className="form-row">
                <Label inputId="team-zip" text="Zip code" />
                <TextInput
                  id="team-zip"
                  placeholder="eq. 11201"
                  value={teamZip}
                  handleChange={val => {
                    setDirty(true);
                    setTeamZip(val);
                  }}
                />
              </div>
              <div className="form-row">
                <Label inputId="team-city" text="City" />
                <TextInput
                  id="team-city"
                  value={teamCity}
                  handleChange={val => {
                    setDirty(true);
                    setTeamCity(val);
                  }}
                />
              </div>
              <div className="form-row">
                <Label inputId="team-state" text="State" />
                <TextInput
                  id="team-state"
                  value={teamState}
                  handleChange={val => {
                    setDirty(true);
                    setTeamState(val);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="form-submit">
            <Button
              theme="link"
              type="button"
              disabled={loading}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              theme="success"
              type="submit"
              disabled={loading}
            >
              Save edits
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

EditTeamForm.propTypes = {
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  team: PropTypes.object.isRequired,
  refreshActiveTeamAction: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  refreshActiveTeamAction: refreshActiveTeam,
};

export default connect(null, mapDispatchToProps)(EditTeamForm);
