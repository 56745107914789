import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import RTable from 'react-table';
import {
  displayValue,
} from 'shared/helpers';
import './styles.scss';

const mapInningsToTable = (game, home, away) => {
  const homeObj = {};
  const awayObj = {};

  home.forEach((r, i) => { homeObj[`inn_${i + 1}`] = r; });
  homeObj.r = get(game, 'r_home') || 0;
  away.forEach((r, i) => { awayObj[`inn_${i + 1}`] = r; });
  awayObj.r = get(game, 'r_away') || 0;

  return [awayObj, homeObj];
};

const getScores = game => {
  const homeInnings = get(game, 'r_by_inning_home') || [];
  const awayInnings = get(game, 'r_by_inning_away') || [];

  const results = mapInningsToTable(game, homeInnings, awayInnings);
  return results;
};

const GameResultsTable = ({
  game,
}) => {
  const scores = getScores(game);

  return (
    <div className="GameResultsTable">
      <RTable
        data={scores}
        columns={[
          {
            accessor: 'inn_1',
            Header: '1',
            minWidth: 50,
            Cell: cellData => displayValue(cellData.value, 'X'),
          },
          {
            accessor: 'inn_2',
            Header: '2',
            minWidth: 50,
            Cell: cellData => displayValue(cellData.value, 'X'),
          },
          {
            accessor: 'inn_3',
            Header: '3',
            minWidth: 50,
            Cell: cellData => displayValue(cellData.value, 'X'),
          },
          {
            accessor: 'inn_4',
            Header: '4',
            minWidth: 50,
            Cell: cellData => displayValue(cellData.value, 'X'),
          },
          {
            accessor: 'inn_5',
            Header: '5',
            minWidth: 50,
            Cell: cellData => displayValue(cellData.value, 'X'),
          },
          {
            accessor: 'inn_6',
            Header: '6',
            minWidth: 50,
            Cell: cellData => displayValue(cellData.value, 'X'),
          },
          {
            accessor: 'r',
            Header: 'R',
            minWidth: 50,
            Cell: cellData => displayValue(cellData.value, 'X'),
            className: 'border',
            headerClassName: 'border',
          },
          {
            accessor: 'h',
            Header: 'H',
            minWidth: 50,
            Cell: cellData => displayValue(cellData.value, 'X'),
          },
          {
            accessor: 'e',
            Header: 'E',
            minWidth: 50,
            Cell: cellData => displayValue(cellData.value, 'X'),
          },
        ]}
        noDataText=""
        minRows={2}
        showPagination={false}
        sortable={false}
        resizable={false}
      />
    </div>
  );
};

GameResultsTable.propTypes = {
  game: PropTypes.object.isRequired,
};

export default GameResultsTable;
