/* eslint-disable */
import React from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone-uploader';
import './styles.scss';
import 'react-dropzone-uploader/dist/styles.css';

const FileUploader = ({
  disabled,
  label,
  multipleFiles,
  // getUploadParams,
  maxFiles,
  onWrongFileTypeReject,
  onUploadFail,
  onUploadSuccess,
  onUploadStart,
  onItemRemove,
  onSubmit,
  validFileTypes,
  progress,
  onPreview,
  // ...rest
}) => {
  const handleChangeStatus = ({ meta }, status) => {
    switch (status) {
      case 'started':
        return onUploadStart(meta);
      case 'removed':
        return onItemRemove(meta);
      case 'rejected_file_type':
        return onWrongFileTypeReject(meta);
      case 'headers_received':
        return onUploadSuccess(meta);
      case 'aborted':
        return onUploadFail(meta);
      case 'done':
        return onPreview(meta);
      default:
        return undefined;
    }
  };

  const handleSubmit = (files, allFiles) => {
    // console.log(files.map(f => f.meta));
    onSubmit(get(allFiles, '[0]'));
  };

  const inputContent = () => (
    <div className="InputContent" key={label}>
      <div className="title">{label}</div>
      <div className="description">
        File types:
        <span>{validFileTypes}</span>
      </div>
    </div>
  );

  return (
    <div className="FileUploader">
      {/* <Dropzone
        disabled={disabled}
        getUploadParams={getUploadParams}
        onChangeStatus={handleChangeStatus}
        inputWithFilesContent={null}
        maxFiles={maxFiles}
        multiple={multipleFiles}
        accept={validFileTypes}
        inputContent={inputContent}
        {...rest}
      /> */}
      <div className="progress-bar">
        <div className="progress-bar-fill" style={{ width: `${Math.max(0, progress)}%` }} />
      </div>
      <Dropzone
        disabled={disabled}
        onChangeStatus={handleChangeStatus}
        onSubmit={handleSubmit}
        maxFiles={maxFiles}
        multiple={multipleFiles}
        accept={validFileTypes}
        inputWithFilesContent={null}
        inputContent={inputContent}
        submitButtonDisabled={false}
      />
    </div>
  );
};

FileUploader.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  disabled: PropTypes.bool,
  // getUploadParams: PropTypes.func.isRequired,
  maxFiles: PropTypes.number,
  multipleFiles: PropTypes.bool,
  onItemRemove: PropTypes.func,
  onUploadFail: PropTypes.func,
  onUploadStart: PropTypes.func,
  onUploadSuccess: PropTypes.func,
  onSubmit: PropTypes.func,
  onWrongFileTypeReject: PropTypes.func,
  progress: PropTypes.number,
  validFileTypes: PropTypes.string,
  onPreview: PropTypes.func,
};

FileUploader.defaultProps = {
  label: 'Drag files or click to browse',
  disabled: false,
  maxFiles: 1,
  multipleFiles: false,
  onItemRemove: () => { },
  onUploadFail: () => { },
  onUploadSuccess: () => { },
  onUploadStart: () => { },
  onSubmit: () => { },
  onWrongFileTypeReject: () => { },
  onPreview: () => { },
  progress: null,
  validFileTypes: '.csv',
};

export default FileUploader;
