import React from "react";
import PropTypes from "prop-types";
import { InputErrorMessage, LoadingIndicator } from "shared/components";
import "./styles.scss";

const TextInput = ({
  disabled,
  error,
  handleChange,
  loading,
  type,
  ...rest
}) => {
  const handleInputChange = (e) => handleChange(e.target.value);

  return (
    <div className="TextInput">
      <input
        className={error ? "has-error" : ""}
        type={type}
        disabled={disabled}
        onChange={handleInputChange}
        {...rest}
      />
      {loading && <LoadingIndicator />}
      <InputErrorMessage text={error} />
    </div>
  );
};

TextInput.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  type: PropTypes.string,
};

TextInput.defaultProps = {
  disabled: false,
  error: "",
  loading: false,
  type: "text",
};

export default TextInput;
