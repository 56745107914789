import React, { useState } from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  Notification,
} from 'shared/components';
import { unfollowTeam } from './actions';
import './styles.scss';

const DeleteUserTeamPopup = ({
  close,
  isOpen,
  refreshUserTeamsAction,
  team,
  userId,
}) => {
  const teamId = get(team, 'id');
  const teamName = get(team, 'team.name');

  const [loading, setLoading] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();

    setLoading(true);

    unfollowTeam(teamId)
      .then(() => {
        refreshUserTeamsAction(userId);
        Notification('success', 'Team succesfully unfollowed');
        close();
      })
      .catch(() => {
        setLoading(false);
        Notification('error', 'Error occured', 'There was an error while unfollowing a team');
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      closeCb={close}
      title="Are you sure you want to unfollow this team?"
      size="md"
    >
      <div className="DeleteUserTeamPopup">
        <form
          className="DeleteUserTeamPopup-form"
          onSubmit={handleSubmit}
        >
          <span>
            {teamName}
          </span>
          <div className="form-submit">
            <Button
              theme="link"
              type="button"
              disabled={loading}
              onClick={close}
            >
              Cancel
            </Button>
            <Button
              theme="error"
              type="submit"
              disabled={loading}
            >
              Unfollow
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

DeleteUserTeamPopup.propTypes = {
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  refreshUserTeamsAction: PropTypes.func.isRequired,
  team: PropTypes.object.isRequired,
  userId: PropTypes.number.isRequired,
};

export default DeleteUserTeamPopup;
