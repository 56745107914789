import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import {
  Route,
  Switch,
} from 'react-router-dom';
import {
  ContentLoader,
  PageHeader,
} from 'shared/components';
import { getActivePlayers } from 'store/activeTeam/actions';
import PlayerContainer from '../pages/PlayerContainer';
import PlayersList from '../pages/PlayersList';
import {
  getPlayers,
} from './actions';

const PlayersDashboard = ({
  activeSeason,
  players,
  getActivePlayersAction,
  team,
}) => {
  const [loading, setLoading] = useState(true);
  const teamId = get(team, 'id');
  const seasonId = get(activeSeason, 'id');

  const isMounted = React.useRef(true);

  const fetchPlayers = () => {
    setLoading(true);

    getPlayers(teamId, seasonId)
      .then(res => {
        if (isMounted.current) {
          const data = get(res, 'data.results') || [];
          getActivePlayersAction(data);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchPlayers();
  }, [seasonId]);

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  if (loading) {
    return (
      <div className="PlayersDashboard">
        <PageHeader disabledSeason />
        <ContentLoader text="Getting team players list" />
      </div>
    );
  }

  return (
    <div className="PlayersDashboard">
      <Switch>
        <Route
          exact
          path="/:teamId/players"
          render={() => <PlayersList team={team} players={players} activeSeason={activeSeason} />}
        />
        <Route
          path="/:teamId/players/:playerId"
          render={() => <PlayerContainer activeSeason={activeSeason} />}
        />
      </Switch>
    </div>
  );
};

PlayersDashboard.propTypes = {
  activeSeason: PropTypes.object.isRequired,
  players: PropTypes.array.isRequired,
  getActivePlayersAction: PropTypes.func.isRequired,
  team: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  players: get(state, 'activeTeam.players'),
});

const mapDispatchToProps = {
  getActivePlayersAction: getActivePlayers,
};

export default connect(mapStateToProps, mapDispatchToProps)(PlayersDashboard);
