import React from 'react';
import PropTypes from 'prop-types';
import {
  NotAuthorized,
} from 'shared/components';
import {
  isAdmin,
} from 'shared/helpers';
import Scorebook from './components/Scorebook';
import './styles.scss';

const GameScorebook = ({
  teamId,
}) => {
  const isAuth = isAdmin(teamId);

  if (!isAuth) {
    return <NotAuthorized message="Only team admins can see game stats" />;
  }

  return (
    <div className="GameScorebook">
      <div className="GameScorebook-container">
        <Scorebook title="Home Scorebook Actions" />
        <Scorebook title="Away Scorebook Actions" />
      </div>
    </div>
  );
};

GameScorebook.propTypes = {
  teamId: PropTypes.number.isRequired,
};

export default GameScorebook;
