import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { withRouter } from 'react-router-dom';
import api from 'shared/api';
import {
  Button,
  IconDelete,
  PageHeader,
  Table,
  Tab,
  Tabs,
  TabContent,
  TabsHeader,
  TextInput,
  Selector,
  Notification,
} from 'shared/components';
import {
  displayValue,
  isAdmin,
  isEditableSeason,
  isPlayersFamily,
} from 'shared/helpers';
import {
  playerPositionOptions,
  playerSideOptions,
} from 'shared/constants';
import { getActivePlayers } from 'store/activeTeam/actions';
import AddPlayerForm from './components/AddPlayerForm';
import DeletePlayerPopup from './components/DeletePlayerPopup';
import RuleAlert from './components/RuleAlert';
import Lineup from './components/Lineup';
import {
  getPlayers,
} from '../../PlayersDashboard/actions';
import './styles.scss';

const PlayersList = ({
  activeSeason,
  getActivePlayersAction,
  history,
  team,
  players,
  userId,
  seasonId,
  seasons,
}) => {
  const teamId = get(team, 'id');
  const isAuth = isAdmin(teamId);
  const isMounted = React.useRef(true);

  const [loading, setLoading] = useState(false);
  const [isAddPlayerModalDisplayed, setAddPlayerModalDisplay] = useState(false);
  const [playerToDelete, setPlayerToDelete] = useState(null);
  const [isDeletePlayerModalDisplayed, setDeletePlayerModalDisplay] = useState(false);

  const fetchPlayers = () => {
    getPlayers(teamId, seasonId)
      .then(res => {
        if (isMounted.current) {
          const data = get(res, 'data.results') || [];
          getActivePlayersAction(data);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const redirectToPlayerPage = rowData => {
    const playerId = get(rowData, 'original.player.id');

    if (!isAuth && !isPlayersFamily(playerId)) {
      return Notification(
        'error',
        'You are not authorized for this action',
        'Only admin or family members can view players stats',
      );
    }

    return history.push(`/${teamId}/players/${playerId}`);
  };

  const handlePlayerDelete = index => {
    if (!isAuth) {
      return Notification(
        'error',
        'You are not authorized for this action',
        'Only admin can delete players from the team',
      );
    }

    if (!isEditableSeason(activeSeason.id)) {
      return Notification(
        'error',
        'Action not available for past seasons',
      );
    }

    const selected = players[index];

    setPlayerToDelete(selected);
    return setDeletePlayerModalDisplay(true);
  };

  const handlePlayerAdd = () => {
    if (!isAuth) {
      return Notification(
        'error',
        'You are not authorized for this action',
        'Only admin can add players to the team',
      );
    }

    if (!isEditableSeason(activeSeason.id)) {
      return Notification(
        'error',
        'Action not available for past seasons',
      );
    }

    return setAddPlayerModalDisplay(true);
  };

  const currentSeasonIndex = seasons.findIndex(s => s.id === seasonId);
  const pastSeasonIndex = currentSeasonIndex + 1;
  const pastSeason = seasons[pastSeasonIndex];

  const copyPlayers = () => {
    if (!isAuth) {
      return Notification(
        'error',
        'You are not authorized for this action',
        'Only admin can add players to the team',
      );
    }

    if (!isEditableSeason(activeSeason.id)) {
      return Notification(
        'error',
        'Action not available for past seasons',
      );
    }

    setLoading(true);
    return api.post(`/api/v1/teams/${teamId}/players_copy/?from_season=${pastSeason.id}&to_season=${seasonId}`)
      .then(() => {
        fetchPlayers();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <div className="PlayersList">
      <PageHeader />
      <Tabs>
        <TabsHeader>
          <Tab>Roster</Tab>
          <Tab>Lineup</Tab>
        </TabsHeader>
        <TabContent>
          <div className="PlayerList-roster">
            <div className="PlayerList-roster-actions">
              <Button
                size="sm"
                onClick={handlePlayerAdd}
              >
                Add a player
              </Button>
              {
                !players.length && !!pastSeason && (
                  <Button
                    size="sm"
                    theme="info"
                    onClick={() => copyPlayers()}
                  >
                    Copy players from past season
                  </Button>
                )
              }
            </div>
            <Table
              data={players}
              noDataText=""
              filterable
              loading={loading}
              NoDataComponent={() => null}
              defaultFilterMethod={(filter, row) => {
                const rowValue = row[filter.id] || '';
                if (!rowValue) { return false; }
                return (
                  String(row[filter.id]).toLowerCase().includes(filter.value.toLowerCase())
                );
              }}
              columns={[
                {
                  accessor: 'player.first_name',
                  Header: 'First name',
                  // Cell: cellData => displayValue(cellData.value),
                  Cell: cellData => (
                    <div className="firstname-container">
                      {cellData.value}
                      {
                        cellData.value === 'Matija' && (
                          <div>
                            <RuleAlert />
                          </div>
                        )
                      }
                    </div>
                  ),
                  Filter: cellInfo => {
                    const { filter, onChange } = cellInfo;

                    return (
                      <TextInput
                        placeholder="Search by first name"
                        handleChange={val => onChange(val)}
                        value={filter ? filter.value : ''}
                      />
                    );
                  },
                },
                {
                  accessor: 'player.last_name',
                  Header: 'Last name',
                  Cell: cellData => displayValue(cellData.value),
                  Filter: cellInfo => {
                    const { filter, onChange } = cellInfo;

                    return (
                      <TextInput
                        placeholder="Search by last name"
                        handleChange={val => onChange(val)}
                        value={filter ? filter.value : ''}
                      />
                    );
                  },
                },
                {
                  accessor: 'player.position',
                  Header: 'Position',
                  Cell: cellData => {
                    const position = get(cellData, 'value') || '';
                    const item = playerPositionOptions.find(p => p.value === position);
                    return displayValue(get(item, 'label'));
                  },
                  // eslint-disable-next-line consistent-return
                  filterMethod: (filter, row) => {
                    if (!filter.value) {
                      return true;
                    }
                    const rowValue = row[filter.id] || '';
                    if (!rowValue) { return false; }

                    return rowValue === filter.value;
                  },
                  Filter: cellInfo => {
                    const { filter, onChange } = cellInfo;
                    return (
                      <Selector
                        options={playerPositionOptions}
                        value={get(filter, 'value') || ''}
                        handleChange={val => onChange(val)}
                        isClearable
                      />
                    );
                  },
                },
                {
                  accessor: 'player.throws',
                  Header: 'Throws',
                  Cell: cellData => {
                    const position = get(cellData, 'value') || '';
                    const item = playerSideOptions.find(p => p.value === position);
                    return displayValue(get(item, 'label'));
                  },
                  // eslint-disable-next-line consistent-return
                  filterMethod: (filter, row) => {
                    if (!filter.value) {
                      return true;
                    }
                    const rowValue = row[filter.id] || '';
                    if (!rowValue) { return false; }

                    return rowValue === filter.value;
                  },
                  Filter: cellInfo => {
                    const { filter, onChange } = cellInfo;
                    return (
                      <Selector
                        options={playerSideOptions}
                        value={get(filter, 'value') || ''}
                        handleChange={val => onChange(val)}
                        isClearable
                      />
                    );
                  },
                },
                {
                  accessor: 'player.bats',
                  Header: 'Bats',
                  Cell: cellData => {
                    const position = get(cellData, 'value') || '';
                    const item = playerSideOptions.find(p => p.value === position);
                    return displayValue(get(item, 'label'));
                  },
                  // eslint-disable-next-line consistent-return
                  filterMethod: (filter, row) => {
                    if (!filter.value) {
                      return true;
                    }
                    const rowValue = row[filter.id] || '';
                    if (!rowValue) { return false; }

                    return rowValue === filter.value;
                  },
                  Filter: cellInfo => {
                    const { filter, onChange } = cellInfo;
                    return (
                      <Selector
                        options={playerSideOptions}
                        value={get(filter, 'value') || ''}
                        handleChange={val => onChange(val)}
                        isClearable
                      />
                    );
                  },
                },
                {
                  accessor: 'jersey_nr',
                  Header: 'Jersey number',
                  Cell: cellData => displayValue(cellData.value),
                  Filter: cellInfo => {
                    const { filter, onChange } = cellInfo;

                    return (
                      <TextInput
                        placeholder="Search by jersey number"
                        handleChange={val => onChange(val)}
                        value={filter ? filter.value : ''}
                      />
                    );
                  },
                },
                {
                  Header: 'Delete',
                  headerClassName: 'text-center',
                  className: 'text-center button-wrapper',
                  sortable: false,
                  filterable: false,
                  Cell: cellData => (
                    <button
                      aria-label="Delete player"
                      className="delete-button"
                      onClick={e => {
                        e.stopPropagation();
                        handlePlayerDelete(cellData.index);
                      }}
                      type="button"
                    >
                      <IconDelete
                        height="20px"
                        width="20px"
                        color="#ee5253"
                      />
                    </button>
                  ),
                  width: 100,
                },
              ]}
              clickable
              handleClick={rowData => redirectToPlayerPage(rowData)}
              minRows={1}
              showPagination={false}
              defaultSorted={[
                {
                  id: 'first_name',
                  desc: false,
                },
              ]}
            />
          </div>
        </TabContent>
        <TabContent>
          <Lineup
            players={players}
            teamId={get(team, 'id')}
            seasonId={get(activeSeason, 'id')}
          />
        </TabContent>
      </Tabs>
      {
        isAddPlayerModalDisplayed && (
          <AddPlayerForm
            isOpen={isAddPlayerModalDisplayed}
            close={() => setAddPlayerModalDisplay(false)}
            teamId={teamId}
            activeSeason={activeSeason}
            userId={userId}
          />
        )
      }
      {
        isDeletePlayerModalDisplayed && (
          <DeletePlayerPopup
            isOpen={isDeletePlayerModalDisplayed}
            close={() => setDeletePlayerModalDisplay(false)}
            player={playerToDelete}
            teamId={teamId}
            activeSeason={activeSeason}
          />
        )
      }
    </div>
  );
};

PlayersList.propTypes = {
  activeSeason: PropTypes.object.isRequired,
  getActivePlayersAction: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  team: PropTypes.object.isRequired,
  players: PropTypes.array.isRequired,
  userId: PropTypes.number.isRequired,
  seasonId: PropTypes.number.isRequired,
  seasons: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  userId: get(state, 'user.userID'),
  userPlayers: get(state, 'user.details.players'),
  seasonId: get(state, 'activeTeam.activeSeason.id'),
  seasons: get(state, 'activeTeam.seasons'),
});

const mapDispatchToProps = {
  getActivePlayersAction: getActivePlayers,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PlayersList));
