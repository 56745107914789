/* eslint-disable */
import api from "shared/api";

export const getDefinedStats = () => {
  const url = `/api/v1/statdefs/`;
  return api.get(url);
};

export const getDefinedStat = (id) => {
  const url = `/api/v1/statdefs/${id}/`;
  return api.get(url);
};

export const getPlayer = (eventId) => {
  const url = `/api/v1/playerevents/?event__id=${eventId}`;
  return api.get(url);
};

export const getTestingEvent = (eventId) => {
  const url = `/api/v1/testingevents/?event__id=${eventId}`;
  return api.get(url);
};
