import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import get from 'lodash.get';
import {
  Button,
  IconUnfollow,
  Notification,
  Table,
} from 'shared/components';
import {
  displayValue,
} from 'shared/helpers';
import { refreshUserTeams } from 'store/userTeams/actions';
import DeleteUserTeamPopup from './components/DeleteUserTeamPopup';
import './styles.scss';

const ProfileTeams = ({
  history,
  refreshUserTeamsAction,
  userId,
  userTeams,
}) => {
  const [isDeleteTeamDisplayed, setDeleteTeamDisplay] = useState(false);
  const [teamToDelete, setTeamToDelete] = useState(null);

  const redirectToTeamPage = rowData => {
    const teamId = get(rowData, 'original.team.id');
    history.push(`/${teamId}/`);
  };

  const handleUnfollowTeamClick = userTeam => {
    setTeamToDelete(userTeam);
    setDeleteTeamDisplay(true);
  };

  return (
    <div className="ProfileTeams">
      <Table
        data={userTeams}
        columns={[
          {
            accessor: 'team.name',
            Header: 'Name',
            Cell: cellData => displayValue(cellData.value),
          },
          {
            accessor: 'team.gender',
            Header: 'Gender',
            Cell: cellData => displayValue(cellData.value),
          },
          {
            accessor: 'role',
            Header: 'Role',
            Cell: cellData => displayValue(cellData.value),
          },
          {
            Header: 'Unfollow',
            headerClassName: 'text-center',
            className: 'text-center button-wrapper',
            sortable: false,
            Cell: rowData => (
              <Button
                aria-label="Unfollow team"
                onClick={e => {
                  e.stopPropagation();
                  const role = get(rowData, 'original.role') || '';
                  if (role === 'admin' || role === 'coach') {
                    return Notification('warning', `User with role ${role} can't unfollow teams`);
                  }
                  return handleUnfollowTeamClick(rowData.original);
                }}
                theme="link"
              >
                <IconUnfollow
                  height="20px"
                  width="20px"
                  stroke="#ee5253"
                />
              </Button>
            ),
            width: 150,
          },
        ]}
        clickable
        handleClick={rowData => redirectToTeamPage(rowData)}
        minRows={1}
        showPagination={false}
      />
      {
        isDeleteTeamDisplayed && (
          <DeleteUserTeamPopup
            isOpen={isDeleteTeamDisplayed}
            close={() => setDeleteTeamDisplay(false)}
            refreshUserTeamsAction={refreshUserTeamsAction}
            team={teamToDelete}
            userId={userId}
          />
        )
      }
    </div>
  );
};

ProfileTeams.propTypes = {
  userId: PropTypes.number.isRequired,
  history: PropTypes.object.isRequired,
  refreshUserTeamsAction: PropTypes.func.isRequired,
  userTeams: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  userTeams: get(state, 'userTeams.details'),
});

const mapDispatchToProps = ({
  refreshUserTeamsAction: refreshUserTeams,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProfileTeams));
