export const parseAddres = address => {
  const data = {};

  address.forEach(component => {
    const { types } = component;

    if (types.indexOf('locality') > -1) {
      data.city = component.long_name;
    }

    if (types.indexOf('administrative_area_level_1') > -1) {
      data.state = component.short_name;
    }

    if (types.indexOf('postal_code') > -1) {
      data.zipcode = component.long_name;
    }

    if (types.indexOf('country') > -1) {
      data.country = component.long_name;
    }
  });

  return data;
};
