import get from 'lodash.get';
import sortBy from 'lodash.sortby';
import moment from 'moment';
import {
  defaultDateTimeFormat,
  playerSideOptions,
} from 'shared/constants';
import store from 'store';

export const isValidDate = (dateString) => {
  const regEx = /^\d{4}-\d{2}-\d{2}$/;

  if (typeof dateString !== 'string') {
    return false;
  }

  if (!dateString.match(regEx)) {
    return false;
  }
  const d = new Date(dateString);
  if (Number.isNaN(d.getTime())) {
    return false;
  }
  return d.toISOString().slice(0, 10) === dateString;
};

export const buildCustomErrorMeta = err => ({
  data: get(err, 'response.data'),
  errorMessage: get(err, 'message'),
  headers: get(err, 'response.headers'),
  statusText: get(err, 'response.statusText'),
});

export const copyText = (val) => {
  if (!val) {
    return false;
  }
  const textField = document.createElement('textarea');
  textField.innerText = val;
  document.body.appendChild(textField);
  textField.select();
  const result = document.execCommand('copy');
  textField.remove();
  return result;
};

export const isJSON = (val) => {
  try {
    JSON.parse(val);
  } catch (e) {
    return false;
  }
  return true;
};

export const parseObject = (val) => {
  let data = {};

  if (!val || !isJSON(val)) {
    return data;
  }

  const result = JSON.parse(val);
  if (typeof result === 'object' && !Array.isArray(result)) {
    data = result;
  }

  return data;
};

export const formatDate = (val, formatType = defaultDateTimeFormat, fallback = '-') => {
  const isValid = moment(val).isValid();

  if (!val || !isValid) {
    return fallback;
  }
  return moment(val).format(formatType);
};

export const displayValue = (val, fallback = '-') => {
  if (!val && val !== 0) {
    return fallback;
  }
  return val;
};

export const getGenderLabel = (value, fallback = '-') => {
  switch (value) {
    case 'M':
      return 'Male';
    case 'F':
      return 'Female';
    case 'C':
      return 'Coed';
    default:
      return fallback;
  }
};

export const getAgeLabel = (value, fallback = '-') => {
  switch (value) {
    case '12_under':
      return '12 and under';
    case '13_18_years':
      return '13 - 18 Years';
    case 'college':
      return 'College';
    case 'adult':
      return 'Adult';
    default:
      return fallback;
  }
};

export const getCountryLabel = (value, fallback = 'Other') => {
  switch (value) {
    case 'USA':
    case 'usa':
      return 'USA';
    default:
      return fallback;
  }
};

export const getSideLabel = val => {
  const sideObject = playerSideOptions.find(s => s.value === val);

  if (!sideObject) {
    return '-';
  }

  return sideObject.label;
};

export const encodeSeasons = seasons => {
  let formatedSeasons = [];
  if (seasons && Array.isArray(seasons)) {
    formatedSeasons = seasons.map(s => {
      const obj = {};
      let tempS;
      const seasonField = s.season;
      switch (seasonField) {
        case 'spring': {
          tempS = 1;
          break;
        }
        case 'summer': {
          tempS = 2;
          break;
        }
        case 'fall': {
          tempS = 3;
          break;
        }
        case 'winter': {
          tempS = 4;
          break;
        }
        default: {
          tempS = 1;
        }
      }

      obj.id = s.id;
      obj.is_current = s.is_current;
      obj.year = s.year;
      obj.year_repr = s.year_repr;
      obj.season = tempS;

      return obj;
    });
  }

  return formatedSeasons;
};

export const decodeSeasons = seasons => {
  let reformated = [];
  if (seasons && Array.isArray(seasons)) {
    reformated = seasons.map(s => {
      const obj = {};
      let tempS;
      const seasonField = s.season;
      switch (seasonField) {
        case 1: {
          tempS = 'spring';
          break;
        }
        case 2: {
          tempS = 'summer';
          break;
        }
        case 3: {
          tempS = 'fall';
          break;
        }
        case 4: {
          tempS = 'winter';
          break;
        }
        default: {
          tempS = '';
        }
      }

      obj.id = s.id;
      obj.is_current = s.is_current;
      obj.year = s.year;
      obj.year_repr = s.year_repr;
      obj.season = tempS;

      return obj;
    });
  }

  return reformated;
};

export const sortSeasons = seasons => {
  const formatedSeasons = encodeSeasons(seasons);
  const sorted = sortBy(formatedSeasons, ['year', 'season']);
  const reformated = decodeSeasons(sorted);

  return reformated.reverse();
};

export const isCurrentSeason = () => {
  const state = store.getState();
  const activeSeason = get(state, 'activeTeam.activeSeason') || {};

  return get(activeSeason, 'is_current');
};

export const isEditableSeason = seasonId => {
  const state = store.getState();
  const seasons = get(state, 'activeTeam.seasons') || [];
  const isStaff = get(state, 'user.details.is_staff') || false;

  if (isStaff) {
    return true;
  }

  const sorted = sortSeasons(seasons);
  const currentSeasonIndex = sorted.findIndex(s => s.is_current);
  const selectedSeasonIndex = sorted.findIndex(s => s.id === seasonId);
  const isEditable = currentSeasonIndex >= selectedSeasonIndex;
  return isEditable;
};

export const checkUserTeam = (userTeams, activeTeamId) => {
  const isUserTeam = userTeams
    .filter(team => get(team, 'team.id') === Number(activeTeamId))
    .filter(Boolean);

  if (!isUserTeam.length) {
    return null;
  }

  return {
    id: get(isUserTeam, '[0].id'),
    role: get(isUserTeam, '[0].role'),
    team: get(isUserTeam, '[0].team'),
  };
};

export const isAdmin = teamId => {
  const state = store.getState();
  const userTeams = get(state, 'userTeams.details') || [];
  const isUserTeam = checkUserTeam(userTeams, teamId);
  const isStaff = get(state, 'user.details.is_staff') || false;

  if (isStaff) {
    return true;
  }

  if (!isUserTeam) {
    return false;
  }

  const role = get(isUserTeam, 'role');

  if (role === 'admin' || role === 'coach' || role === 'scorekeeper' || role === 'assistant_coach' || role === 'team_volunteer') {
    return true;
  }

  return false;
};

export const isPlayersFamily = playerId => {
  const state = store.getState();
  const userPlayers = get(state, 'user.details.players') || [];

  const hasPlayer = userPlayers.find(up => up.id === playerId);
  return hasPlayer;
};

export const checkExistingLineupPosition = (position, lineup) => {
  const positionValue = get(position, 'value');
  const isExisting = lineup.find(l => l.position === positionValue);
  return isExisting;
};

export const mapPlayersToSelector = players => {
  const options = [];

  players.forEach(p => {
    const player = get(p, 'player');

    options.push({
      data: player,
      value: get(player, 'id'),
      label: `${get(player, 'first_name')} ${get(player, 'last_name')}`,
    });
  });

  return options;
};

export const sumFooterTotal = (data, id) => data.reduce((acc, val) => acc + val[id], 0);

export const sumFooterTotalFloat = (data, id, dec = 1) => (
  parseFloat(data.reduce((acc, val) => acc + val[id], 0)).toFixed(dec)
);

export const mapDivisionsToSelector = (divisions = []) => {
  const all = {
    label: 'All divisions',
    value: 'all',
  };

  const mapped = divisions.map(d => ({
    label: d,
    value: d,
  }));

  mapped.unshift(all);

  return mapped;
};

export const capitalizeFirstLetter = (string) => {
  if (!string) {
    return undefined;
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};
