import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import get from "lodash.get";
import { Page, Tab, Tabs, TabContent, TabsHeader } from "shared/components";
import { cleanTeam } from "store/activeTeam/actions";
import EventsDashboard from "EventsDashboard";
import EventStatsList from "../EventStatsList/EventStatsList";
import { useHistory } from "react-router-dom";
import "./styles.scss";

const EventManagamentContainer = ({
  cleanActiveTeam,
  location,
  organizationId,
}) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const navigate = useHistory();

  const openSelectedTab = (i) => setSelectedTab(i);
  console.log("Org: ", organizationId);
  useEffect(() => {
    console.log("Location: ", location);
    const queryParams = get(location, "search");
    if (!queryParams) {
      setSelectedTab(0);
    }
    if (
      location?.pathname.includes("/events/stat") ||
      location?.pathname.includes("/events/stat/")
    ) {
      setSelectedTab(1);
    }
    if (queryParams.indexOf("st=1") >= 0) {
      setSelectedTab(2);
    }
    if (queryParams.indexOf("st=3") >= 0) {
      setSelectedTab(3);
    }
  }, [location]);

  useEffect(() => {
    cleanActiveTeam();
  }, []);

  return (
    <div className="EventManagamentContainer">
      <Page>
        <Tabs
          defaultFocus
          selectedIndex={selectedTab}
          onSelect={(tabIndex) => {
            setSelectedTab(tabIndex);
            if (tabIndex == 0) {
              navigate.push("/events/");
            }
            if (tabIndex == 1) {
              navigate.push("/events/stats/");
            }
          }}
          forceRenderTabPanel
        >
          <TabsHeader>
            <Tab>Events</Tab>
            <Tab>Event statistics</Tab>
          </TabsHeader>
          <TabContent>
            <EventsDashboard />
          </TabContent>
          <TabContent>
            <EventsDashboard />
          </TabContent>
        </Tabs>
      </Page>
    </div>
  );
};

EventManagamentContainer.propTypes = {
  cleanActiveTeam: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  userTeams: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  userTeams: get(state, "userTeams.details"),
  organizationId: get(state, "user.details.organizations[0].id"),
});

const mapDispatchToProps = {
  cleanActiveTeam: cleanTeam,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EventManagamentContainer));
