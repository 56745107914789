import get from 'lodash.get';

export const mapTeamsToSelector = (data, selectedTeams) => {
  const teamOptions = data.map((d) => ({
    value: d.id,
    label: d.name,
    data: d,
  }));
  if (selectedTeams.length === 1) { return teamOptions; }
  const uniqueTeams = teamOptions.filter((t) => {
    const isSelected = selectedTeams.find(st => get(st, 'team.value') === t.value);
    if (isSelected) { return false; }
    return true;
  });
  return uniqueTeams;
};
