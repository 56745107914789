import React, { useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import {
  Button,
  Label,
  Modal,
  Notification,
  Selector,
} from 'shared/components';
import {
  checkExistingLineupPosition,
} from 'shared/helpers';
import {
  playerPositionOptions,
  reserveOptions,
} from 'shared/constants';
import {
  mapPlayersToSelector,
} from './helpers';
import { createPlayer, patchPlayer } from './actions';
import './styles.scss';

const AddLineupPlayer = ({
  close,
  isOpen,
  refreshActiveLineup,
  teamId,
  seasonId,
  players,
  lineup,
}) => {
  const lineupId = get(lineup, 'id');
  const lineupPlayers = get(lineup, 'lineup_players');
  const [loading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);

  const [player, setPlayer] = useState('');
  const [position, setPosition] = useState({});
  const [reserve, setReserve] = useState(reserveOptions[0]);

  const addUniquePositionLineupPlayer = (data) => {
    createPlayer(data)
      .then(() => {
        refreshActiveLineup(teamId, seasonId);
        Notification('success', 'New player added to team lineup');
        close();
      })
      .catch(() => {
        setLoading(false);
        Notification(
          'error',
          'Error occured',
          'There was an error while adding a new player to lineup',
        );
      });
  };

  const handleLineupEdit = (data, existing) => {
    createPlayer(data)
      .then(() => {
        const existingPlayerId = get(existing, 'id');
        return patchPlayer(existingPlayerId, { position: 'EH' });
      })
      .then(() => {
        refreshActiveLineup(teamId, seasonId);
        Notification('success', 'New player added to team lineup');
        close();
      })
      .catch(() => {
        setLoading(false);
        Notification(
          'error',
          'Error occured',
          'There was an error while adding a new player to lineup',
        );
      });
  };

  const handleSubmit = e => {
    e.preventDefault();

    setLoading(true);

    const isReserve = get(reserve, 'value') === 'yes';

    const data = {
      player: get(player, 'value'),
      position: get(position, 'value') || '',
      lineup: lineupId,
      is_reserve: isReserve,
    };

    const existingPositionPlayer = checkExistingLineupPosition(position, lineupPlayers);

    if (!existingPositionPlayer) {
      addUniquePositionLineupPlayer(data);
    } else {
      handleLineupEdit(data, existingPositionPlayer);
    }
  };

  const handleClose = () => {
    if (
      !dirty ||
      window.confirm('Are you sure you want to discard your changes?')
    ) {
      close();
    }
  };

  const playersList = mapPlayersToSelector(players);

  return (
    <Modal
      isOpen={isOpen}
      closeCb={handleClose}
      title="Add a player to lineup"
      size="sm"
    >
      <div className="AddLineupPlayer">
        <form
          className="AddLineupPlayer-form"
          onSubmit={handleSubmit}
        >
          <div className="form-row">
            <Label
              inputId="player-position"
              text="Position"
            />
            <Selector
              options={playerPositionOptions}
              value={get(position, 'value') || ''}
              handleChange={val => {
                setDirty(true);
                const selected = playerPositionOptions.find(option => option.value === val);
                setPosition(selected || {});
              }}
            />
          </div>
          <div className="form-row">
            <Label
              inputId="player"
              text="Player"
            />
            <Selector
              options={playersList}
              value={get(player, 'value') || ''}
              handleChange={val => {
                setDirty(true);
                const selected = playersList.find(option => option.value === val);
                setPlayer(selected || {});
              }}
            />
          </div>
          <div className="form-row">
            <Label
              inputId="player-reserve"
              text="Is reserve"
            />
            <Selector
              options={reserveOptions}
              value={get(reserve, 'value') || ''}
              handleChange={val => {
                setDirty(true);
                const selected = reserveOptions.find(option => option.value === val);
                setReserve(selected || {});
              }}
            />
          </div>
          <div className="form-submit">
            <Button
              theme="link"
              type="button"
              disabled={loading}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              theme="success"
              type="submit"
              disabled={loading}
            >
              Add player
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

AddLineupPlayer.propTypes = {
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  refreshActiveLineup: PropTypes.func.isRequired,
  teamId: PropTypes.number.isRequired,
  lineup: PropTypes.object.isRequired,
  seasonId: PropTypes.number.isRequired,
  players: PropTypes.array.isRequired,
};

export default AddLineupPlayer;
