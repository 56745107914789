/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import get from "lodash.get";
import {
  Button,
  DescriptionList,
  IconDelete,
  IconEdit,
  Notification,
  Table,
} from "shared/components";
import {
  displayValue,
  formatDate,
  isAdmin,
  isPlayersFamily,
  getSideLabel,
} from "shared/helpers";
import { getUserSilent } from "store/user/actions";
import { defaultDateFormat } from "shared/constants";
import EditPlayerForm from "./components/EditPlayerForm";
import AddPlayerFamilyForm from "./components/AddPlayerFamilyForm";
import AddPlayerParentsForm from "./components/AddPlayerParentsForm";
import EditPlayerParentsForm from "./components/EditPlayerParentsForm";
import EditPlayerGuardianForm from "./components/EditPlayerGuardianForm";
import DeletePlayerFamilyPopup from "./components/DeletePlayerFamilyPopup";
import DeletePlayerParentPopup from "./components/DeletePlayerParentPopup";
import EditImageForm from "./components/EditImageForm/EditImageForm";
import {
  fetchPlayerUsers,
  getPlayerImage,
  UploadImage,
  SetMainImage,
} from "./actions";
import FallbackProfile from "../../../../shared/components/icons/stockphoto.jpeg";
import "./styles.scss";

const PlayerProfile = ({
  player,
  getUserAction,
  fetchPlayerData,
  teamId,
  userId,
}) => {
  const playerId = get(player, "player.id");
  const isAuth = isAdmin(teamId);
  const isMounted = React.useRef(true);
  const image = useRef(null);

  const [playerCommunityLoading, setPlayerCommunityLoading] = useState(true);
  const [playerUsers, setPlayerUsers] = useState([]);
  const [isEditPlayerDisplayed, setEditPlayerDisplay] = useState(false);
  const [isAddPlayerFamilyDisplayed, setAddPlayerFamilyDisplay] =
    useState(false);
  const [isAddPlayerParentsDisplayed, setAddPlayerParentsDisplay] =
    useState(false);
  const [isDeletePlayerFamilyDisplayed, setDeletePlayerFamilyDisplay] =
    useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [isEditPlayerGuardianDisplayed, setEditPlayerGuardianDisplay] =
    useState(false);
  const [parentToEditIndex, setParentToEditIndex] = useState(null);
  const [parentToDeleteIndex, setParentToDeleteIndex] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [isImageEditDisplayed, setIsImageEditDisplayed] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);

  const handleAddingCurrentUser = (users, currentUserId) => {
    const isCurrent = users.find((u) => u.user.id === currentUserId);

    if (isCurrent) {
      getUserAction();
    }
  };

  const getPlayerUsers = () => {
    fetchPlayerUsers(playerId)
      .then((res) => {
        const users = get(res, "data.results") || [];
        if (isMounted.current) {
          setPlayerUsers(users);
          setPlayerCommunityLoading(false);

          handleAddingCurrentUser(users, userId);
        }
      })
      .catch(() => {
        setPlayerCommunityLoading(false);
        Notification(
          "error",
          "Error occured",
          "There was an error while getting the player family members"
        );
      });
  };

  useEffect(() => {
    getPlayerUsers();
    getPlayerImage(player.player.id).then((res) => {
      setProfileImage(res.data.results[0]?.image);
    });

    return () => {
      isMounted.current = false;
    };
  }, []);

  const handlePlayerEdit = () => {
    if (!isAuth && !isPlayersFamily(playerId)) {
      return Notification(
        "error",
        "You are not authorized for this action",
        "Only admin or family can edit players"
      );
    }

    return setEditPlayerDisplay(true);
  };

  const handlePlayerFamilyAddClick = () => {
    if (!isAuth && !isPlayersFamily(playerId)) {
      return Notification(
        "error",
        "You are not authorized for this action",
        "Only admin or family can edit players"
      );
    }
    return setAddPlayerFamilyDisplay(true);
  };

  const handlePlayerParentsAddClick = () => {
    if (!isAuth && !isPlayersFamily(playerId)) {
      return Notification(
        "error",
        "You are not authorized for this action",
        "Only admin or family can edit players"
      );
    }
    return setAddPlayerParentsDisplay(true);
  };

  const handlePlayerParentsEditClick = (index) => {
    if (!isAuth && !isPlayersFamily(playerId)) {
      return Notification(
        "error",
        "You are not authorized for this action",
        "Only admin or family can edit players"
      );
    }

    return setParentToEditIndex(index);
  };

  const handlePlayerFamilyDeleteClick = (user) => {
    if (!isAuth && !isPlayersFamily(playerId)) {
      return Notification(
        "error",
        "You are not authorized for this action",
        "Only admin or family can edit players"
      );
    }
    setUserToDelete(user);
    return setDeletePlayerFamilyDisplay(true);
  };

  // const verifiedUsers = playerUsers.filter(u => u.user.email_verified);
  const getPlayerGuardianActionTitle = () =>
    get(player, "player.guardian_first_name") &&
    get(player, "player.guardian_first_name")
      ? "Edit player guardian"
      : "Add player guardian";

  const parents = get(player, "player.parents") || [];

  const handleLogoEditDisplayed = () => {
    return setIsImageEditDisplayed(true);
  };

  const handleSubmitClick = (val) => {
    const formData = new FormData();
    formData.append("image", val.file);
    UploadImage(player.player.id, formData).then((res) => {
      console.log("upload", res);
      setProfileImage(res.data.image);
      SetMainImage(res.data.id, player.player.id).then(() =>
        setIsImageEditDisplayed(false)
      );
    });
    setSelectedFile(val.file);
  };

  const handleWrongFileType = () => {
    Notification(
      "error",
      "Wrong file type",
      "Only image files can be uploaded"
    );
    setUploading(false);
  };

  const handleItemRemove = () => {
    setUploading(false);
    setProgress(0);
  };

  useEffect(() => {
    if (selectedFile) {
      console.log("img", selectedFile);
      image.current.src = URL.createObjectURL(selectedFile);
    }
  }, [selectedFile]);

  return (
    <div className="PlayerProfile">
      <Button size="sm" onClick={handleLogoEditDisplayed}>
        Edit profile image
      </Button>
      <div className="Profile">
        <img
          ref={image}
          id="profile"
          src={profileImage ? profileImage : FallbackProfile}
          alt="profile"
        />
      </div>
      <div className="PlayerProfile-actions">
        <Button size="sm" onClick={handlePlayerEdit}>
          Edit player
        </Button>
      </div>
      <div className="PlayerProfile-details">
        <DescriptionList
          data={[
            {
              label: "Jersey number",
              value: displayValue(get(player, "jersey_nr")),
            },
            {
              label: "First name",
              value: displayValue(get(player, "player.first_name")),
            },
            {
              label: "Last name",
              value: displayValue(get(player, "player.last_name")),
            },
            {
              label: "Date of birth",
              value: formatDate(
                get(player, "player.date_of_birth"),
                defaultDateFormat
              ),
            },
            { label: "City", value: displayValue(get(player, "player.city")) },
            {
              label: "State",
              value: displayValue(get(player, "player.state")),
            },

            {
              label: "Position",
              value: displayValue(get(player, "player.position")),
            },
            { label: "Bats", value: getSideLabel(get(player, "player.bats")) },
            {
              label: "Throws",
              value: getSideLabel(get(player, "player.throws")),
            },
            {
              label: "Height",
              value: displayValue(get(player, "player.height")),
            },
            {
              label: "Weight",
              value: displayValue(get(player, "player.weight")),
            },
            {
              label: "Graduation year",
              value: displayValue(get(player, "player.graduation_year")),
            },
            {
              label: "Biography",
              value: displayValue(get(player, "player.bio")),
            },
          ]}
        />
      </div>
      <div className="PlayerProfile-parents">
        <h3>Parents contact info</h3>
        <Button size="sm" onClick={handlePlayerParentsAddClick}>
          Add parent info
        </Button>
        {
          <Table
            data={parents.map((p) => JSON.parse(p))}
            columns={[
              {
                accessor: "name",
                Header: "Name",
                Cell: (cellData) => displayValue(cellData.value),
              },
              {
                accessor: "email",
                Header: "Email",
                Cell: (cellData) => displayValue(cellData.value),
              },
              {
                accessor: "phone",
                Header: "Phone",
                Cell: (cellData) => displayValue(cellData.value),
              },
              {
                Header: "Edit",
                headerClassName: "text-center",
                className: "text-center button-wrapper",
                sortable: false,
                Cell: (cellData) => (
                  <button
                    aria-label="Edit rule"
                    className="edit-button"
                    onClick={(e) => {
                      e.stopPropagation();
                      handlePlayerParentsEditClick(cellData.index);
                    }}
                    type="button"
                  >
                    <IconEdit height="20px" width="20px" />
                  </button>
                ),
                width: 100,
              },
              {
                Header: "Delete",
                headerClassName: "text-center",
                className: "text-center button-wrapper",
                sortable: false,
                Cell: (cellData) => (
                  <button
                    aria-label="Delete player"
                    className="delete-button"
                    onClick={(e) => {
                      e.stopPropagation();
                      setParentToDeleteIndex(cellData.index);
                    }}
                    type="button"
                  >
                    <IconDelete height="20px" width="20px" color="#ee5253" />
                  </button>
                ),
                width: 100,
              },
            ]}
            minRows={1}
            showPagination={false}
          />
        }
      </div>
      <div className="PlayerProfile-community">
        <h3>Players family</h3>
        <Button size="sm" onClick={handlePlayerFamilyAddClick}>
          Add family to the player
        </Button>
        <Table
          data={playerUsers}
          loading={playerCommunityLoading}
          noDataText=""
          columns={[
            {
              accessor: "user.email",
              Header: "Email",
              Cell: (cellData) => displayValue(cellData.value),
            },
            {
              accessor: "user.first_name",
              Header: "First name",
              Cell: (cellData) => displayValue(cellData.value),
            },
            {
              accessor: "user.last_name",
              Header: "Last name",
              Cell: (cellData) => displayValue(cellData.value),
            },
            {
              Header: "Delete",
              headerClassName: "text-center",
              className: "text-center button-wrapper",
              sortable: false,
              Cell: (cellData) => (
                <button
                  aria-label="Delete player"
                  className="delete-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    handlePlayerFamilyDeleteClick(cellData.original);
                  }}
                  type="button"
                >
                  <IconDelete height="20px" width="20px" color="#ee5253" />
                </button>
              ),
              width: 100,
            },
          ]}
          minRows={1}
          showPagination={false}
        />
      </div>
      <div className="PlayerProfile-guardian">
        <h3>Players guardian</h3>
        <Button size="sm" onClick={() => setEditPlayerGuardianDisplay(true)}>
          {getPlayerGuardianActionTitle()}
        </Button>
        <DescriptionList
          data={[
            {
              label: "First name",
              value: displayValue(get(player, "player.guardian_first_name")),
            },
            {
              label: "Last name",
              value: displayValue(get(player, "player.guardian_last_name")),
            },
          ]}
        />
      </div>
      {isEditPlayerDisplayed && (
        <EditPlayerForm
          isOpen={isEditPlayerDisplayed}
          close={() => setEditPlayerDisplay(false)}
          fetchPlayerData={fetchPlayerData}
          player={get(player, "player")}
          jerseyNr={get(player, "jersey_nr")}
        />
      )}
      {isAddPlayerFamilyDisplayed && (
        <AddPlayerFamilyForm
          isOpen={isAddPlayerFamilyDisplayed}
          close={() => setAddPlayerFamilyDisplay(false)}
          getPlayerUsers={getPlayerUsers}
          player={get(player, "player")}
        />
      )}
      {isDeletePlayerFamilyDisplayed && (
        <DeletePlayerFamilyPopup
          isOpen={isDeletePlayerFamilyDisplayed}
          close={() => setDeletePlayerFamilyDisplay(false)}
          user={userToDelete}
          getPlayerUsers={getPlayerUsers}
        />
      )}
      {isEditPlayerGuardianDisplayed && (
        <EditPlayerGuardianForm
          isOpen={isEditPlayerGuardianDisplayed}
          close={() => setEditPlayerGuardianDisplay(false)}
          fetchPlayerData={fetchPlayerData}
          player={get(player, "player")}
          title={getPlayerGuardianActionTitle()}
        />
      )}
      {isAddPlayerParentsDisplayed && (
        <AddPlayerParentsForm
          isOpen={isAddPlayerParentsDisplayed}
          close={() => setAddPlayerParentsDisplay(false)}
          fetchPlayerData={fetchPlayerData}
          player={get(player, "player")}
        />
      )}
      {parentToEditIndex !== null && (
        <EditPlayerParentsForm
          isOpen={parentToEditIndex !== null}
          close={() => setParentToEditIndex(null)}
          fetchPlayerData={fetchPlayerData}
          player={get(player, "player")}
          parentIndex={parentToEditIndex}
          parents={parents}
        />
      )}
      {parentToDeleteIndex !== null && (
        <DeletePlayerParentPopup
          isOpen={parentToDeleteIndex !== null}
          close={() => setParentToDeleteIndex(null)}
          fetchPlayerData={fetchPlayerData}
          player={get(player, "player")}
          parentIndex={parentToDeleteIndex}
          parents={parents}
        />
      )}
      {isImageEditDisplayed && (
        <EditImageForm
          isOpen={isImageEditDisplayed}
          close={() => setIsImageEditDisplayed(false)}
          profileImage={profileImage}
          uploading={uploading}
          progress={progress}
          handleSubmitClick={handleSubmitClick}
          handleWrongFileType={handleWrongFileType}
          handleItemRemove={handleItemRemove}
          validFileTypes={".jpg, .png, .jpeg"}
          selectedFile={selectedFile}
          preview={preview}
          setPreview={setPreview}
        />
      )}
    </div>
  );
};

PlayerProfile.propTypes = {
  player: PropTypes.object,
  fetchPlayerData: PropTypes.func.isRequired,
  getUserAction: PropTypes.func.isRequired,
  teamId: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
};

PlayerProfile.defaultProps = {
  player: null,
};

const mapStateToProps = (state) => ({
  userId: get(state, "user.userID"),
});

const mapDispatchToProps = {
  getUserAction: getUserSilent,
};

export default connect(mapStateToProps, mapDispatchToProps)(PlayerProfile);
