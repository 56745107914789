import React, { useState } from 'react';
import { connect } from 'react-redux';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  Notification,
} from 'shared/components';
import { refreshActiveGames } from 'store/activeTeam/actions';
import { deleteGame } from './actions';
import './styles.scss';

const DeleteGamePopup = ({
  activeSeason,
  close,
  isOpen,
  refreshActiveGamesAction,
  team,
  game,
}) => {
  const teamId = get(team, 'id');
  const seasonId = get(activeSeason, 'id');

  const [loading, setLoading] = useState(false);

  const handleSubmit = e => {
    const gameId = get(game, 'id');
    e.preventDefault();

    setLoading(true);

    deleteGame(gameId)
      .then(() => {
        refreshActiveGamesAction(teamId, seasonId);
        Notification('success', 'Game deleted');
        close();
      })
      .catch(() => {
        setLoading(false);
        Notification('error', 'Error occured', 'There was an error while deleting a game');
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      closeCb={close}
      title="Are you sure you want to delete this game?"
      size="md"
    >
      <div className="DeleteGamePopup">
        <form
          className="DeleteGamePopup-form"
          onSubmit={handleSubmit}
        >
          <p>Deletion is permanent and cannot be reversed!</p>
          <div className="form-submit">
            <Button
              theme="link"
              type="button"
              disabled={loading}
              onClick={close}
            >
              Cancel
            </Button>
            <Button
              theme="error"
              type="submit"
              disabled={loading}
            >
              Delete
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

DeleteGamePopup.propTypes = {
  activeSeason: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  refreshActiveGamesAction: PropTypes.func.isRequired,
  team: PropTypes.object.isRequired,
  game: PropTypes.object.isRequired,
};

const mapDispatchToProps = {
  refreshActiveGamesAction: refreshActiveGames,
};

export default connect(null, mapDispatchToProps)(DeleteGamePopup);
