import React from "react";
import { Redirect } from "react-router-dom";

const isAuthorized = (WrappedComponent, userId = null, organizationId = []) => {
  const wrapped = () => {
    console.log(organizationId[0]?.id);

    if (!userId) {
      return <Redirect to="/login" />;
    }
    if (userId && !organizationId[0]?.id) {
      return <Redirect to="/error" />;
    }

    return <WrappedComponent userId={userId} />;
  };

  return wrapped;
};

export default isAuthorized;
