import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import {
  Button,
  Table,
  IconDelete,
  IconEdit,
  Notification,
} from 'shared/components';
import {
  displayValue,
} from 'shared/helpers';
import { fetchSuplementalRules, deleteRule } from './actions';
import AddSuplementalRule from '../AddSuplementalRule';
import EditSuplementalRule from '../EditSuplementalRule';
import SuplementalRuleMeta from '../SuplementalRuleMeta';
import DeleteSuplementalRulePopup from '../DeleteSuplementalRulePopup';
import './styles.scss';

const SuplementalRules = ({
  leagueId,
  isLeagueAdmin,
}) => {
  const [loading, setLoading] = useState(true);
  const [rules, setRules] = useState([]);
  const [ruleToEdit, setRuleToEdit] = useState(null);
  const [isEditFormDisplayed, setEditFormDisplay] = useState(false);
  const [isAddRuleFormDisplayed, setAddRuleFormDisplay] = useState(false);
  const [deletingRule, setDeletingRule] = useState(false);
  const [ruleToDelete, setRuleToDelete] = useState(null);
  const [isDeleteRuleFormDisplayed, setDeleteRuleFormDisplay] = useState(false);

  const getSuplementalRules = () => {
    setLoading(true);
    fetchSuplementalRules(leagueId)
      .then(res => {
        const data = get(res, 'data.results') || [];
        setRules(data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getSuplementalRules();
  }, [leagueId]);

  const handleRuleDelete = () => {
    const ruleID = get(ruleToDelete, 'id');
    setDeletingRule(true);

    deleteRule(ruleID)
      .then(() => {
        setDeleteRuleFormDisplay(false);
        setDeletingRule(false);
        setRuleToDelete(null);
        Notification('success', 'Suplemental rule deleted from the league');
        getSuplementalRules();
      })
      .catch(() => {
        setDeletingRule(false);
        Notification(
          'error',
          'Error occured',
          'There was an error while deleting suplemental rule from the league.',
        );
      });
  };

  return (
    <div className="SuplementalRules">
      <div className="suplemental-rules-container">
        <div className="heading">
          Suplemental rules
        </div>
        <div className="actions">
          {isLeagueAdmin && (
            <Button
              size="sm"
              theme="default"
              onClick={() => setAddRuleFormDisplay(true)}
              disabled={loading}
            >
              Add new suplemental rule
            </Button>
          )}
        </div>
        <div className="suplemental-rules-list">
          <Table
            data={rules}
            noDataText="No suplemental rules added to this league"
            columns={[
              // {
              //   accessor: 'code',
              //   Header: 'Code',
              //   // headerClassName: 'text-center',
              //   // className: 'text-center',
              //   Cell: cellData => displayValue(cellData.value),
              // },
              {
                accessor: 'description',
                Header: 'Rule description',
                Cell: (cellData) => {
                  const desc = get(cellData, 'value');
                  const code = get(cellData, 'original.code');

                  return desc ? displayValue(desc) : displayValue(code);
                },
              },
              {
                accessor: 'rule_meta',
                Header: 'Values',
                Cell: (cellData) => {
                  const data = get(cellData, 'value');
                  return <SuplementalRuleMeta ruleMeta={data} />;
                },
              },
              {
                Header: 'Edit',
                headerClassName: 'text-center',
                className: 'text-center button-wrapper',
                sortable: false,
                Cell: cellData => (
                  <button
                    aria-label="Edit rule"
                    className="edit-button"
                    onClick={e => {
                      e.stopPropagation();
                      setRuleToEdit(cellData.original);
                      setEditFormDisplay(true);
                    }}
                    type="button"
                  >
                    <IconEdit
                      height="20px"
                      width="20px"
                    />
                  </button>
                ),
                width: 100,
                show: isLeagueAdmin,
              },
              {
                Header: 'Delete',
                headerClassName: 'text-center',
                className: 'text-center button-wrapper',
                sortable: false,
                Cell: cellData => (
                  <button
                    aria-label="Delete rule"
                    className="delete-button"
                    onClick={e => {
                      e.stopPropagation();
                      setRuleToDelete(get(cellData, 'original'));
                      setDeleteRuleFormDisplay(true);
                    }}
                    type="button"
                  >
                    <IconDelete
                      height="20px"
                      width="20px"
                      color="#ee5253"
                    />
                  </button>
                ),
                width: 100,
                show: isLeagueAdmin,
              },
            ]}
            minRows={1}
            showPagination={false}
            loading={loading}
          />
        </div>
      </div>
      {
        isAddRuleFormDisplayed && (
          <AddSuplementalRule
            isOpen={isAddRuleFormDisplayed}
            close={() => setAddRuleFormDisplay(false)}
            refetchRules={getSuplementalRules}
            leagueId={leagueId}
            existingRules={rules}
          />
        )
      }
      {
        isDeleteRuleFormDisplayed && (
          <DeleteSuplementalRulePopup
            isOpen={isDeleteRuleFormDisplayed}
            close={() => setDeleteRuleFormDisplay(false)}
            confirmCb={handleRuleDelete}
            loading={deletingRule}
            ruleToDelete={ruleToDelete}
          />
        )
      }
      {
        isEditFormDisplayed && (
          <EditSuplementalRule
            isOpen={isEditFormDisplayed}
            close={() => setEditFormDisplay(false)}
            ruleToEdit={ruleToEdit}
            refetchRules={getSuplementalRules}
          />
        )
      }
    </div>
  );
};

SuplementalRules.propTypes = {
  leagueId: PropTypes.number.isRequired,
  isLeagueAdmin: PropTypes.bool.isRequired,
};

export default SuplementalRules;
