import React from 'react';
import {
  UnderConstruction,
} from 'shared/components';

const ProfileAlerts = () => (
  <div className="ProfileAlerts" style={{ marginTop: '10px' }}>
    <UnderConstruction />
  </div>
);

export default ProfileAlerts;
