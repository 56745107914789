export const mockStandard = {
  data: [
    {
      jersey_nr: 12,
      player: 'John Doe',
      ip: 2.0,
      gs: 1,
      w: 0,
      l: 4,
      sv: 0,
      bs: 0,
      h: 5,
      bb: 7,
      so: 0,
      r: 13,
      er: 16,
    },
  ],
  total: {
    ip: 2.0,
    gs: 1,
    w: 0,
    l: 4,
    sv: 0,
    bs: 0,
    h: 5,
    bb: 7,
    so: 0,
    r: 13,
    er: 16,
  },
};

export const mockEfficiency = {
  data: [
    {
      jersey_nr: 12,
      player: 'John Doe',
      ip: 1.0,
      p: 19,
      ts: 24,
      tb: 17,
      pip: 31.500,
      pbf: 2.333,
      3: 15,
      loo: 1,
      '1st2out': 0,
      '123inn': 0,
      13: 0,
    },
  ],
  total: {
    ip: 1.0,
    p: 19,
    ts: 24,
    tb: 17,
    pip: 31.500,
    pbf: 2.333,
    3: 15,
    loo: 1,
    '1st2out': 0,
    '123inn': 0,
    13: 0,
  },
};

export const mockCommand = {
  data: [
    {
      jersey_nr: 12,
      player: 'John Doe',
      ip: 1.1,
      bf: 19,
      fps: 24,
      bbinn: 2.000,
      '0bbinn': 0,
      bbs: 3,
      lobb: 1,
      loo: 1,
      lobbs: 0,
      hbp: 5,
      wp: 0,
    },
  ],
  total: {
    ip: 1.1,
    bf: 19,
    fps: 24,
    bbinn: 2.000,
    '0bbinn': 0,
    bbs: 3,
    lobb: 1,
    loo: 1,
    lobbs: 0,
    hbp: 5,
    wp: 0,
  },
};

export const mockBatterResults = {
  data: [
    {
      jersey_nr: 12,
      player: 'John Doe',
      ip: 1.1,
      bf: 32,
      aba: 14,
      sm: 10,
      kbb: 0.750,
      whb: 5,
      hhb: 6,
      go: 0,
      fo: 0,
      baa: 0.500,
      hr: 3,
      babip: 0.333,
    },
  ],
  total: {
    ip: 1.1,
    bf: 32,
    aba: 14,
    sm: 10,
    kbb: 0.750,
    whb: 5,
    hhb: 6,
    go: 0,
    fo: 0,
    baa: 0.500,
    hr: 3,
    babip: 0.333,
  },
};

export const mockRuns = {
  data: [
    {
      jersey_nr: 12,
      player: 'John Doe',
      ip: 2.0,
      lob: 2,
      bk: 1,
      pik: 0,
      sb: 1,
      cs: 1,
      sbperc: 0.666,
    },
  ],
  total: {
    ip: 2.0,
    lob: 2,
    bk: 1,
    pik: 0,
    sb: 1,
    cs: 1,
    sbperc: 0.666,
  },
};

export const mockSituational = {
  data: [
    {
      jersey_nr: 12,
      player: 'John Doe',
      sw: 22,
      fpso: 4,
      fpsw: 1,
      fpsh: 4,
      gb: 0,
      lnd: 2,
      flb: 5,
    },
  ],
  total: {
    sw: 22,
    fpso: 4,
    fpsw: 1,
    fpsh: 4,
    gb: 0,
    lnd: 2,
    flb: 5,
  },
};
