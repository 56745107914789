import get from 'lodash.get';
import {
  sortSeasons,
} from 'shared/helpers';
import * as actionTypes from './actionTypes';

const sortedSeasons = action => sortSeasons(get(action, 'league.seasons') || []);

const currentSeason = seasons => seasons.find(s => s.is_current);

const setActiveSeasonFromQueryParam = (action) => {
  const seasons = get(action, 'league.seasons') || [];
  const selectedSeason = seasons.find(s => s.id === action.seasonId);

  if (selectedSeason) {
    return selectedSeason;
  }

  return get(sortedSeasons(action), '[0]') || {};
};

const setActiveSeason = action => {
  if (action.seasonId) {
    return setActiveSeasonFromQueryParam(action);
  }
  const current = currentSeason(sortedSeasons(action));

  if (!current) {
    return get(sortedSeasons(action), '[0]') || {};
  }

  return current;
};

const initialState = {
  loading: true,
  details: null,
  seasons: [],
  activeSeason: {},
  teams: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.getLeagueInit:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.getLeagueSuccess:
      return {
        ...state,
        loading: false,
        details: action.league,
        activeDivision: 'all',
        seasons: sortedSeasons(action),
        activeSeason: setActiveSeason(action),
      };
    case actionTypes.getLeagueError:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.refreshLeague:
      return {
        ...state,
        details: action.league,
        seasons: sortedSeasons(action),
        activeSeason: setActiveSeason(action),
      };
    case actionTypes.setActiveDivision:
      return {
        ...state,
        activeDivision: action.activeDivision,
      };
    case actionTypes.getActiveSeason:
      return {
        ...state,
        activeSeason: action.season,
      };
    case actionTypes.getLeagueTeams:
      return {
        ...state,
        teams: action.teams,
      };
    case actionTypes.cleanActiveLeague:
      return {
        ...state,
      };
    case actionTypes.cleanLeague:
      return initialState;
    default:
      return state;
  }
}
