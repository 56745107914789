import React from "react";
import PropTypes from "prop-types";
// import { withRouter } from 'react-router-dom';
import { Button } from "shared/components";
import icon from "./authentication.svg";
import "./styles.scss";
import { connect } from "react-redux";
import { logout, logoutError, logoutSuccess } from "store/user/actions";
import api from "shared/api";

const NoOrganization = ({ message }) => {
  console.log(typeof logout, typeof logoutError, typeof logoutSuccess);

  const handleLogout = () => {
    api.post("/rest-auth/logout/").then((res) => {
      console.log(res);
      location.pathname = "/login/";
    });
  };

  return (
    <div className="NotAuthorized">
      <img src={icon} alt="Not authorized" />
      <p>
        This user does not have organization assigned to it. Please contact your
        organization admin.
      </p>
      <p className="message">{message}</p>
      <Button onClick={handleLogout}>Logout</Button>
    </div>
  );
};

NoOrganization.propTypes = {
  // history: PropTypes.object.isRequired,
  message: PropTypes.string,
};

NoOrganization.defaultProps = {
  message: "",
};

// export default withRouter(NotAuthorized);
export default NoOrganization;
