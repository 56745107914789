import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import {
  Button,
  ContentLoader,
  Checkbox,
  Label,
  InputErrorMessage,
  Modal,
  Notification,
  Selector,
  TextInput,
} from 'shared/components';
import {
  emailRegex,
  userRoleOptions,
} from 'shared/constants';
import { getTeams, addUserToTeams } from './actions';
import { mapTeamsToSelector } from './helpers';
import './styles.scss';

const AddLeagueUser = ({
  activeSeason,
  close,
  isOpen,
}) => {
  const isMounted = useRef(true);
  const seasonID = get(activeSeason, 'id');
  const rolesWithoutFamily = userRoleOptions.filter(ur => ur.value !== 'family');
  const createTeamRow = (id, teams) => ({
    id,
    team: get(teams, '[0]'),
    teamError: '',
    role: get(rolesWithoutFamily, '[0]') || '',
  });

  const [teamsFetching, setTeamsFetching] = useState(true);
  const [leagueTeams, setLeagueTeams] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [selectedTeams, setSelectedTeams] = useState([createTeamRow(Math.random(), [])]);
  const [addToAllTeams, setAddToAllTeams] = useState(false);
  const [allTeamsRole, setAllTeamsRole] = useState(get(rolesWithoutFamily, '[0]') || '');

  const fetchLeagueTeams = () => {
    getTeams(seasonID)
      .then(res => {
        const data = get(res, 'data') || [];
        setLeagueTeams(data);
        setTeamsFetching(false);
      })
      .catch(() => {
        setTeamsFetching(false);
      });
  };

  useEffect(() => {
    fetchLeagueTeams();
    return () => {
      isMounted.current = false;
    };
  }, []);

  const validateEmail = val => emailRegex.test(val);

  const handleEmailValidation = (val) => {
    const isEmailValid = validateEmail(val);
    if (!val) {
      setEmailError('This field is required');
      return false;
    }
    if (!isEmailValid) {
      setEmailError('Enter valid email');
      return false;
    }
    setEmailError('');
    return true;
  };

  const validateTeams = () => {
    if (addToAllTeams) {
      return true;
    }
    let isValid = true;
    const newTeams = selectedTeams.map((st) => {
      if (!get(st, 'team.value')) {
        isValid = false;
        return ({
          ...st,
          teamError: 'This field is required',
        });
      }
      return st;
    });

    setSelectedTeams(newTeams);
    return isValid;
  };

  const handleTeamChange = (team, teamIndex) => {
    const newTeams = selectedTeams.map((t, i) => {
      if (i === teamIndex) {
        return ({
          ...t,
          team,
          teamError: '',
        });
      }
      return t;
    });

    setSelectedTeams(newTeams);
  };

  const handleTeamRoleChange = (role, teamIndex) => {
    const newTeams = selectedTeams.map((t, i) => {
      if (i === teamIndex) {
        return ({
          ...t,
          role,
        });
      }
      return t;
    });

    setSelectedTeams(newTeams);
  };

  const handleSubmit = e => {
    e.preventDefault();
    const areTeamsValid = validateTeams();
    const isEmailValid = handleEmailValidation(email);
    if (!areTeamsValid || !isEmailValid) {
      Notification('error', 'Some fields are not valid', 'Fix all errors before submiting the form');
      return false;
    }

    setLoading(true);
    let data;
    if (addToAllTeams) {
      data = {
        email,
        teams: leagueTeams.map(lt => ({ id: get(lt, 'id'), role: get(allTeamsRole, 'value') })),
      };
    } else {
      data = {
        email,
        teams: selectedTeams.map(st => ({ id: get(st, 'team.value'), role: get(st, 'role.value') })),
      };
    }

    addUserToTeams(data)
      .then(() => {
        Notification('success', 'New user added to the league');
        close();
      })
      .catch(() => {
        setLoading(false);
        Notification('error', 'Error occured', 'There was an error while adding new user to the league');
      });
    return true;
  };

  const handleClose = () => {
    if (
      !dirty ||
      window.confirm('Are you sure you want to discard your changes?')
    ) {
      close();
    }
  };

  const teamOptions = mapTeamsToSelector(leagueTeams, selectedTeams);

  if (teamsFetching) {
    return (
      <Modal
        isOpen={isOpen}
        closeCb={handleClose}
        title="Add league user"
        size="lg"
      >
        <div className="AddLeagueUser">
          <ContentLoader text="" />
        </div>
      </Modal>
    );
  }

  if (addToAllTeams) {
    return (
      <Modal
        isOpen={isOpen}
        closeCb={handleClose}
        title="Add league user"
        size="md"
      >
        <div className="AddLeagueUser">
          <form
            className="AddLeagueUser-form"
            onSubmit={handleSubmit}
          >
            <div className="form-row email-input-wrapper">
              <Label
                inputId="user-email"
                text="Email"
              />
              <TextInput
                id="user-email"
                value={email}
                handleChange={val => {
                  setDirty(true);
                  setEmail(val);
                  handleEmailValidation(val);
                }}
                placeholder="Enter email"
                disabled={loading}
                error={emailError}
                type="email"
              />
              <div className="add-to-all">
                <Checkbox
                  inputId="add-to-all-teams"
                  value={addToAllTeams}
                  handleChange={val => {
                    setDirty(true);
                    setAddToAllTeams(val);
                  }}
                  disabled={loading}
                  label="Add user to all teams in a league"
                />
              </div>
            </div>
            <div className="all-teams-role">
              <div className="form-row">
                <Label inputId="user-role" text="Role" />
                <Selector
                  options={rolesWithoutFamily}
                  id="user-role"
                  value={get(allTeamsRole, 'value')}
                  disabled={loading}
                  handleChange={val => {
                    const selected = rolesWithoutFamily.find(o => o.value === val);
                    setDirty(true);
                    setAllTeamsRole(selected);
                  }}
                />
              </div>
            </div>
            <div className="form-submit">
              <Button
                theme="link"
                type="button"
                disabled={loading}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                theme="success"
                type="submit"
                disabled={loading}
              >
                Add user
              </Button>
            </div>
          </form>
        </div>
      </Modal>
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      closeCb={handleClose}
      title="Add league user"
      size="lg"
    >
      <div className="AddLeagueUser">
        <form
          className="AddLeagueUser-form"
          onSubmit={handleSubmit}
        >
          <div className="form-row email-input-wrapper">
            <Label
              inputId="user-email"
              text="Email"
            />
            <TextInput
              id="user-email"
              value={email}
              handleChange={val => {
                setDirty(true);
                setEmail(val);
                handleEmailValidation(val);
              }}
              placeholder="Enter email"
              disabled={loading}
              error={emailError}
              type="email"
            />
            <div className="add-to-all">
              <Checkbox
                inputId="add-to-all-teams"
                value={addToAllTeams}
                handleChange={val => {
                  setDirty(true);
                  setSelectedTeams([createTeamRow(Math.random(), [])]);
                  setAddToAllTeams(val);
                }}
                disabled={loading}
                label="Add user to all teams in a league"
              />
            </div>
          </div>
          <div className="dynamic-row-wrapper">
            {
              selectedTeams.map((team, teamIndex) => (
                <div key={get(team, 'id')} className="team-rows">
                  <div className="form-row">
                    <Label inputId="team" text="Team" />
                    <Selector
                      options={teamOptions}
                      id="team"
                      value={get(team, 'team.value')}
                      disabled={loading}
                      handleChange={val => {
                        const selected = teamOptions.find(o => o.value === val);
                        setDirty(true);
                        handleTeamChange(selected, teamIndex);
                      }}
                    />
                    <InputErrorMessage text={get(team, 'teamError')} />
                  </div>
                  <div className="form-row">
                    <Label inputId="user-role" text="Role" />
                    <Selector
                      options={rolesWithoutFamily}
                      id="user-role"
                      value={get(team, 'role.value')}
                      disabled={loading}
                      handleChange={val => {
                        const selected = rolesWithoutFamily.find(o => o.value === val);
                        setDirty(true);
                        handleTeamRoleChange(selected, teamIndex);
                      }}
                    />
                  </div>
                  <div className="remove-row">
                    <Button
                      theme="error"
                      size="sm"
                      type="submit"
                      disabled={loading || selectedTeams.length === 1}
                      onClick={() => {
                        const newList = selectedTeams.filter((_, i) => i !== teamIndex);
                        setSelectedTeams(newList);
                      }}
                    >
                      Remove
                    </Button>
                  </div>
                </div>
              ))
            }
            <div className="add-row-btn">
              <Button
                theme="link"
                size="sm"
                disabled={loading || !teamOptions.length}
                onClick={() => {
                  setDirty(true);
                  const newRow = createTeamRow(Math.random(), []);
                  const newList = [...selectedTeams, newRow];
                  setSelectedTeams(newList);
                }}
              >
                Add another row
              </Button>
            </div>
          </div>
          <div className="form-submit">
            <Button
              theme="link"
              type="button"
              disabled={loading}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              theme="success"
              type="submit"
              disabled={loading}
            >
              Add user
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

AddLeagueUser.propTypes = {
  activeSeason: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default AddLeagueUser;
