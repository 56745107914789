/* eslint-disable */
import api from 'shared/api';

export const getTeams = (org) => {
  const url = `/api/v1/teams/?organization=${org}`;
  return api.get(url);
};
export const getSeasons = (org) => {
  const url = `/api/v1/seasons/?organization=${org}`;
  return api.get(url);
};

export const getPlayerStats = (team,season) => {
  const url = `/api/v1/playerstats/?team__id=${team}&season__id=${season}`;
  return api.get(url);
};