import React from 'react';
import PropTypes from 'prop-types';
import {
  PageHeader,
} from 'shared/components';
import './styles.scss';

const Page = ({
  children,
  disabledSeason,
  teamHeader,
}) => (
  <section className="Page">
    {
      teamHeader && <PageHeader disabledSeason={disabledSeason} />
    }
    <main className="Page-content">
      {children}
    </main>
  </section>
);

Page.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array, PropTypes.string]),
  disabledSeason: PropTypes.bool,
  teamHeader: PropTypes.bool,
};

Page.defaultProps = {
  children: null,
  disabledSeason: false,
  teamHeader: false,
};

export default Page;
