/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { withRouter } from 'react-router-dom';
import {
  Table,
  Selector,
} from 'shared/components';
import {
  displayValue,
} from 'shared/helpers';
import { getLeagueTeams, setActiveDivision } from 'store/activeLeague/actions';
import { getEvents, getPlayerStats } from './actions';
import './styles.scss';

const Events = ({ teams, organizationId }) => {
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState(teams);
  const [refetching, setRefetching] = useState(false);


  useEffect(() => {
    getEvents(organizationId).then((res) => {
      setEvents(res.data);
      setLoading(false);
    })
  }, []);


  const [eventFilter, setEventFilter] = useState([{label: 'Graveyard Boys', id: 17}]);
  const [pickedEvent, setPickedEvent] = useState(17);
  const [eventsType, setEventsType] = useState('Tournament');

  const Type = [{label: 'Tournament', value: 'Tournament'},{label: 'Testing', value: 'Testing'}]


  useEffect(() => {
    if (events?.results){
      let newFilter = []
      events?.results.forEach(t => {if(t.type?.toLowerCase() == eventsType?.toLowerCase()) newFilter.push({label: t.name, value: t.id});})
      setEventFilter(newFilter);
      setPickedEvent(null);
    }
  },[events, eventsType])

  const [results, setResults] = useState([]);

 useEffect(() => {
  getPlayerStats(pickedEvent).then(res => { setRefetching(false); setResults(res.data.results);  
  });
 }, [pickedEvent])
 

  return (
    <div className="LeagueTeamsList">
      <div className="LeagueTeamsList-filters">
        <Selector
          options={Type}
          value={eventsType}
          handleChange={val => { setEventsType(val); setRefetching(true);}}
          disabled={loading}
        />
        <Selector
          options={eventFilter}
          value={pickedEvent}
          handleChange={val => { setPickedEvent(val); setRefetching(true);}}
          disabled={loading}
        />
      </div>
      <div className="teams-container">
        <Table
          data={results}
          noDataText=""
          loading={loading || refetching}
          columns={[
            {
              accessor: 'player',
              Header: 'Player',
              width: 250,
            },
            {
              accessor: 'ab',
              Header: 'AB',
              Cell: cellData => displayValue(cellData.value),
            },
            {
              accessor: 'obp',
              Header: 'OBP',
              Cell: cellData => displayValue(cellData.value),
            },
            {
              accessor: 'era',
              Header: 'ERA',
              Cell: cellData => displayValue(cellData.value),
            },
            {
              accessor: 'ba',
              Header: 'BA',
              Cell: cellData => displayValue(cellData.value),
            },
          ]}
          clickable
          handleClick={rowData => console.log(rowData)}
          minRows={1}
          showPagination={false}
        />
      </div>
    </div>
  );
};

Events.propTypes = {
  teams: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  teams: get(state, 'activeLeague.teams'),
  divisions: get(state, 'activeLeague.details.divisions'),
  activeDivision: get(state, 'activeLeague.activeDivision'),
});

const mapDispatchToProps = {
  getLeagueTeamsAction: getLeagueTeams,
  setActiveDivisionAction: setActiveDivision,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Events));
