import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { NavLink, withRouter } from 'react-router-dom';
import {
  Dropdown,
} from 'shared/components';
import './styles.scss';

class LeaguesNavigation extends Component {
  constructor() {
    super();

    this.state = {
      isMenuDisplayed: false,
    };

    this.popupRef = React.createRef();
  }

  handleDropdown = () => {
    const { isMenuDisplayed } = this.state;
    this.setState({
      isMenuDisplayed: !isMenuDisplayed,
    });
  }

  render() {
    const { isMenuDisplayed } = this.state;
    const { userLeagues } = this.props;

    return (
      <div className="LeaguesNavigation" style={{ marginRight: '40px' }}>
        <div className="profile-label">
          <div className="sidebar-item" onClick={this.handleDropdown} role="presentation">
            Your leagues
            <span className="arrow-down">
              {`${'▾'}`}
            </span>
          </div>
        </div>
        {isMenuDisplayed && (
          <Dropdown ref={this.popupRef} close={this.handleDropdown}>
            {
              <div className="LeaguesNavigation-menu">
                {
                  userLeagues.map(l => (
                    <NavLink
                      key={get(l, 'id')}
                      to={`/leagues/${get(l, 'id')}`}
                      onClick={this.handleDropdown}
                    >
                      {`${get(l, 'name')}`}
                    </NavLink>
                  ))
                }
              </div>
            }
          </Dropdown>
        )}
      </div>
    );
  }
}

LeaguesNavigation.propTypes = {
  userLeagues: PropTypes.array,
};

LeaguesNavigation.defaultProps = {
  userLeagues: [],
};

export default withRouter(LeaguesNavigation);
