import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const Button = ({
  children,
  disabled,
  onClick,
  size,
  theme,
  ...rest
}) => (
  <button
    className={`Button Button-size-${size} Button-theme-${theme}`}
    type="button"
    disabled={disabled}
    onClick={onClick}
    {...rest}
  >
    {children}
  </button>
);

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  theme: PropTypes.oneOf(['default', 'warning', 'success', 'error', 'info', 'link']),
};

Button.defaultProps = {
  disabled: false,
  onClick: null,
  size: 'md',
  theme: 'default',
};

export default Button;
