import React from 'react';
import PropTypes from 'prop-types';
import {
  Tab,
  Tabs,
  TabContent,
  TabsHeader,
  Table,
} from 'shared/components';
import {
  displayValue,
} from 'shared/helpers';
import {
  mockStandard,
  mockEfficiency,
  mockCommand,
  mockBatterResults,
  mockRuns,
  mockSituational,
} from './mockData';
import './styles.scss';

const GameStatsPitching = ({
  // eslint-disable-next-line no-unused-vars
  teamStatsId,
}) => (
  <div className="GameStatsPitching">
    <div className="GameStatsPitching-tabs">
      <Tabs>
        <TabsHeader>
          <Tab>Standard</Tab>
          <Tab>Efficiency</Tab>
          <Tab>Command</Tab>
          <Tab>Batter Results</Tab>
          <Tab>Runs and Running Game</Tab>
          <Tab>Situational</Tab>
        </TabsHeader>
        <TabContent>
          <Table
            data={mockStandard.data}
            columns={[
              {
                accessor: 'jersey_nr',
                Header: '#',
                Cell: cellData => displayValue(cellData.value),
                width: 50,
                Footer: '',
              },
              {
                accessor: 'player',
                Header: 'Player',
                Cell: cellData => displayValue(cellData.value),
                maxWidth: 200,
                minWidth: 90,
                Footer: 'Team totals',
                getFooterProps: () => ({
                  className: 'ft-left-pos',
                }),
              },
              {
                accessor: 'ip',
                Header: 'IP',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockStandard.total.ip,
              },
              {
                accessor: 'gs',
                Header: 'GS',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockStandard.total.gs,
              },
              {
                accessor: 'w',
                Header: 'W',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockStandard.total.w,
              },
              {
                accessor: 'l',
                Header: 'L',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockStandard.total.l,
              },
              {
                accessor: 'sv',
                Header: 'SV',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockStandard.total.sv,
              },
              {
                accessor: 'bs',
                Header: 'BS',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockStandard.total.bs,
              },
              {
                accessor: 'h',
                Header: 'H',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockStandard.total.h,
              },
              {
                accessor: 'bb',
                Header: 'BB',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockStandard.total.bb,
              },
              {
                accessor: 'so',
                Header: 'SO',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockStandard.total.so,
              },
              {
                accessor: 'r',
                Header: 'R',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockStandard.total.r,
              },
              {
                accessor: 'er',
                Header: 'ER',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockStandard.total.er,
              },
            ]}
            minRows={1}
            showPagination={false}
          />
        </TabContent>
        <TabContent>
          <Table
            data={mockEfficiency.data}
            columns={[
              {
                accessor: 'jersey_nr',
                Header: '#',
                Cell: cellData => displayValue(cellData.value),
                width: 50,
                Footer: '',
              },
              {
                accessor: 'player',
                Header: 'Player',
                Cell: cellData => displayValue(cellData.value),
                maxWidth: 200,
                minWidth: 90,
                Footer: 'Team totals',
                getFooterProps: () => ({
                  className: 'ft-left-pos',
                }),
              },
              {
                accessor: 'ip',
                Header: 'IP',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockEfficiency.total.ip,
              },
              {
                accessor: 'p',
                Header: '#P',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockEfficiency.total.p,
              },
              {
                accessor: 'ts',
                Header: 'TS',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockEfficiency.total.ts,
              },
              {
                accessor: 'tb',
                Header: 'TB',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockEfficiency.total.tb,
              },
              {
                accessor: 'pip',
                Header: 'P/IP',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockEfficiency.total.pip,
              },
              {
                accessor: 'pbf',
                Header: 'P/BF',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockEfficiency.total.pbf,
              },
              {
                accessor: '3',
                Header: '<3',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockEfficiency.total['3'],
              },
              {
                accessor: 'loo',
                Header: 'LOO',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockEfficiency.total.loo,
              },
              {
                accessor: '1st2out',
                Header: '1ST2OUT',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 50,
                Footer: mockEfficiency.total['1st2out'],
              },
              {
                accessor: '123inn',
                Header: '123INN',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockEfficiency.total['123inn'],
              },
              {
                accessor: '13',
                Header: '<13',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockEfficiency.total['13'],
              },
            ]}
            minRows={1}
            showPagination={false}
          />
        </TabContent>
        <TabContent>
          <Table
            data={mockCommand.data}
            columns={[
              {
                accessor: 'jersey_nr',
                Header: '#',
                Cell: cellData => displayValue(cellData.value),
                width: 50,
                Footer: '',
              },
              {
                accessor: 'player',
                Header: 'Player',
                Cell: cellData => displayValue(cellData.value),
                maxWidth: 200,
                minWidth: 90,
                Footer: 'Team totals',
                getFooterProps: () => ({
                  className: 'ft-left-pos',
                }),
              },
              {
                accessor: 'ip',
                Header: 'IP',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockCommand.total.ip,
              },
              {
                accessor: 'bf',
                Header: 'BF',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockCommand.total.bf,
              },
              {
                accessor: 'fps',
                Header: 'FPS',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockCommand.total.fps,
              },
              {
                accessor: 'bbinn',
                Header: 'BB/INN',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockCommand.total.bbinn,
              },
              {
                accessor: '0bbinn',
                Header: '0BBINN',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockCommand.total['0bbinn'],
              },
              {
                accessor: 'bbs',
                Header: 'BBS',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockCommand.total.bbs,
              },
              {
                accessor: 'lobb',
                Header: 'LOBB',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockCommand.total.lobb,
              },
              {
                accessor: 'lobbs',
                Header: 'LOBBS',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockCommand.total.lobbs,
              },
              {
                accessor: 'hbp',
                Header: 'HBP',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockCommand.total.hbp,
              },
              {
                accessor: 'wp',
                Header: 'WP',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockCommand.total.wp,
              },
            ]}
            minRows={1}
            showPagination={false}
          />
        </TabContent>
        <TabContent>
          <Table
            data={mockBatterResults.data}
            columns={[
              {
                accessor: 'jersey_nr',
                Header: '#',
                Cell: cellData => displayValue(cellData.value),
                width: 50,
                Footer: '',
              },
              {
                accessor: 'player',
                Header: 'Player',
                Cell: cellData => displayValue(cellData.value),
                maxWidth: 200,
                minWidth: 90,
                Footer: 'Team totals',
                getFooterProps: () => ({
                  className: 'ft-left-pos',
                }),
              },
              {
                accessor: 'ip',
                Header: 'IP',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockBatterResults.total.ip,
              },
              {
                accessor: 'bf',
                Header: 'BF',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockBatterResults.total.bf,
              },
              {
                accessor: 'aba',
                Header: 'ABA',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockBatterResults.total.aba,
              },
              {
                accessor: 'sm',
                Header: 'SM',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockBatterResults.total.sm,
              },
              {
                accessor: 'kbb',
                Header: 'K/BB',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockBatterResults.total.kbb,
              },
              {
                accessor: 'whb',
                Header: 'WHB',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockBatterResults.total.whb,
              },
              {
                accessor: 'hhb',
                Header: 'HHB',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockBatterResults.total.hhb,
              },
              {
                accessor: 'go',
                Header: 'GO',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockBatterResults.total.go,
              },
              {
                accessor: 'fo',
                Header: 'FO',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockBatterResults.total.fo,
              },
              {
                accessor: 'baa',
                Header: 'BAA',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockBatterResults.total.baa,
              },
              {
                accessor: 'hr',
                Header: 'HR',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockBatterResults.total.hr,
              },
              {
                accessor: 'babip',
                Header: 'BABIP',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockBatterResults.total.babip,
              },
            ]}
            minRows={1}
            showPagination={false}
          />
        </TabContent>
        <TabContent>
          <Table
            data={mockRuns.data}
            columns={[
              {
                accessor: 'jersey_nr',
                Header: '#',
                Cell: cellData => displayValue(cellData.value),
                width: 50,
                Footer: '',
              },
              {
                accessor: 'player',
                Header: 'Player',
                Cell: cellData => displayValue(cellData.value),
                maxWidth: 200,
                minWidth: 90,
                Footer: 'Team totals',
                getFooterProps: () => ({
                  className: 'ft-left-pos',
                }),
              },
              {
                accessor: 'ip',
                Header: 'IP',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockRuns.total.ip,
              },
              {
                accessor: 'lob',
                Header: 'LOB',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockRuns.total.lob,
              },
              {
                accessor: 'bk',
                Header: 'BK',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockRuns.total.bk,
              },
              {
                accessor: 'pik',
                Header: 'PK',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockRuns.total.pik,
              },
              {
                accessor: 'sb',
                Header: 'SB',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockRuns.total.sb,
              },
              {
                accessor: 'cs',
                Header: 'CS',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockRuns.total.cs,
              },
              {
                accessor: 'sbperc',
                Header: 'SB%',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockRuns.total.sbperc,
              },
            ]}
            minRows={1}
            showPagination={false}
          />
        </TabContent>
        <TabContent>
          <Table
            data={mockSituational.data}
            columns={[
              {
                accessor: 'jersey_nr',
                Header: '#',
                Cell: cellData => displayValue(cellData.value),
                width: 50,
                Footer: '',
              },
              {
                accessor: 'player',
                Header: 'Player',
                Cell: cellData => displayValue(cellData.value),
                maxWidth: 200,
                minWidth: 90,
                Footer: 'Team totals',
                getFooterProps: () => ({
                  className: 'ft-left-pos',
                }),
              },
              {
                accessor: 'sw',
                Header: 'SW',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockSituational.total.sw,
              },
              {
                accessor: 'fpso',
                Header: 'FPSO',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockSituational.total.fpso,
              },
              {
                accessor: 'fpsw',
                Header: 'FPSW',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockSituational.total.fpsw,
              },
              {
                accessor: 'fpsh',
                Header: 'FPSW',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockSituational.total.fpsh,
              },
              {
                accessor: 'gb',
                Header: 'GB',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockSituational.total.gb,
              },
              {
                accessor: 'lnd',
                Header: 'LND',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockSituational.total.lnd,
              },
              {
                accessor: 'flb',
                Header: 'FLB',
                Cell: cellData => displayValue(cellData.value),
                minWidth: 40,
                Footer: mockSituational.total.flb,
              },
            ]}
            minRows={1}
            showPagination={false}
          />
        </TabContent>
      </Tabs>
    </div>
  </div>
);

GameStatsPitching.propTypes = {
  teamStatsId: PropTypes.number.isRequired,
};

export default GameStatsPitching;
