import * as actionTypes from './actionTypes';

const initialState = {
  checkingUser: false,
  registerLoading: false,
  loading: true,
  details: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.loginInit:
      return {
        ...state,
        checkingUser: true,
      };
    case actionTypes.loginSuccess:
      return {
        ...state,
        details: action.user,
        userID: action.user.id,
        checkingUser: false,
      };
    case actionTypes.loginError:
      return {
        ...state,
        error: action.payload,
        checkingUser: false,
      };
    case actionTypes.logoutSuccess:
      return {
        ...state,
        userID: null,
        details: {},
      };
    case actionTypes.logoutError:
      return {
        ...state,
        error: action.payload,
      };
    case actionTypes.registerInit:
      return {
        ...state,
        registerLoading: true,
      };
    case actionTypes.registerSuccess:
      return {
        ...state,
        registerLoading: false,
      };
    case actionTypes.registerError:
      return {
        ...state,
        registerLoading: false,
      };
    case actionTypes.getUserInit:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.getUserSuccess:
      return {
        ...state,
        loading: false,
        userID: action.user.id,
        details: action.user,
      };
    case actionTypes.getUserError:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.refreshUser:
      return {
        ...state,
        details: action.user,
      };
    default:
      return state;
  }
}
